import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import filter from "../../Assets/icons/filter.png";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import "../Products/Product.css";
import {
  getTransferList,
  getSearchTransferList,
} from "../../Redux/actions/transferActions";
import LoadingModal from "../PopupModal/LoadingModal";
import axios from "axios";
import generatePDF from "../../Services/pdfGenerator";
import exportExcel from "../../Services/ExcelGenerator";
import TransferListTableRow from "./reusable/TransferListTableRow";

const TransferList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [exportData, setExportData] = useState(false);

  const { loading, transferList, error } = useSelector(
    (state) => state.transferList
  );

  const { searchLoading, searchTransfer, searchError } = useSelector(
    (state) => state.searchTransferList
  );

  useEffect(() => {
    dispatch(getTransferList(limit, offset));
  }, [dispatch]);
  useEffect(() => {
    if (transferList?.data) {
      setTotalItems(transferList.data.count);
    }
  }, [transferList]);
  //console.log(totalItems);
  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
  };

  const handleSearchResult = (e) => {
    //console.log(e.target.value);
    if (e.target.value.length > 0) {
      setShowSearchResult(true);
      dispatch(getSearchTransferList(e.target.value));
    } else {
      setShowSearchResult(false);
    }
  };

  /** Export All Data */
  const getDataFromApi = (type) => {
    setExportData(true);
    try {
      //const form_data = new FormData();
      //form_data.append("csv_file", filefield);
      //const body = data;
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const api = `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/TransferList/?limit=${totalItems}&offset=0`;

      axios
        .get(api, settings)
        .then((res) => {
          //console.log("success", res.data.results);
          //setAssetList([...assetList, ...res.data.results]);
          if (type === "pdf") {
            setExportData(false);
            generatePDF(
              res.data.results,
              "Transfer",
              [
                "Transfer ID",
                "Transfer Date",
                "From",
                "To",
                "Grand Total",
                "Staff Remarks",
                "Transferred By",
              ],
              "Care-Box Transfer List",
              totalItems
            );
          } else if (type === "excel") {
            const exportData = [];
            res.data.results.map((each) => {
              let obj = {};
              obj["Transfer ID"] = each.id;
              obj["Transfer Date"] = each.date;
              obj["From"] = each.transfer_from.name;
              obj["To"] = each.transfer_to.name;
              obj["Grand Total"] = each.transfer_total;
              obj["Staff Remarks"] = each.staff_remark;
              obj["Transferred By"] = each.issued_by.Name;
              exportData.push(obj);
            });
            exportExcel(exportData, "Care_Box_Transfer_List.xlsx");
            setExportData(false);
          }
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };

  return (
    <div>
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Transfer List</h4>
              <p className="product-paragraph">
                {" "}
                Transfer <FontAwesomeIcon
                  icon={faChevronRight}
                  size="xs"
                />{" "}
                Transfer List
              </p>
            </Col>
            <Col lg={6} className="d-flex justify-content-lg-end">
              <button
                className="pe-3 ps-3 border-0 add-product-button"
                onClick={() => navigate("/addTransfer")}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ paddingRight: "0.2rem" }}
                />{" "}
                Add Transfer
              </button>
            </Col>
          </Row>
          <div
            className="mb-4 p-3 card product-card container-view"
            style={{ minHeight: "50vh" }}
          >
            <Row className="mb-4">
              <Col lg={6}>
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchResult}
                />
              </Col>
              <Col lg={6} className="d-flex justify-content-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as pdf</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getDataFromApi("pdf")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as excel</Tooltip>}
                >
                  <img
                    //className="pe-3"
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getDataFromApi("excel")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            <Table responsive bordered hover>
              <thead className="text-center table-header">
                <tr>
                  {/* <th>
                    <Form.Check type="checkbox" id="checkbox" />
                  </th> */}
                  <th>ID</th>
                  <th>Date</th>
                  <th>From Warehouse/Store Name</th>
                  <th>To Warehouse/Store Name</th>
                  <th>Grand Total</th>
                  <th>Staff Remarks</th>
                  <th>Transferred By</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {showSearchResult &&
                searchTransfer?.data !== undefined &&
                searchTransfer?.data.results.length !== 0 ? (
                  searchTransfer?.data.results.map((transfer) => (
                    <TransferListTableRow
                      key={transfer.id}
                      transfer={transfer}
                    />
                  ))
                ) : !showSearchResult &&
                  transferList?.data !== undefined &&
                  transferList?.data.results.length !== 0 ? (
                  transferList?.data.results.map((transfer) => (
                    <TransferListTableRow
                      key={transfer.id}
                      transfer={transfer}
                    />
                  ))
                ) : loading === true ? (
                  <tr>
                    <td colSpan="12">
                      <Spinner animation="border" variant="warning" />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="12">No Result Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {pageCount > 1 && !showSearchResult ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default TransferList;
