import jsPDF from "jspdf";
import "jspdf-autotable";

const addFooters = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
  }
};

const generatePDF = (data, dataType, columnData, pdfTitle, totalItems) => {
  // initialize jsPDF
  const date = new Date();
  if (data?.length === 0 || !data) {
    alert("No data Found");
  } else if (data?.length !== totalItems) {
    alert("Data is not ready for download . Try Again after a minute");
  } else {
    const doc = new jsPDF();
    // define the columns we want and their titles
    //const tableColumn = ["User ID", "Name", "Contact No", "Role", "Email"];
    // define an empty array of rows
    const tableRows = [];

    // for each ticket pass all its data into an array
    if (dataType === "Category") {
      data.forEach((each) => {
        const rowData = [
          each.id,
          each.name,
          each.location,
          // called date-fns to format the date on the ticket
          //format(new Date(ticket.updated_at), "yyyy-MM-dd"),
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    } else if (dataType === "SubCategory") {
      data.forEach((each) => {
        const rowData = [each.id, each.name, each.catagory_name];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    } else if (dataType === "Product") {
      data
        .sort((a, b) => a.id - b.id)
        .forEach((each) => {
          const rowData = [
            each.id,
            each.product_name,
            each.supplier,
            each.catagory,
            each.purchase_price,
            each.sale_price,
            each.unit_size,
            each.stocks,
            each.sale_vat,
            each.discount,
          ];
          tableRows.push(rowData);
        });
    } else if (dataType === "Supplier") {
      data.forEach((each) => {
        const rowData = [
          each.id,
          each.category_of_Supplier,
          each.supplier_name,
          each.supplier_address,
          each.contact_number,
          each.contact_person,
          each.email_address,
          each.supplier_product_category === "Non-Pharmacy"
            ? "Other"
            : each.supplier_product_category,
          each.status,
          each.created_at,
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    } else if (dataType === "Vendor") {
      data.forEach((each) => {
        const rowData = [
          each.id,
          each.category_of_vendor,
          each.vendor_name,
          each.vendor_address,
          each.contact_number,
          each.contact_Person,
          each.email_address,
          each.status,
          each.created_at,
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    } else if (dataType === "Transfer") {
      data.forEach((each) => {
        const rowData = [
          each.id,
          each.date,
          each.transfer_from.name,
          each.transfer_to.name,
          each.transfer_total,
          each.staff_remark,
          each.issued_by.Name,
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    } else if (dataType === "Destination") {
      data.forEach((each) => {
        const rowData = [
          each.id,
          each.name,
          each.warehouse_status,
          each.contact_number,
          each.email,
          each.city,
          each.address,
          each.active_status,
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    } else if (dataType === "Due Detail") {
      data.forEach((each) => {
        const rowData = [
          each.id,
          each.user.Name,
          each.Phone,
          each.order_type,
          each.paymentMethod,
          each.totalVatAmount,
          each.discountAmount,
          each.totalPrice,
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    } else if (dataType === "Purchase") {
      data.forEach((each) => {
        const rowData = [
          each.id,
          each.vendor_name,
          each.reference,
          each.purchase_date,
          each.total_purchase_value,
          each.additional_commission,
          each.total_vat,
          each.net_purchase_value,
          each.createdby_name[0].created_by__Name,
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    } else if (dataType === "Return") {
      data.forEach((each) => {
        const rowData = [
          each.uuid,
          each.vendor_name,
          each.created_at.split("T")[0],
          each.totalreturnvalue,
          each.netreturnvalue,
          each.createdby_name,
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    } else if (dataType === "Purchase_Request") {
      data.forEach((each) => {
        const rowData = [
          each.id,
          each.total_items,
          each.created_at.split("T")[0],
          each.requested_by.Name,
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    } else if (dataType === "Void") {
      data.forEach((each) => {
        const rowData = [
          each.void_id,
          each.invoice_number,
          each.total_quantity,
          each.vat_amount,
          each.discount_amount,
          each.net_amount,
          each.voided_by,
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    }
    var width = doc.internal.pageSize.getWidth();
    // Pdf title. and margin-top + margin-left
    doc.text(pdfTitle, width / 2, 15, { align: "center" });

    // startY is basically margin-top
    doc.autoTable({
      //styles: { fillColor: [0, 0, 0] },
      startY: 20,
      columns: columnData,
      body: tableRows,
      theme: "plain",
      styles: { halign: "center", lineColor: "DCE0E4", lineWidth: 0.2 },
      headStyles: {
        textColor: "black",
        fillColor: "#fafbfe",
      },
    });
    // ticket title. and margin-top + margin-left
    //doc.text(pdfTitle, width / 2, 15, { align: "center" });
    // we define the name of our PDF file.
    addFooters(doc);
    doc.save(pdfTitle + "_" + date.toLocaleTimeString());
  }
};

export default generatePDF;
