import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Col, Container, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import { getWarehouseDetails } from "../../Redux/actions/warehouseActions";

const EachWarehouse = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const { loading, warehouse, error } = useSelector(
    (state) => state.warehouseDetails
  );
  useEffect(() => {
    dispatch(getWarehouseDetails(param.id));
  }, [dispatch, param.id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //console.log(warehouse);
  return (
    <div>
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <Container fluid>
          <h4 className="product-heading container-view">
            Destination Details
          </h4>
          <p className="product-paragraph container-view">
            Destination <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
            Destination List <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
            Full Details of Destination
          </p>
          <div className="mb-4 p-3 card product-card container-view">
            <Row className="mb-4">
              <Col lg={9} className="mb-2">
                <Table className="mb-0" responsive="xl" bordered hover>
                  <tbody>
                    <tr>
                      <th className="col-4">Destination Id</th>
                      <td>{warehouse.id}</td>
                    </tr>
                    <tr>
                      <th className="col-4">Contact Number</th>
                      <td>{warehouse.contact_number}</td>
                    </tr>
                    <tr>
                      <th className="col-4">Email Address</th>
                      <td>{warehouse.email}</td>
                    </tr>
                    <tr>
                      <th className="col-4">City</th>
                      <td>{warehouse.city}</td>
                    </tr>
                    <tr>
                      <th className="col-4">Country</th>
                      <td>{warehouse.country}</td>
                    </tr>
                    <tr>
                      <th className="col-4">Zip Code</th>
                      <td>{warehouse.zip_code}</td>
                    </tr>
                    <tr>
                      <th className="col-4">Address</th>
                      <td>{warehouse.address}</td>
                    </tr>
                    <tr>
                      <th className="col-4">Description</th>
                      <td>{warehouse.description}</td>
                    </tr>
                    <tr>
                      <th className="col-4">Type</th>
                      <td>{warehouse.warehouse_status}</td>
                    </tr>
                    <tr>
                      <th className="col-4">Status</th>
                      <td>{warehouse.active_status}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col lg={3} className="d-none d-flex justify-content-end">
                <button className="pe-4 ps-4 border-0 print-button">
                  Print{" "}
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{ paddingLeft: "0.5rem" }}
                  />
                </button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default EachWarehouse;
