import { Col } from "react-bootstrap";
import CountUp from "react-countup";

const ProductsCard = ({ title, icon, value = 0 }) => {
  return (
    <Col xl={4}>
      <div className="dashborad-card">
        <div className="d-flex p-3">
          <img className="dashboard-card-image" src={icon} alt={title} />
          <div className="dashboard-card-para">
            <p className="dashboard-card-text">{title}</p>
            <span className="dashboard-card-count">
              <CountUp start={0} end={value} duration={1} separator="," />
            </span>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ProductsCard;
