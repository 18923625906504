import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faAdd } from "@fortawesome/free-solid-svg-icons";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import DeleteModal from "../PopupModal/DeleteModal";
import LoadingModal from "../PopupModal/LoadingModal";
import "./Product.css";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import filter from "../../Assets/icons/filter.png";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import {
  getProductList,
  searchProductList,
  updateProductStatus,
} from "../../Redux/actions/productActions";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import generatePDF from "../../Services/pdfGenerator";
import exportExcel from "../../Services/ExcelGenerator";
import { SuccessAlert } from "../notify/notify";
import ImportFileModal from "../PopupModal/ImportFileModal";
import { setPageNumberAction } from "../../Redux/actions/sidebarActions";
import ProductTableRow from "./reusable/ProductTableRow";

const ProductList = () => {
  const dispatch = useDispatch();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [limit, setLimit] = useState(30);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [exportData, setExportData] = useState(false);
  const [allProductList, setAllProductList] = useState([]);
  const [type, setType] = useState("");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState(false);
  const [searchKeyword, setSearchKeyWord] = useState("");
  const [importProductModal, setImportProductModal] = useState(false);
  const [searchApiCall, setSearchApiCall] = useState(true);

  const [forcePage, setForcePage] = useState(true);
  const paginateRef = useRef(null);

  //console.log("s", queryParameters.get("p"));

  const { loading, productList, error } = useSelector(
    (state) => state.productList
  );

  const { searchLoading, searchProducts, searchError } = useSelector(
    (state) => state.searchProductList
  );

  const { updateloading, message, updateError } = useSelector(
    (state) => state.productStatusUpdate
  );

  const { pageState } = useSelector((state) => state.pageNumberState);

  /** With Page Url */

  // useEffect(() => {
  //   if (paginateRef.current && queryParameters.get("p") && totalItems) {
  //     let selectedPage = parseInt(queryParameters.get("p") - 1);
  //     //setForcePage(queryParameters.get("p"));
  //     //handleOnClick(queryParameters.get("p") - 1);
  //     paginateRef.current.handlePageChange(selectedPage);
  //   }
  // }, [queryParameters, totalItems]);

  /** With Redux State */

  useEffect(() => {
    // implement redux state instead of url params
    if (
      paginateRef.current &&
      pageState.pageNumber &&
      totalItems &&
      forcePage
    ) {
      let selectedPage = parseInt(pageState.pageNumber);
      paginateRef.current.handlePageChange(selectedPage);
    }
  }, [totalItems]);

  useEffect(() => {
    if (!pageState.cacheData) {
      dispatch(getProductList(limit, offset));
    }
  }, [dispatch]);

  useEffect(() => {
    if (productList?.data) {
      setTotalItems(productList.data.count);
    }
  }, [productList]);

  useEffect(() => {
    if (exportData && allProductList.length === totalItems) {
      if (type === "pdf") {
        setExportData(false);
        generatePDF(
          allProductList,
          "Product",
          [
            "ID",
            "Name",
            "Supplier",
            "Category",
            "P.Price",
            "S.Price",
            "Size",
            "Stocks",
            "S.Vat",
            "Disc.",
          ],
          "Care-Box Product List",
          totalItems
        );
      } else if (type === "excel") {
        const exportedData = [];
        allProductList
          .sort((a, b) => a.id - b.id)
          .map((each) => {
            let obj = {};
            obj["Product ID"] = each.id;
            obj["Product Name"] = each.product_name;
            obj["Generic"] = each.generic;
            obj["Supplier"] = each.supplier;
            obj["Category"] = each.catagory;
            obj["Sub Category"] = each.sub_catagory_1;
            obj["Purchase Price"] = each.purchase_price;
            obj["MRP"] = each.sale_price;
            obj["Size & Units"] = each.unit_size;
            obj["Total Stocks"] = each.stocks;
            obj["Warehouse Stocks"] = each.wh_stock;
            obj["Display Stocks"] = each.retail_stock;
            obj["Sale Vat"] = each.sale_vat;
            obj["Discount"] = each.discount;
            obj["Barcode"] = each.barcode;
            exportedData.push(obj);
          });
        exportExcel(exportedData, "Care_Box_Product_List.xlsx");
        setExportData(false);
      }
    }
  }, [exportData, allProductList]);

  useEffect(() => {
    if (alertMessage && message === undefined && updateError !== undefined) {
      console.log(updateError);
      if (updateError.active) {
        alert(updateError.active);
        setAlertMessage(false);
      } else {
        alert("Something Went Wrong");
        setAlertMessage(false);
      }
    }
    if (alertMessage && message !== undefined) {
      SuccessAlert("Product Status Updated Successfully");
      setAlertMessage(false);
      if (showSearchResult) {
        //handleSearchResult(e);
        dispatch(searchProductList(searchKeyword));
      } else {
        dispatch(getProductList(limit, offset));
      }
    }
  }, [alertMessage, message, updateError]);
  //console.log(totalItems);
  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
    dispatch(getProductList(limit, newOffset));
    dispatch(
      setPageNumberAction(event.selected, event.selected ? true : false)
    );
    setForcePage(false);
  };
  //console.log(initialPage);

  useEffect(() => {
    if (searchProducts?.data?.waiting) {
      setSearchApiCall(false);
    } else {
      setSearchApiCall(true);
    }
  }, [searchProducts]);

  useEffect(() => {
    if (searchKeyword.length > 1 && searchApiCall) {
      //console.log("debounce");
      //debounce(dispatch(searchProductList(searchValue)), 5000);
      dispatch(searchProductList(searchKeyword));
    }
  }, [searchKeyword]);

  const handleSearchResult = (e) => {
    //console.log(e.target.value);
    //setSearchKeyWord(e.target.value);
    if (e.target.value.length > 1) {
      setShowSearchResult(true);
      setTimeout(() => {
        setSearchKeyWord(e.target.value);
        // console.log("timeout");
      }, 500);
    } else {
      setShowSearchResult(false);
    }
  };

  const handleDeleteProduct = (item) => {
    setSelectedItem(item);
    setDeleteModalShow(true);
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, parseInt(id)]);
    setSelectedItems([
      ...selectedItems,
      productList?.data.results.find((item) => item.id === parseInt(id)),
    ]);
    //setSelectedItems(array);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
      setSelectedItems(
        selectedItems.filter((item) => item.id !== parseInt(id))
      );
      setIsCheckAll(false);
    }
    //console.log(typeof parseInt(id));
    //console.log(checked);
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    //const allItems = showSearchResult ? searchProducts : productList;
    setSelectedItems(productList?.data.results);
    setIsCheck(productList?.data.results.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
      setSelectedItems([]);
    }
  };

  /** Export All Data */
  const getDataFromApi = (apiLimit, apiOffset) => {
    try {
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const api = `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/list/?limit=${apiLimit}&offset=${apiOffset}`;

      axios
        .get(api, settings)
        .then((res) => {
          setAllProductList((prevData) => [...prevData, ...res.data.results]);
          //console.log(allProductList.length);
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };
  const getAllProducts = () => {
    const getDataLimit = 500;
    if (allProductList.length !== totalItems) {
      let offsetValue = 0;
      const totalCall = Math.ceil(totalItems / getDataLimit);
      for (let i = 0; i < totalCall; i++) {
        //console.log("inside");
        // if (i === totalCall - 1) {
        //   setAllData(true);
        //   console.log("inside");
        // }
        getDataFromApi(getDataLimit, offsetValue);
        //await dispatch(getAllProductList(100, offsetValue));
        //setOffsetCall(100 + offsetCall);
        offsetValue = offsetValue + getDataLimit;
        //console.log(offsetValue);
      }
    }
  };

  const handleProductStatusUpdate = (id, status) => {
    const body = {
      active: status ? 0 : 1,
    };
    dispatch(updateProductStatus(id, body));
    setAlertMessage(true);
  };

  //console.log(allProductList);
  return (
    <div>
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        product={selectedItem}
        action={() => setShowSearchResult(false)}
        limit={limit}
        offset={offset}
        totalItems={totalItems}
      />
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <ImportFileModal
        show={importProductModal}
        onHide={() => setImportProductModal(false)}
        title={`Product List`}
        importType="product"
      />
      <Modal backdrop="static" keyboard={false} show={updateloading}>
        <Modal.Body>
          <Row className="mt-2 d-flex justify-content-center">
            <Spinner animation="border" variant="warning" />
            <p className="mt-3 text-center">
              <b>Please Wait for a moment......</b>
            </p>
          </Row>
        </Modal.Body>
      </Modal>
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Product List</h4>
              <p className="product-paragraph">
                {" "}
                Products <FontAwesomeIcon
                  icon={faChevronRight}
                  size="xs"
                />{" "}
                Product List
              </p>
            </Col>
            <Col lg={6} className="d-flex justify-content-lg-end">
              <button
                className="pe-3 ps-3 border-0 add-product-button"
                onClick={() => setImportProductModal(true)}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ paddingRight: "0.2rem" }}
                />{" "}
                Import Product File
              </button>
            </Col>
          </Row>
          <div
            className="mb-4 p-3 card product-card container-view"
            style={{ minHeight: "50vh" }}
          >
            <Row className="mb-4">
              <Col lg={6} className="mb-2 mb-lg-0">
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchResult}
                />
              </Col>
              <Col lg={6} className="d-flex justify-content-md-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as a Pdf</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => {
                      if (selectedItems.length && !exportData) {
                        generatePDF(
                          selectedItems,
                          "Product",
                          [
                            "ID",
                            "Name",
                            "Supplier",
                            "Category",
                            "P.Price",
                            "S.Price",
                            "Size",
                            "Stocks",
                            "S.Vat",
                            "Disc.",
                          ],
                          "Care-Box Product List (Selected)",
                          selectedItems.length
                        );
                      } else {
                        setExportData(true);
                        getAllProducts();
                        setType("pdf");
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">Export as a Excel</Tooltip>
                  }
                >
                  <img
                    //className="pe-3"
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => {
                      if (selectedItems.length && !exportData) {
                        const exportedData = [];
                        selectedItems
                          .sort((a, b) => a.id - b.id)
                          .map((each) => {
                            let obj = {};
                            obj["Product ID"] = each.id;
                            obj["Product Name"] = each.product_name;
                            obj["Generic"] = each.generic;
                            obj["Supplier"] = each.supplier;
                            obj["Category"] = each.catagory;
                            obj["Sub Category"] = each.sub_catagory_1;
                            obj["Purchase Price"] = each.purchase_price;
                            obj["MRP"] = each.sale_price;
                            obj["Size & Units"] = each.unit_size;
                            obj["Total Stocks"] = each.stocks;
                            obj["Warehouse Stocks"] = each.wh_stock;
                            obj["Display Stocks"] = each.retail_stock;
                            obj["Sale Vat"] = each.sale_vat;
                            obj["Discount"] = each.discount;
                            obj["Barcode"] = each.barcode;
                            exportedData.push(obj);
                          });
                        exportExcel(
                          exportedData,
                          "Care_Box_Selected_Product_List.xlsx"
                        );
                      } else {
                        setExportData(true);
                        getAllProducts();
                        setType("excel");
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            <Table responsive hover>
              <thead className="table-header">
                <tr>
                  <th>
                    <Form.Check
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      onChange={handleSelectAll}
                      checked={isCheckAll}
                    />
                  </th>
                  <th>Id</th>
                  <th>Image</th>
                  <th>P.Name</th>
                  <th>Generic</th>
                  <th>Supplier</th>
                  <th>Sub category</th>
                  <th>P.Price</th>
                  <th>MRP</th>
                  <th>Size & Units</th>
                  <th>Stocks</th>
                  <th>S.Vat</th>
                  <th>Discount</th>
                  <th>Actions</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="">
                {showSearchResult &&
                !searchLoading &&
                searchProducts?.data !== undefined &&
                searchProducts?.data.results &&
                searchProducts?.data.results.length !== 0 ? (
                  searchProducts?.data.results.map((product) => (
                    <ProductTableRow
                      key={product.id}
                      product={product}
                      handleClick={handleClick}
                      isCheck={isCheck}
                      handleProductStatusUpdate={handleProductStatusUpdate}
                    />
                  ))
                ) : productList?.data !== undefined &&
                  productList?.data.results.length !== 0 &&
                  !showSearchResult ? (
                  productList?.data.results.map((product) => (
                    <ProductTableRow
                      key={product.id}
                      product={product}
                      handleClick={handleClick}
                      isCheck={isCheck}
                      handleProductStatusUpdate={handleProductStatusUpdate}
                    />
                  ))
                ) : loading === true ||
                  searchLoading ||
                  searchProducts?.data?.waiting ? (
                  <tr className="text-center">
                    <td colSpan="15">
                      <Spinner animation="border" variant="warning" />
                    </td>
                  </tr>
                ) : (
                  <tr className="text-center">
                    <td colSpan="15">No Result Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {pageCount > 1 && !showSearchResult ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                ref={paginateRef}
                onPageChange={handlePageClick}
                // onClick={(e) => {
                //   handleOnClick(e.nextSelectedPage);
                //   console.log(e);
                // }}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                //initialPage={initialPage}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ProductList;
