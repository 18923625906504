import {
  SUPPLIER_WISE_ALL_STOCK_REQUEST,
  SUPPLIER_WISE_ALL_STOCK_SUCCESS,
  SUPPLIER_WISE_ALL_STOCK_FAILURE,
  SUPPLIER_WISE_FREQUENCY_ANALYSIS_REQUEST,
  SUPPLIER_WISE_FREQUENCY_ANALYSIS_SUCCESS,
  SUPPLIER_WISE_FREQUENCY_ANALYSIS_FAILURE,
  SLOW_MOVING_PRODUCT_REQUEST,
  SLOW_MOVING_PRODUCT_SUCCESS,
  SLOW_MOVING_PRODUCT_FAILURE,
  FAST_MOVING_PRODUCT_REQUEST,
  FAST_MOVING_PRODUCT_SUCCESS,
  FAST_MOVING_PRODUCT_FAILURE,
  NON_MOVING_PRODUCT_REQUEST,
  NON_MOVING_PRODUCT_SUCCESS,
  NON_MOVING_PRODUCT_FAILURE,
  STOCK_RETURN_REPORT_REQUEST,
  STOCK_RETURN_REPORT_SUCCESS,
  STOCK_RETURN_REPORT_FAILURE,
  ZERO_STOCK_REPORT_REQUEST,
  ZERO_STOCK_REPORT_SUCCESS,
  ZERO_STOCK_REPORT_FAILURE,
  GP_COMPARE_REPORT_REQUEST,
  GP_COMPARE_REPORT_SUCCESS,
  GP_COMPARE_REPORT_FAILURE,
  COMPARE_PRODUCT_GP_REQUEST,
  COMPARE_PRODUCT_GP_SUCCESS,
  COMPARE_PRODUCT_GP_FAILURE,
  NEAR_EXPIRY_PRODUCT_REQUEST,
  NEAR_EXPIRY_PRODUCT_SUCCESS,
  NEAR_EXPIRY_PRODUCT_FAILURE,
  EXPIRY_PRODUCT_REQUEST,
  EXPIRY_PRODUCT_SUCCESS,
  EXPIRY_PRODUCT_FAILURE,
} from "../type.js";

export const allStockListReducer = (state = { stockList: {} }, action) => {
  switch (action.type) {
    case SUPPLIER_WISE_ALL_STOCK_REQUEST:
      return { loading: true, ...state };
    case SUPPLIER_WISE_ALL_STOCK_SUCCESS:
      return { loading: false, stockList: action.payload };
    case SUPPLIER_WISE_ALL_STOCK_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const frequencyAnalysisReducer = (
  state = { analysisList: {} },
  action
) => {
  switch (action.type) {
    case SUPPLIER_WISE_FREQUENCY_ANALYSIS_REQUEST:
      return { analysisLoading: true, ...state };
    case SUPPLIER_WISE_FREQUENCY_ANALYSIS_SUCCESS:
      return { analysisLoading: false, analysisList: action.payload };
    case SUPPLIER_WISE_FREQUENCY_ANALYSIS_FAILURE:
      return { analysisLoading: false, analysisError: action.payload };
    default:
      return state;
  }
};

export const slowMovingProductReducer = (
  state = { slowMovingList: {} },
  action
) => {
  switch (action.type) {
    case SLOW_MOVING_PRODUCT_REQUEST:
      return { slowMovingLoading: true, ...state };
    case SLOW_MOVING_PRODUCT_SUCCESS:
      return { slowMovingLoading: false, slowMovingList: action.payload };
    case SLOW_MOVING_PRODUCT_FAILURE:
      return { slowMovingLoading: false, slowMovingError: action.payload };
    default:
      return state;
  }
};

export const fastMovingProductReducer = (
  state = { fastMovingList: {} },
  action
) => {
  switch (action.type) {
    case FAST_MOVING_PRODUCT_REQUEST:
      return { fastMovingList: true, ...state };
    case FAST_MOVING_PRODUCT_SUCCESS:
      return { fastMovingLoading: false, fastMovingList: action.payload };
    case FAST_MOVING_PRODUCT_FAILURE:
      return { fastMovingLoading: false, fastMovingError: action.payload };
    default:
      return state;
  }
};

export const nonMovingProductReducer = (
  state = { nonMovingList: {} },
  action
) => {
  switch (action.type) {
    case NON_MOVING_PRODUCT_REQUEST:
      return { nonMovingLoading: true, ...state };
    case NON_MOVING_PRODUCT_SUCCESS:
      return { nonMovingLoading: false, nonMovingList: action.payload };
    case NON_MOVING_PRODUCT_FAILURE:
      return { nonMovingLoading: false, nonMovingError: action.payload };
    default:
      return state;
  }
};

export const stockReturnReducer = (state = { stockReturnList: {} }, action) => {
  switch (action.type) {
    case STOCK_RETURN_REPORT_REQUEST:
      return { stockReturnLoading: true, ...state };
    case STOCK_RETURN_REPORT_SUCCESS:
      return { stockReturnLoading: false, stockReturnList: action.payload };
    case STOCK_RETURN_REPORT_FAILURE:
      return { stockReturnLoading: false, stockReturnError: action.payload };
    default:
      return state;
  }
};

export const zeroStockReportReducer = (
  state = { zeroStockList: {} },
  action
) => {
  switch (action.type) {
    case ZERO_STOCK_REPORT_REQUEST:
      return { zeroStockLoading: true, ...state };
    case ZERO_STOCK_REPORT_SUCCESS:
      return { zeroStockLoading: false, zeroStockList: action.payload };
    case ZERO_STOCK_REPORT_FAILURE:
      return { zeroStockLoading: false, zeroStockError: action.payload };
    default:
      return state;
  }
};

export const gpCompareReportReducer = (
  state = { gpCompareList: {} },
  action
) => {
  switch (action.type) {
    case GP_COMPARE_REPORT_REQUEST:
      return { gpCompareLoading: true, ...state };
    case GP_COMPARE_REPORT_SUCCESS:
      return { gpCompareLoading: false, gpCompareList: action.payload };
    case GP_COMPARE_REPORT_FAILURE:
      return { gpCompareLoading: false, gpCompareError: action.payload };
    default:
      return state;
  }
};

export const compareProductGpReducer = (
  state = { compareProductList: {} },
  action
) => {
  switch (action.type) {
    case COMPARE_PRODUCT_GP_REQUEST:
      return { compareProductLoading: true, ...state };
    case COMPARE_PRODUCT_GP_SUCCESS:
      return {
        compareProductLoading: false,
        compareProductList: action.payload,
      };
    case COMPARE_PRODUCT_GP_FAILURE:
      return {
        compareProductLoading: false,
        compareProductError: action.payload,
      };
    default:
      return state;
  }
};

export const nearExpiryProductReducer = (
  state = { nearExpiryProductList: {} },
  action
) => {
  switch (action.type) {
    case NEAR_EXPIRY_PRODUCT_REQUEST:
      return { nearExpiryProductLoading: true, ...state };
    case NEAR_EXPIRY_PRODUCT_SUCCESS:
      return {
        nearExpiryProductLoading: false,
        nearExpiryProductList: action.payload,
      };
    case NEAR_EXPIRY_PRODUCT_FAILURE:
      return {
        nearExpiryProductLoading: false,
        nearExpiryProductError: action.payload,
      };
    default:
      return state;
  }
};

export const expiryProductReducer = (
  state = { expiryProductList: {} },
  action
) => {
  switch (action.type) {
    case EXPIRY_PRODUCT_REQUEST:
      return { expiryProductLoading: true, ...state };
    case EXPIRY_PRODUCT_SUCCESS:
      return {
        expiryProductLoading: false,
        expiryProductList: action.payload,
      };
    case EXPIRY_PRODUCT_FAILURE:
      return {
        expiryProductLoading: false,
        expiryProductError: action.payload,
      };
    default:
      return state;
  }
};
