import axios from "axios";
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAILURE,
  EACH_PRODUCT_DETAIL_REQUEST,
  EACH_PRODUCT_DETAIL_SUCCESS,
  EACH_PRODUCT_DETAIL_FAILURE,
  SEARCH_PRODUCT_LIST_REQUEST,
  SEARCH_PRODUCT_LIST_SUCCESS,
  SEARCH_PRODUCT_LIST_FAILURE,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAILURE,
  PRODUCT_STATUS_UPDATE_REQUEST,
  PRODUCT_STATUS_UPDATE_SUCCESS,
  PRODUCT_STATUS_UPDATE_FAILURE,
  SEARCH_PRODUCT_WITH_BARCODE_REQUEST,
  SEARCH_PRODUCT_WITH_BARCODE_SUCCESS,
  SEARCH_PRODUCT_WITH_BARCODE_FAILURE,
} from "../type.js";

export const getProductList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/list/?limit=${limit}&offset=${offset}`,
      config
    );

    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getEachProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: EACH_PRODUCT_DETAIL_REQUEST });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/${id}/`,
      config
    );
    dispatch({
      type: EACH_PRODUCT_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EACH_PRODUCT_DETAIL_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchProductList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_PRODUCT_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const instance = axios.create();

    instance.interceptors.response.use(
        (response) => {
          if(response.data && response.data.waiting && response.data.message === "Indexing in progress"){
            setTimeout(() => {
              dispatch(searchProductList(keyword))
              }, 6000)
          }
          return response;
        },
        (err) => {
          return Promise.reject(err);
        }
    );
    const data = await instance.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/list/?search=${keyword}&limit=100&offset=0`,
      config
    );

    dispatch({
      type: SEARCH_PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_PRODUCT_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteSingleProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/delete/${id}/`,
      config
    );
    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createProduct = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PRODUCT_REQUEST,
    });
    //const body = {};
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/create/`,
      body,
      config
    );

    dispatch({
      type: CREATE_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PRODUCT_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const updateProduct = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/update/${id}/`,
      body,
      config
    );

    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_UPDATE_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const updateProductStatus = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_STATUS_UPDATE_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/ProductActiveInactivePutView/?id=${id}`,
      body,
      config
    );

    dispatch({
      type: PRODUCT_STATUS_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_STATUS_UPDATE_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const productSearchWithBarcode = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_PRODUCT_WITH_BARCODE_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/BarcodeList/${keyword}/`,
      config
    );

    dispatch({
      type: SEARCH_PRODUCT_WITH_BARCODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_PRODUCT_WITH_BARCODE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
