import jsPDF from "jspdf";
import "jspdf-autotable";

const addFooters = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
  }
};

const SingleRequestPurchasePdf = (itemDetails) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });
  const date = new Date();
  const tableRows = itemDetails.product_items.map((item) => [
    item.id,
    item.product_name,
    item.quantity,
    item.unit_or_size,
    item.justification,
  ]);
  const columnData = [
    "SL No.",
    "Product Name",
    "Quantity",
    "Unit of Measurement",
    "Justification",
  ];
  var title = "Care-Box";
  var reportName = "Purchase Request Details";
  var titleWidth = doc.getTextDimensions(title).w;
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  var x = (pageWidth - titleWidth) / 2;
  doc.text(title, x, 14);
  doc.text(
    reportName,
    (pageWidth - doc.getTextDimensions(reportName).w) / 2,
    20
  );
  doc.setFontSize(8);
  doc.text(`PO ID: ${itemDetails.id}`, 16, 24);
  doc.text(
    `Created Date: ${itemDetails.created_at.split("T")[0]}`,
    doc.internal.pageSize.width - 48,
    24
  );
  doc.setFontSize(8);
  doc.text(`Total Items: ${itemDetails.total_items}`, 16, 28);
  doc.text(
    `Requested By: ${itemDetails.requested_by.Name}`,
    doc.internal.pageSize.width - 48,
    28
  );
  doc.autoTable({
    //styles: { fillColor: [0, 0, 0] },
    startY: 40,
    columns: columnData,
    body: tableRows,
    theme: "plain",
    styles: {
      halign: "center",
      lineColor: "DCE0E4",
      lineWidth: 0.2,
    },
    headStyles: {
      textColor: "black",
      fillColor: "#fafbfe",
    },
  });
  addFooters(doc);
  doc.save(
    "Care-Box-Purchase-Request-Details" +
      itemDetails.id +
      "_" +
      date.toLocaleTimeString()
  );
};

export default SingleRequestPurchasePdf;
