import {
  GENERIC_LIST_REQUEST,
  GENERIC_LIST_SUCCESS,
  GENERIC_LIST_FAILURE,
  CREATE_GENERIC_REQUEST,
  CREATE_GENERIC_SUCCESS,
  CREATE_GENERIC_FAILURE,
  SEARCH_GENERIC_LIST_REQUEST,
  SEARCH_GENERIC_LIST_SUCCESS,
  SEARCH_GENERIC_LIST_FAILURE,
} from "../type.js";

export const genericListReducer = (state = { genericList: {} }, action) => {
  switch (action.type) {
    case GENERIC_LIST_REQUEST:
      return { ...state, loading: true };
    case GENERIC_LIST_SUCCESS:
      return { ...state, loading: false, genericList: action.payload };
    case GENERIC_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createGenericReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_GENERIC_REQUEST:
      return { ...state, createLoading: true };
    case CREATE_GENERIC_SUCCESS:
      return {
        ...state,
        createLoading: false,
        message: action.payload,
      };
    case CREATE_GENERIC_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.payload,
      };
    default:
      return state;
  }
};

export const searchGenericReducer = (
  state = { searchGenericList: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_GENERIC_LIST_REQUEST:
      return { ...state, searchLoading: true };
    case SEARCH_GENERIC_LIST_SUCCESS:
      return {
        ...state,
        searchLoading: false,
        searchGenericList: action.payload,
      };
    case SEARCH_GENERIC_LIST_FAILURE:
      return { ...state, searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};
