import React, { useState, useEffect } from "react";
import { Row, Modal, Button } from "react-bootstrap";

const CommentModal = (props) => {
  return (
    <>
      <Modal {...props}>
        <Modal.Header closeButton>
          <Modal.Title>{props.product.product_name}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-4">
          <Row className="d-flex justify-content-center">
            <p>
              <b className="me-2">Product Comment:</b>
              {props.product.productComments !== ""
                ? props.product.productComments
                : "No Comment Available"}
            </p>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CommentModal;
