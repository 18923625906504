import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFile } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { Table, Form } from "react-bootstrap";
import { Fragment } from "react";

const PurchaseListTableRow = ({
  purchase,
  handleClick,
  isCheck,
  selected,
  setCommentModalShow,
  setSelectedItem,
}) => {
  const navigate = useNavigate();
  return (
    <Fragment key={purchase.id}>
      <tr className="accordion accordion-flush" id="accordionFlushExample">
        <td>
          <Form.Check
            key={purchase.id}
            type="checkbox"
            id={purchase.id}
            onChange={handleClick}
            checked={isCheck.includes(purchase.id)}
          />
        </td>
        <td>{purchase.purchase_id ? purchase.purchase_id : purchase.id}</td>
        <td>{purchase.vendor_name ? purchase.vendor_name : "N/A"}</td>
        <td>{purchase.reference ? purchase.reference : "N/A"}</td>
        <td>{purchase.purchase_date ? purchase.purchase_date : "N/A"}</td>
        <td>{purchase.total_purchase_value}</td>
        <td>{purchase.additional_commission}</td>
        <td>{purchase.net_purchase_value}</td>
        <td>{purchase.total_vat}</td>
        <td>{purchase.createdby_name[0]?.created_by__Name}</td>
        <td style={{ cursor: "pointer" }}>
          <FontAwesomeIcon
            icon={faEye}
            color="#637381"
            className="collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
            // onClick={() =>
            //   setSelected(
            //     selected !== purchase.id ? purchase.id : null
            //   )
            // }
            onClick={() => navigate(`/purchase/${purchase.id}`)}
          />
          <a
            href={purchase.document}
            attributes-list="true"
            download="optional-value"
            target="_blank"
          >
            <FontAwesomeIcon
              className="ps-1"
              icon={faFile}
              color="#637381"
            ></FontAwesomeIcon>
          </a>
        </td>
      </tr>
      <tr
        id={selected === purchase.id ? "flush-collapseOne" : "collapse"}
        className={
          selected === purchase.id
            ? "accordion-collapse collapse show"
            : "accordion-collapse collapse"
        }
        aria-labelledby="flush-headingOne"
        data-bs-parent="#accordionFlushExample"
      >
        <td colSpan={12}>
          <Table className="inside-table mt-2" responsive>
            <thead className="text-center ">
              <tr>
                <th>Id</th>
                <th>Product Name</th>
                <th>Unit</th>
                <th>Qty</th>
                <th>P.Price</th>
                <th>Vat%</th>
                <th>New P.Price</th>
                <th>MRP</th>
                <th>New MRP</th>
                <th>GP%</th>
                <th>SubTotal</th>
                <th>Barcode</th>
                <th>Batch No.</th>
                <th>Supplier Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {purchase.purchase.map((item) => (
                <tr key={item.id}>
                  <td>{item.product}</td>
                  <td>{item.product_name}</td>
                  <td>{item.product__unit}</td>
                  <td>{item.quantity_amount}</td>
                  <td>{item.purchase_price}</td>
                  <td>{item.purchase_vat}%</td>
                  <td>{item.new_purchase_price}</td>
                  <td>{item.sales_price}</td>
                  <td>{item.new_sales_price}</td>
                  <td>{item.gross_profit}</td>
                  <td>{!item.subtotal ? "N/A" : item.subtotal}</td>
                  <td>
                    {!item.product__barcode ? "N/A" : item.product__barcode}
                  </td>
                  <td>{item.batch_no}</td>
                  <td>{item.supplier_name}</td>
                  <td style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon
                      icon={faComments}
                      color="#833586"
                      onClick={() => {
                        setCommentModalShow(true);
                        setSelectedItem(item);
                      }}
                    />
                    {/* <FontAwesomeIcon
                            icon={faTrashCan}
                            color="#E40000"
                            style={{ paddingLeft: "0.4rem" }}
                            onClick={() => {
                                setDeleteModalShow(true);
                                setSelectedItem(item);
                            }}
                        /> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </td>
      </tr>
    </Fragment>
  );
};

export default PurchaseListTableRow;
