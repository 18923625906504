import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useLocalStorage from "../../hooks/useLocalStorage";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../Products/Product.css";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faTrash,
  faBarcode,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { createPurchase } from "../../Redux/actions/purchaseActions";
import {
  searchProductList,
  productSearchWithBarcode,
} from "../../Redux/actions/productActions";
import { getVendorList } from "../../Redux/actions/vendorActions";
import { SuccessAlert, ErrorAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";
import uploadIcon from "../../Assets//icons/upload-image.png";
import "../Return/Return.css";
import AddPurchaseModal from "../PopupModal/AddPurchaseModal/AddPurchaseModal";

const AddPurchase = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const inputRef = useRef(null);
  const expireRef = useRef(null);
  const purchaseRef = useRef(null);
  const [commission, setCommission] = useState(0);
  const [selectProduct, setSelectProduct] = useState({});
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [reference, setReference] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [batchNo, setBatchNo] = useState("");
  const [qty, setQty] = useState(0);
  const [qtyType, setQtyType] = useState("");
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [purchaseVat, setPurchaseVat] = useState(0);
  const [newPurchasePrice, setNewPurchasePrice] = useState(0);
  const [salesPrice, setSalesPrice] = useState(0);
  const [salesVat, setSalesVat] = useState(0);
  const [newSalesPrice, setNewSalesPrice] = useState(0);
  const [comments, setComments] = useState("");
  const [purchaseDocument, setPurchaseDocument] = useState(null);
  const [productList, setProductList] = useLocalStorage("purchaseList", []);
  const [barcode, setBarcode] = useState("");
  const [searchBarcode, setSearchBarcode] = useState(false);
  const [totalPurchasePrice, setTotalPurchasePrice] = useState(0);
  const [bonusQty, setBonusQty] = useState(0);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [searchApiCall, setSearchApiCall] = useState(true);

  const { searchLoading, searchProducts } = useSelector(
    (state) => state.searchProductList
  );
  const { vendorList } = useSelector((state) => state.vendorList);

  const { loading, message, error } = useSelector(
    (state) => state.createPurchase
  );

  const { searchBarcodeLoading, searchBarcodeProduct, searchBarcodeError } =
    useSelector((state) => state.searchProductBarcode);
  useEffect(() => {
    dispatch(getVendorList(500, 0));
  }, []);
  // Get the current date
  const currentDate = new Date();
  // Format the current date as YYYY-MM-DD
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  useEffect(() => {
    //console.log(selectProduct);
    if (selectProduct.id !== undefined) {
      setQtyType(
        selectProduct.stock_type !== null ? selectProduct.stock_type : ""
      );
      setPurchasePrice(
        selectProduct.purchase_price !== null ? selectProduct.purchase_price : 0
      );
      setSalesPrice(
        selectProduct.sale_price !== null ? selectProduct.sale_price : 0
      );
      setSalesVat(selectProduct.sale_vat !== null ? selectProduct.sale_vat : 0);
      setNewPurchasePrice(
        selectProduct.new_purchase_price !== null
          ? selectProduct.new_purchase_price
          : 0
      );
      setNewSalesPrice(
        selectProduct.new_sale_price !== null ? selectProduct.new_sale_price : 0
      );
      setPurchaseVat(
        selectProduct.purchase_vat !== null ? selectProduct.purchase_vat : 0
      );
    }
  }, [selectProduct]);

  useEffect(() => {
    if (totalPurchasePrice.length > 1 && qty) {
      if (selectProduct.purchase_price) {
        setNewPurchasePrice((totalPurchasePrice / parseInt(qty)).toFixed(4));
      } else {
        setPurchasePrice((totalPurchasePrice / parseInt(qty)).toFixed(4));
      }
    }
  }, [totalPurchasePrice, qty, selectProduct]);

  useEffect(() => {
    if (alertMessage && message === undefined && error !== undefined) {
      setAlertMessage(false);
      if (error.message) {
        alert(error.message);
      } else if (error.error) {
        alert(error.error);
      } else {
        alert("Something Went Wrong");
      }
    }
    if (alertMessage && message !== undefined) {
      //console.log(message);
      setProductList([]);
      setVendorId("");
      setPurchaseDate(formattedDate);
      setReference("");
      setPurchaseDocument(null);
      alert(
        `New Purchase Created Successfully. Purchase Id: ${message.pop_id}`
      );
      SuccessAlert(
        `New Purchase Created Successfully. Purchase Id: ${message.pop_id}`
      );
      setAlertMessage(false);
    }
  }, [alertMessage, message, error]);
  useEffect(() => {
    //console.log(searchBarcodeLoading);
    if (searchBarcode) {
      //console.log(searchBarcodeError);
      if (searchBarcodeProduct && searchBarcodeProduct.data !== undefined) {
        //console.log("success");
        setSelectProduct(searchBarcodeProduct.data.result);
        setSearchBarcode(false);
        setBarcode("");
      }
      if (searchBarcodeError !== undefined) {
        //console.log("error");
        alert(searchBarcodeError);
        setSelectProduct({});
        setSearchBarcode(false);
        setBarcode("");
        //ErrorAlert(searchBarcodeError);
      }
    }
  }, [searchBarcodeProduct, searchBarcodeError, searchBarcode]);

  useEffect(() => {
    // Set the current date as the default value
    setPurchaseDate(formattedDate);
  }, []);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (searchProducts?.data?.waiting) {
      setSearchApiCall(false);
    } else {
      setSearchApiCall(true);
    }
  }, [searchProducts]);

  const handleSearchResult = (e) => {
    //console.log(e.target.value);
    if (e.target.value.length > 1) {
      //console.log("inside");
      setShowSearchResult(true);
      //dispatch(searchProductList(e.target.value));
      setTimeout(() => {
        setSearchValue(e.target.value);
        // console.log("timeout");
      }, 700);
      //debounce(dispatch(searchProductList(e.target.value)), 500);
    } else {
      setShowSearchResult(false);
    }
  };
  useEffect(() => {
    if (searchValue.length > 1 && searchApiCall) {
      //console.log("debounce");
      //debounce(dispatch(searchProductList(searchValue)), 5000);
      dispatch(searchProductList(searchValue));
    }
  }, [searchValue]);
  //const debounceSearch = debounce()
  const productListEachId = productList.map((each) => each.product_id);
  //console.log(qtyType);

  // by hasan
  const productDuplicationHandler = (matchingProductArray, batchNo) => {
    // console.log("matchingProductArray = ", matchingProductArray);
    let result = false;
    matchingProductArray.map((eachProduct) => {
      // console.log("eachProduct.batch_no ", eachProduct.batch_no);
      // console.log("batchNo ", batchNo);
      if (eachProduct.batch_no.trim() == batchNo.trim()) {
        result = true;
      }
    });

    return result;
  };
  // end of by hasan

  const clickAddProduct = () => {
    // console.log("selectProduct = ", selectProduct);
    let matchingProductArray;

    if (productListEachId.includes(selectProduct.id)) {
      matchingProductArray = productList.filter(
        (each) => each.product_id === selectProduct.id
      );
      // console.log("matchingProductArray  = ", matchingProductArray);
    }

    if (!selectProduct.id) {
      alert("Please Select a Product");
    } else if (!selectProduct.supplier) {
      alert("Product must have a supplier");
    } else if (expireDate === "") {
      alert("Expire Date can not be blank");
    } else if (batchNo === "") {
      alert("Batch No. can not be blank");
    } else if (
      matchingProductArray &&
      batchNo &&
      productDuplicationHandler(matchingProductArray, batchNo)
    ) {
      alert("Product with same Batch No already exist in the list");
    } else if (qty == 0) {
      alert("Product Quantity can not be zero");
    } else if (qty && !/^\d+$/.test(qty)) {
      alert("Product Quantity must be integer value");
    } else if (bonusQty && !/^\d+$/.test(bonusQty)) {
      alert("Product Bonus Quantity must be integer value");
    } else if (purchasePrice == 0 || purchasePrice === "") {
      alert("Purchase Price can not be zero or blank");
    } else if (
      salesPrice == 0 ||
      parseFloat(salesPrice) < parseFloat(purchasePrice)
    ) {
      //console.log(salesPrice < purchasePrice);
      alert("MRP can not be zero or less than purchase price");
    } else if (
      newPurchasePrice !== 0 &&
      newPurchasePrice !== "" &&
      (newSalesPrice == 0 || newSalesPrice == "")
    ) {
      alert("New MRP Can not be zero or blank");
    } else if (
      newPurchasePrice &&
      parseFloat(newSalesPrice) < parseFloat(newPurchasePrice)
    ) {
      alert("New MRP Can not be less than New Purchase price");
    } else {
      setProductList([
        ...productList,
        {
          product_id: selectProduct.id,
          name:
            selectProduct.product_name +
            " " +
            selectProduct.generic +
            " " +
            selectProduct.sub_catagory_1,
          units: selectProduct.unit_size,
          supplierName: selectProduct.supplier,
          barcode: selectProduct.barcode,
          supplier_id: selectProduct.supplier_id,
          quantity: qtyType,
          quantity_amount: qty,
          bonus_quantity: bonusQty ? bonusQty : 0,
          purchase_price: purchasePrice,
          purchase_vat: purchaseVat !== "" ? purchaseVat : 0,
          new_purchase_price: newPurchasePrice !== "" ? newPurchasePrice : 0,
          sales_price: salesPrice,
          sale_vat: salesVat !== "" ? salesVat : 0,
          new_sales_price: newSalesPrice !== "" ? newSalesPrice : 0,
          batch_no: batchNo,
          expire_date: expireDate,
          reference: reference,
          productComments: comments,
          gross_profit:
            newPurchasePrice !== 0 &&
            newPurchasePrice !== "" &&
            newSalesPrice !== 0 &&
            newSalesPrice !== ""
              ? (
                  ((newSalesPrice - newPurchasePrice) / newPurchasePrice) *
                  100
                ).toFixed(2)
              : (((salesPrice - purchasePrice) / purchasePrice) * 100).toFixed(
                  2
                ),
          subtotal:
            newPurchasePrice !== 0 && newPurchasePrice !== ""
              ? (parseFloat(newPurchasePrice) * (purchaseVat / 100) +
                  parseFloat(newPurchasePrice)) *
                qty
              : (parseFloat(purchasePrice) +
                  parseFloat(purchasePrice) * (purchaseVat / 100)) *
                qty,
        },
      ]);
      setSelectProduct({});
      setQtyType("");
      setQty(0);
      setPurchasePrice(0);
      setPurchaseVat(0);
      setNewPurchasePrice(0);
      setSalesPrice(0);
      setSalesVat(0);
      setNewSalesPrice(0);
      setBatchNo("");
      setExpireDate("");
      setComments("");
      setTotalPurchasePrice("");
      setSearchValue("");
      inputRef.current.value = "";
      setBonusQty(0);
    }
  };

  const removeProduct = (productInfo) => {
    // console.log("productInfo = ", productInfo);
    // console.log("productList ", productList);
    let result = productList.filter((product) => {
      // console.log("product.product_id = ", product.product_id);
      if (
        product.product_id === productInfo.product_id &&
        product.batch_no === productInfo.batch_no
      ) {
        // console.log("match");
        return false;
      }

      return true;
    });

    // console.log("result = ", result);
    setProductList(result);

    alert(`Removed Product Successfully`);
  };
  //console.log(newPurchasePrice);
  const totalPurchaseValue = productList.reduce(
    (initialValue, product) =>
      initialValue +
      (product.new_purchase_price != 0 && product.new_purchase_price !== ""
        ? product.new_purchase_price
        : product.purchase_price) *
        product.quantity_amount,
    0
  );
  const totalVat = productList.reduce(
    (initialValue, product) =>
      initialValue +
      (product.new_purchase_price != 0 && product.new_purchase_price !== ""
        ? product.new_purchase_price
        : product.purchase_price) *
        (product.purchase_vat / 100) *
        product.quantity_amount,
    0
  );
  //console.log(productList);
  const handleSubmitPurchase = (e) => {
    e.preventDefault();
    if (
      productList.length !== 0 &&
      vendorId.length &&
      purchaseDate.length &&
      purchaseDocument
    ) {
      const body = {
        Purchaseitems: productList,
        vendor_id: vendorId,
        total_purchase_value: totalPurchaseValue,
        additional_commission: commission,
        total_vat: totalVat.toFixed(2),
        net_purchase_value: totalPurchaseValue + totalVat - commission,
        purchase_date: purchaseDate,
        reference: reference,
        document: purchaseDocument,
      };
      let form_data = new FormData();
      form_data.append("Purchaseitems", localStorage.getItem("purchaseList"));
      form_data.append("vendor_id", vendorId);
      form_data.append("total_purchase_value", totalPurchaseValue);
      form_data.append("additional_commission", commission);
      form_data.append("total_vat", totalVat.toFixed(2));
      form_data.append(
        "net_purchase_value",
        totalPurchaseValue + totalVat - commission
      );
      form_data.append("purchase_date", purchaseDate);
      form_data.append("reference", reference);
      form_data.append("document", purchaseDocument);
      dispatch(createPurchase(form_data));
      setAlertMessage(true);
      setCommission(0);
    } else if (productList.length === 0) {
      alert("Select Product to Add Purchase/Challan");
    } else if (vendorId.length === 0) {
      alert("Select Vendor");
    } else if (purchaseDate.length === 0) {
      alert("Select Purchase Date");
    } else if (!purchaseDocument) {
      alert("Add Purchase Document");
    }
  };
  const handleFileUpload = (e) => {
    setPurchaseDocument(e.target.files[0]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (barcode) {
      dispatch(productSearchWithBarcode(barcode));
      ref.current.blur();
      setTimeout(() => {
        setSearchBarcode(true);
        ref.current.value = "";
        setBarcode("");
        //ref.current.focus();
      }, 500);
    }
  };
  // My Custom Code (Hasan)
  const [suggestionSelect, setSuggestionSelect] = useState(null);
  const containerRef = useRef(null);
  const [childOffsets, setChildOffsets] = useState([]);

  useEffect(() => {
    setSuggestionSelect(null);
  }, [searchProducts]);

  useEffect(() => {
    if (
      showSearchResult &&
      searchProducts?.data !== undefined &&
      searchProducts?.data.results &&
      searchProducts?.data.results.length !== 0
    ) {
      //console.log("containerRef = ", containerRef.current);
      const childDivs = Array.from(containerRef.current.children);
      // console.log('childDivs = ', childDivs);
      const offsets = childDivs.map((child) => child.offsetTop);
      // console.log('offsets', offsets);
      setChildOffsets(offsets);
    }
  }, [searchProducts, showSearchResult]);

  const searchInputSuggestionHendler = (e) => {
    if (
      showSearchResult &&
      searchProducts?.data !== undefined &&
      searchProducts?.data.results?.length !== 0
    ) {
      if (e.key === "ArrowUp") {
        // console.log("In searchInputSuggestionHndler, you pressed = ", e.key);
        // console.log("searchProducts = ",searchProducts.data.results);
        if (suggestionSelect > 0) {
          setSuggestionSelect((previousState) => previousState - 1);
          // console.log("searchProducts = ",searchProducts.data.results);

          containerRef.current.scrollTo({
            top: childOffsets[suggestionSelect - 1],
            behavior: "smooth",
          });
        }
      }
      if (e.key === "ArrowDown") {
        if (suggestionSelect || suggestionSelect === 0) {
          if (searchProducts.data?.results?.length > suggestionSelect + 1) {
            setSuggestionSelect((previousState) => previousState + 1);
            // console.log("searchProducts = ",searchProducts.data.results);
            containerRef.current.scrollTo({
              top: childOffsets[suggestionSelect],
              behavior: "smooth",
            });
          }
        } else {
          setSuggestionSelect(0);
          // console.log("searchProducts = ",searchProducts.data.results);
        }
      }
      if (e.key === "Enter") {
        if (suggestionSelect || suggestionSelect === 0) {
          // console.log("Dispatch your action");
          setSelectProduct(searchProducts.data.results[suggestionSelect]);
          setShowSearchResult(false);
          setSuggestionSelect(null);
          expireRef.current.focus();
        }
      }
    }
  };

  //End of My Custom Code (Hasan)
  //
  //console.log(productList);
  // console.log(parseInt(commission).toFixed(2));
  const listWithIndices = productList.map((value, index) => ({ value, index }));
  //listWithIndices.sort((a, b) => b.index - a.index);

  return (
    <div>
      <AddPurchaseModal
        show={previewOpen}
        onHide={() => setPreviewOpen(false)}
        productList={productList}
        listWithIndices={listWithIndices}
        vendorId={vendorId}
        purchaseDate={purchaseDate}
        totalPurchaseValue={totalPurchaseValue.toFixed(2)}
        commission={commission}
        totalVat={totalVat.toFixed(2)}
        netPurchase={(totalPurchaseValue + totalVat - commission).toFixed(2)}
      />
      <SideBar />
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <h4 className="product-heading container-view">
            Product Receiving (GRN)
          </h4>
          <p className="product-paragraph container-view">
            Purchase <FontAwesomeIcon icon={faChevronRight} size="xs" /> Product
            Receiving (GRN)
          </p>
          {/* <p className="text-center">
            {selectProduct.product_name !== undefined
              ? `Selected Product Name: ${selectProduct.product_name} ${selectProduct.generic} (${selectProduct.unit_size})`
              : ""}
          </p> */}
          <div className="mb-4 card product-card container-view">
            <div style={{ paddingLeft: "12px", paddingRight: "12px" }}>
              <Row className="pt-3 px-2" style={{ backgroundColor: "#DCE0E4" }}>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Vendor Name<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => {
                        setVendorId(e.target.value);
                        purchaseRef.current.focus();
                      }}
                      value={vendorId}
                      required
                    >
                      <option value="">Choose Vendor Name</option>
                      {vendorList.data?.results.map((vendor) => (
                        <option key={vendor.id} value={vendor.id}>
                          {vendor.vendor_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Vendor Id<span></span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Vendor Id
                      "
                      value={vendorId}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Purchase Date<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Purchase Date"
                      ref={purchaseRef}
                      value={purchaseDate}
                      onChange={(e) => setPurchaseDate(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Reference/PO Id </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="N/A"
                      value={reference}
                      onChange={(e) => setReference(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="p-4">
              <Row>
                <Col lg={12} style={{ position: "relative" }}>
                  <Form.Group
                    className=" form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="search"
                      ref={inputRef}
                      placeholder="Search By Product Name or Id or Barcode"
                      onChange={handleSearchResult}
                      autoComplete="off"
                      onKeyDown={(e) => {
                        //console.log(e);
                        searchInputSuggestionHendler(e);
                      }}
                    />
                    {showSearchResult ? (
                      !searchLoading &&
                      searchProducts?.data !== undefined &&
                      searchProducts?.data.results?.length ? (
                        <div
                          className="autocomplete_items shadow-lg"
                          //onScroll={DivScroll}
                          ref={containerRef}
                          style={{ zIndex: "2" }}
                        >
                          {searchProducts?.data.results.map((product, i) => (
                            <div
                              //className="card shadow m-2 rounded border border-0 p-2"
                              className={`card shadow m-2 rounded border border-0 p-2 ${
                                suggestionSelect !== null &&
                                suggestionSelect === i
                                  ? "selectedSearchItemHover"
                                  : ""
                              }`}
                              key={i}
                              onClick={() => {
                                setSelectProduct(product);
                                setShowSearchResult(false);
                                expireRef.current.focus();
                              }}
                            >
                              <div>
                                <div className="d-flex align-content-center">
                                  <div className="">
                                    {product.product_name} - {product.generic} (
                                    {product.unit_size}) - (
                                    {product.sub_catagory_1})
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : !searchLoading &&
                        searchProducts?.data !== undefined &&
                        searchProducts?.data.results?.length === 0 ? (
                        <div
                          className="autocomplete_items shadow-lg"
                          //onScroll={DivScroll}
                          ref={containerRef}
                          style={{ zIndex: "2" }}
                        >
                          <div className="m-2 p-2">
                            <div>
                              <div className="d-flex align-content-center justify-content-center">
                                <div>No Product Found</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="autocomplete_items shadow-lg"
                          //onScroll={DivScroll}
                          ref={containerRef}
                          style={{ zIndex: "2" }}
                        >
                          <div className="m-2 p-2 ">
                            <div>
                              <div className="d-flex align-content-center justify-content-center">
                                <div>
                                  <Spinner
                                    animation="border"
                                    variant="warning"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Barcode</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="Product Barcode"
                        //placeholder="Search Product With Barcode"
                        style={{ borderRight: "0px" }}
                        //value={selectProduct.barcode}
                        value={
                          selectProduct.barcode !== null &&
                          selectProduct.barcode !== undefined
                            ? selectProduct.barcode
                            : ""
                        }
                        name="barcode"
                        //ref={ref}
                        //value={barcode}
                        // onChange={(e) => {
                        //   setBarcode(e.target.value);
                        // }}
                        //onChange={(e) => setBarcode(e.target.value)}
                        // onSubmit={(e) => {
                        //   console.log("inside");
                        //   e.preventDefault();
                        //   dispatch(productSearchWithBarcode(barcode));
                        // }}
                        disabled
                      />
                      <InputGroup.Text style={{ background: "white" }}>
                        <FontAwesomeIcon icon={faBarcode} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Product Name<span></span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Product Name"
                      value={
                        selectProduct.product_name !== undefined
                          ? selectProduct.product_name
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Size & Units<span></span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Product Size & Units"
                      value={
                        selectProduct.unit_size !== undefined
                          ? selectProduct.unit_size
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Generic Name<span></span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Generic Name"
                      value={
                        selectProduct.generic !== undefined
                          ? selectProduct.generic
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Category Name<span></span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Product Category"
                      value={
                        selectProduct.catagory !== undefined
                          ? selectProduct.catagory
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Enter Date of Expiry
                      <span className="required-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="date"
                      value={expireDate}
                      ref={expireRef}
                      onChange={(e) => setExpireDate(e.target.value)}
                      min={purchaseDate}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Batch No.<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Batch No."
                      value={batchNo}
                      onChange={(e) => setBatchNo(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {/* <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Supplier Id<span></span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Supplier Id"
                      value={
                        selectProduct.supplier_id !== null &&
                        selectProduct.supplier_id !== undefined
                          ? selectProduct.supplier_id
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col> */}
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Supplier Name<span></span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Supplier Name"
                      value={
                        selectProduct.supplier !== null &&
                        selectProduct.supplier !== undefined
                          ? selectProduct.supplier
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Row>
                    <Col lg={6}>
                      <Form.Group
                        className="form-group mb-3"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Qty<span className="required-field">*</span>
                        </Form.Label>
                        <Row>
                          {/* <Col xxl={2} xl={3} lg={4}>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label="Box"
                          value="Box"
                          onChange={(e) => setQtyType(e.target.value)}
                          checked={qtyType === "Box" ? true : false}
                          disabled={!selectProduct.stock_type ? false : true}
                        />
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label="Pcs"
                          value="Pcs"
                          onChange={(e) => setQtyType(e.target.value)}
                          checked={qtyType === "Pcs" ? true : false}
                          disabled={!selectProduct.stock_type ? false : true}
                        />
                      </Col> */}
                          <Col lg={12}>
                            <Form.Control
                              type="number"
                              placeholder="Quantity"
                              value={qty}
                              onChange={(e) => setQty(e.target.value)}
                              min={0}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group
                        className="form-group mb-3"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Bonus Qty<span></span>
                        </Form.Label>
                        <Row>
                          <Col lg={12}>
                            <Form.Control
                              type="number"
                              placeholder="bonus"
                              value={bonusQty}
                              onChange={(e) => setBonusQty(e.target.value)}
                              min={0}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Total T.P<span></span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Total Purchase Price"
                      value={totalPurchasePrice}
                      onChange={(e) => {
                        setTotalPurchasePrice(e.target.value);
                      }}
                      //disabled={selectProduct.purchase_price ? true : false}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Purchase Price<span className="required-field">*</span>
                      {selectProduct.purchase_price && purchasePrice === "" ? (
                        <FontAwesomeIcon
                          icon={faRotateRight}
                          color="#ff9900"
                          style={{ paddingLeft: "0.4rem", cursor: "pointer" }}
                          onClick={() => {
                            setPurchasePrice(selectProduct.purchase_price);
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Purchase Price"
                      value={purchasePrice}
                      onChange={(e) => {
                        setPurchasePrice(e.target.value);
                      }}
                      //disabled={selectProduct.purchase_price ? true : false}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      New Purchase Price<span></span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="New Purchase Price"
                      value={newPurchasePrice}
                      min={0}
                      onChange={(e) => {
                        //console.log(e.target.value);
                        setNewPurchasePrice(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Purchase Vat (%)<span></span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Vat"
                      value={purchaseVat}
                      onChange={(e) => setPurchaseVat(e.target.value)}
                      min={0}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      MRP<span className="required-field">*</span>
                      {selectProduct.sale_price && salesPrice === "" ? (
                        <FontAwesomeIcon
                          icon={faRotateRight}
                          color="#ff9900"
                          style={{ paddingLeft: "0.4rem", cursor: "pointer" }}
                          onClick={() => {
                            setSalesPrice(selectProduct.sale_price);
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Sales Price"
                      value={salesPrice}
                      onChange={(e) => {
                        setSalesPrice(e.target.value);
                      }}
                      //disabled={selectProduct.sale_price ? true : false}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      New MRP<span></span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="New Sales Price"
                      value={newSalesPrice}
                      onChange={(e) => {
                        setNewSalesPrice(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Sales Vat (%)<span></span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Vat"
                      value={salesVat}
                      onChange={(e) => setSalesVat(e.target.value)}
                      min={0}
                    />
                  </Form.Group>
                </Col>
                <Col lg={9}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Product Comments</Form.Label>
                    <Form.Control
                      type="text"
                      //as="textarea"
                      //rows={4}
                      //style={{ height: "100px" }}
                      placeholder="Write Your Comments Here....."
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xl={3} lg={6} className="mb-2">
                  <Button
                    className="submit-btn border-0"
                    onClick={clickAddProduct}
                  >
                    Add Product
                  </Button>
                </Col>
              </Row>
              <Table
                className="mt-3 mb-4"
                responsive
                hover
                style={{ fontSize: "14px" }}
              >
                <thead className="text-center table-header">
                  <tr>
                    <th>SL</th>
                    <th>Barcode</th>
                    <th>Information</th>
                    <th>Units</th>
                    <th>Qty</th>
                    <th>Bonus Qty</th>
                    <th>P.Price</th>
                    <th>P.Vat</th>
                    <th>New P.Price</th>
                    <th>S.Price</th>
                    <th>New S.Price</th>
                    <th>GP%</th>
                    <th>Subtotal</th>
                    <th>Batch No.</th>
                    <th>Supplier Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {productList.length > 0 ? (
                    listWithIndices
                      .sort((a, b) => b.index - a.index)
                      .map((list, index) => (
                        <tr
                          className={
                            list.index === listWithIndices.length - 1
                              ? "inside-table"
                              : ""
                          }
                          key={index}
                        >
                          <td>{index + 1}</td>
                          <td>{list.value.barcode}</td>
                          <td>{list.value.name}</td>
                          <td>{list.value.units}</td>
                          <td>{list.value.quantity_amount}</td>
                          <td>{list.value.bonus_quantity}</td>
                          <td>{list.value.purchase_price}</td>
                          <td>{list.value.purchase_vat}%</td>
                          <td>{list.value.new_purchase_price}</td>
                          <td>{list.value.sales_price}</td>
                          <td>{list.value.new_sales_price}</td>
                          <td>{list.value.gross_profit}%</td>
                          <td>{list.value.subtotal.toFixed(2)}</td>
                          <td>{list.value.batch_no}</td>
                          <td>{list.value.supplierName}</td>
                          <td style={{ cursor: "pointer" }}>
                            <OverlayTrigger
                              key="export2"
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">Remove</Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                color="#E40000"
                                style={{ paddingLeft: "0.4rem" }}
                                onClick={() => removeProduct(list.value)}
                              />
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="17">No Product Added</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Row className="mt-2 pt-2 table-header">
                <Col xl={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Total Purchase Value</Form.Label>
                    <Form.Control
                      type="number"
                      name="totalPurchaseValue"
                      value={totalPurchaseValue.toFixed(2)}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col xl={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Additional Commission </Form.Label>
                    <Form.Control
                      type="number"
                      value={commission}
                      onChange={(e) => {
                        e.target.value.length === 0
                          ? setCommission(0)
                          : e.target.value >= 0
                          ? setCommission(e.target.value)
                          : setCommission(0);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xl={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Total Vat (৳)</Form.Label>
                    <Form.Control
                      type="number"
                      name="totalVat"
                      value={totalVat.toFixed(2)}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col xl={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Net Purchase Value</Form.Label>
                    <Form.Control
                      type="number"
                      value={(
                        totalPurchaseValue +
                        totalVat -
                        commission
                      ).toFixed(2)}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    Purchase Document<span className="required-field">*</span>
                  </Form.Label>
                  <br />
                  <label
                    className="pt-4 pb-4"
                    htmlFor="inputTag"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      border: "1px solid #DCE0E4",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <img
                        src={uploadIcon}
                        alt="uploadIcon"
                        height={30}
                        width={30}
                      />
                      <span className="image-upload-text">
                        Upload Documents
                      </span>
                      {purchaseDocument ? (
                        <p>
                          <b>Uploaded file:</b> {purchaseDocument.name}
                        </p>
                      ) : (
                        <p>
                          <b>Chosen file:</b> No file chosen yet
                        </p>
                      )}
                    </div>
                    <input
                      id="inputTag"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                    />
                  </label>
                </Form.Group>
              </Row>
              {loading === true ? (
                <Row className="mt-2">
                  <Col xl={4} lg={12} className="d-flex justify-content-center">
                    <Spinner animation="border" variant="warning" />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row className="mt-3">
                <Col xl={2} lg={4} className="mb-2">
                  {loading === true ? (
                    <Button
                      className="disable-submit-btn"
                      variant="secondary"
                      disabled
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      className="submit-btn border-0"
                      onClick={handleSubmitPurchase}
                    >
                      Submit
                    </Button>
                  )}
                </Col>

                <Col xl={2} lg={4} className="mb-2">
                  <Button
                    className="preview-btn border-0"
                    onClick={() => setPreviewOpen(true)}
                  >
                    Preview
                  </Button>
                </Col>

                <Col xl={2} lg={4}>
                  <Button className="cancel-btn border-0">Cancel</Button>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AddPurchase;
