import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ProductForm from "./ProductForm";
import "./Product.css";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { SuccessAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";
import {
  getEachProductDetails,
  updateProduct,
} from "../../Redux/actions/productActions";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const UpdateProduct = () => {
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useDispatch();
  const { product, error } = useSelector((state) => state.productDetail);
  const { loading, message, updateError } = useSelector(
    (state) => state.updateProduct
  );
  const [productName, setProductName] = useState("");
  const [genericName, setGenericName] = useState("");
  const [subcategoryName1, setSubcategoryName1] = useState("");
  const [subcategoryName2, setSubcategoryName2] = useState("");
  const [subcategoryName3, setSubcategoryName3] = useState("");
  const [sizeUnits, setSizeUnits] = useState("");
  const [quantityType, setQuantityType] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [supplier, setSupplier] = useState("");
  const [barcode, setBarcode] = useState("");
  const [barcodeType, setBarcodeType] = useState("");
  const [description, setDescription] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [salesPrice, setSalesPrice] = useState("");
  const [newSalesPrice, setNewSalesPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [purchaseVat, setPurchaseVat] = useState("");
  const [saleVat, setSaleVat] = useState("");
  const [productType, setProductType] = useState("");
  const [promotionBrand, setPromotionBrand] = useState("");
  const [productLocation, setProductLocation] = useState("");
  const [requiredPres, setRequiredPres] = useState("");
  const [productImage, setProductImage] = useState(null);
  const [promotion, setPromotion] = useState("");
  const [promotion1, setPromotion1] = useState("");
  const [appPromotion, setAppPromotion] = useState("");
  const [packSize, setPackSize] = useState(1);
  const [profitValue, setProfitValue] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);
  useEffect(() => {
    dispatch(getEachProductDetails(param.id));
  }, [dispatch]);

  useEffect(() => {
    if (product && product.id !== undefined) {
      setProductName(product.product_name);
      setGenericName(product.generic_name !== null ? product.generic_name : "");
      setSubcategoryName1(
        product.sub_catagory_1_id !== null ? product.sub_catagory_1_id : ""
      );
      setSubcategoryName2(
        product.sub_catagory_2_id !== null ? product.sub_catagory_2_id : ""
      );
      setSubcategoryName3(
        product.sub_catagory_3_id !== null ? product.sub_catagory_3_id : ""
      );
      setSizeUnits(product.unit_size !== null ? product.unit_size : "");
      setQuantityType(product.stock_type !== null ? product.stock_type : "");
      setQuantity(product.stocks !== null ? product.stocks : 0);
      setSupplier(product.supplier_id !== null ? product.supplier_id : "");
      setBarcode(
        product.barcode !== null && product.barcode_type !== "auto generate"
          ? product.barcode
          : ""
      );
      setDescription(product.description);
      setPurchasePrice(
        product.purchase_price !== null ? product.purchase_price : ""
      );
      setSalesPrice(product.sale_price !== null ? product.sale_price : "");
      setNewSalesPrice(
        product.new_sale_price !== null ? product.new_sale_price : ""
      );
      setDiscountPrice(product.discount !== null ? product.discount : "");
      setPurchaseVat(product.purchase_vat !== null ? product.purchase_vat : "");
      setSaleVat(product.sale_vat !== null ? product.sale_vat : "");
      setProductType(product.product_type);
      setPromotionBrand(
        product.brand_promotion !== null ? product.brand_promotion : ""
      );
      setProductLocation(product.location !== null ? product.location : "");
      setRequiredPres(product.require_prescription);
      setPromotion(product.promotion !== null ? product.promotion : "");
      setPromotion1(product.promotion1 !== null ? product.promotion1 : "");
      setAppPromotion(
        product.app_promotion !== null ? product.app_promotion : ""
      );
      setProductImage(product.image);
      setBarcodeType(product.barcode_type);
      setSearchTag(
        product.product_search_tag !== null ? product.product_search_tag : ""
      );
      setProfitValue(product.profit ? product.profit : "");
      setPackSize(product.pack_size);
      setAlertMessage(false);
    }
  }, [product]);

  useEffect(() => {
    if (alertMessage && message === undefined && updateError !== undefined) {
      //console.log(updateError);
      if (updateError.barcode) {
        alert(updateError.barcode);
        setAlertMessage(false);
      } else if (updateError.stock_type) {
        alert(`Stock Type: ${updateError.stock_type}`);
        setAlertMessage(false);
      } else if (updateError.location) {
        alert(`Product location: ${updateError.location}`);
        setAlertMessage(false);
      } else if (updateError.sale_price) {
        alert(`Sales Price: ${updateError.sale_price}`);
        setAlertMessage(false);
      } else if (updateError.purchase_price) {
        alert(`Purchase Price: ${updateError.purchase_price}`);
        setAlertMessage(false);
      } else if (updateError.new_sale_price) {
        alert(`New Sales Price: ${updateError.new_sale_price}`);
        setAlertMessage(false);
      } else if (updateError.discount) {
        alert(`Discount: ${updateError.discount}`);
        setAlertMessage(false);
      } else if (updateError.purchase_vat) {
        alert(`Purchase Vat: ${updateError.purchase_vat}`);
        setAlertMessage(false);
      } else if (updateError.sale_vat) {
        alert(`Sale Vat: ${updateError.sale_vat}`);
        setAlertMessage(false);
      } else if (updateError.product_image) {
        alert(`Product Image: ${updateError.product_image}`);
        setAlertMessage(false);
      } else if (updateError.warning) {
        alert(`${updateError.warning}`);
        setAlertMessage(false);
      } else {
        alert("Something Went Wrong");
        setAlertMessage(false);
      }
    }
    if (alertMessage && message !== undefined) {
      SuccessAlert("Product Updated Successfully");
      setAlertMessage(false);
      setTimeout(() => {
        navigate(`/product/${param.id}`);
      }, 2500);
    }
  }, [alertMessage, message, updateError]);

  // const profitValue =
  //   salesPrice && purchasePrice
  //     ? ((salesPrice - purchasePrice) / salesPrice).toFixed(2) * 100
  //     : "";

  const formData = [
    { key: "product_name", value: productName },
    { key: "generic_name", value: genericName },
    { key: "unit_size", value: sizeUnits },
    { key: "sub_catagory_1", value: subcategoryName1 },
    { key: "sub_catagory_2", value: subcategoryName2 },
    { key: "sub_catagory_3", value: subcategoryName3 },
    { key: "supplier", value: supplier },
    { key: "description", value: description },
    { key: "discount", value: discountPrice },
    { key: "purchase_vat", value: purchaseVat },
    { key: "sale_vat", value: saleVat },
    { key: "product_type", value: productType },
    { key: "brand_promotion", value: promotionBrand },
    { key: "promotion", value: promotion },
    { key: "promotion1", value: promotion1 },
    { key: "app_promotion", value: appPromotion },
    { key: "require_prescription", value: requiredPres },
    //{ key: "profit", value: profitValue },
    { key: "location", value: productLocation },
    { key: "product_search_tag", value: searchTag },
    { key: "pack_size", value: packSize ? packSize : 0 },
  ];

  const handleSubmitForm = (e) => {
    if (typeof productImage === "string") {
      e.preventDefault();
      let form_data = new FormData();
      // form_data.append("product_name", productName);
      // form_data.append("generic_name", genericName);
      // form_data.append("sub_catagory_1", subcategoryName1);
      // form_data.append("sub_catagory_2", subcategoryName2);
      // form_data.append("sub_catagory_3", subcategoryName3);
      // form_data.append("unit_size", sizeUnits);
      // form_data.append("supplier", supplier);
      // form_data.append("stock_type", quantityType);
      // form_data.append("description", description);
      // form_data.append("purchase_price", purchasePrice);
      // form_data.append("sale_price", salesPrice);
      // form_data.append("new_sale_price", newSalesPrice);
      // form_data.append("discount", discountPrice);
      // form_data.append("purchase_vat", purchaseVat);
      // form_data.append("sale_vat", saleVat);
      // form_data.append("product_type", productType);
      // form_data.append("brand_promotion", promotionBrand);
      // form_data.append("location", productLocation);
      // form_data.append("promotion", promotion);
      // form_data.append("promotion1", promotion1);
      // form_data.append("app_promotion", appPromotion);
      // form_data.append("require_prescription", requiredPres);
      // form_data.append("profit", profitValue);
      let post = formData.filter((data) => data.value !== "");
      post.map((data) => form_data.append(data.key, data.value));
      //console.log(post);
      if (
        barcode !== product.barcode &&
        product.barcode_type !== "auto generate"
      ) {
        form_data.append("barcode", barcode);
      }
      if (barcode !== "" && product.barcode_type === "auto generate") {
        form_data.append("barcode", barcode);
      }
      //console.log(form_data);
      dispatch(updateProduct(product.id, form_data));
      setAlertMessage(true);
    } else {
      e.preventDefault();
      let form_data = new FormData();
      // form_data.append("product_name", productName);
      // form_data.append("generic_name", genericName);
      // form_data.append("sub_catagory_1", subcategoryName1);
      // form_data.append("sub_catagory_2", subcategoryName2);
      // form_data.append("sub_catagory_3", subcategoryName3);
      // form_data.append("unit_size", sizeUnits);
      // form_data.append("supplier", supplier);
      // form_data.append("stock_type", quantityType);
      // form_data.append("description", description);
      // form_data.append("purchase_price", purchasePrice);
      // form_data.append("sale_price", salesPrice);
      // form_data.append("new_sale_price", newSalesPrice);
      // form_data.append("discount", discountPrice);
      // form_data.append("purchase_vat", purchaseVat);
      // form_data.append("sale_vat", saleVat);
      // form_data.append("product_type", productType);
      // form_data.append("brand_promotion", promotionBrand);
      // form_data.append("location", productLocation);
      // form_data.append("promotion", promotion);
      // form_data.append("promotion1", promotion1);
      // form_data.append("app_promotion", appPromotion);
      // form_data.append("require_prescription", requiredPres);
      let post = formData.filter((data) => data.value !== "");
      post.map((data) => form_data.append(data.key, data.value));
      form_data.append("product_image", productImage);
      //form_data.append("profit", profitValue);
      if (
        barcode !== product.barcode &&
        product.barcode_type !== "auto generate"
      ) {
        form_data.append("barcode", barcode);
      }
      if (barcode !== "" && product.barcode_type === "auto generate") {
        form_data.append("barcode", barcode);
      }
      dispatch(updateProduct(product.id, form_data));
      setAlertMessage(true);
    }
  };

  //console.log(error);
  return (
    <>
      {error && error.includes("no product found") ? (
        <NotFoundPage></NotFoundPage>
      ) : (
        <div>
          <SideBar />
          <div className="mainContent">
            <Header></Header>
            <ToastContainer></ToastContainer>
            <Container fluid>
              <h4 className="product-heading container-view">
                Product Details Update
              </h4>
              <p className="product-paragraph container-view">
                Products <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
                Product List <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
                Update Product Details ({product.id})
              </p>
              <div className="mb-4 card product-card container-view">
                <ProductForm
                  loading={loading}
                  productId={product.id}
                  productName={productName}
                  setProductName={setProductName}
                  genericName={genericName}
                  setGenericName={setGenericName}
                  subcategoryName1={subcategoryName1}
                  setSubcategoryName1={setSubcategoryName1}
                  subcategoryName2={subcategoryName2}
                  setSubcategoryName2={setSubcategoryName2}
                  subcategoryName3={subcategoryName3}
                  setSubcategoryName3={setSubcategoryName3}
                  sizeUnits={sizeUnits}
                  setSizeUnits={setSizeUnits}
                  quantityType={quantityType}
                  setQuantityType={setQuantityType}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  supplier={supplier}
                  setSupplier={setSupplier}
                  barcode={barcode}
                  setBarcode={setBarcode}
                  description={description}
                  setDescription={setDescription}
                  purchasePrice={purchasePrice}
                  setPurchasePrice={setPurchasePrice}
                  salesPrice={salesPrice}
                  setSalesPrice={setSalesPrice}
                  newSalesPrice={newSalesPrice}
                  setNewSalesPrice={setNewSalesPrice}
                  discountPrice={discountPrice}
                  setDiscountPrice={setDiscountPrice}
                  purchaseVat={purchaseVat}
                  setPurchaseVat={setPurchaseVat}
                  saleVat={saleVat}
                  setSaleVat={setSaleVat}
                  productType={productType}
                  setProductType={setProductType}
                  promotionBrand={promotionBrand}
                  setPromotionBrand={setPromotionBrand}
                  productLocation={productLocation}
                  setProductLocation={setProductLocation}
                  requiredPres={requiredPres}
                  setRequiredPres={setRequiredPres}
                  productImage={productImage}
                  setProductImage={setProductImage}
                  promotion={promotion}
                  setPromotion={setPromotion}
                  promotion1={promotion1}
                  setPromotion1={setPromotion1}
                  appPromotion={appPromotion}
                  setAppPromotion={setAppPromotion}
                  barcodeType={barcodeType}
                  autoBarcode={product.barcode}
                  handleSubmitForm={handleSubmitForm}
                  profitValue={profitValue}
                  searchTag={searchTag}
                  setSearchTag={setSearchTag}
                  packSize={packSize}
                  setPackSize={setPackSize}
                />
              </div>
            </Container>
          </div>
          <Footer></Footer>
        </div>
      )}
    </>
  );
};

export default UpdateProduct;
