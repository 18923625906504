import React, { useEffect } from "react";
import { Row, Col, Table, Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const PoPreviewModal = (props) => {
  const { supplierList } = useSelector((state) => state.supplierList);
  const supplier = supplierList.data?.results.filter(
    (supplier) => supplier.id === parseInt(props.supplierId)
  );

  useEffect(() => {
    if (props.show) {
      // Add overflow-x hidden to the html element
      document.documentElement.style.overflow = "hidden";
    } else {
      // Remove overflow-x hidden when the modal is closed
      document.documentElement.style.overflow = "";
    }

    // Cleanup function to reset the overflow when the component unmounts
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, [props.show]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      fullscreen={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto" id="contained-modal-title-vcenter">
          Purchae Order Preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="p-4"
        style={{ maxHeight: "80vh", overflowY: "scroll" }}
      >
        <Row>
          {supplier && supplier.length && (
            <p>Selected Supplier Name: {supplier[0].supplier_name}</p>
          )}
        </Row>

        <div className="table-container">
          <Table
            //responsive
            bordered
            hover
            style={{ fontSize: "12px" }}
          >
            <thead>
              <tr>
                <th>SL No.</th>
                <th>Product Information</th>
                <th>Total Stock</th>
                <th>PO Quantity</th>
                <th>CPU</th>
                <th>RPU</th>
                <th>Pack Size</th>
                <th>Total Cost</th>
              </tr>
            </thead>
            <tbody>
              {props.select === "true" ? (
                <>
                  {props.purchaseorderlist.map((product, i) => (
                    <tr key={product.id}>
                      <td>{i + 1}</td>
                      <td>
                        {product.product_name}
                        {" - "}
                        {product.unit}
                        {" - "}
                        {product.sub_category}
                      </td>
                      <td>{product.stock}</td>
                      <td>{product.order_quantity}</td>
                      <td>
                        {product.new_purchase_price
                          ? product.new_purchase_price
                          : product.purchase_price}
                      </td>
                      <td>
                        {product.new_product_price
                          ? product.new_product_price
                          : product.product_price}
                      </td>
                      <td>{product.pack_size ? product.pack_size : 1}</td>
                      <td>
                        {product.new_purchase_price
                          ? (
                              product.new_purchase_price *
                              product.order_quantity
                            ).toFixed(2)
                          : (
                              product.purchase_price * product.order_quantity
                            ).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                  <tr style={{ fontWeight: "600" }}>
                    <td className="text-start" colSpan={3}>
                      Grand Total
                    </td>
                    <td>
                      {props.purchaseorderlist.reduce(
                        (initialValue, product) =>
                          initialValue + product.order_quantity,
                        0
                      )}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      {props.purchaseorderlist
                        .reduce(
                          (initialValue, product) =>
                            initialValue +
                            (product.new_purchase_price
                              ? product.new_purchase_price
                              : product.purchase_price) *
                              product.order_quantity,
                          0
                        )
                        .toFixed(2)}
                      ৳
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {props.addedproduct.map((product, i) => (
                    <tr key={product.id}>
                      <td>{i + 1}</td>
                      <td>
                        {product.product_name}
                        {" - "}
                        {product.unit}
                        {" - "}
                        {product.sub_category}
                      </td>
                      <td>{product.stock}</td>
                      <td>{product.order_quantity}</td>
                      <td>
                        {product.new_purchase_price
                          ? product.new_purchase_price
                          : product.purchase_price}
                      </td>
                      <td>
                        {product.new_product_price
                          ? product.new_product_price
                          : product.product_price}
                      </td>
                      <td>{product.pack_size ? product.pack_size : 1}</td>
                      <td>
                        {product.new_purchase_price
                          ? (
                              product.new_purchase_price *
                              product.order_quantity
                            ).toFixed(2)
                          : (
                              product.purchase_price * product.order_quantity
                            ).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                  <tr style={{ fontWeight: "600" }}>
                    <td className="text-start" colSpan={3}>
                      Grand Total
                    </td>
                    <td>
                      {props.addedproduct.reduce(
                        (initialValue, product) =>
                          initialValue + product.order_quantity,
                        0
                      )}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      {props.addedproduct
                        .reduce(
                          (initialValue, product) =>
                            initialValue +
                            (product.new_purchase_price
                              ? product.new_purchase_price
                              : product.purchase_price) *
                              product.order_quantity,
                          0
                        )
                        .toFixed(2)}
                      ৳
                    </td>
                  </tr>
                </>
              )}
              {props.purchaseorderlist.length === 0 &&
                props.addedproduct.length === 0 && (
                  <tr className="text-center">
                    <td colSpan="8">No Product Found For Preview</td>
                  </tr>
                )}
            </tbody>
          </Table>
        </div>
        <Row className="mt-5 d-flex justify-content-center">
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button className="cancel-btn border-0" onClick={props.onHide}>
              Go Back
            </Button>
          </Col>
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button
              className="submit-btn border-0"
              onClick={() => {
                props.action();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PoPreviewModal;
