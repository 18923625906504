import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  IMPORT_FILE_REQUEST,
  IMPORT_FILE_SUCCESS,
  IMPORT_FILE_FAILURE,
} from "../type";
import axios from "axios";

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    let form_data = new FormData();
    form_data.append("username", username);
    form_data.append("password", password);

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/token/Inventory/`,
      form_data
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("access_token", data.access);
    localStorage.setItem("refresh_token", data.refresh);
  } catch (error) {
    console.log(error);
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "Content-Type": "application/json",
    },
  };
  let form_data = new FormData();
  form_data.append("refresh_token", localStorage.getItem("refresh_token"));

  axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/user/logout/blacklist/`,
    form_data,
    config
  );
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("url");

  dispatch({ type: USER_LOGOUT });
};

export const getUserDetails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/user/viewprofile/`,
      config
    );

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const importFileAction = (body, url) => async (dispatch) => {
  try {
    dispatch({
      type: IMPORT_FILE_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/upload${url}`,
      body,
      config
    );

    dispatch({
      type: IMPORT_FILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: IMPORT_FILE_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
