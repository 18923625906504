import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userLoginReducer,
  userDetailsReducer,
  importFileReducer,
} from "./reducers/userReducers";
import {
  SetPageNumberReducer,
  SidebarReducer,
} from "./reducers/sidebarReducers";
import {
  supplierListReducer,
  createSupplierReducer,
  deleteSupplierReducer,
  updateSupplierReducer,
  SupplierDetailsReducers,
  searchSupplierListReducer,
} from "./reducers/supplierReducers";
import {
  vendorListReducer,
  createVendorReducer,
  deleteVendorReducer,
  updateVendorReducer,
  vendorDetailsReducers,
  searchVendorListReducer,
} from "./reducers/vendorReducers";
import {
  productListReducer,
  createProductReducer,
  deleteProductReducer,
  productDetailsReducers,
  searchProductListReducer,
  updateProductReducer,
  updateProductStatusReducer,
  searchProductWithBarcodeReducer,
} from "./reducers/productReducers";
import {
  purchaseListReducer,
  deletePurchaseReducer,
  deletePurchaseItemReducer,
  searchPurchaseListReducer,
  createPurchaseReducer,
  purchaseDetailsReducers,
  purchaseRequestListReducer,
  searchPurchaseRequestListReducer,
  createPurchaseRequestReducer,
  deletePurchaseRequestReducer,
  createProductRequestReducer,
  productRequestListReducer,
  deleteRequestProductReducer,
  updatePurchaseRequestReducer,
} from "./reducers/purchaseReducers";
import {
  subcategoryListReducer,
  createSubcategoryReducer,
  searchSubcategoryListReducer,
  deleteSubcategoryReducer,
  updateSubcategoryReducer,
} from "./reducers/subcategoryReducers";
import {
  categoryListReducer,
  createCategoryReducer,
  searchCategoryListReducer,
  deleteCategoryReducer,
  updateCategoryReducer,
} from "./reducers/categoryReducers";
import {
  returnListReducer,
  deleteReturnReducer,
  deleteReturnItemReducer,
  searchReturnListReducer,
  createReturnReducer,
  BatchDetailsReducers,
} from "./reducers/returnReducers";
import {
  warehouseListReducer,
  deleteWarehouseReducer,
  createWarehouseReducer,
  updateWarehouseReducer,
  warehouseDetailsReducers,
  searchWarehouseListReducer,
} from "./reducers/warehouseReducers";
import {
  transferListReducer,
  createTransferReducer,
  transferDetailsReducers,
  searchTransferListReducer,
  searchTransferPurchaseListReducer,
  searchPurchaseProductListReducer,
} from "./reducers/transferReducers";
import { dashboardDetailsReducers } from "./reducers/dashboardReducers";
import {
  duePaymentListReducer,
  updateDuePaymentReducer,
  searchDuePaymentListReducer,
} from "./reducers/duePaymentReducers";
import {
  poProductFilterReducer,
  createPurchaseOrderReducer,
  purchaseOrderListReducer,
  purchaseOrderDetailsReducers,
  searchPurchaseOrderListReducer,
} from "./reducers/purchaseOrderReducers";
import {
  subcategoryStockReducer,
  categoryStockReducer,
  subcategoryWiseProductReducer,
  allSupplierStockReducer,
  supplierWiseStockReducer,
} from "./reducers/stockReportReducers";
import {
  allStockListReducer,
  frequencyAnalysisReducer,
  slowMovingProductReducer,
  fastMovingProductReducer,
  nonMovingProductReducer,
  stockReturnReducer,
  zeroStockReportReducer,
  gpCompareReportReducer,
  compareProductGpReducer,
  nearExpiryProductReducer,
  expiryProductReducer,
} from "./reducers/stockAnalysisReducers";
import { returnStockReportReducer } from "./reducers/returnStockReducer";
import {
  productBiographyReducers,
  inventoryListReducers,
  createPhysicalInventoryReducers,
  physicalInventoryListReducers,
  physicalInventoryUpdateReducers,
  inventoryLogListReducers,
} from "./reducers/utilitiesReducers";
import {
  supplierWiseRcvReducer,
  supplierRcvSummaryReducer,
  vendorWiseRcvReducer,
  barcodeStockRcvReducer,
} from "./reducers/receiveReportReducers";
import {
  voidListReducer,
  createVoidReducer,
  voidDetailsReducers,
  searchVoidListReducer,
  searchInvoiceReducer,
  searchInvoiceDetailsReducer,
} from "./reducers/voidReducers";
import {
  genericListReducer,
  createGenericReducer,
  searchGenericReducer,
} from "./reducers/genericReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userDetails: userDetailsReducer,
  sideBarState: SidebarReducer,
  supplierList: supplierListReducer,
  createSupplier: createSupplierReducer,
  deleteSupplier: deleteSupplierReducer,
  updateSupplier: updateSupplierReducer,
  supplierDetail: SupplierDetailsReducers,
  searchSupplierList: searchSupplierListReducer,
  vendorList: vendorListReducer,
  createVendor: createVendorReducer,
  deleteVendor: deleteVendorReducer,
  updateVendor: updateVendorReducer,
  vendorDetail: vendorDetailsReducers,
  searchVendorList: searchVendorListReducer,
  purchaseList: purchaseListReducer,
  deletePurchase: deletePurchaseReducer,
  deletePurchaseItem: deletePurchaseItemReducer,
  searchPurchaseList: searchPurchaseListReducer,
  createPurchase: createPurchaseReducer,
  purchaseDetail: purchaseDetailsReducers,
  purchaseRequestList: purchaseRequestListReducer,
  searchPurchaseRequestList: searchPurchaseRequestListReducer,
  createPurchaseRequest: createPurchaseRequestReducer,
  deletePurchaseRequest: deletePurchaseRequestReducer,
  updatePurchaseRequest: updatePurchaseRequestReducer,
  createProductRequest: createProductRequestReducer,
  productRequestList: productRequestListReducer,
  deleteRequestProduct: deleteRequestProductReducer,
  subcategoryList: subcategoryListReducer,
  createSubcategory: createSubcategoryReducer,
  deleteSubcategory: deleteSubcategoryReducer,
  updateSubcategory: updateSubcategoryReducer,
  searchSubcategoryList: searchSubcategoryListReducer,
  categoryList: categoryListReducer,
  createCategory: createCategoryReducer,
  deleteCategory: deleteCategoryReducer,
  updateCategory: updateCategoryReducer,
  searchCategoryList: searchCategoryListReducer,
  productList: productListReducer,
  productDetail: productDetailsReducers,
  deleteProduct: deleteProductReducer,
  createProduct: createProductReducer,
  updateProduct: updateProductReducer,
  searchProductList: searchProductListReducer,
  productStatusUpdate: updateProductStatusReducer,
  searchProductBarcode: searchProductWithBarcodeReducer,
  returnList: returnListReducer,
  deleteReturn: deleteReturnReducer,
  deleteReturnItem: deleteReturnItemReducer,
  searchReturnList: searchReturnListReducer,
  createReturn: createReturnReducer,
  batchDetailsList: BatchDetailsReducers,
  dashboardDetail: dashboardDetailsReducers,
  warehouseList: warehouseListReducer,
  deleteWarehouse: deleteWarehouseReducer,
  createWarehouse: createWarehouseReducer,
  updateWarehouse: updateWarehouseReducer,
  warehouseDetails: warehouseDetailsReducers,
  searchWarehouseList: searchWarehouseListReducer,
  transferList: transferListReducer,
  createTransfer: createTransferReducer,
  transferDetail: transferDetailsReducers,
  searchTransferList: searchTransferListReducer,
  searchTransferPurchaseList: searchTransferPurchaseListReducer,
  searchPurchaseProductList: searchPurchaseProductListReducer,
  duePaymentList: duePaymentListReducer,
  updateDuePayment: updateDuePaymentReducer,
  searchDuePayment: searchDuePaymentListReducer,
  poProductFilter: poProductFilterReducer,
  createPurchaseOrder: createPurchaseOrderReducer,
  purchaseOrderList: purchaseOrderListReducer,
  purchaseOrderDetails: purchaseOrderDetailsReducers,
  searchPurchaseOrder: searchPurchaseOrderListReducer,
  subCategoryStock: subcategoryStockReducer,
  categoryStock: categoryStockReducer,
  allStock: allStockListReducer,
  frequencyAnalysis: frequencyAnalysisReducer,
  subCategoryWiseProducts: subcategoryWiseProductReducer,
  fastMovingProductList: fastMovingProductReducer,
  slowMovingProductList: slowMovingProductReducer,
  nonMovingProuctList: nonMovingProductReducer,
  stockReturnList: stockReturnReducer,
  zeroStockProducts: zeroStockReportReducer,
  gpCompareProducts: gpCompareReportReducer,
  returnStockReport: returnStockReportReducer,
  allSupplierStock: allSupplierStockReducer,
  supplierWiseStock: supplierWiseStockReducer,
  compareProductGp: compareProductGpReducer,
  nearExpiryProduct: nearExpiryProductReducer,
  expiryProduct: expiryProductReducer,
  productBiography: productBiographyReducers,
  inventoryList: inventoryListReducers,
  createPhysicalInventory: createPhysicalInventoryReducers,
  physicalInventoryList: physicalInventoryListReducers,
  updatePhysicalInventory: physicalInventoryUpdateReducers,
  inventoryLogList: inventoryLogListReducers,
  supplierWiseRcvItems: supplierWiseRcvReducer,
  supplierRcvSummaryDetails: supplierRcvSummaryReducer,
  vendorWiseRcvItems: vendorWiseRcvReducer,
  barcodeStockRcvItems: barcodeStockRcvReducer,
  voidList: voidListReducer,
  createVoid: createVoidReducer,
  voidDetails: voidDetailsReducers,
  searchVoidList: searchVoidListReducer,
  searchInvoice: searchInvoiceReducer,
  searchInvoiceDetails: searchInvoiceDetailsReducer,
  importFile: importFileReducer,
  genericList: genericListReducer,
  createGeneric: createGenericReducer,
  searchGeneric: searchGenericReducer,
  pageNumberState: SetPageNumberReducer,
});
const menuToggleState =
  localStorage.getItem("menuState") === "true" ? true : false;

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

//console.log(menuToggleState);
const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
  sideBarState: {
    toggleClick: menuToggleState,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
