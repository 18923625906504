import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenAlt, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const WarehouseListTableRow = ({ warehouse, handleDeleteWarehouse }) => {
  const navigate = useNavigate();
  return (
    <tr key={warehouse.id}>
      {/* <td>
          <Form.Check type="checkbox" id="checkbox" />
        </td> */}
      <td>{warehouse.id}</td>
      <td>{warehouse.name}</td>
      <td>{warehouse.contact_number}</td>
      <td>{warehouse.email}</td>
      <td>{warehouse.city}</td>
      <td>{warehouse.address?.substring(0, 15)}</td>
      <td>{warehouse.active_status}</td>
      <td>{warehouse.warehouse_status}</td>
      <td style={{ cursor: "pointer" }}>
        <FontAwesomeIcon
          icon={faEye}
          color="#637381"
          onClick={() => navigate(`/destination/${warehouse.id}`)}
        />
        <FontAwesomeIcon
          icon={faPenAlt}
          color="#637381"
          style={{ paddingLeft: "0.405rem" }}
          onClick={() => navigate(`/updateDestination/${warehouse.id}`)}
        />
        <FontAwesomeIcon
          icon={faTrashCan}
          color="#E40000"
          style={{ paddingLeft: "0.405rem" }}
          onClick={() => handleDeleteWarehouse(warehouse)}
        />
      </td>
    </tr>
  );
};

export default WarehouseListTableRow;
