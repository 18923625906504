import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useLocalStorage from "../../hooks/useLocalStorage";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../Products/Product.css";
import "./Return.css";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faTrash,
  faBarcode,
} from "@fortawesome/free-solid-svg-icons";
import {
  createReturn,
  getBatchDetails,
} from "../../Redux/actions/returnActions";
import {
  searchProductList,
  productSearchWithBarcode,
} from "../../Redux/actions/productActions";
import { getVendorList } from "../../Redux/actions/vendorActions";
import { SuccessAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";
import AddReturnModal from "../PopupModal/AddReturnModal/AddReturnModal";

const AddReturn = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [selectProduct, setSelectProduct] = useState({});
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [reasons, setReasons] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [batchNo, setBatchNo] = useState("");
  const [qty, setQty] = useState(0);
  const [qtyType, setQtyType] = useState("");
  const [priceType, setPriceType] = useState("old");
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [salesPrice, setSalesPrice] = useState(0);
  const [purchaseVat, setPurchaseVat] = useState(0);
  const [returnList, setReturnList] = useLocalStorage("returnList", []);
  const [barcode, setBarcode] = useState("");
  const [searchBarcode, setSearchBarcode] = useState(false);
  const [searchApiCall, setSearchApiCall] = useState(true);
  const { searchLoading, searchProducts } = useSelector(
    (state) => state.searchProductList
  );
  const { vendorList } = useSelector((state) => state.vendorList);

  const { loading, message, error } = useSelector(
    (state) => state.createReturn
  );

  const { searchBarcodeLoading, searchBarcodeProduct, searchBarcodeError } =
    useSelector((state) => state.searchProductBarcode);

  const [previewOpen, setPreviewOpen] = useState(false);

  const batchDetail = useSelector((state) => state.batchDetailsList);

  useEffect(() => {
    dispatch(getVendorList(500, 0));
  }, []);

  useEffect(() => {
    //console.log(selectProduct);
    if (selectProduct.id !== undefined) {
      setQtyType(
        selectProduct.stock_type !== null ? selectProduct.stock_type : ""
      );
      setPurchasePrice(
        selectProduct.purchase_price !== null ? selectProduct.purchase_price : 0
      );
      setSalesPrice(
        selectProduct.sale_price !== null ? selectProduct.sale_price : 0
      );
      setPurchaseVat(
        selectProduct.purchase_vat !== null ? selectProduct.purchase_vat : 0
      );
      dispatch(getBatchDetails(selectProduct.id));
    }
  }, [selectProduct]);

  useEffect(() => {
    if (alertMessage && message === undefined && error !== undefined) {
      setAlertMessage(false);
      if (error.message) {
        alert(error.message);
      } else if (error.error) {
        alert(error.error);
      } else {
        alert("Something Went Wrong");
      }
    }
    if (alertMessage && message !== undefined) {
      //console.log(message);
      setReturnList([]);
      setVendorId("");
      SuccessAlert(
        `New Return Created Successfully. Return Id: ${message.order_id}`
      );
      setAlertMessage(false);
    }
  }, [alertMessage, message, error]);
  useEffect(() => {
    //console.log(searchBarcodeLoading);
    if (searchBarcode) {
      //console.log(searchBarcodeError);
      if (searchBarcodeProduct && searchBarcodeProduct.data !== undefined) {
        //console.log("success");
        setSelectProduct(searchBarcodeProduct.data.result);
        setSearchBarcode(false);
        setBarcode("");
      }
      if (searchBarcodeError !== undefined) {
        //console.log("error");
        alert(searchBarcodeError);
        setSelectProduct({});
        setSearchBarcode(false);
        setBarcode("");
        //ErrorAlert(searchBarcodeError);
      }
    }
  }, [searchBarcodeProduct, searchBarcodeError, searchBarcode]);

  useEffect(() => {
    if (searchProducts?.data?.waiting) {
      setSearchApiCall(false);
    } else {
      setSearchApiCall(true);
    }
  }, [searchProducts]);

  // Hasan involvement
  const [searchText, setSearchText] = useState("");

  // const handleSearchResult = (e) => {
  //   //console.log(e.target.value);
  //   if (e.target.value.length > 1) {
  //     //console.log("inside");
  //     setShowSearchResult(true);
  //     dispatch(searchProductList(e.target.value));
  //   } else {
  //     setShowSearchResult(false);
  //   }
  // };

  const doSearch = () => {
    if (searchText && searchText.length > 1 && searchApiCall) {
      // console.log("doSearch");
      setShowSearchResult(true);
      dispatch(searchProductList(searchText));
    }
  };

  useEffect(() => {
    if (searchText === "") {
      setShowSearchResult(false);
    }
  }, [searchText]);

  useEffect(() => {
    const debounceDelay = 600; // Adjust this delay as needed

    const debounceHandler = setTimeout(() => {
      doSearch();
    }, debounceDelay);

    return () => {
      // Cleanup the previous timeout when the input value changes
      clearTimeout(debounceHandler);
    };
  }, [searchText]);

  // end of Hasan involvement

  // by hasan
  const productDuplicationHandler = (matchingProductArray, batchNo) => {
    // console.log("matchingProductArray = ", matchingProductArray);
    let result = false;
    matchingProductArray.map((eachProduct) => {
      // console.log("eachProduct.batch_no ", eachProduct.batch_no);
      // console.log("batchNo ", batchNo);
      if (eachProduct.batch_no.trim() == batchNo.trim()) {
        result = true;
      }
    });

    return result;
  };
  // end of by hasan

  const productListEachId = returnList.map((each) => each.product_id);

  const clickAddProduct = () => {
    // console.log("selectProduct ", selectProduct);
    // console.log("returnList ", returnList);
    // console.log("productListEachId ", productListEachId);

    // console.log("selectProduct = ", selectProduct);
    let matchingProductArray;

    if (productListEachId.includes(selectProduct.id)) {
      matchingProductArray = returnList.filter(
        (each) => each.product_id === selectProduct.id
      );
      // console.log("matchingProductArray  = ", matchingProductArray);
    }

    if (!selectProduct.id) {
      alert("Please Select a Product");
    } else if (!selectProduct.supplier) {
      alert("Product must have a supplier");
    } else if (qty == 0) {
      alert("Product Quantity can not be zero");
    } else if (qty > selectProduct.wh_stock) {
      alert("Product Quantity can not be greater than warehouse stock");
    } else if (batchNo === "") {
      alert("Batch No. can not be blank");
    } else if (
      matchingProductArray &&
      batchNo &&
      productDuplicationHandler(matchingProductArray, batchNo)
    ) {
      alert("Product with same Batch No already exist in the list");
    } else if (reasons === "") {
      alert("Reasons can not be blank");
    } else {
      setReturnList([
        ...returnList,
        {
          product_id: selectProduct.id,
          name: selectProduct.product_name,
          units: selectProduct.unit_size,
          quantity_type: qtyType,
          quantity: parseInt(qty),
          purchase_price: selectProduct.purchase_price,
          new_purchaseprice: selectProduct.new_purchase_price
            ? selectProduct.new_purchase_price
            : 0,
          sale_price: selectProduct.sale_price,
          new_saleprice: selectProduct.new_sale_price
            ? selectProduct.new_sale_price
            : 0,
          vat: purchaseVat !== "" ? purchaseVat : 0,
          barcode: selectProduct.barcode,
          batch_no: batchNo,
          supplierName: selectProduct.supplier,
          supplier: selectProduct.supplier_id,
          reasons: reasons,
          subtotal: (purchasePrice + purchasePrice * (purchaseVat / 100)) * qty,
          netValue: purchasePrice * qty,
        },
      ]);
      setSelectProduct({});
      setQtyType("");
      setQty(0);
      setPurchasePrice(0);
      setPurchaseVat(0);
      setSalesPrice(0);
      setBatchNo("");
      setReasons("");
    }
  };

  const removeProduct = (id) => {
    setReturnList(
      returnList.filter((product) => {
        return product.product_id !== id;
      })
    );
    alert(`Removed Product ID ${id}`);
  };

  const totalReturnValue = returnList.reduce(
    (initialValue, product) => initialValue + product.subtotal,
    0
  );
  const netReturnValue = returnList.reduce(
    (initialValue, product) => initialValue + product.netValue,
    0
  );
  const handleSubmitReturn = (e) => {
    e.preventDefault();
    if (returnList.length !== 0 && vendorId.length) {
      const body = {
        returnitems: returnList,
        totalreturnvalue: totalReturnValue,
        netreturnvalue: netReturnValue,
        vendor_id: vendorId,
      };
      //console.log(body);
      dispatch(createReturn(body));
      setAlertMessage(true);
    } else if (returnList.length === 0) {
      alert("Select Product to Return");
    } else if (vendorId.length === 0) {
      alert("Select Vendor");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (barcode) {
      dispatch(productSearchWithBarcode(barcode));
      ref.current.blur();
      setTimeout(() => {
        setSearchBarcode(true);
        ref.current.value = "";
        setBarcode("");
        //ref.current.focus();
      }, 500);
    }
  };

  // My Custom Code (Hasan)
  const [suggestionSelect, setSuggestionSelect] = useState(null);
  const containerRef = useRef(null);
  const [childOffsets, setChildOffsets] = useState([]);

  useEffect(() => {
    setSuggestionSelect(null);
  }, [searchProducts]);

  useEffect(() => {
    if (
      showSearchResult &&
      searchProducts?.data !== undefined &&
      searchProducts?.data.results &&
      searchProducts?.data.results.length !== 0
    ) {
      // console.log("containerRef = ", containerRef.current);
      const childDivs = Array.from(containerRef.current.children);
      // console.log('childDivs = ', childDivs);
      const offsets = childDivs.map((child) => child.offsetTop);
      // console.log('offsets', offsets);
      setChildOffsets(offsets);
    }
  }, [searchProducts, showSearchResult]);

  const searchInputSuggestionHendler = (e) => {
    if (
      showSearchResult &&
      searchProducts?.data !== undefined &&
      searchProducts?.data.results?.length !== 0
    ) {
      if (e.key === "ArrowUp") {
        // console.log("In searchInputSuggestionHndler, you pressed = ", e.key);
        // console.log("searchProducts = ",searchProducts.data.results);
        if (suggestionSelect > 0) {
          setSuggestionSelect((previousState) => previousState - 1);
          // console.log("searchProducts = ",searchProducts.data.results);

          containerRef.current.scrollTo({
            top: childOffsets[suggestionSelect - 1],
            behavior: "smooth",
          });
        }
      }
      if (e.key === "ArrowDown") {
        if (suggestionSelect || suggestionSelect === 0) {
          if (searchProducts.data.results.length > suggestionSelect + 1) {
            setSuggestionSelect((previousState) => previousState + 1);
            // console.log("searchProducts = ",searchProducts.data.results);
            containerRef.current.scrollTo({
              top: childOffsets[suggestionSelect],
              behavior: "smooth",
            });
          }
        } else {
          setSuggestionSelect(0);
          // console.log("searchProducts = ",searchProducts.data.results);
        }
      }
      if (e.key === "Enter") {
        if (suggestionSelect || suggestionSelect === 0) {
          // console.log("Dispatch your action");
          setSelectProduct(searchProducts.data.results[suggestionSelect]);
          setShowSearchResult(false);
          setSuggestionSelect(null);
        }
      }
    }
  };

  //End of My Custom Code (Hasan)

  const listWithIndices = returnList.map((value, index) => ({ value, index }));
  //console.log(batchDetail);
  return (
    <div>
      <AddReturnModal
        show={previewOpen}
        onHide={() => setPreviewOpen(false)}
        returnList={returnList}
        listWithIndices={listWithIndices}
        totalReturnValue={totalReturnValue.toFixed(2)}
        netReturnValue={netReturnValue.toFixed(2)}
      />

      <SideBar />
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <h4 className="product-heading container-view">Return Add</h4>
          <p className="product-paragraph container-view">
            Return <FontAwesomeIcon icon={faChevronRight} size="xs" /> Add
            Return
          </p>
          <div className="mb-4 card product-card container-view">
            <div className="p-4">
              <Row>
                <Col lg={7} style={{ position: "relative" }}>
                  <Form.Group
                    className=" form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="search"
                      placeholder="Search By Product Name or Id or Barcode"
                      // onChange={handleSearchResult}

                      onChange={(e) => setSearchText(e.target.value)}
                      autoComplete="off"
                      onKeyDown={(e) => searchInputSuggestionHendler(e)}
                    />
                    {showSearchResult ? (
                      searchProducts?.data !== undefined &&
                      searchProducts?.data.results?.length ? (
                        <div
                          className="autocomplete_items shadow-lg"
                          //onScroll={DivScroll}
                          ref={containerRef}
                          style={{ zIndex: "2" }}
                        >
                          {searchProducts?.data.results.map((product, i) => (
                            <div
                              className={`card shadow m-2 rounded border border-0 p-2 ${
                                suggestionSelect !== null &&
                                suggestionSelect === i
                                  ? "selectedSearchItemHover"
                                  : ""
                              }`}
                              key={i}
                              onClick={() => {
                                setSelectProduct(product);
                                setShowSearchResult(false);
                              }}
                            >
                              <div>
                                <div className="d-flex align-content-center">
                                  <div className="">
                                    {product.product_name} {product.generic} - (
                                    {product.unit_size}) - (
                                    {product.sub_catagory_1})
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : !searchLoading &&
                        searchProducts?.data !== undefined &&
                        searchProducts?.data.results?.length === 0 ? (
                        <div
                          className="autocomplete_items shadow-lg"
                          //onScroll={DivScroll}
                          ref={containerRef}
                          style={{ zIndex: "2" }}
                        >
                          <div className="m-2 p-2">
                            <div>
                              <div className="d-flex align-content-center justify-content-center">
                                <div>No Product Found</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="autocomplete_items shadow-lg"
                          //onScroll={DivScroll}
                          ref={containerRef}
                          style={{ zIndex: "2" }}
                        >
                          <div className="m-2 p-2 ">
                            <div>
                              <div className="d-flex align-content-center justify-content-center">
                                <div>
                                  <Spinner
                                    animation="border"
                                    variant="warning"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={5}>
                  {selectProduct.product_name !== undefined ? (
                    <Form.Group
                      className=" form-group mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label> Selected Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Product name"
                        value={selectProduct.product_name}
                        disabled
                      />
                    </Form.Group>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Product Barcode</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="Search Product With Barcode"
                        style={{ borderRight: "0px" }}
                        value={
                          selectProduct.barcode !== null &&
                          selectProduct.barcode !== undefined
                            ? selectProduct.barcode
                            : ""
                        }
                        name="barcode"
                        disabled
                        //ref={ref}
                        //onChange={(e) => setBarcode(e.target.value)}
                      />
                      <InputGroup.Text style={{ background: "white" }}>
                        <FontAwesomeIcon icon={faBarcode} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Size & Units<span></span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Product Size & Units"
                      value={
                        selectProduct.unit_size !== undefined
                          ? selectProduct.unit_size
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Current Date </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Get From System"
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Vendor Id<span></span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Select Vendor Name"
                      value={vendorId}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Vendor Name<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setVendorId(e.target.value)}
                      value={vendorId}
                      required
                    >
                      <option value="">Choose Vendor Name</option>
                      {vendorList.data?.results.map((vendor) => (
                        <option key={vendor.id} value={vendor.id}>
                          {vendor.vendor_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Reasons<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="e.g:Damaged , expired"
                      value={reasons}
                      onChange={(e) => setReasons(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={4} lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Supplier Id</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Supplier Id"
                      value={
                        selectProduct.supplier_id !== null &&
                        selectProduct.supplier_id !== undefined
                          ? selectProduct.supplier_id
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Supplier Name </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Supplier Name"
                      value={
                        selectProduct.supplier !== null &&
                        selectProduct.supplier !== undefined
                          ? selectProduct.supplier
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} lg={6}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="d-flex justify-content-between">
                      Purchase Price
                      <div>
                        <button
                          className={
                            priceType === "old"
                              ? "purchase-price-btn active-btn"
                              : "purchase-price-btn inactive-btn me-2"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            if (selectProduct.id) {
                              setPriceType("old");
                              setPurchasePrice(selectProduct.purchase_price);
                              setSalesPrice(selectProduct.sale_price);
                            } else {
                              alert("Please , Select A Product");
                            }
                          }}
                        >
                          Old
                        </button>
                        <button
                          className={
                            priceType === "new"
                              ? "purchase-price-btn active-btn"
                              : "purchase-price-btn inactive-btn ms-2"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              selectProduct.id &&
                              selectProduct.new_purchase_price
                            ) {
                              setPriceType("new");
                              setPurchasePrice(
                                selectProduct.new_purchase_price
                              );
                              setSalesPrice(selectProduct.new_sale_price);
                            } else if (
                              selectProduct.id &&
                              !selectProduct.new_purchase_price
                            ) {
                              alert("Product do not have new Purchase Price");
                            } else {
                              alert("Please , Select A Product");
                            }
                          }}
                        >
                          New
                        </button>
                      </div>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Purchase Price"
                      value={purchasePrice}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Quantity<span className="required-field">*</span>
                    </Form.Label>
                    <Row>
                      {/* <Col xxl={3} xl={4} lg={5}>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label="Box"
                          value="Box"
                          onChange={(e) => setQtyType(e.target.value)}
                          checked={qtyType === "Box" ? true : false}
                          disabled={!selectProduct.stock_type ? false : true}
                        />
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label="Pcs"
                          value="Pcs"
                          onChange={(e) => setQtyType(e.target.value)}
                          checked={qtyType === "Pcs" ? true : false}
                          disabled={!selectProduct.stock_type ? false : true}
                        />
                      </Col> */}
                      <Col lg={12}>
                        <Form.Control
                          type="number"
                          placeholder="Quantity"
                          min={0}
                          value={qty}
                          onChange={(e) => setQty(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      In Stock (Wh)<span></span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="In Stock"
                      value={
                        selectProduct.wh_stock !== undefined
                          ? selectProduct.wh_stock
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Batch No.<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={batchNo}
                      onChange={(e) => setBatchNo(e.target.value)}
                    >
                      <option value="">Choose Batch Name</option>
                      {batchDetail.batchList?.batch?.map((batch) => (
                        <option key={batch} value={batch}>
                          {batch}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      MRP <span></span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Sales Price"
                      value={salesPrice}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xl={3} lg={6} className="mb-2">
                  <Button
                    className="submit-btn border-0"
                    onClick={() => clickAddProduct()}
                  >
                    Add Product
                  </Button>
                </Col>
              </Row>
              <Table className="mt-3 mb-4" responsive="xxl" hover>
                <thead className="text-center table-header">
                  <tr>
                    <th>SL</th>
                    <th>Name</th>
                    <th>Units</th>
                    <th>Qty</th>
                    <th>P. Price</th>
                    <th>Vat</th>
                    <th>Batch No.</th>
                    <th>Subtotal</th>
                    <th>Barcode</th>
                    <th>S. Name</th>
                    <th>Reasons</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {returnList.length > 0 ? (
                    listWithIndices
                      .sort((a, b) => b.index - a.index)
                      .map((list, index) => (
                        <tr
                          key={index}
                          className={
                            list.index === listWithIndices.length - 1
                              ? "inside-table"
                              : ""
                          }
                        >
                          <td>{index + 1}</td>
                          <td>{list.value.name}</td>
                          <td>{list.value.units}</td>
                          <td>{list.value.quantity}</td>
                          <td>{list.value.purchase_price}</td>
                          <td>{list.value.vat}%</td>
                          <td>{list.value.batch_no}</td>
                          <td>{list.value.subtotal.toFixed(2)}</td>
                          <td>{list.value.barcode}</td>
                          <td>{list.value.supplierName}</td>
                          <td>{list.value.reasons}</td>
                          <td style={{ cursor: "pointer" }}>
                            <FontAwesomeIcon
                              icon={faTrash}
                              color="#E40000"
                              style={{ paddingLeft: "0.4rem" }}
                              onClick={() =>
                                removeProduct(list.value.product_id)
                              }
                            />
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="13">No Product Added</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Row className="mt-2 pt-2 table-header">
                <Col lg={6}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Total Return Value</Form.Label>
                    <Form.Control
                      type="number"
                      value={totalReturnValue.toFixed(2)}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Net Return Value</Form.Label>
                    <Form.Control
                      type="number"
                      value={netReturnValue.toFixed(2)}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              {loading === true ? (
                <Row className="mt-2">
                  <Col xl={4} lg={12} className="d-flex justify-content-center">
                    <Spinner animation="border" variant="warning" />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row className="mt-3">
                <Col xl={2} lg={4} className="mb-2">
                  {loading === true ? (
                    <Button
                      className="disable-submit-btn"
                      variant="secondary"
                      disabled
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      className="submit-btn border-0"
                      onClick={handleSubmitReturn}
                    >
                      Submit
                    </Button>
                  )}
                </Col>

                <Col xl={2} lg={4} className="mb-2">
                  <Button
                    className="preview-btn border-0"
                    onClick={() => setPreviewOpen(true)}
                  >
                    Preview
                  </Button>
                </Col>

                <Col xl={2} lg={4}>
                  <Button className="cancel-btn border-0">Cancel</Button>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AddReturn;
