import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  updateSubcategory,
  getSubcategoryList,
} from "../../Redux/actions/subcategoryActions";
import { SuccessAlert } from "../notify/notify";

const UpdateSubCategoryModal = (props) => {
  const dispatch = useDispatch();
  const [updateSubcategoryName, setUpdateSubcategoryName] = useState("");
  const [updateCategoryId, setUpdateCategoryId] = useState("");
  const [updateMsg, setUpdateMsg] = useState(false);

  const { loading, message, error } = useSelector(
    (state) => state.updateSubcategory
  );

  useEffect(() => {
    if (props.subcategory !== "") {
      setUpdateSubcategoryName(props.subcategory.name);
      setUpdateCategoryId(props.subcategory.catagory);
    }
  }, [props.subcategory]);

  useEffect(() => {
    if (message !== undefined && updateMsg === true) {
      //console.log("inside", deleteUserResponse.message);
      SuccessAlert("Sub-Category Updated Successfully");
      dispatch(getSubcategoryList());
      setUpdateMsg(false);
    }
    if (error !== undefined && updateMsg === true) {
      setUpdateMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [message, error]);

  const updateSubcategoryHnadle = (e) => {
    e.preventDefault();
    if (
      updateSubcategoryName !== props.subcategory.name ||
      updateCategoryId !== props.subcategory.catagory
    ) {
      const body = {
        name: updateSubcategoryName,
        catagory: updateCategoryId,
      };
      dispatch(updateSubcategory(props.subcategory.id, body));
      setUpdateMsg(true);
      props.onHide();
    } else {
      props.onHide();
    }
  };
  //console.log(props.subcategoy);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto" id="contained-modal-title-vcenter">
          Update Sub Category ({props.subcategory.id})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <b>Sub Category Name</b>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="e.g: Baby Food"
              value={updateSubcategoryName}
              onChange={(e) => setUpdateSubcategoryName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className=" form-group mb-3" controlId="formBasicEmail">
            <Form.Label>Category Name</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => setUpdateCategoryId(e.target.value)}
              value={updateCategoryId}
              required
            >
              {props.categories?.results.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Form>
        <Row className="mt-3 d-flex justify-content-center">
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button className="cancel-btn border-0" onClick={props.onHide}>
              Cancel
            </Button>
          </Col>
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button
              className="submit-btn border-0"
              onClick={updateSubcategoryHnadle}
            >
              Update
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateSubCategoryModal;
