import {
  ALL_SUPPLIER_WISE_STOCK_REQUEST,
  ALL_SUPPLIER_WISE_STOCK_SUCCESS,
  ALL_SUPPLIER_WISE_STOCK_FAILURE,
  SUPPLIER_WISE_STOCK_REQUEST,
  SUPPLIER_WISE_STOCK_SUCCESS,
  SUPPLIER_WISE_STOCK_FAILURE,
  SUBCATEGORY_STOCK_REPORT_REQUEST,
  SUBCATEGORY_STOCK_REPORT_SUCCESS,
  SUBCATEGORY_STOCK_REPORT_FAILURE,
  CATEGORY_STOCK_REPORT_REQUEST,
  CATEGORY_STOCK_REPORT_SUCCESS,
  CATEGORY_STOCK_REPORT_FAILURE,
  SUB_CATEGORY_WISE_PRODUCT_REQUEST,
  SUB_CATEGORY_WISE_PRODUCT_SUCCESS,
  SUB_CATEGORY_WISE_PRODUCT_FAILURE,
} from "../type.js";

export const allSupplierStockReducer = (
  state = { allSupplierStockList: {} },
  action
) => {
  switch (action.type) {
    case ALL_SUPPLIER_WISE_STOCK_REQUEST:
      return { allSupplierStockLoading: true, ...state };
    case ALL_SUPPLIER_WISE_STOCK_SUCCESS:
      return {
        allSupplierStockLoading: false,
        allSupplierStockList: action.payload,
      };
    case ALL_SUPPLIER_WISE_STOCK_FAILURE:
      return {
        allSupplierStockLoading: false,
        allSupplierStockError: action.payload,
      };
    default:
      return state;
  }
};

export const supplierWiseStockReducer = (
  state = { supplierWiseStock: {} },
  action
) => {
  switch (action.type) {
    case SUPPLIER_WISE_STOCK_REQUEST:
      return { supplierWiseStockLoading: true, ...state };
    case SUPPLIER_WISE_STOCK_SUCCESS:
      return {
        supplierWiseStockLoading: false,
        supplierWiseStock: action.payload,
      };
    case SUPPLIER_WISE_STOCK_FAILURE:
      return {
        allSupplierStockLoading: false,
        supplierWiseStockError: action.payload,
      };
    default:
      return state;
  }
};

export const subcategoryStockReducer = (
  state = { subcategoryStockList: {} },
  action
) => {
  switch (action.type) {
    case SUBCATEGORY_STOCK_REPORT_REQUEST:
      return { subCategoryLoading: true, ...state };
    case SUBCATEGORY_STOCK_REPORT_SUCCESS:
      return {
        subCategoryLoading: false,
        subcategoryStockList: action.payload,
      };
    case SUBCATEGORY_STOCK_REPORT_FAILURE:
      return { subCategoryLoading: false, subCategoryError: action.payload };
    default:
      return state;
  }
};

export const categoryStockReducer = (
  state = { categoryStockList: {} },
  action
) => {
  switch (action.type) {
    case CATEGORY_STOCK_REPORT_REQUEST:
      return { categoryLoading: true, ...state };
    case CATEGORY_STOCK_REPORT_SUCCESS:
      return { categoryLoading: false, categoryStockList: action.payload };
    case CATEGORY_STOCK_REPORT_FAILURE:
      return { categoryLoading: false, categoryError: action.payload };
    default:
      return state;
  }
};

export const subcategoryWiseProductReducer = (
  state = { subcategoryWiseProductList: {} },
  action
) => {
  switch (action.type) {
    case SUB_CATEGORY_WISE_PRODUCT_REQUEST:
      return { productLoading: true, ...state };
    case SUB_CATEGORY_WISE_PRODUCT_SUCCESS:
      return {
        productLoading: false,
        subcategoryWiseProductList: action.payload,
      };
    case SUB_CATEGORY_WISE_PRODUCT_FAILURE:
      return { productLoading: false, productError: action.payload };
    default:
      return state;
  }
};
