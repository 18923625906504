import axios from "axios";
import {
  PURCHASE_ORDER_LIST_REQUEST,
  PURCHASE_ORDER_LIST_SUCCESS,
  PURCHASE_ORDER_LIST_FAILURE,
  CREATE_PURCHASE_ORDER_REQUEST,
  CREATE_PURCHASE_ORDER_SUCCESS,
  CREATE_PURCHASE_ORDER_FAILURE,
  EACH_PURCHASE_ORDER_DETAIL_REQUEST,
  EACH_PURCHASE_ORDER_DETAIL_SUCCESS,
  EACH_PURCHASE_ORDER_DETAIL_FAILURE,
  SEARCH_PURCHASE_ORDER_LIST_REQUEST,
  SEARCH_PURCHASE_ORDER_LIST_SUCCESS,
  SEARCH_PURCHASE_ORDER_LIST_FAILURE,
  PO_PRODUCT_FILTER_REQUEST,
  PO_PRODUCT_FILTER_SUCCESS,
  PO_PRODUCT_FILTER_FAILURE,
} from "../type.js";

export const getPurchaseOrderList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: PURCHASE_ORDER_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/purchaseOrderList/?limit=${limit}&offset=${offset}`,
      config
    );

    dispatch({
      type: PURCHASE_ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PURCHASE_ORDER_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createPurchaseOrder =
  (supplierId, vendorId, purchaseProducts) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_PURCHASE_ORDER_REQUEST,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
      };
      const body =
        vendorId !== ""
          ? {
              supplier: supplierId,
              vendor: vendorId,
              purchase_product: purchaseProducts,
            }
          : {
              supplier: supplierId,
              purchase_product: purchaseProducts,
            };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/purchaseOrder/`,
        body,
        config
      );

      dispatch({
        type: CREATE_PURCHASE_ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_PURCHASE_ORDER_FAILURE,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const getPurchaseOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: EACH_PURCHASE_ORDER_DETAIL_REQUEST });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/PurchaseOrderDetails/${id}/`,
      config
    );
    dispatch({
      type: EACH_PURCHASE_ORDER_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EACH_PURCHASE_ORDER_DETAIL_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSearchPurchaseOrderList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_PURCHASE_ORDER_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/purchaseOrderList/?query=${keyword}&limit=20&offset=0`,
      config
    );

    dispatch({
      type: SEARCH_PURCHASE_ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_PURCHASE_ORDER_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const poProductFilterAction =
  (keyword, supplierId) => async (dispatch) => {
    try {
      dispatch({
        type: PO_PRODUCT_FILTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const body = {
        supplier_id: supplierId,
      };
      const data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/PoProductFilter${keyword}`,
        body,
        config
      );
      dispatch({
        type: PO_PRODUCT_FILTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PO_PRODUCT_FILTER_FAILURE,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
