import jsPDF from "jspdf";
import "jspdf-autotable";

const addFooters = (doc, date, time) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text("Date:" + date, 16, doc.internal.pageSize.height - 10);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
    doc.text(
      "Time:" + time,
      doc.internal.pageSize.width - 35,
      doc.internal.pageSize.height - 10
    );
  }
};

const ProductBiographyPdf = (itemDetails) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });
  const date = new Date();
  const tableRows = itemDetails.map((item, i) => [
    i + 1,
    item.date,
    item.quantity_in,
    item.quantity_out,
    item.cpu,
    item.rpu,
    item.steps,
    item.user,
  ]);

  const grandTotalRow = [
    {
      content: `Total`,
      styles: {
        fontStyle: "bold",
      },
    },
    "",

    {
      content: `${itemDetails.reduce((previousResult, currentValue) => {
        return previousResult + currentValue.quantity_in;

        // console.log("currentValue = ", currentValue);
        // console.log(
        //   "currentValue.quantity_in = ",
        //   currentValue.quantity_in
        // );
      }, 0)}`,
      styles: {
        fontStyle: "bold",
      },
    },
    {
      content: `${itemDetails.reduce((previousResult, currentValue) => {
        return previousResult + currentValue.quantity_out;

        // console.log("currentValue = ", currentValue);
        // console.log(
        //   "currentValue.quantity_in = ",
        //   currentValue.quantity_in
        // );
      }, 0)}`,
      styles: {
        fontStyle: "bold",
      },
    },

    "",
    "",
    "",
    "",
  ];

  tableRows.push(grandTotalRow);

  const columnData = [
    "SL No",
    "Date",
    "Quantity Inn",
    "Quantity Out",
    "CPU",
    "RPU",
    "Steps",
    "User",
  ];
  var title = "Care-Box";
  var reportName = "Product Biography";
  var titleWidth = doc.getTextDimensions(title).w;
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  var x = (pageWidth - titleWidth) / 2;
  doc.text(title, x, 14);
  doc.text(
    reportName,
    (pageWidth - doc.getTextDimensions(reportName).w) / 2,
    20
  );
  doc.setFontSize(8);
  doc.text(
    `Product Information: ${itemDetails[0].product_name} ${itemDetails[0].generic_name} ${itemDetails[0].product_unit}`,
    16,
    28
  );
  doc.text(
    `Product Barcode: ${itemDetails[0].barcode}`,
    doc.internal.pageSize.width - 56,
    28
  );
  doc.setFontSize(8);

  doc.autoTable({
    //styles: { fillColor: [0, 0, 0] },
    startY: 35,
    columns: columnData,
    body: tableRows,
    theme: "plain",
    styles: {
      halign: "center",
      lineColor: "DCE0E4",
      lineWidth: 0.2,
    },
    headStyles: {
      textColor: "black",
      fillColor: "#fafbfe",
    },
  });

  addFooters(doc, date.toLocaleDateString(), date.toLocaleTimeString());
  doc.save(
    "Care-Box-Product Biography" +
      itemDetails.id +
      "_" +
      date.toLocaleTimeString()
  );
};

export default ProductBiographyPdf;
