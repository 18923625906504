import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Spinner, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { nearExpiryProductReport } from "../../../Redux/actions/stockAnalysisActions";

const NearExpiryReport = (props) => {
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (props.nearExpiryProductList?.data) {
      setTotalItems(props.nearExpiryProductList.data.count);
    }
  }, [props.nearExpiryProductList]);
  const pageCount = Math.ceil(totalItems / props.limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.limit) % totalItems;
    if (props.categoryId !== "") {
      if (props.fromDate !== "" && props.toDate !== "") {
        dispatch(
          nearExpiryProductReport(
            `?limit=${props.limit}&offset=${newOffset}&Catagory=${props.categoryId}&From=${props.fromDate}&To=${props.toDate}`
          )
        );
      } else {
        dispatch(
          nearExpiryProductReport(
            `?limit=${props.limit}&offset=${newOffset}&Catagory=${props.categoryId}`
          )
        );
      }
    }
  };
  return (
    <>
      <Table bordered responsive>
        <thead className="text-center report-table-header report-border">
          <tr>
            <th>Barcode</th>
            <th>Supplier Name</th>
            <th>Category</th>
            <th>Product Information</th>
            <th>Batch No.</th>
            <th>Received (Qty)</th>
            <th>Sold (Qty)</th>
            <th>Balance (Qty)</th>
            <th>CPU</th>
            <th>RPU</th>
            <th>Pft%</th>
            <th>Purchase Date</th>
            <th>Expiry Date</th>
          </tr>
        </thead>
        <tbody className="text-center report-table-body">
          {props.nearExpiryProductList.data &&
          !props.nearExpiryProductLoading &&
          props.nearExpiryProductList.data.data.length ? (
            <>
              {props.nearExpiryProductList.data.data.map((prod, i) => (
                <tr key={i}>
                  <td>
                    {prod.product__barcode ? prod.product__barcode : "N/A"}
                  </td>
                  <td>{prod.product__supplier_Name__supplier_name}</td>
                  <td>{prod.product__Sub_catagory_1__catagory__name}</td>
                  <td>
                    {prod.product__product_name} {prod.product__generic_name}{" "}
                    {prod.product__unit}
                  </td>
                  <td>{prod.batch_no} </td>
                  <td>{prod.totalreceived}</td>
                  <td>{prod.soldtocustomer}</td>
                  <td>{prod.balanceQty}</td>
                  <td>
                    {prod.product__new_purchase_price
                      ? prod.product__new_purchase_price
                      : prod.product__purchase_price}
                  </td>
                  <td>
                    {prod.product__new_product_price
                      ? prod.product__new_product_price
                      : prod.product__product_price}
                  </td>
                  <td>
                    {prod.product__Profit_margin
                      ? prod.product__Profit_margin
                      : "N/A"}
                  </td>
                  <td>{prod.created_at__date}</td>
                  <td>{prod.expire_date}</td>
                </tr>
              ))}
              <tr className="grand-total-row">
                <td>Grand Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {props.nearExpiryProductList.data.data.reduce(
                    (total, prod) => (total += prod.totalreceived),
                    0
                  )}
                </td>
                <td>
                  {props.nearExpiryProductList.data.data.reduce(
                    (total, prod) => (total += prod.soldtocustomer),
                    0
                  )}
                </td>
                <td>
                  {props.nearExpiryProductList.data.data.reduce(
                    (total, prod) => (total += prod.balanceQty),
                    0
                  )}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </>
          ) : props.nearExpiryProductLoading ? (
            <tr>
              <td colSpan={13}>
                <Spinner animation="border" variant="warning" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={13}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
      {pageCount > 1 && !props.nearExpiryProductLoading ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousClassName="hide"
          nextClassName="hide"
          activeLinkClassName="active"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default NearExpiryReport;
