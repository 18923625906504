import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Spinner, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getGpCompareReport } from "../../../Redux/actions/stockAnalysisActions";

const GpWiseProductReport = (props) => {
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (props.gpCompareList?.data) {
      setTotalItems(props.gpCompareList.data.count);
    }
  }, [props.gpCompareList]);
  const pageCount = Math.ceil(totalItems / props.limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.limit) % totalItems;
    if (props.fromPercentage !== "" || props.toPercentage !== "") {
      dispatch(
        getGpCompareReport(
          `?limit=${props.limit}&offset=${newOffset}&supplier=${props.supplierId}&from_percentage=${props.fromPercentage}&to_percentage=${props.toPercentage}`
        )
      );
    } else {
      dispatch(
        getGpCompareReport(
          `?limit=${props.limit}&offset=${newOffset}&supplier_id=${props.supplierId}`
        )
      );
    }
  };
  return (
    <>
      <div className="mt-2 transfer-table">
        <Row
          className="mx-0 py-2 table-header"
          //style={{ borderBottom: "1px solid #dee2e6" }}
        >
          <Col lg={4}>
            <b>Supplier Name: </b>{" "}
            {props.selectedSupplier.length
              ? props.selectedSupplier[0].supplier_name
              : ""}
          </Col>
          <Col lg={2}>
            <b>Total Products: </b>{" "}
            {props.gpCompareList.data
              ? props.gpCompareList.data.results.length
              : ""}
          </Col>
          <Col lg={3} className="d-flex justify-content-center">
            <b>From Percentage: </b>{" "}
            {props.fromPercentage ? props.fromPercentage : "N/A"}
          </Col>
          <Col lg={3} className="d-flex justify-content-end">
            <b>To Percentage: </b>
            {props.toPercentage ? props.toPercentage : "N/A"}
          </Col>
        </Row>
      </div>
      <Table bordered responsive>
        <thead className="text-center report-table-header report-border">
          <tr>
            <th>Barcode</th>
            <th>Category</th>
            <th>Product Information</th>
            <th>CPU</th>
            <th>RPU</th>
            <th>Pft%</th>
          </tr>
        </thead>
        <tbody className="text-center report-table-body">
          {props.gpCompareList.data &&
          !props.gpCompareLoading &&
          props.gpCompareList.data.results.length ? (
            props.gpCompareList.data.results.map((prod, i) => (
              <tr key={i}>
                <td>{prod.barcode ? prod.barcode : "N/A"}</td>
                <td>{prod.category_name}</td>
                <td>
                  {prod.product_name} {prod.generic_name} {prod.unit}
                </td>
                <td>{prod.cost_per_unit}</td>
                <td>{prod.retail_per_unit}</td>
                <td>{prod.gross_profit}</td>
              </tr>
            ))
          ) : props.gpCompareLoading ? (
            <tr>
              <td colSpan={12}>
                <Spinner animation="border" variant="warning" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={12}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
      {pageCount > 1 && !props.gpCompareLoading ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousClassName="hide"
          nextClassName="hide"
          activeLinkClassName="active"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default GpWiseProductReport;
