import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getCategoryStockReport } from "../../../Redux/actions/stockReportActions";

const CategoryStockReport = (props) => {
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (props.categoryStockList?.data) {
      setTotalItems(props.categoryStockList.data.count);
    }
  }, [props.categoryStockList]);
  const pageCount = Math.ceil(totalItems / props.limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.limit) % totalItems;
    if (props.fromDate !== "" && props.toDate !== "") {
      dispatch(
        getCategoryStockReport(
          `/?limit=${props.limit}&offset=${newOffset}&from_date=${props.fromDate}&to_date=${props.toDate}`
        )
      );
    } else {
      dispatch(
        getCategoryStockReport(`/?limit=${props.limit}&offset=${newOffset}`)
      );
    }
  };
  return (
    <>
      <Table className="mt-3" responsive bordered>
        <thead className="text-center report-table-header">
          <tr>
            <th>Category Name</th>
            <th>Location</th>
            <th>Total Received Qty</th>
            <th>Stock Return</th>
            <th>Total Sold</th>
            <th>Exchange/Void</th>
            <th>Balance Stock</th>
            <th>Total Balance(Cost)</th>
            <th>Total Balance(Sale)</th>
          </tr>
        </thead>
        <tbody className="text-center report-table-body">
          {props.categoryStockList.data && !props.categoryLoading ? (
            <>
              {props.categoryStockList.data.results.map((cat, i) => (
                <tr key={i}>
                  <td>{cat.category_name}</td>
                  <td>{cat.category_location}</td>
                  <td>{cat.total_received}</td>
                  <td>{cat.total_returned}</td>
                  <td>{cat.sold}</td>
                  <td>{cat.total_exchanged}</td>
                  <td>{cat.stock}</td>
                  <td>{cat.cost_price}</td>
                  <td>{cat.sale_price}</td>
                </tr>
              ))}
              <tr className="grand-total-row">
                <td className="text-start">Grand Total</td>
                <td></td>
                <td>
                  {props.categoryStockList.data.results.reduce(
                    (total, prod) => (total += prod.total_received),
                    0
                  )}
                </td>
                <td>
                  {props.categoryStockList.data.results.reduce(
                    (total, prod) => (total += prod.total_returned),
                    0
                  )}
                </td>
                <td>
                  {props.categoryStockList.data.results.reduce(
                    (total, prod) => (total += prod.sold),
                    0
                  )}
                </td>
                <td>
                  {props.categoryStockList.data.results.reduce(
                    (total, prod) => (total += prod.total_exchanged),
                    0
                  )}
                </td>
                <td>
                  {props.categoryStockList.data.results.reduce(
                    (total, prod) => (total += prod.stock),
                    0
                  )}
                </td>
                <td>
                  {props.categoryStockList.data.results
                    .reduce((total, prod) => (total += prod.cost_price), 0)
                    .toFixed(2)}
                </td>
                <td>
                  {props.categoryStockList.data.results
                    .reduce((total, prod) => (total += prod.sale_price), 0)
                    .toFixed(2)}
                </td>
              </tr>
            </>
          ) : props.categoryLoading ? (
            <tr>
              <td colSpan={12}>
                <Spinner animation="border" variant="warning" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={12}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
      {pageCount > 1 && !props.categoryLoading ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousClassName="hide"
          nextClassName="hide"
          activeLinkClassName="active"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CategoryStockReport;
