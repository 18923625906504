import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import {
  createProductRequest,
  getProductRequestList,
  createPurchaseRequest,
  deleteRequestProduct,
} from "../../Redux/actions/purchaseActions";
import { SuccessAlert, ErrorAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";
import AddRequestPurchaseModal from "../PopupModal/AddRequestPurchaseModal/AddRequestPurchaseModal";

const AddRequestPurchase = () => {
  const dispatch = useDispatch();
  const [productName, setProductName] = useState("");
  const [productQuantity, setProductQuantity] = useState("");
  const [productSize, setProductSize] = useState("");
  const [productJustification, setProductJustification] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [createMessage, setCreateMessage] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false);

  const { loading, message, error } = useSelector(
    (state) => state.createProductRequest
  );
  const productRequestResponse = useSelector(
    (state) => state.productRequestList
  );
  const deleteProductRequestResponse = useSelector(
    (state) => state.deleteRequestProduct
  );
  const purchaseRequestResponse = useSelector(
    (state) => state.createPurchaseRequest
  );
  useEffect(() => {
    dispatch(getProductRequestList());
  }, []);
  useEffect(() => {
    if (alertMessage && message === undefined && error !== undefined) {
      if (error.product_name) {
        alert(`Product Name: ${error.product_name}`);
        setAlertMessage(false);
      } else if (error.quantity) {
        alert(`Product Quantity: ${error.quantity}`);
        setAlertMessage(false);
      } else if (error.unit_or_size) {
        alert(`Unit of Measurement (UOM): ${error.unit_or_size}`);
        setAlertMessage(false);
      } else if (error.justification) {
        alert(`Justifications: ${error.justification}`);
        setAlertMessage(false);
      } else {
        alert(error);
        setAlertMessage(false);
      }
    }
    if (alertMessage && message !== undefined) {
      dispatch(getProductRequestList());
      setProductName("");
      setProductQuantity("");
      setProductSize("");
      setProductJustification("");
      setAlertMessage(false);
    }
  }, [message, error]);

  useEffect(() => {
    if (
      createMessage &&
      purchaseRequestResponse.message === undefined &&
      purchaseRequestResponse.error !== undefined
    ) {
      if (error.product_items) {
        alert(`Product Items: ${error.product_items}`);
        setCreateMessage(false);
      } else {
        alert(error);
        setCreateMessage(false);
      }
    }
    if (createMessage && purchaseRequestResponse.message !== undefined) {
      dispatch(getProductRequestList());
      SuccessAlert(
        `Purchase Request Created Successfully. Purchase Request ID: ${purchaseRequestResponse.message.data}`
      );
      setAlertMessage(false);
    }
  }, [purchaseRequestResponse.message, purchaseRequestResponse.error]);

  useEffect(() => {
    if (
      deleteMessage &&
      deleteProductRequestResponse.message === undefined &&
      deleteProductRequestResponse.error !== undefined
    ) {
      setDeleteMessage(false);
      ErrorAlert("Something Went Wrong. Please Try Again");
    }
    if (deleteMessage && deleteProductRequestResponse.message !== undefined) {
      dispatch(getProductRequestList());
      setDeleteMessage(false);
      SuccessAlert("Product Removed Successfully");
    }
  }, [
    deleteProductRequestResponse.message,
    deleteProductRequestResponse.error,
  ]);
  const handleAddProduct = (e) => {
    e.preventDefault();
    let body = {
      product_name: productName,
      quantity: productQuantity,
      unit_or_size: productSize,
      justification: productJustification,
    };
    dispatch(createProductRequest(body));
    setAlertMessage(true);
  };
  const deleteProductRequest = (id) => {
    dispatch(deleteRequestProduct(id));
    setDeleteMessage(true);
  };
  const ProductIds = [];
  const handleCreatePurchaseRequest = (e) => {
    e.preventDefault();
    if (productRequestResponse.productRequestList?.data?.results.length) {
      productRequestResponse.productRequestList?.data?.results.map(
        (product) => {
          ProductIds.push(product.id);
        }
      );
      const body = {
        product_items: ProductIds,
      };
      //console.log(body);
      dispatch(createPurchaseRequest(body));
      setCreateMessage(true);
    } else {
      alert("Add Product to Create Purchase Request");
    }
  };
  //console.log(deleteProductRequestResponse);
  return (
    <div>
      <AddRequestPurchaseModal
        show={previewOpen}
        onHide={() => setPreviewOpen(false)}
        productRequestResponse={productRequestResponse}
      />

      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <h4 className="product-heading container-view">
            Add Purchase Request
          </h4>
          <p className="product-paragraph container-view">
            Purchase <FontAwesomeIcon icon={faChevronRight} size="xs" /> Add
            Purchase Request
          </p>
          {/* <div className="mb-4 p-3 card product-card container-view"></div> */}
          <div className="mb-4 p-3 card product-card container-view">
            <Form onSubmit={handleCreatePurchaseRequest}>
              <Row>
                <h4 style={{ fontSize: "20px", fontWeight: "600" }}>
                  Add Purchase Request:
                </h4>
              </Row>
              <Row className="mt-3">
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Product Name<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Product Name"
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Product Quantity<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="e.g: 1/2/3"
                      value={productQuantity}
                      onChange={(e) => setProductQuantity(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Unit of Measurement (UOM)
                      <span className="required-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="(Unit & Size) eg: 250gm"
                      value={productSize}
                      onChange={(e) => setProductSize(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    Justifications<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    style={{ height: "100px" }}
                    placeholder="Write Your Justifications Here....."
                    value={productJustification}
                    onChange={(e) => setProductJustification(e.target.value)}
                  />
                </Form.Group>
              </Row>
              {loading === true ? (
                <Row className="mt-2 d-flex justify-content-center">
                  <Spinner animation="border" variant="warning" />
                </Row>
              ) : (
                <></>
              )}
              <Row className="mt-3">
                <Col xl={3} lg={6} className="mb-2">
                  <Button
                    className="submit-btn border-0"
                    type="submit"
                    onClick={handleAddProduct}
                  >
                    Add Product
                  </Button>
                </Col>
              </Row>
              <Table
                className="mt-3 mb-4"
                responsive="xxl"
                hover
                style={{ fontSize: "14px" }}
              >
                <thead className="text-center table-header">
                  <tr>
                    <th>SL No.</th>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Unit of Measurement</th>
                    <th>Justifications</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {productRequestResponse.productRequestList.data !==
                    undefined &&
                  productRequestResponse.productRequestList?.data.results
                    .length ? (
                    productRequestResponse.productRequestList.data.results.map(
                      (item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{item.product_name}</td>
                          <td>{item.quantity}</td>
                          <td>{item.unit_or_size}</td>
                          <td>{item.justification}</td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteProductRequest(item.id)}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              color="#E40000"
                              style={{ paddingLeft: "0.4rem" }}
                            />
                          </td>
                        </tr>
                      )
                    )
                  ) : productRequestResponse.loading === true ? (
                    <tr>
                      <td colSpan="12">
                        <Spinner animation="border" variant="warning" />
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="12">No Result Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {purchaseRequestResponse.loading === true ? (
                <Row className="mt-2">
                  <Col xl={4} lg={12} className="d-flex justify-content-center">
                    <Spinner animation="border" variant="warning" />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row className="mt-3">
                <Col xl={2} lg={4} className="mb-2">
                  {purchaseRequestResponse.loading === true ? (
                    <Button
                      className="disable-submit-btn"
                      variant="secondary"
                      disabled
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button className="submit-btn border-0" type="submit">
                      Submit
                    </Button>
                  )}
                </Col>

                <Col xl={2} lg={4} className="mb-2">
                  <Button
                    className="preview-btn border-0"
                    onClick={() => setPreviewOpen(true)}
                  >
                    Preview
                  </Button>
                </Col>

                <Col xl={2} lg={4}>
                  <Button className="cancel-btn border-0">Cancel</Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AddRequestPurchase;
