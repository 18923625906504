import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getSubcategoryWiseProduct } from "../../../Redux/actions/stockReportActions";

const SubcategoryWiseProduct = (props) => {
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (props.subcategoryWiseProductList?.data) {
      setTotalItems(props.subcategoryWiseProductList.data.count);
    }
  }, [props.subcategoryWiseProductList]);
  const pageCount = Math.ceil(totalItems / props.limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.limit) % totalItems;
    if (props.selectReport === "Sub Category wise Product Information") {
      if (props.selectSubcategory === "all") {
        dispatch(
          getSubcategoryWiseProduct(
            `/?limit=${props.limit}&offset=${newOffset}`
          )
        );
      } else if (
        props.selectSubcategory !== "all" &&
        props.selectSubcategory !== ""
      ) {
        dispatch(
          getSubcategoryWiseProduct(
            `/?limit=${props.limit}&offset=${newOffset}&sub_category=${props.selectSubcategory}`
          )
        );
      } else {
        alert("Please Select Sub Category");
      }
    } else {
      if (props.selectProduct !== "") {
        dispatch(
          getSubcategoryWiseProduct(
            `/?limit=${props.limit}&offset=${newOffset}&search=${props.selectProduct}`
          )
        );
      } else {
        alert("Please Select Product");
      }
    }
  };
  return (
    <>
      <Table className="mt-3" responsive bordered>
        <thead className="text-center report-table-header">
          <tr>
            <th>Product Information</th>
            <th>Sub Category</th>
            <th>Barcode</th>
            <th>Total Received(Qty)</th>
            <th>Return(Qty)</th>
            <th>Exchanged/Void(Qty)</th>
            <th>Total Sold(Qty)</th>
            <th>Balance(Qty)</th>
            <th>Cost(Tk)</th>
            <th>Sale(Tk)</th>
          </tr>
        </thead>
        <tbody className="text-center report-table-body">
          {props.subcategoryWiseProductList.data &&
          !props.productLoading &&
          props.subcategoryWiseProductList.data.results.length ? (
            <>
              {props.subcategoryWiseProductList.data.results.map((prod, i) => (
                <tr key={i}>
                  <td>{prod.product_name}</td>
                  <td>{prod.sub_category}</td>
                  <td>{prod.barcode ? prod.barcode : "N/A"}</td>
                  <td>{prod.total_purchased ? prod.total_purchased : 0}</td>
                  <td>{prod.total_returned ? prod.total_returned : 0}</td>
                  <td>{prod.total_exchanged ? prod.total_exchanged : 0}</td>
                  <td>{prod.sold ? prod.sold : 0}</td>
                  <td>{prod.stock}</td>
                  <td>{prod.purchase_price}</td>
                  <td>{prod.sell_price}</td>
                </tr>
              ))}
              <tr className="grand-total-row">
                <td className="text-start">Grand Total</td>
                <td></td>
                <td></td>
                <td>
                  {props.subcategoryWiseProductList.data.results.reduce(
                    (total, prod) =>
                      prod.total_purchased
                        ? (total += prod.total_purchased)
                        : (total += 0),
                    0
                  )}
                </td>
                <td>
                  {props.subcategoryWiseProductList.data.results.reduce(
                    (total, prod) =>
                      prod.total_returned
                        ? (total += prod.total_returned)
                        : (total += 0),
                    0
                  )}
                </td>
                <td>
                  {props.subcategoryWiseProductList.data.results.reduce(
                    (total, prod) =>
                      prod.total_exchanged
                        ? (total += prod.total_exchanged)
                        : (total += 0),
                    0
                  )}
                </td>
                <td>
                  {props.subcategoryWiseProductList.data.results.reduce(
                    (total, prod) =>
                      prod.sold ? (total += prod.sold) : (total += 0),
                    0
                  )}
                </td>
                <td>
                  {props.subcategoryWiseProductList.data.results.reduce(
                    (total, prod) =>
                      prod.stock ? (total += prod.stock) : (total += 0),
                    0
                  )}
                </td>
                <td>
                  {props.subcategoryWiseProductList.data.results
                    .reduce(
                      (total, prod) =>
                        prod.purchase_price
                          ? (total += prod.purchase_price)
                          : (total += 0),
                      0
                    )
                    .toFixed(2)}
                </td>
                <td>
                  {props.subcategoryWiseProductList.data.results
                    .reduce(
                      (total, prod) =>
                        prod.sell_price
                          ? (total += prod.sell_price)
                          : (total += 0),
                      0
                    )
                    .toFixed(2)}
                </td>
              </tr>
            </>
          ) : props.productLoading ? (
            <tr>
              <td colSpan={12}>
                <Spinner animation="border" variant="warning" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={12}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
      {pageCount > 1 && !props.productLoading ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousClassName="hide"
          nextClassName="hide"
          activeLinkClassName="active"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SubcategoryWiseProduct;
