import { Fragment } from "react";
import { Table, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";

const ReturnListTableRow = ({
  list,
  handleClick,
  isCheck,
  setSelected,
  selected,
  setDeleteModalShow,
  setSelectedItem,
}) => {
  return (
    <Fragment key={list.uuid}>
      <tr className="accordion accordion-flush" id="accordionFlushExample">
        <td>
          <Form.Check
            key={list.uuid}
            type="checkbox"
            id={list.uuid}
            onChange={handleClick}
            checked={isCheck.includes(list.uuid)}
          />
        </td>
        <td>{list.uuid}</td>
        <td>{list.vendor_name}</td>
        <td>{list.totalreturnvalue}</td>
        <td>{list.netreturnvalue}</td>
        <td>{list.created_at.split("T")[0]}</td>
        <td>{list.createdby_name}</td>
        <td style={{ cursor: "pointer" }}>
          <FontAwesomeIcon
            icon={faEye}
            color="#637381"
            className="collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
            onClick={() =>
              setSelected(selected !== list.uuid ? list.uuid : null)
            }
          />
          {/* <FontAwesomeIcon
                            icon={faTrashCan}
                            color="#E40000"
                            style={{ paddingLeft: "0.4rem" }}
                            onClick={() => {
                              setDeleteModalShow(true);
                              setSelectedReturn(list);
                            }}
                          /> */}
        </td>
      </tr>
      <tr
        id={selected === list.uuid ? "flush-collapseOne" : "collapse"}
        className={
          selected === list.uuid
            ? "accordion-collapse collapse show"
            : "accordion-collapse collapse"
        }
        aria-labelledby="flush-headingOne"
        data-bs-parent="#accordionFlushExample"
      >
        <td colSpan={12}>
          <Table className="mt-2 inside-table" responsive>
            <thead className="text-center">
              <tr>
                <th>Id</th>
                <th>Product Name</th>
                <th>Unit</th>
                <th>Qty</th>
                <th>P. Price</th>
                <th>Vat %</th>
                <th>Batch No.</th>
                <th>SubTotal</th>
                <th>Barcode</th>
                <th>S. Name</th>
                <th>Reasons</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {list.returnitem.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.product__product_name}</td>
                  <td>{item.product__unit}</td>
                  <td>{item.quantity}</td>
                  <td>{item.purchase_price}</td>
                  <td>{item.vat ? item.vat + "%" : "N/A"}</td>
                  <td>{item.batch_no}</td>
                  <td>{item.subtotal}</td>
                  <td>{item.product__barcode}</td>
                  <td>
                    {item.supplier__supplier_name
                      ? item.supplier__supplier_name
                      : "N/A"}
                  </td>
                  <td>{item.reasons}</td>
                  <td className="d-none" style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      color="#E40000"
                      style={{ paddingLeft: "0.4rem" }}
                      onClick={() => {
                        setDeleteModalShow(true);
                        setSelectedItem(item);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </td>
      </tr>
    </Fragment>
  );
};

export default ReturnListTableRow;
