import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Col,
  Container,
  Row,
  Table,
  Form,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import "../Transfer/transfer.css";
import "../Return/Return.css";
import {
  createVoid,
  getInvoiceDetails,
  getInvoiceId,
} from "../../Redux/actions/voidActions";
import { convertTime } from "../../Services/ConvertDateTime";
import { SuccessAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";
import "./AddVoid.css";
import SingleVoidReason from "./VoidReasons/SingleVoidReason";
import { VoidReasonsData } from "./VoidReasonsData";
import VoidReasonComment from "./VoidReasons/VoidReasonComment";

const AddVoid = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showResults, setShowResults] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [orderVoidList, setOrderVoidList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [voidReason, setVoidReason] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);
  const [voidUser, setVoidUser] = useState(false);

  const [voidReasonOption, setVoidReasonOption] = useState([]); // added by hasan

  const [
    isVoidReasonOthersOptionSelected,
    setIsVoidReasonOthersOptionSelected,
  ] = useState(false);
  const [voidReasonDescription, setVoidReasonDescription] = useState("");
  // console.log("voidReasonOption = ", voidReasonOption);

  const { invoiceLoading, invoice } = useSelector(
    (state) => state.searchInvoice
  );
  const { invoiceDetailsLoading, invoiceDetails } = useSelector(
    (state) => state.searchInvoiceDetails
  );
  const { loading, message, error } = useSelector((state) => state.createVoid);
  const { user } = useSelector((state) => state.userDetails);

  useEffect(() => {
    if (user && user.can_perform_void) {
      setVoidUser(true);
    }
  }, [user]);

  useEffect(() => {
    if (
      showResults &&
      !showSearchResult &&
      invoice &&
      invoice.data &&
      invoice.data.Orderplaced &&
      invoice.data.Orderplaced.length === 1 &&
      !invoiceLoading
    ) {
      dispatch(getInvoiceDetails(invoice.data.Orderplaced[0].id));
      setShowSearchResult(true);
    }
  }, [showResults, invoice, invoiceLoading]);

  useEffect(() => {
    if (invoiceDetails && invoiceDetails.data && invoiceDetails.data.products) {
      setOrderVoidList(
        invoiceDetails.data.products.map((product) => ({
          ...product,
          product_void_quantity: 0,
        }))
      );
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (alertMessage && message === undefined && error !== undefined) {
      setAlertMessage(false);
      if (error.message) {
        alert(error.message);
      } else if (error.Warning) {
        alert(error.Warning);
      } else {
        alert("Something Went Wrong");
      }
    }
    if (alertMessage && message !== undefined) {
      setShowSearchResult(false);
      SuccessAlert(`New Void Created Successfully`);
      setSearchText("");
      setAlertMessage(false);
      setShowResults(false);
      setOrderVoidList([]);
      setVoidReason("");
      setTimeout(() => {
        navigate(`/void/${message.data}`);
      }, 2500);
    }
  }, [alertMessage, message, error]);

  const browseInvoice = () => {
    if (searchText !== "") {
      setShowSearchResult(false);
      setShowResults(true);
      setSelectAll(false);
      dispatch(getInvoiceId(searchText));
    } else {
      alert("Write Down Invoice Id To Browse Invoice");
    }
  };

  const updateVoidQty = (event, item) => {
    setOrderVoidList(
      orderVoidList.map((product) =>
        product.id === item.id
          ? {
              ...product,
              product_void_quantity:
                event.target.value.length === 0
                  ? 0
                  : event.target.value >= 0 &&
                    event.target.value <= item.Quantity
                  ? parseInt(event.target.value)
                  : product.product_void_quantity,
            }
          : product
      )
    );
  };
  const submitVoid = () => {
    const body = orderVoidList.filter(
      (product) => product.product_void_quantity > 0
    );
    // const bodyData = {
    //   order_placed: invoice.data.Orderplaced[0].id,
    //   void_reasons: voidReason,
    //   order_products: body.map((p) => ({
    //     order_id: p.id,
    //     void_quantity: p.product_void_quantity,
    //   })),
    // };
    // console.log(bodyData);
    if (!voidReasonOption.length) {
      return alert("Please select a Void Reason");
    }

    if (voidReasonOption.length) {
      let isOthersOptionSelected = voidReasonOption.find(
        (item) => item.id === 8
      );

      if (isOthersOptionSelected) {
        if (voidReasonDescription.trim().length === 0) {
          return alert("Please Write Void Reasons");
        }
      }
    }

    if (voidReasonOption.length) {
      // console.log("voidReasonOption = ", voidReasonOption);

      let modifiedVoidReasonOption = voidReasonOption.filter(
        (item) => item.id !== 8
      );

      // console.log("modifiedVoidReasonOption = ", modifiedVoidReasonOption);

      let modifiedVoidReasonOptionStringArray = modifiedVoidReasonOption.map(
        (item) => {
          if (item.id !== 8) {
            return item.value;
          }
        }
      );

      // console.log(
      //   "modifiedVoidReasonOptionStringArray = ",
      //   modifiedVoidReasonOptionStringArray
      // );

      const modifiedVoidReasonOptionString =
        modifiedVoidReasonOptionStringArray.join(", ");

      // console.log(
      //   "modifiedVoidReasonOptionString = ",
      //   modifiedVoidReasonOptionString
      // );

      let finalVoidReasonString;
      if (voidReasonDescription) {
        finalVoidReasonString = `${modifiedVoidReasonOptionString}, ${voidReasonDescription}`;
      } else {
        finalVoidReasonString = modifiedVoidReasonOptionString;
      }

      // console.log("finalVoidReasonString = ", finalVoidReasonString);

      const bodyData = {
        order_placed: invoice.data.Orderplaced[0].id,
        void_reasons: finalVoidReasonString,
        order_products: body.map((p) => ({
          order_id: p.id,
          void_quantity: p.product_void_quantity,
        })),
      };

      if (bodyData.order_products.length) {
        dispatch(createVoid(bodyData));
        setAlertMessage(true);
      } else {
        alert("Please Give Correct Information");
      }
    } else {
      alert("Please Give Correct Information");
    }
  };
  //console.log(orderVoidList);
  const totalPrice = orderVoidList.reduce(
    (initialValue, product) =>
      initialValue + product.pricePerItem * product.product_void_quantity,
    0
  );

  const totalDiscountAmount = orderVoidList.reduce(
    (initialValue, product) =>
      initialValue +
      (product.Quantity && product.product_void_quantity
        ? (product.discountAmount / product.Quantity) *
          product.product_void_quantity
        : 0),
    0
  );

  const totalLessAmount = orderVoidList.reduce(
    (initialValue, product) =>
      initialValue +
      (product.Quantity && product.product_void_quantity
        ? (product.lessAmount / product.Quantity) *
          product.product_void_quantity
        : 0),
    0
  );
  const subtotalAmount = totalPrice - totalDiscountAmount - totalLessAmount;
  const totalVatAmount = orderVoidList.reduce(
    (initialValue, product) =>
      initialValue +
      (product.Quantity && product.product_void_quantity
        ? (product.vatAmount / product.Quantity) * product.product_void_quantity
        : 0),
    0
  );
  const changeSoldQtyToVoidQty = () => {
    if (!selectAll) {
      setSelectAll(true);
      setOrderVoidList(
        orderVoidList.map((product) => ({
          ...product,
          product_void_quantity: product.Quantity,
        }))
      );
    } else {
      setSelectAll(false);
      setOrderVoidList(
        orderVoidList.map((product) => ({
          ...product,
          product_void_quantity: 0,
        }))
      );
    }
  };

  const totalSoldQty = orderVoidList.reduce(
    (initialValue, product) => initialValue + product.Quantity,
    0
  );

  // set Void Reason Logic
  // const [voidReasonOption, setVoidReasonOption] = useState("");
  useEffect(() => {
    if (voidReasonOption.length) {
      let result = voidReasonOption.find((item) => item.id === 8);
      if (result) {
        setIsVoidReasonOthersOptionSelected(result);
      } else {
        setIsVoidReasonOthersOptionSelected(false);
        setVoidReasonDescription("");
      }
    } else {
      setIsVoidReasonOthersOptionSelected(false);
      setVoidReasonDescription("");
    }

    //
  }, [voidReasonOption]);

  return (
    <>
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer />
        <Container fluid>
          <h4 className="product-heading container-view">Void Add</h4>
          <p className="product-paragraph container-view">
            {" "}
            Void <FontAwesomeIcon icon={faChevronRight} size="xs" /> Add Void
          </p>
          {voidUser ? (
            <div
              className="mb-4 card container-view"
              style={{ paddingLeft: "12px", paddingRight: "12px" }}
            >
              <Row className="mb-4 pt-3" style={{ backgroundColor: "#DCE0E4" }}>
                <Col lg={6}>
                  <Form.Group
                    className=" form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search By Invoice Number"
                      onChange={(e) => setSearchText(e.target.value)}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Button
                    className="report-btn border-0"
                    onClick={browseInvoice}
                  >
                    Browse Invoice
                  </Button>
                </Col>
              </Row>
              {showResults &&
              showSearchResult &&
              invoiceDetails &&
              invoiceDetails.data ? (
                <>
                  <div className="mt-3">
                    <Row
                      className="mx-0 py-2 table-header"
                      style={{
                        border: "1px solid #dee2e6",
                        fontSize: "14px",
                      }}
                    >
                      <Col lg={3}>
                        <b>Invoice Number:</b> {invoiceDetails.data.invoice_id}
                      </Col>
                      <Col>
                        <b>Total Items:</b>{" "}
                        {invoiceDetails.data.products.length}
                      </Col>
                      <Col>
                        <b>Total Qty:</b> {totalSoldQty}
                      </Col>
                      <Col>
                        <b>Invoice Date:</b>{" "}
                        {invoiceDetails.data.createdAt.split("T")[0]}
                      </Col>
                      <Col>
                        <b>Invoice Time:</b>{" "}
                        {convertTime(
                          invoiceDetails.data.createdAt.split("T")[0]
                        )}
                      </Col>
                      <Col>
                        <b>Payment Method:</b>{" "}
                        {invoiceDetails.data.paymentMethod}
                      </Col>
                    </Row>
                    <Table
                      responsive
                      bordered
                      hover
                      style={{ fontSize: "14px" }}
                    >
                      <thead className="table-header">
                        <tr>
                          <th>
                            <OverlayTrigger
                              key="side-panel"
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">
                                  {!selectAll
                                    ? "Select All Product"
                                    : "Deselect All Product"}
                                </Tooltip>
                              }
                            >
                              <Form.Check
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                onChange={changeSoldQtyToVoidQty}
                                checked={selectAll}
                              />
                            </OverlayTrigger>
                          </th>
                          <th>SL</th>
                          <th>Barcode</th>
                          <th>Product Information</th>
                          <th>Sold Qty</th>
                          <th>RPU</th>
                          <th>Vat(৳)</th>
                          <th>Discount(৳)</th>
                          <th>Less Amount</th>
                          <th>Net Amount</th>
                          <th>Prev. Void Qty</th>
                          <th>Void Qty</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderVoidList && orderVoidList.length ? (
                          orderVoidList.map((item, i) => (
                            <tr key={i}>
                              <td>
                                <Form.Check
                                  key={item.id}
                                  type="checkbox"
                                  checked={selectAll}
                                  readOnly
                                />
                              </td>
                              <td>{i + 1}</td>
                              <td>{item.barcode ? item.barcode : "N/A"}</td>
                              <td>
                                {item.productName +
                                  " " +
                                  item.style_size +
                                  " " +
                                  item.product_sub_categorty}
                              </td>
                              <td>{item.Quantity}</td>
                              <td>{item.pricePerItem}</td>
                              <td>{item.vatAmount}</td>
                              <td>{item.discountAmount}</td>
                              <td>{item.lessAmount}</td>
                              <td>{item.net_amount}</td>
                              <td>{item.void_quantity}</td>
                              <td>
                                <input
                                  className="add-void-input-field"
                                  type="number"
                                  min={0}
                                  value={item.product_void_quantity}
                                  max={item.Quantity}
                                  onChange={(event) => {
                                    updateVoidQty(event, item);
                                    // setAddedProduct((prevState) => {
                                    //   const updatedProducts = [...prevState];
                                    //   const productIndex =
                                    //     updatedProducts.findIndex(
                                    //       (prod) => prod.id === product.id
                                    //     );

                                    //   if (productIndex !== -1) {
                                    //     {
                                    //       updatedProducts[
                                    //         productIndex
                                    //       ].order_quantity = parseInt(
                                    //         event.target.value
                                    //       );
                                    //     }
                                    //   } else {
                                    //     updatedProducts.push({
                                    //       ...product,
                                    //       order_quantity:
                                    //         event.target.value.length === 0
                                    //           ? 0
                                    //           : event.target.value >= 0
                                    //           ? parseInt(event.target.value)
                                    //           : product.order_quantity,
                                    //     });
                                    //   }

                                    //   return updatedProducts;
                                    // });
                                  }}
                                  disabled={selectAll}
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="10">No Product Found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>

                    <Row
                      style={{ backgroundColor: "#DCE0E4" }}
                      className="pt-3"
                    >
                      <div>
                        Void Reasons <span className="required-field">*</span>
                      </div>
                      <Col>
                        <div className="void-reasons row row-cols-1 row-cols-lg-2 gx-3 gy-3 gy-lg-0">
                          <div className="col">
                            <div className="row row-cols-1 row-cols-md-2 g-3 align-items-center">
                              {VoidReasonsData.map((reason) => (
                                <SingleVoidReason
                                  reason={reason}
                                  key={reason.id}
                                  voidReasonOption={voidReasonOption}
                                  setVoidReasonOption={setVoidReasonOption}
                                />
                              ))}
                            </div>
                          </div>
                          <div className="col">
                            {isVoidReasonOthersOptionSelected && (
                              <VoidReasonComment
                                voidReasonOption={voidReasonOption}
                                voidReasonDescription={voidReasonDescription}
                                setVoidReasonDescription={
                                  setVoidReasonDescription
                                }
                              />
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ backgroundColor: "#DCE0E4" }}>
                      <Col lg={2}>
                        <Form.Group
                          className=" form-group mb-3"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Total Price</Form.Label>
                          <Form.Control
                            type="text"
                            value={totalPrice.toFixed(2)}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={2}>
                        <Form.Group
                          className=" form-group mb-3"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Disc. Amount</Form.Label>
                          <Form.Control
                            type="text"
                            value={totalDiscountAmount.toFixed(2)}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={2}>
                        <Form.Group
                          className=" form-group mb-3"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Less Amount</Form.Label>
                          <Form.Control
                            type="text"
                            value={totalLessAmount.toFixed(2)}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={2}>
                        <Form.Group
                          className=" form-group mb-3"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Subtotal</Form.Label>
                          <Form.Control
                            type="text"
                            value={subtotalAmount.toFixed(2)}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={2}>
                        <Form.Group
                          className=" form-group mb-3"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Vat</Form.Label>
                          <Form.Control
                            type="text"
                            value={totalVatAmount.toFixed(2)}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={2}>
                        <Form.Group
                          className=" form-group mb-3"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Net Amount</Form.Label>
                          <Form.Control
                            type="text"
                            value={
                              parseFloat(subtotalAmount.toFixed(2)) +
                              parseFloat(totalVatAmount.toFixed(2))
                            }
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {loading === true ? (
                      <Row className="mt-2">
                        <Col
                          xl={4}
                          lg={12}
                          className="d-flex justify-content-center"
                        >
                          <Spinner animation="border" variant="warning" />
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                    <Row className="my-4">
                      <Col xl={2} lg={6}>
                        {loading ? (
                          <Button
                            className="disable-submit-btn"
                            variant="secondary"
                            disabled
                          >
                            Submit Void
                          </Button>
                        ) : (
                          <Button
                            className="submit-btn border-0"
                            type="submit"
                            onClick={submitVoid}
                          >
                            Submit Void
                          </Button>
                        )}
                      </Col>
                      <Col xl={2} lg={6}>
                        <Button
                          className="cancel-btn border-0"
                          onClick={() => navigate(0)}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : invoice.data &&
                invoice.data.Orderplaced &&
                invoice.data.Orderplaced.length !== 1 ? (
                <div className="my-3 d-flex justify-content-center">
                  <p style={{ fontWeight: 700, fontSize: "16px" }}>
                    No Invoice Found
                  </p>
                </div>
              ) : invoiceLoading || invoiceDetailsLoading ? (
                <div className="my-3 d-flex justify-content-center">
                  <Spinner animation="border" variant="warning" />
                </div>
              ) : (
                <div className="mt-3 d-flex justify-content-center">
                  <p style={{ fontWeight: 700, fontSize: "16px" }}>
                    Select A Invoice To Add Void
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div
              className="py-3 card container-view"
              style={{ paddingLeft: "12px", paddingRight: "12px" }}
            >
              <div className="d-flex justify-content-center">
                <span style={{ fontWeight: 700, fontSize: "16px" }}>
                  You do not have the permissions to Add Void.
                </span>
              </div>
            </div>
          )}
        </Container>
      </div>
      <Footer></Footer>
    </>
  );
};

export default AddVoid;
