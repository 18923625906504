import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useLocalStorage from "../../hooks/useLocalStorage";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownButton,
  Spinner,
} from "react-bootstrap";
import totalProductsIcon from "../../Assets/icons/totalProducts.png";
import totalPurchaseIcon from "../../Assets/icons/totalPurchase.png";
import totalReturnPurchaseIcon from "../../Assets/icons/totalReturnPurchase.png";
import totalSupplierIcon from "../../Assets/icons/totalSupplier.png";
import totalVendorIcon from "../../Assets/icons/totalVendor.png";
import totalUserIcon from "../../Assets/icons/totalUser.png";
import "./DashBoard.css";
import "../Products/Product.css";
import { getDashboardList } from "../../Redux/actions/dashboardActions";
import { Pie, Bar, Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ServerErrorPage from "../NotFoundPage/ServerErrorPage";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/actions/userActions";
import ProductsCard from "./reuseable/ProductsCard/ProductsCard";
import TotalCard from "./reuseable/TotalCard/TotalCard";
Chart.register(...registerables);

const DashBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [purchaseKeyword, setPurchaseKeyword] = useLocalStorage(
    "purchaseKeyword",
    "month"
  );
  const [returnKeyword, setReturnKeyword] = useLocalStorage(
    "returnKeyword",
    "month"
  );
  //const [changeAnalysis, setChangeAnalysis] = useState(false);
  const { loading, dashboard, error } = useSelector(
    (state) => state.dashboardDetail
  );
  // useEffect(() => {
  //   if (dashboard.data === undefined || changeAnalysis === true) {
  //     dispatch(getDashboardList(purchaseKeyword, returnKeyword));
  //     setChangeAnalysis(false);
  //   }
  // }, [dispatch, purchaseKeyword, returnKeyword, changeAnalysis]);
  useEffect(() => {
    dispatch(getDashboardList(purchaseKeyword, returnKeyword));
  }, [dispatch, purchaseKeyword, returnKeyword]);
  //console.log(dashboard);
  //console.log(error);
  useEffect(() => {
    if (error === "Given token not valid for any token type") {
      dispatch(logout());
      navigate(0);
    }
  }, [error]);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const labels = dashboard?.data?.purchase_analysis
    .map((purchase) =>
      purchaseKeyword === "day"
        ? purchase.date
        : purchaseKeyword === "year"
        ? purchase.year
        : purchase.month
    )
    .reverse();
  const data = {
    labels,
    datasets: [
      {
        label: "Purchase Amount",
        data: dashboard?.data?.purchase_analysis
          .map((purchase) => purchase.amount)
          .reverse(),
        backgroundColor: "#0CB8DD",
      },
    ],
  };
  const returnLabels = dashboard?.data?.return_analysis
    .map((purchase) =>
      returnKeyword === "day"
        ? purchase.date
        : returnKeyword === "year"
        ? purchase.year
        : purchase.month
    )
    .reverse();
  const returnData = {
    labels: returnLabels,
    datasets: [
      {
        label: "Return Amount",
        data: dashboard?.data?.return_analysis
          .map((purchase) => purchase.amount)
          .reverse(),
        borderColor: "#4BC18D",
        backgroundColor: "#4BC18D",
      },
    ],
  };
  //console.log(returnLabels);
  return (
    <>
      {dashboard && dashboard.data ? (
        <div>
          <SideBar />
          <div className="mainContent">
            <Header />
            <Container fluid className="mb-4">
              <Row className="container-view">
                <ProductsCard
                  title="Total Products"
                  icon={totalProductsIcon}
                  value={dashboard.data ? dashboard.data.total_products : 0}
                />
                <ProductsCard
                  title="Sales Today (Products)"
                  icon={totalPurchaseIcon}
                  value={dashboard.data ? dashboard.data.total_sell_today : 0}
                />
                <ProductsCard
                  title="Stock Out (Products)"
                  icon={totalReturnPurchaseIcon}
                  value={
                    dashboard.data
                      ? dashboard.data.total_out_of_stock_products
                      : 0
                  }
                />
              </Row>
              <Row className="container-view">
                <TotalCard
                  title="Total Suppliers"
                  icon={totalSupplierIcon}
                  value={dashboard.data ? dashboard.data.total_suppliers : 0}
                  backgroundColor="#FF9900"
                />

                <TotalCard
                  title="Total Vendors"
                  icon={totalVendorIcon}
                  value={dashboard.data ? dashboard.data.total_vendors : 0}
                  backgroundColor="#1B2850"
                />

                <TotalCard
                  title="Total Users"
                  icon={totalUserIcon}
                  value={dashboard.data ? dashboard.data.total_users : 0}
                  backgroundColor="#33C481"
                />
              </Row>
              <Row className="container-view">
                <Col xl={7}>
                  <div className="product-card">
                    <div className="mx-4 mt-4 d-flex justify-content-between">
                      <p className="dashboard-card-header">Purchase Analysis</p>
                      <DropdownButton
                        id="dropdown-basic-button"
                        variant="secondary"
                        size="sm"
                        title={
                          purchaseKeyword === "day"
                            ? "Daily Analysis"
                            : purchaseKeyword === "year"
                            ? "Yearly Analysis"
                            : "Monthly Analysis"
                        }
                        className=""
                      >
                        <Dropdown.Item
                          onClick={() => {
                            setPurchaseKeyword("month");
                            //setChangeAnalysis(true);
                          }}
                          disabled={purchaseKeyword === "month" ? true : false}
                        >
                          Monthly
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setPurchaseKeyword("day");
                            //setChangeAnalysis(true);
                          }}
                          disabled={purchaseKeyword === "day" ? true : false}
                        >
                          Daily
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setPurchaseKeyword("year");
                            //setChangeAnalysis(true);
                          }}
                          disabled={purchaseKeyword === "year" ? true : false}
                        >
                          Yearly
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div
                      className="d-flex justify-content-center px-3 mb-2 analysis-card"
                      //style={{ height: "350px" }}
                    >
                      <Bar options={options} data={data} />
                    </div>
                  </div>
                </Col>
                <Col xl={5}>
                  <div className="product-card">
                    <p className="m-4 dashboard-card-header">
                      Section Wise Total Product
                    </p>
                    <div
                      className="d-flex justify-content-center"
                      style={{ height: "37.35vh" }}
                    >
                      <Pie
                        data={{
                          labels:
                            dashboard.data &&
                            dashboard.data.section_wise_total_product.length
                              ? dashboard.data.section_wise_total_product.map(
                                  (location) => `${location.location_value}`
                                )
                              : [],

                          datasets: [
                            {
                              label: "Count",
                              data:
                                dashboard.data &&
                                dashboard.data.section_wise_total_product.length
                                  ? dashboard.data.section_wise_total_product.map(
                                      (location) => `${location.count}`
                                    )
                                  : [],

                              backgroundColor: [
                                "#01C0EA",
                                "#EB3A3A",
                                "#FF9900",
                                "#33C481",
                                "rgba(0, 117, 191, 0.2)",
                              ],

                              hoverOffset: 4,
                            },
                          ],
                        }}
                        options={{
                          plugins: {
                            legend: {
                              display: true,
                              position: "bottom",
                            },
                          },
                        }}
                        //style={{ height: "250px", width: "250px" }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="container-view">
                <Col xl={7}>
                  <div className="product-card">
                    <div className="mx-4 mt-4 d-flex justify-content-between">
                      <p className="dashboard-card-header">Return Analysis</p>
                      <DropdownButton
                        id="dropdown-basic-button"
                        variant="secondary"
                        size="sm"
                        title={
                          returnKeyword === "day"
                            ? "Daily Analysis"
                            : returnKeyword === "year"
                            ? "Yearly Analysis"
                            : "Monthly Analysis"
                        }
                      >
                        <Dropdown.Item
                          onClick={() => {
                            setReturnKeyword("month");
                            //setChangeAnalysis(true);
                          }}
                          disabled={returnKeyword === "month" ? true : false}
                        >
                          Monthly
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setReturnKeyword("day");
                            //setChangeAnalysis(true);
                          }}
                          disabled={returnKeyword === "day" ? true : false}
                        >
                          Daily
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setReturnKeyword("year");
                            //setChangeAnalysis(true);
                          }}
                          disabled={returnKeyword === "year" ? true : false}
                        >
                          Yearly
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div
                      className="d-flex justify-content-center px-3 mb-2 analysis-card"
                      //style={{ height: "350px" }}
                    >
                      <Line options={options} data={returnData} />
                    </div>
                  </div>
                </Col>
                <Col xl={5}>
                  <div className="product-card">
                    <p className="ps-4 pt-4 dashboard-card-header">
                      Recently Added Products(Last 10)
                    </p>
                    <div
                      className="ps-4 pe-4 pb-2"
                      style={{ height: "38.2vh", overflow: "scroll" }}
                    >
                      <Table className="" responsive="xl" hover>
                        <thead
                          className="text-center table-header"
                          style={{ fontSize: "14px" }}
                        >
                          <tr>
                            <th>Product ID</th>
                            <th>Product Name</th>
                            <th>P.Price</th>
                            <th>Stocks</th>
                          </tr>
                        </thead>
                        <tbody
                          className="text-center"
                          style={{ fontSize: "12px" }}
                        >
                          {dashboard.data ? (
                            dashboard.data.recently_added_products.map(
                              (product) => (
                                <tr key={product.id}>
                                  <td>{product.id}</td>
                                  <td>{product.product_name}</td>
                                  <td>
                                    {product.purchase_price
                                      ? product.purchase_price
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {product.stock}
                                    {product.stock_type}
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td colSpan="12">No Result Found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="container-view">
                <Col xl={5}>
                  <div className="product-card">
                    <p className="ps-4 pt-4 dashboard-card-header">
                      Out of Stock Products(Last 10)
                    </p>
                    <div
                      className="ps-4 pe-4 pb-2"
                      style={{ height: "30vh", overflow: "scroll" }}
                    >
                      <Table responsive hover>
                        <thead
                          className="text-center table-header"
                          style={{ fontSize: "14px" }}
                        >
                          <tr>
                            <th>Product ID</th>
                            <th>Product Name</th>
                            <th>Units</th>
                            <th>W.Stocks</th>
                          </tr>
                        </thead>
                        <tbody
                          className="text-center"
                          style={{ fontSize: "12px" }}
                        >
                          {dashboard.data ? (
                            dashboard.data.out_of_stock_products.map(
                              (product) => (
                                <tr key={product.id}>
                                  <td>{product.id}</td>
                                  <td>{product.product_name}</td>
                                  <td>{product.unit ? product.unit : "N/A"}</td>
                                  <td>
                                    {product.wh_stock ? product.wh_stock : "0"}
                                    {product.stock_type}
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td colSpan="12">No Result Found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
                <Col xl={7}>
                  <div className="product-card">
                    <p className="ps-4 pt-4 dashboard-card-header">
                      Expired Products(Last 10)
                    </p>
                    <div
                      className="ps-4 pe-4 pb-2"
                      style={{ height: "30vh", overflow: "scroll" }}
                    >
                      <Table responsive hover>
                        <thead
                          className="text-center table-header"
                          style={{ fontSize: "14px" }}
                        >
                          <tr>
                            <th>Product Id</th>
                            <th>Product Name</th>
                            <th>Batch No.</th>
                            <th>Quantity</th>
                            <th>Vendor Name</th>
                            <th>Expiry Date</th>
                          </tr>
                        </thead>
                        <tbody
                          className="text-center"
                          style={{ fontSize: "12px" }}
                        >
                          {dashboard.data &&
                          dashboard.data.expired_products.length ? (
                            dashboard.data.expired_products.map(
                              (product, index) => (
                                <tr key={index}>
                                  <td>{product.product_id}</td>
                                  <td>{product.product_name}</td>
                                  <td>{product.batch_no}</td>
                                  <td>
                                    {product.expired_product_quantity}
                                    {product.stock_type}
                                  </td>
                                  <td>{product.vendor_name}</td>
                                  <td>{product.expire_date}</td>
                                </tr>
                              )
                            )
                          ) : loading === true ? (
                            <tr>
                              <td colSpan="12">
                                <Spinner animation="border" variant="warning" />
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan="12">No Result Found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      ) : error === "Network Error" ? (
        <ServerErrorPage />
      ) : (
        <div>
          <SideBar />
          <div className="mainContent">
            <Header />
            <Container>
              <div
                className="mt-2"
                style={{
                  height: "80vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="">
                  <Spinner animation="grow" variant="warning" />
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

export default DashBoard;
