import {
  RETURN_LIST_REQUEST,
  RETURN_LIST_SUCCESS,
  RETURN_LIST_FAILURE,
  RETURN_DELETE_REQUEST,
  RETURN_DELETE_SUCCESS,
  RETURN_DELETE_FAILURE,
  RETURNED_ITEM_DELETE_REQUEST,
  RETURNED_ITEM_DELETE_SUCCESS,
  RETURNED_ITEM_DELETE_FAILURE,
  SEARCH_RETURN_LIST_REQUEST,
  SEARCH_RETURN_LIST_SUCCESS,
  SEARCH_RETURN_LIST_FAILURE,
  CREATE_RETURN_REQUEST,
  CREATE_RETURN_SUCCESS,
  CREATE_RETURN_FAILURE,
  PRODUCT_BATCH_DETAIL_REQUEST,
  PRODUCT_BATCH_DETAIL_SUCCESS,
  PRODUCT_BATCH_DETAIL_FAILURE,
} from "../type.js";
import axios from "axios";

export const getReturnList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: RETURN_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/returnproduct/v1/inventory/ReturnProductListView/?limit=${limit}&offset=${offset}&ordering=-id`,
      config
    );

    dispatch({
      type: RETURN_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RETURN_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteReturn = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RETURN_DELETE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/returnproduct/v1/inventory/ReturnOrderplacedDeleteView/${id}/`,
      config
    );
    dispatch({
      type: RETURN_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RETURN_DELETE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteReturnItem = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RETURNED_ITEM_DELETE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/returnproduct/v1/inventory/DeleteInventoryReturnProductView/${id}/`,
      config
    );
    dispatch({
      type: RETURNED_ITEM_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RETURNED_ITEM_DELETE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const searchReturnList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_RETURN_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/returnproduct/v1/inventory/ReturnProductListView/?limit=5&offset=0&ordering=-id&search=${keyword}`,
      config
    );

    dispatch({
      type: SEARCH_RETURN_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_RETURN_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createReturn = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_RETURN_REQUEST,
    });
    //const body = {};
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/returnproduct/v1/inventory/ReturnProductPostView/`,
      body,
      config
    );

    dispatch({
      type: CREATE_RETURN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_RETURN_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getBatchDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_BATCH_DETAIL_REQUEST });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/polls/BatchInfoView/?productid=${id}`,
      config
    );
    dispatch({
      type: PRODUCT_BATCH_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_BATCH_DETAIL_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
