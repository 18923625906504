import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Col, Container, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import { getSupplierDetails } from "../../Redux/actions/supplierActions";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const EachSupplier = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const { loading, supplier, error } = useSelector(
    (state) => state.supplierDetail
  );
  useEffect(() => {
    dispatch(getSupplierDetails(param.id));
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //console.log(error, loading, supplier);
  return (
    <>
      {error && error.includes("Not found.") ? (
        <NotFoundPage></NotFoundPage>
      ) : (
        <div>
          <SideBar></SideBar>
          <div className="mainContent">
            <Header></Header>
            <Container fluid>
              <h4 className="product-heading container-view">
                Supplier Details
              </h4>
              <p className="product-paragraph container-view">
                Supplier <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
                Supplier List{" "}
                <FontAwesomeIcon icon={faChevronRight} size="xs" /> Full Details
                of Supplier
              </p>
              <div className="mb-4 p-3 card product-card container-view">
                <Row className="mb-4">
                  <Col lg={9} className="mb-2">
                    <Table className="mb-0" responsive="xl" bordered hover>
                      <tbody>
                        <tr>
                          <th className="col-4">Supplier Id</th>
                          <td>{supplier.id}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Category of Supplier</th>
                          <td>{supplier.category_of_Supplier}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Supplier Name</th>
                          <td>{supplier.supplier_name}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Supplier Address</th>
                          <td>
                            {supplier.supplier_address
                              ? supplier.supplier_address
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Contact Number</th>
                          <td>
                            {supplier.contact_number
                              ? supplier.contact_number
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Email Address</th>
                          <td>
                            {supplier.email_address
                              ? supplier.email_address
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Contact Person</th>
                          <td>
                            {supplier.contact_person
                              ? supplier.contact_person
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Supplier Product Category</th>
                          <td>
                            {supplier.supplier_product_category ===
                            "Non-Pharmacy"
                              ? "Other"
                              : supplier.supplier_product_category}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Status</th>
                          <td>{supplier.status}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Create Time</th>
                          <td>{supplier.created_at}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col lg={3} className="d-none d-flex justify-content-end">
                    <button className="pe-4 ps-4 border-0 print-button">
                      Print{" "}
                      <FontAwesomeIcon
                        icon={faPrint}
                        style={{ paddingLeft: "0.5rem" }}
                      />
                    </button>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Footer></Footer>
        </div>
      )}
    </>
  );
};

export default EachSupplier;
