import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSupplierList,
  searchSupplierList,
} from "../../Redux/actions/supplierActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEye,
  faPenAlt,
  faAdd,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import DeleteModal from "../PopupModal/DeleteModal";
import LoadingModal from "../PopupModal/LoadingModal";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import filter from "../../Assets/icons/filter.png";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import "../Products/Product.css";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import generatePDF from "../../Services/pdfGenerator";
import exportExcel from "../../Services/ExcelGenerator";
import ImportFileModal from "../PopupModal/ImportFileModal";

const SupplierList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [exportData, setExportData] = useState(false);
  const [importSupplierModal, setImportSupplierModal] = useState(false);

  const { loading, supplierList, error } = useSelector(
    (state) => state.supplierList
  );

  const { searchLoading, searchSuppliers, searchError } = useSelector(
    (state) => state.searchSupplierList
  );

  useEffect(() => {
    dispatch(getSupplierList(limit, offset));
  }, [dispatch, offset]);

  useEffect(() => {
    if (supplierList?.data) {
      setTotalItems(supplierList.data.count);
    }
  }, [supplierList]);
  //console.log(totalItems);
  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
  };

  const handleSearchResult = (e) => {
    //console.log(e.target.value);
    if (e.target.value.length > 1) {
      setShowSearchResult(true);
      dispatch(searchSupplierList(e.target.value));
    } else {
      setShowSearchResult(false);
    }
  };

  //console.log(error);
  /** Export All Data */
  const getDataFromApi = (type) => {
    setExportData(true);
    try {
      //const form_data = new FormData();
      //form_data.append("csv_file", filefield);
      //const body = data;
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const api = `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/SupplierListView/?limit=${totalItems}&offset=0`;

      axios
        .get(api, settings)
        .then((res) => {
          //console.log("success", res.data.results);
          //setAssetList([...assetList, ...res.data.results]);
          if (type === "pdf") {
            setExportData(false);
            generatePDF(
              res.data.results,
              "Supplier",
              [
                "Supplier ID",
                "Category of Supplier",
                "Supplier Name",
                "Address",
                "Contact No.",
                "Contact Person",
                "Email Address",
                "Product Category",
                "Status",
                "Created At",
              ],
              "Care-Box Supplier List",
              totalItems
            );
          } else if (type === "excel") {
            const exportData = [];
            res.data.results.map((each) => {
              let obj = {};
              obj["Supplier ID"] = each.id;
              obj["Category of Supplier"] = each.category_of_Supplier;
              obj["Supplier Name"] = each.supplier_name;
              obj["Address"] = each.supplier_address;
              obj["Contact No."] = each.contact_number;
              obj["Contact Person"] = each.contact_person;
              obj["Email Address"] = each.email_address;
              obj["Product Category"] =
                each.supplier_product_category === "Non-Pharmacy"
                  ? "Other"
                  : each.supplier_product_category;
              obj["Status"] = each.status;
              obj["Created At"] = each.created_at;
              exportData.push(obj);
            });
            exportExcel(exportData, "Care_Box_Supplier_List.xlsx");
            setExportData(false);
          }
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };
  return (
    <div>
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        supplier={selectedItem}
        action={() => setShowSearchResult(false)}
        limit={limit}
        offset={offset}
        totalItems={totalItems}
      />
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <ImportFileModal
        show={importSupplierModal}
        onHide={() => setImportSupplierModal(false)}
        title={`Supplier List`}
        importType="supplier"
      />
      <SideBar />
      <div className="mainContent">
        <Header />
        <ToastContainer />
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Supplier List</h4>
              <p className="product-paragraph">
                {" "}
                Supplier <FontAwesomeIcon
                  icon={faChevronRight}
                  size="xs"
                />{" "}
                Supplier List
              </p>
            </Col>
            {/* <Col lg={6} className="d-flex justify-content-lg-end">
              <button
                className="pe-3 ps-3 border-0 add-product-button"
                onClick={() => navigate("/addSupplier")}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ paddingRight: "0.2rem" }}
                />{" "}
                Add Supplier
              </button>
            </Col> */}
            <Col lg={6} className="d-flex justify-content-lg-end">
              <button
                className="pe-3 ps-3 border-0 add-product-button"
                onClick={() => setImportSupplierModal(true)}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ paddingRight: "0.2rem" }}
                />{" "}
                Import Supplier File
              </button>
            </Col>
          </Row>
          <div className="mb-4 p-3 card product-card container-view">
            <Row className="mb-4">
              <Col lg={6} className="mb-2 mb-lg-0">
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchResult}
                />
              </Col>
              <Col lg={6} className="d-flex justify-content-md-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as a pdf</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getDataFromApi("pdf")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as excel</Tooltip>}
                >
                  <img
                    //className="pe-3"
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getDataFromApi("excel")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            <Table responsive="xl" hover>
              <thead className="text-center table-header">
                <tr>
                  {/* <th>
                    <Form.Check type="checkbox" id="checkbox" />
                  </th> */}
                  <th>Id</th>
                  <th>Category of Supplier</th>
                  <th>Supplier Name</th>
                  <th>Address</th>
                  <th>Contact No.</th>
                  <th>Contact Person</th>
                  <th>Product Category</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {showSearchResult &&
                searchSuppliers?.data !== undefined &&
                searchSuppliers?.data.results.length !== 0 ? (
                  searchSuppliers?.data.results.map((supplier) => (
                    <tr key={supplier.id}>
                      {/* <td>
                        <Form.Check type="checkbox" id="checkbox" />
                      </td> */}
                      <td>{supplier.id}</td>
                      <td>
                        {supplier.category_of_Supplier
                          ? supplier.category_of_Supplier
                          : "N/A"}
                      </td>
                      <td>{supplier.supplier_name}</td>
                      <td>
                        {supplier.supplier_address
                          ? supplier.supplier_address
                          : "N/A"}
                      </td>
                      <td>
                        {supplier.contact_number
                          ? supplier.contact_number
                          : "N/A"}
                      </td>
                      <td>
                        {supplier.contact_person
                          ? supplier.contact_person
                          : "N/A"}
                      </td>
                      <td>
                        {supplier.supplier_product_category === "Non-Pharmacy"
                          ? "Other"
                          : supplier.supplier_product_category}
                      </td>
                      <td>{supplier.status}</td>
                      <td style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          icon={faEye}
                          color="#637381"
                          onClick={() => navigate(`/supplier/${supplier.id}`)}
                        />
                        <FontAwesomeIcon
                          icon={faPenAlt}
                          color="#637381"
                          style={{ paddingLeft: "0.405rem" }}
                          onClick={() =>
                            navigate(`/updateSupplier/${supplier.id}`)
                          }
                        />
                        {/* <FontAwesomeIcon
                          icon={faTrashCan}
                          color="#E40000"
                          style={{ paddingLeft: "0.405rem" }}
                          onClick={() => {
                            setDeleteModalShow(true);
                            setSelectedItem(supplier);
                          }}
                        /> */}
                      </td>
                    </tr>
                  ))
                ) : !showSearchResult &&
                  supplierList?.data !== undefined &&
                  supplierList?.data.results.length !== 0 &&
                  !loading ? (
                  supplierList?.data.results.map((supplier) => (
                    <tr key={supplier.id}>
                      {/* <td>
                        <Form.Check type="checkbox" id="checkbox" />
                      </td> */}
                      <td>{supplier.id}</td>
                      <td>
                        {supplier.category_of_Supplier
                          ? supplier.category_of_Supplier
                          : "N/A"}
                      </td>
                      <td>{supplier.supplier_name}</td>
                      <td>
                        {supplier.supplier_address
                          ? supplier.supplier_address
                          : "N/A"}
                      </td>
                      <td>
                        {supplier.contact_number
                          ? supplier.contact_number
                          : "N/A"}
                      </td>
                      <td>
                        {supplier.contact_person
                          ? supplier.contact_person
                          : "N/A"}
                      </td>
                      <td>
                        {supplier.supplier_product_category === "Non-Pharmacy"
                          ? "Other"
                          : supplier.supplier_product_category}
                      </td>
                      <td>{supplier.status}</td>
                      <td style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          icon={faEye}
                          color="#637381"
                          onClick={() => navigate(`/supplier/${supplier.id}`)}
                        />
                        <FontAwesomeIcon
                          icon={faPenAlt}
                          color="#637381"
                          style={{ paddingLeft: "0.405rem" }}
                          onClick={() =>
                            navigate(`/updateSupplier/${supplier.id}`)
                          }
                        />
                        {/* <FontAwesomeIcon
                          icon={faTrashCan}
                          color="#E40000"
                          style={{ paddingLeft: "0.405rem" }}
                          onClick={() => {
                            setDeleteModalShow(true);
                            setSelectedItem(supplier);
                          }}
                        /> */}
                      </td>
                    </tr>
                  ))
                ) : loading === true ? (
                  <tr>
                    <td colSpan="12">
                      <Spinner animation="border" variant="warning" />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="12">No Result Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {pageCount > 1 && !showSearchResult ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default SupplierList;
