import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt, faTrashCan } from "@fortawesome/free-solid-svg-icons";

const CategoryTableRow = ({
  category,
  handleClick,
  isCheck,
  handleUpdateCategory,
  handleDeleteCategory,
}) => {
  return (
    <tr key={category.id}>
      <td>
        <Form.Check
          key={category.id}
          name={category.name}
          type="checkbox"
          id={category.id}
          onChange={handleClick}
          checked={isCheck.includes(category.id)}
        />
      </td>
      <td>{category.id}</td>
      <td>{category.name}</td>
      <td>{category.location}</td>
      <td style={{ cursor: "pointer" }}>
        <OverlayTrigger
          key="update"
          placement="top"
          overlay={<Tooltip id="tooltip-top">Update</Tooltip>}
        >
          <FontAwesomeIcon
            icon={faPenAlt}
            color="#637381"
            onClick={() => handleUpdateCategory(category)}
          />
        </OverlayTrigger>
        <OverlayTrigger
          key="delete"
          placement="top"
          overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}
        >
          <FontAwesomeIcon
            icon={faTrashCan}
            color="#E40000"
            style={{ paddingLeft: "0.6rem" }}
            onClick={() => handleDeleteCategory(category)}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default CategoryTableRow;
