import {
  TRANSFER_LIST_REQUEST,
  TRANSFER_LIST_SUCCESS,
  TRANSFER_LIST_FAILURE,
  CREATE_TRANSFER_REQUEST,
  CREATE_TRANSFER_SUCCESS,
  CREATE_TRANSFER_FAILURE,
  EACH_TRANSFER_DETAIL_REQUEST,
  EACH_TRANSFER_DETAIL_SUCCESS,
  EACH_TRANSFER_DETAIL_FAILURE,
  SEARCH_TRANSFER_LIST_REQUEST,
  SEARCH_TRANSFER_LIST_SUCCESS,
  SEARCH_TRANSFER_LIST_FAILURE,
  SEARCH_TRANSFER_PURCHASE_LIST_REQUEST,
  SEARCH_TRANSFER_PURCHASE_LIST_SUCCESS, 
  SEARCH_TRANSFER_PURCHASE_LIST_FAILURE,
  SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_REQUEST,
  SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_SUCCESS,
  SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_FAILURE
} from "../type.js";
import axios from "axios";

export const getTransferList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: TRANSFER_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/TransferList/?limit=${limit}&offset=${offset}`,
      config
    );

    dispatch({
      type: TRANSFER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRANSFER_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createTransfer = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_TRANSFER_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/CreateTransfer/`,
      body,
      config
    );

    dispatch({
      type: CREATE_TRANSFER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_TRANSFER_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getTransferDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: EACH_TRANSFER_DETAIL_REQUEST });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/TransferDetails/${id}/`,
      config
    );
    dispatch({
      type: EACH_TRANSFER_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EACH_TRANSFER_DETAIL_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSearchTransferList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_TRANSFER_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/TransferList/?query=${keyword}&limit=20&offset=0`,
      config
    );

    dispatch({
      type: SEARCH_TRANSFER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_TRANSFER_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSearchPurchaseList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_TRANSFER_PURCHASE_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/transfer-purchase-list-search/?query=${keyword}&limit=20&offset=0`,
      config
    );

    dispatch({
      type: SEARCH_TRANSFER_PURCHASE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_TRANSFER_PURCHASE_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const searchPurchaseProductList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/transfer-purchase-product-list/?query=${keyword}`,
      config
    );

    dispatch({
      type: SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};