import { useNavigate } from "react-router-dom";
import {
  Form,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenAlt } from "@fortawesome/free-solid-svg-icons";

const ProductTableRow = ({
  product,
  handleClick,
  isCheck,
  handleProductStatusUpdate,
}) => {
  const navigate = useNavigate();
  return (
    <tr key={product.id} style={{ verticalAlign: "middle" }}>
      <td>
        <Form.Check
          key={product.id}
          name={product.product_name}
          type="checkbox"
          id={product.id}
          onChange={handleClick}
          checked={isCheck.includes(product.id)}
        />
      </td>
      <td>{product.id}</td>
      <td>
        <img
          src={product.image}
          alt={"Upload Image"}
          className="img-fluid product-img"
        />
      </td>
      <td>{product.product_name?.substring(0, 12)}</td>
      <td>{product.generic ? product.generic.substring(0, 12) : "N/A"}</td>
      <td>{!product.supplier ? "N/A" : product.supplier.substring(0, 12)}</td>
      <td>{product.sub_catagory_1?.substring(0, 12)}</td>
      <td>{product.purchase_price}</td>
      <td>{product.sale_price}</td>
      <td>{product.unit_size}</td>
      <td>{product.stocks}</td>
      <td>{!product.sale_vat ? "0" : product.sale_vat}</td>
      <td>{!product.discount ? "0" : product.discount}</td>
      <td style={{ cursor: "pointer" }}>
        <OverlayTrigger
          key="view"
          placement="top"
          overlay={<Tooltip id="tooltip-top">View</Tooltip>}
        >
          <FontAwesomeIcon
            icon={faEye}
            color="#637381"
            onClick={() => navigate(`/product/${product.id}`)}
          />
        </OverlayTrigger>
        <OverlayTrigger
          key="update"
          placement="top"
          overlay={<Tooltip id="tooltip-top">Update</Tooltip>}
        >
          <FontAwesomeIcon
            icon={faPenAlt}
            color="#637381"
            style={{ paddingLeft: "0.405rem" }}
            onClick={() => navigate(`/updateProduct/${product.id}`)}
          />
        </OverlayTrigger>
        {/* <OverlayTrigger
                key="delete"
                placement="top"
                overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}
            >
                <FontAwesomeIcon
                    icon={faTrashCan}
                    color="#E40000"
                    style={{ paddingLeft: "0.405rem" }}
                    onClick={() => handleDeleteProduct(product)}
                />
            </OverlayTrigger> */}
      </td>
      <td>
        <DropdownButton
          id="dropdown-basic-button"
          variant={product.active ? "outline-success" : "outline-danger"}
          size="sm"
          title={product.active ? "Active" : "InActive"}
          className=""
        >
          <Dropdown.Item
            onClick={() => {
              handleProductStatusUpdate(product.id, product.active);
            }}
            disabled={product.active}
          >
            Active
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              handleProductStatusUpdate(product.id, product.active);
            }}
            disabled={!product.active}
          >
            InActive
          </Dropdown.Item>
        </DropdownButton>
      </td>
    </tr>
  );
};

export default ProductTableRow;
