import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import SideBar from "../SideBar/Sidebar";
import Footer from "../Footer/Footer";
import pdfIcon from "../../Assets/icons/pdf.png";
import calender from "../../Assets/icons/calender.png";
import cancel from "../../Assets/icons/cancel.png";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import "./reports.css";
import { getSupplierList } from "../../Redux/actions/supplierActions";
import { getReturnStockReport } from "../../Redux/actions/returnStockActions";
import StockReportPdf from "../../Services/stockReportPdf";

const ReturnStockReport = () => {
  const dispatch = useDispatch();
  const [selectReport, setSelectReport] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectProduct, setSelectProduct] = useState("");
  const [generateButton, setGenerateButton] = useState(false);
  const [filterDateButton, setFilterDateButton] = useState(false);
  const [supplierId, setSupplierId] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState({});

  const { supplierList } = useSelector((state) => state.supplierList);

  const { loading, returnStockList, error } = useSelector(
    (state) => state.returnStockReport
  );
  const limit = 30;
  const offset = 0;

  useEffect(() => {
    if (!supplierList.data) {
      dispatch(getSupplierList(500, 0));
    }
  }, [supplierList.data]);
  //console.log(returnStockList);
  //console.log(subCategoryLoading, subcategoryStockList, subCategoryError);
  const handleGenerateReport = () => {
    if (selectReport === "Supplier Wise Return" && supplierId === "") {
      alert("Please Select Supplier");
    } else if (selectReport === "Product Wise Return" && selectProduct === "") {
      alert("Please Give Product Value");
    } else if (selectReport === "Supplier Wise Return" && supplierId !== "") {
      setGenerateButton(true);
      if (fromDate !== "" && toDate !== "") {
        dispatch(
          getReturnStockReport(
            `?limit=${limit}&offset=${offset}&supplier=${supplierId}&date_range_after=${fromDate}&date_range_before=${toDate}`
          )
        );
      } else {
        dispatch(
          getReturnStockReport(
            `?limit=${limit}&offset=${offset}&supplier=${supplierId}`
          )
        );
      }
    } else if (selectReport === "Product Wise Return" && selectProduct !== "") {
      setGenerateButton(true);
      if (fromDate !== "" && toDate !== "") {
        dispatch(
          getReturnStockReport(
            `?limit=${limit}&offset=${offset}&search=${selectProduct}&date_range_after=${fromDate}&date_range_before=${toDate}`
          )
        );
      } else {
        dispatch(
          getReturnStockReport(
            `?limit=${limit}&offset=${offset}&search=${selectProduct}`
          )
        );
      }
    }
  };
  const convertReportToPdf = () => {
    if (generateButton && selectReport !== "") {
      if (selectReport === "Supplier Wise Return") {
        var headerRows = [
          [
            {
              content: `Supplier Name: ${selectedSupplier[0].supplier_name}`,
              colSpan: 5,
            },
            {
              content: `From Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 1,
            },
            {
              content: `To Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 1,
            },
          ],
          [
            "Barcode",
            "Product",
            "Return(Qty)",
            "Cost Price",
            "Total Cost Price",
            "Return Date",
            "Returned By",
          ],
        ];
        StockReportPdf(
          headerRows,
          returnStockList.data.results.products,
          selectReport,
          selectReport
        );
      } else if (selectReport === "Product Wise Return") {
        var headerRows = [
          [
            "Return Id",
            "Barcode",
            "Supplier",
            "Product",
            "Return(Qty)",
            "Cost Price",
            "Total Cost Price",
            "Return Date",
            "Returned By",
          ],
        ];
        StockReportPdf(
          headerRows,
          returnStockList.data.results,
          selectReport,
          selectReport
        );
      }
    } else {
      alert("Please Generate Report First");
    }
  };

  return (
    <div>
      <div className="">
        <SideBar></SideBar>
      </div>
      <div className="mainContent">
        <div className="">
          <Header></Header>
        </div>
        <Container fluid>
          <h4 className="product-heading container-view">
            Return Stock Report at a Glance
          </h4>
          <p className="product-paragraph container-view">
            Reports <FontAwesomeIcon icon={faChevronRight} size="xs" /> Return
            Stock Report
          </p>
          <div className="mb-4 report-page-card card product-card container-view">
            <Row className="mb-2">
              <Col xl={9}>
                <img
                  src={filterDateButton ? cancel : calender}
                  alt="calender"
                  height={45}
                  style={{ cursor: "pointer" }}
                  onClick={() => setFilterDateButton(!filterDateButton)}
                />
                <select
                  name="stock-report"
                  className="select-bar"
                  onChange={(e) => {
                    setSelectReport(e.target.value);
                    setGenerateButton(false);
                    setSupplierId("");
                    setSelectProduct("");
                    setToDate("");
                    setFromDate("");
                  }}
                  value={selectReport}
                >
                  <option value="">Select Report Type</option>
                  <option value="Supplier Wise Return">
                    Supplier Wise Return
                  </option>
                  <option value="Product Wise Return">
                    Product Wise Return
                  </option>
                </select>
                {selectReport !== "" ? (
                  <>
                    {selectReport === "Supplier Wise Return" ? (
                      <select
                        name="stock-return-report"
                        className="select-product-type"
                        onChange={(e) => {
                          setSupplierId(e.target.value);
                          setSelectedSupplier(
                            supplierList.data.results.filter(
                              (s) => s.id === parseInt(e.target.value)
                            )
                          );
                          setGenerateButton(false);
                          //console.log(e.target.value);
                        }}
                        value={supplierId}
                      >
                        <option value="">Select Supplier</option>
                        {supplierList.data?.results.map((supplier) => (
                          <option key={supplier.id} value={supplier.id}>
                            {supplier.supplier_name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        name="stock-report"
                        className="search-product"
                        placeholder="Search product"
                        onChange={(e) => setSelectProduct(e.target.value)}
                        value={selectProduct}
                      />
                    )}
                    <Button
                      className="generate-btn border-0"
                      type="submit"
                      onClick={handleGenerateReport}
                    >
                      Generate Report
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Col>
              <Col xl={3} className="d-flex justify-content-end">
                <img
                  className=""
                  src={pdfIcon}
                  alt="pdf"
                  height={45}
                  style={{ cursor: "pointer" }}
                  onClick={convertReportToPdf}
                />
              </Col>
            </Row>
            {filterDateButton ? (
              <Row>
                <Col lg={2} md={4} sm={6}>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label className="report-form-label">
                      From Date
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="From Date"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={2} md={4} sm={6}>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label className="report-form-label">
                      To Date
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="To Date"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                      //style={{ height: "40px" }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <div className="">
              <Row className="mt-3">
                <h4 className="product-heading show-print">
                  {selectReport !== "" &&
                  selectReport === "Supplier Wise Return"
                    ? "Statement of Supplier Return"
                    : selectReport !== "" &&
                      selectReport === "Product Wise Return"
                    ? "Statement of Product Wise Return"
                    : "Select An Option to See Details About Stock Return"}
                </h4>
              </Row>
            </div>
            {selectReport === "Supplier Wise Return" && generateButton ? (
              <>
                <div className="mt-2 transfer-table">
                  <Row
                    className="mx-0 py-2 table-header"
                    //style={{ borderBottom: "1px solid #dee2e6" }}
                  >
                    <Col lg={6}>
                      <b>Supplier Name: </b>{" "}
                      {selectedSupplier.length
                        ? selectedSupplier[0].supplier_name
                        : ""}
                    </Col>
                    <Col lg={3} className="d-flex justify-content-center">
                      <b>From Date: </b> {fromDate !== "" ? fromDate : "N/A"}
                    </Col>
                    <Col lg={3} className="d-flex justify-content-end">
                      <b>To Date: </b>
                      {toDate !== "" ? toDate : "N/A"}
                    </Col>
                  </Row>
                </div>
                <Table bordered responsive>
                  <thead className="text-center report-table-header report-border">
                    <tr>
                      <th>Barcode</th>
                      <th>Product Information</th>
                      <th>Return (Qty)</th>
                      <th>Cost Price</th>
                      <th>Total Cost Price</th>
                      <th>Return Date</th>
                      <th>Returned By</th>
                    </tr>
                  </thead>
                  <tbody className="text-center report-table-body">
                    {returnStockList.data &&
                    !loading &&
                    returnStockList.data.results.products &&
                    returnStockList.data.results.products.length ? (
                      returnStockList.data.results.products.map((prod, i) => (
                        <tr key={i}>
                          <td>
                            {prod.product_barcode
                              ? prod.product_barcode
                              : "N/A"}
                          </td>
                          <td>
                            {prod.product_name} {prod.product_generic_name}{" "}
                            {prod.product_unit}
                          </td>
                          <td>{prod.total_returned_quantity}</td>
                          <td>{prod.avg_cost_price}</td>
                          <td>{prod.total_cost_price}</td>
                          <td>
                            {prod.last_returned_date
                              ? prod.last_returned_date
                              : "N/A"}
                          </td>
                          <td>
                            {prod.last_returned_by
                              ? prod.last_returned_by
                              : "N/A"}
                          </td>
                        </tr>
                      ))
                    ) : loading ? (
                      <tr>
                        <td colSpan={12}>
                          <Spinner animation="border" variant="warning" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={12}>No Data Found</td>
                      </tr>
                    )}
                    {returnStockList.data &&
                    !loading &&
                    returnStockList.data.results.products ? (
                      <tr style={{ fontWeight: "700" }}>
                        <td colSpan={2} className="text-start">
                          Grand Total
                        </td>
                        <td>{returnStockList.data.results.grand_quantity}</td>
                        <td>{returnStockList.data.results.grand_cost_price}</td>
                        <td>
                          {returnStockList.data.results.grand_total_cost_price}
                        </td>
                        <td colSpan={2}></td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
              </>
            ) : selectReport === "Product Wise Return" && generateButton ? (
              <>
                <Table responsive bordered>
                  <thead className="text-center report-table-header report-border">
                    <tr>
                      <th>Return ID</th>
                      <th>Barcode</th>
                      <th>Product Information</th>
                      <th>Supplier Name</th>
                      <th>Return (Qty)</th>
                      <th>Cost Price</th>
                      <th>Total Cost Price</th>
                      <th>Return Date</th>
                      <th>Returned By</th>
                    </tr>
                  </thead>
                  <tbody className="text-center report-table-body">
                    {returnStockList.data &&
                    !loading &&
                    returnStockList.data.results &&
                    returnStockList.data.results.length ? (
                      returnStockList.data.results.map((prod, i) => (
                        <tr key={i}>
                          <td>{prod.returned_id}</td>
                          <td>
                            {prod.product_barcode
                              ? prod.product_barcode
                              : "N/A"}
                          </td>
                          <td>
                            {prod.product_name} {prod.product_generic_name}{" "}
                            {prod.product_unit}
                          </td>
                          <td>
                            {prod.supplier_name ? prod.supplier_name : "N/A"}
                          </td>
                          <td>{prod.returned_quantity}</td>
                          <td>{prod.cost_price}</td>
                          <td>{prod.total_cost_price}</td>
                          <td>
                            {prod.returned_at
                              ? prod.returned_at.split("T")[0]
                              : "N/A"}
                          </td>
                          <td>{prod.returned_by}</td>
                        </tr>
                      ))
                    ) : loading ? (
                      <tr>
                        <td colSpan={12}>
                          <Spinner animation="border" variant="warning" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={12}>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </>
            ) : (
              ""
            )}
            {/* {pageCount > 1 && !loading ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )} */}
          </div>
        </Container>
      </div>
      <div className="">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default ReturnStockReport;
