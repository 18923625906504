import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faAdd } from "@fortawesome/free-solid-svg-icons";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import LoadingModal from "../PopupModal/LoadingModal";
import "./Product.css";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import filter from "../../Assets/icons/filter.png";
import excelIcon from "../../Assets/icons/excel.png";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import exportExcel from "../../Services/ExcelGenerator";
import ImportFileModal from "../PopupModal/ImportFileModal";
import {
  getGenericList,
  getSearchGenericList,
} from "../../Redux/actions/genericActions";

const GenericList = () => {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(30);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [exportData, setExportData] = useState(false);
  const [allGenericList, setAllGenericList] = useState([]);
  const [type, setType] = useState("");
  const [searchKeyword, setSearchKeyWord] = useState("");
  const [importGenericModal, setImportGenericModal] = useState(false);

  const { loading, genericList, error } = useSelector(
    (state) => state.genericList
  );

  const { searchLoading, searchGenericList, searchError } = useSelector(
    (state) => state.searchGeneric
  );

  useEffect(() => {
    dispatch(getGenericList(limit, offset));
  }, [dispatch, offset]);

  useEffect(() => {
    if (genericList.data && genericList.data.generic_names) {
      setTotalItems(genericList.data.generic_names.count);
    }
  }, [genericList]);

  useEffect(() => {
    if (exportData && allGenericList.length === totalItems) {
      if (type === "excel") {
        const exportedData = [];
        allGenericList.map((each) => {
          let obj = {};
          obj["ID"] = each.id;
          obj["Generic Name"] = each.generic_name;
          exportedData.push(obj);
        });
        exportExcel(exportedData, "Care_Box_Generic_List.xlsx");
        setExportData(false);
      }
    }
  }, [exportData, allGenericList]);
  //console.log(totalItems);
  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
  };

  useEffect(() => {
    if (searchKeyword.length > 1) {
      dispatch(getSearchGenericList(searchKeyword));
    }
  }, [searchKeyword]);

  const handleSearchResult = (e) => {
    //console.log(e.target.value);
    //setSearchKeyWord(e.target.value);
    if (e.target.value.length > 1) {
      setShowSearchResult(true);
      setTimeout(() => {
        setSearchKeyWord(e.target.value);
        // console.log("timeout");
      }, 500);
    } else {
      setShowSearchResult(false);
    }
  };

  /** Export All Data */
  const getDataFromApi = (apiLimit, apiOffset) => {
    try {
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const api = `${process.env.REACT_APP_BASE_URL}/api/v1/product/generic-name/generic-name-list/?limit=${apiLimit}&offset=${apiOffset}`;

      axios
        .get(api, settings)
        .then((res) => {
          setAllGenericList((prevData) => [
            ...prevData,
            ...res.data.generic_names.results,
          ]);
          //console.log(allProductList.length);
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };
  const getAllGenerics = () => {
    const getDataLimit = 500;
    if (allGenericList.length !== totalItems) {
      let offsetValue = 0;
      const totalCall = Math.ceil(totalItems / getDataLimit);
      for (let i = 0; i < totalCall; i++) {
        //console.log("inside");
        // if (i === totalCall - 1) {
        //   setAllData(true);
        //   console.log("inside");
        // }
        getDataFromApi(getDataLimit, offsetValue);
        //await dispatch(getAllProductList(100, offsetValue));
        //setOffsetCall(100 + offsetCall);
        offsetValue = offsetValue + getDataLimit;
        //console.log(offsetValue);
      }
    }
  };

  return (
    <div>
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <ImportFileModal
        show={importGenericModal}
        onHide={() => setImportGenericModal(false)}
        title={`Generic List`}
        importType="generic_name"
        url="/generic/"
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Generic List</h4>
              <p className="product-paragraph">
                {" "}
                Products <FontAwesomeIcon
                  icon={faChevronRight}
                  size="xs"
                />{" "}
                Generic List
              </p>
            </Col>
            <Col lg={6} className="d-flex justify-content-lg-end">
              <button
                className="pe-3 ps-3 border-0 add-product-button"
                onClick={() => setImportGenericModal(true)}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ paddingRight: "0.2rem" }}
                />{" "}
                Import Generic File
              </button>
            </Col>
          </Row>
          <div
            className="mb-4 p-3 card product-card container-view"
            style={{ minHeight: "50vh" }}
          >
            <Row className="mb-4">
              <Col lg={6} className="mb-2 mb-lg-0">
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchResult}
                />
              </Col>
              <Col lg={6} className="d-flex justify-content-md-end">
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">Export as a Excel</Tooltip>
                  }
                >
                  <img
                    //className="pe-3"
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => {
                      setExportData(true);
                      getAllGenerics();
                      setType("excel");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
            <Table responsive hover>
              <thead className="table-header">
                <tr>
                  <th>Generic Id</th>
                  <th>Generic Name</th>
                </tr>
              </thead>
              <tbody className="">
                {showSearchResult &&
                !searchLoading &&
                searchGenericList?.data !== undefined &&
                searchGenericList?.data.generic_names.results.length !== 0 ? (
                  searchGenericList?.data.generic_names.results.map(
                    (generic) => (
                      <tr key={generic.id}>
                        <td>{generic.id}</td>
                        <td>{generic.generic_name}</td>
                      </tr>
                    )
                  )
                ) : genericList?.data !== undefined &&
                  genericList?.data.generic_names.results.length !== 0 &&
                  !showSearchResult ? (
                  genericList?.data.generic_names.results.map((generic) => (
                    <tr key={generic.id}>
                      <td>{generic.id}</td>
                      <td>{generic.generic_name}</td>
                    </tr>
                  ))
                ) : loading === true || searchLoading ? (
                  <tr className="text-center">
                    <td colSpan="2">
                      <Spinner animation="border" variant="warning" />
                    </td>
                  </tr>
                ) : (
                  <tr className="text-center">
                    <td colSpan="2">No Result Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {pageCount > 1 && !showSearchResult ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default GenericList;
