import React from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";

const WarehouseForm = (props) => {
  const handleCancelClick = () => {
    window.location.reload();
    //successAlert();
  };
  return (
    <Form className="p-4" onSubmit={props.handleSubmitForm}>
      <Row>
        <Col lg={4}>
          <Form.Group className=" form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Warehouse/Outlet Name<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Warehouse name"
              value={props.destinationName}
              onChange={(e) => props.setDestinationName(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className=" form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Contact Number<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Contact Number"
              maxLength={14}
              value={props.contactNumber}
              onChange={(e) => props.setContactNumber(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className=" form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Email Address<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Email Address"
              value={props.emailAddress}
              onChange={(e) => props.setEmailAddress(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            Address<span className="required-field">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Address"
            style={{ height: "100px" }}
            onChange={(e) => props.setDestinationAddress(e.target.value)}
            value={props.destinationAddress}
            required
          />
        </Form.Group>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              City<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="e.g:Dhaka"
              onChange={(e) => props.setCity(e.target.value)}
              value={props.city}
              required
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Country<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Country Name"
              value={props.country}
              onChange={(e) => props.setCountry(e.target.value)}
              required
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Zip Code<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Zip Code"
              value={props.zipcode}
              onChange={(e) => props.setZipcode(e.target.value)}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            Description<span className="required-field">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Write Description..."
            style={{ height: "100px" }}
            onChange={(e) => props.setDescription(e.target.value)}
            value={props.description}
            required
          />
        </Form.Group>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group className=" form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Status<span className="required-field">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={props.status}
              onChange={(e) => props.setStatus(e.target.value)}
              required
            >
              <option value="" disabled>
                Active or In Active
              </option>
              <option value="active">Active</option>
              <option value="inactive">InActive</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className=" form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Type<span className="required-field">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={props.type}
              onChange={(e) => props.setType(e.target.value)}
              required
            >
              <option value="" disabled>
                Warehouse or Outlet
              </option>
              <option value="warehouse">Warehouse</option>
              <option value="outlet">Outlet</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      {props.loading === true ? (
        <Row className="mt-2">
          <Col xl={4} lg={12} className="d-flex justify-content-center">
            <Spinner animation="border" variant="warning" />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row className="mt-3">
        <Col xl={2} lg={6} className="mb-2">
          {props.loading === true ? (
            <Button className="disable-submit-btn" variant="secondary" disabled>
              Submit
            </Button>
          ) : (
            <Button className="submit-btn border-0" type="submit">
              Submit
            </Button>
          )}
        </Col>
        <Col xl={2} lg={6}>
          <Button className="cancel-btn border-0" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default WarehouseForm;
