import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Col, Container, Row, Table } from "react-bootstrap";
import barcode from "../../Assets/icons/barcode.png";
import printIcon from "../../Assets/icons/print.png";
import tissue from "../../Assets/icons/tissue.png";
import "./Product.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { getEachProductDetails } from "../../Redux/actions/productActions";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import defaultImage from "../../Assets/icons/default.png";

const EachProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { loading, product, error } = useSelector(
    (state) => state.productDetail
  );

  useEffect(() => {
    dispatch(getEachProductDetails(param.id));
  }, [dispatch]);

  const handleClickPrint = () => {
    if (product.barcode_image) {
      window.print();
    } else {
      alert("Product Barcode not Found");
    }
  };

  //console.log(error);
  return (
    <>
      {error && error.includes("no product found") ? (
        <NotFoundPage></NotFoundPage>
      ) : (
        <div>
          <div className="hide-print">
            <SideBar></SideBar>
          </div>
          <div className="mainContent">
            <div className="hide-print">
              <Header></Header>
            </div>
            <Container fluid>
              <Row className="ms-2 me-2">
                <Col lg={6} className="hide-print">
                  <h4 className="product-heading">Product Details</h4>
                  <p className="product-paragraph">
                    Products <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
                    Product List{" "}
                    <FontAwesomeIcon icon={faChevronRight} size="xs" /> Full
                    Details of Product
                  </p>
                </Col>
                <Col lg={6} className="d-flex justify-content-lg-end">
                  <button
                    className="pe-3 ps-3 border-0 add-product-button hide-print"
                    onClick={() => navigate(`/updateProduct/${product.id}`)}
                  >
                    <FontAwesomeIcon
                      icon={faPenAlt}
                      style={{ paddingRight: "0.2rem" }}
                    />{" "}
                    Update Product
                  </button>
                </Col>
              </Row>
              <Row className="animated fadeInTop">
                <Col lg={9}>
                  <div className="mb-4 p-3 card product-card container-view">
                    <Row className="" style={{ marginLeft: "0px" }}>
                      <Col
                        className="p-3"
                        xxl={4}
                        xl={5}
                        lg={7}
                        style={{
                          border: "1px solid #DCE0E4",
                          borderRadius: "5px",
                        }}
                      >
                        <Row>
                          <Col lg={8}>
                            <img
                              className="w-100"
                              src={product.barcode_image}
                              alt="barcode"
                              height={80}
                            />
                          </Col>
                          <Col
                            lg={4}
                            className="mt-2 d-flex justify-content-end"
                          >
                            <img
                              className="hide-print"
                              src={printIcon}
                              alt="printIcon"
                              height={45}
                              style={{ cursor: "pointer" }}
                              onClick={handleClickPrint}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Table
                      className="mt-4 mb-0 hide-print"
                      responsive="xl"
                      bordered
                      hover
                    >
                      <tbody>
                        <tr>
                          <th className="col-4">Product Name</th>
                          <td>{product.product_name}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Generic name</th>
                          <td>
                            {product.generic_name
                              ? product.generic_name
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Barcode Type</th>
                          <td>{product.barcode_type}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Categoty</th>
                          <td>{product.catagory}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Sub Category 1</th>
                          <td>
                            {!product.sub_catagory_1
                              ? "N/A"
                              : product.sub_catagory_1}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Sub Category 2</th>
                          <td>
                            {!product.sub_catagory_2
                              ? "N/A"
                              : product.sub_catagory_2}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Sub Category 3</th>
                          <td>
                            {!product.sub_catagory_3
                              ? "N/A"
                              : product.sub_catagory_3}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Units</th>
                          <td>{product.unit_size}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Total Stocks</th>
                          <td>{product.stocks}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Warehouse Stocks</th>
                          <td>{product.wh_stock}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Retail Stocks</th>
                          <td>{product.retail_stock}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Pack Size</th>
                          <td>{product.pack_size}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Supplier Name</th>
                          <td>
                            {!product.supplier ? "N/A" : product.supplier}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Purchase Price</th>
                          <td>
                            {product.purchase_price !== null
                              ? product.purchase_price + "৳"
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">New Purchase Price</th>
                          <td>
                            {!product.new_purchase_price
                              ? "N/A"
                              : product.new_purchase_price + "৳"}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">MRP</th>
                          <td>
                            {product.sale_price !== null
                              ? product.sale_price + "৳"
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">New MRP</th>
                          <td>
                            {!product.new_sale_price
                              ? "N/A"
                              : product.new_sale_price + "৳"}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Profit in %</th>
                          <td>
                            {!product.profit
                              ? "N/A"
                              : parseFloat(product.profit).toFixed(2) + "%"}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Discount in %</th>
                          <td>{!product.discount ? "0" : product.discount}%</td>
                        </tr>
                        <tr>
                          <th className="col-4">Purchase Vat in %</th>
                          <td>
                            {!product.purchase_vat ? "0" : product.purchase_vat}
                            %
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Sale Vat in %</th>
                          <td>{!product.sale_vat ? "0" : product.sale_vat}%</td>
                        </tr>
                        <tr>
                          <th className="col-4">Product Commission in %</th>
                          <td>
                            {!product.product_commission
                              ? "0"
                              : product.product_commission}
                            %
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Product type</th>
                          <td>{product.product_type}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Product Location</th>
                          <td>
                            {product.location !== null
                              ? product.location
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Required Prescription</th>
                          <td>{product.require_prescription}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Promotion Brand</th>
                          <td>
                            {!product.brand_promotion
                              ? "N/A"
                              : product.brand_promotion}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Promotion</th>
                          <td>
                            {!product.promotion ? "N/A" : product.promotion}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Promotion 1</th>
                          <td>
                            {!product.promotion1 ? "N/A" : product.promotion1}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">App Promotion Field</th>
                          <td>
                            {!product.app_promotion
                              ? "N/A"
                              : product.app_promotion}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Product Search Tag</th>
                          <td>
                            {!product.product_search_tag
                              ? "N/A"
                              : product.product_search_tag}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Product Description</th>
                          <td>
                            {!product.description ? "N/A" : product.description}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
                <Col lg={3} className="mb-3 hide-print">
                  <div className="p-2 card product-card product-details-right-section">
                    <img src={product.image} alt={"Upload Image"} />
                  </div>
                  <div className="mt-3 card product-details-right-section">
                    <Table
                      className="mb-0"
                      responsive
                      bordered
                      hover
                      style={{ fontSize: "14px" }}
                    >
                      <thead className="text-center">
                        <tr>
                          <th>Batch No.</th>
                          <th>Qty</th>
                          <th>Expiry Date</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {product.batch && product.batch.length ? (
                          product.batch?.map((batch, i) => (
                            <tr key={i}>
                              <td>{batch.batch_name}</td>
                              <td>{batch.quantity}</td>
                              <td>{batch.expire_date}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="12">No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="hide-print">
            <Footer></Footer>
          </div>
        </div>
      )}
    </>
  );
};

export default EachProduct;
