import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Modal, Button } from "react-bootstrap";
import {
  deletePurchaseRequest,
  getPurchaseRequestList,
} from "../../Redux/actions/purchaseActions";
import {
  deleteSingleProduct,
  getProductList,
} from "../../Redux/actions/productActions";
import {
  getSubcategoryList,
  deleteSubcategory,
} from "../../Redux/actions/subcategoryActions";
import {
  getCategoryList,
  deleteCategory,
} from "../../Redux/actions/categoryActions";
import {
  deletePurchase,
  deletePurchaseItem,
  getPurchaseList,
} from "../../Redux/actions/purchaseActions";
import { deleteVendor, getVendorList } from "../../Redux/actions/vendorActions";
import {
  deleteSupplier,
  getSupplierList,
} from "../../Redux/actions/supplierActions";
import {
  deleteReturn,
  deleteReturnItem,
  getReturnList,
} from "../../Redux/actions/returnActions";
import {
  deleteWarehouse,
  getWarehouseList,
} from "../../Redux/actions/warehouseActions";
import { SuccessAlert } from "../notify/notify";

const DeleteModal = (props) => {
  const dispatch = useDispatch();
  const [alertMsg, setAlertMsg] = useState(false);
  const [productAlertmsg, setProductAlertMsg] = useState(false);
  const [subcategoryAlertMsg, setSubcategoryAlertMsg] = useState(false);
  const [categoryAlertMsg, setCategoryAlertMsg] = useState(false);
  const [purchaseAlertMsg, setPurchaseAlertMsg] = useState(false);
  const [purchaseItemAlertMsg, setPurchaseItemAlertMsg] = useState(false);
  const [vendorAlertMsg, setVendorAlertMsg] = useState(false);
  const [supplierAlertMsg, setSupplierAlertMsg] = useState(false);
  const [returnAlertMsg, setReturnAlertMsg] = useState(false);
  const [returnItemAlertMsg, setReturnItemAlertMsg] = useState(false);
  const [warehouseAlertMsg, setWarehouseAlertMsg] = useState(false);
  const { loading, message, error } = useSelector(
    (state) => state.deletePurchaseRequest
  );

  const deleteProductResponse = useSelector((state) => state.deleteProduct);

  const deleteSubcategoryResponse = useSelector(
    (state) => state.deleteSubcategory
  );

  const deleteCategoryResponse = useSelector((state) => state.deleteCategory);

  const deletePurchaseResponse = useSelector((state) => state.deletePurchase);

  const deletePurchaseItemResponse = useSelector(
    (state) => state.deletePurchaseItem
  );

  const deleteVendorResponse = useSelector((state) => state.deleteVendor);

  const deleteSupplierResponse = useSelector((state) => state.deleteSupplier);

  const deleteReturnResponse = useSelector((state) => state.deleteReturn);

  const deleteReturnItemResponse = useSelector(
    (state) => state.deleteReturnItem
  );

  const deleteWarehouseResponse = useSelector((state) => state.deleteWarehouse);

  useEffect(() => {
    if (message !== undefined && alertMsg === true) {
      SuccessAlert("Purchase Request Deleted Successfully");
      dispatch(getPurchaseRequestList(10, 0));
      setAlertMsg(false);
    }
    if (error !== undefined && alertMsg === true) {
      setAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [message, error, alertMsg]);

  useEffect(() => {
    if (
      deleteProductResponse.message !== undefined &&
      productAlertmsg === true
    ) {
      SuccessAlert("Product Deleted Successfully");
      if (props.totalItems <= props.limit) {
        dispatch(getProductList(props.limit, 0));
      } else if (
        props.totalItems > props.limit &&
        props.totalItems % props.limit === 1
      ) {
        dispatch(getProductList(props.limit, 0));
      } else {
        dispatch(getProductList(props.limit, props.offset));
      }
      setProductAlertMsg(false);
    }
    if (deleteProductResponse.error !== undefined && productAlertmsg === true) {
      setProductAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [
    deleteProductResponse.message,
    deleteProductResponse.error,
    productAlertmsg,
  ]);

  useEffect(() => {
    if (
      deleteSubcategoryResponse.message !== undefined &&
      subcategoryAlertMsg === true
    ) {
      SuccessAlert("Sub-Category Deleted Successfully");
      dispatch(getSubcategoryList(15, 0));
      setSubcategoryAlertMsg(false);
    }
    if (
      deleteSubcategoryResponse.error !== undefined &&
      subcategoryAlertMsg === true
    ) {
      setSubcategoryAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [
    deleteSubcategoryResponse.message,
    deleteSubcategoryResponse.error,
    subcategoryAlertMsg,
  ]);

  useEffect(() => {
    if (
      deleteCategoryResponse.message !== undefined &&
      categoryAlertMsg === true
    ) {
      SuccessAlert("Category Deleted Successfully");
      dispatch(getCategoryList(15, 0));
      setCategoryAlertMsg(false);
    }
    if (
      deleteCategoryResponse.error !== undefined &&
      categoryAlertMsg === true
    ) {
      setCategoryAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [
    deleteCategoryResponse.message,
    deleteCategoryResponse.error,
    categoryAlertMsg,
  ]);

  useEffect(() => {
    if (
      deletePurchaseResponse.message !== undefined &&
      purchaseAlertMsg === true
    ) {
      SuccessAlert("Purchase Deleted Successfully");
      dispatch(getPurchaseList(15, 0));
      setPurchaseAlertMsg(false);
    }
    if (
      deletePurchaseResponse.error !== undefined &&
      purchaseAlertMsg === true
    ) {
      setPurchaseAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [
    deletePurchaseResponse.message,
    deletePurchaseResponse.error,
    purchaseAlertMsg,
  ]);

  useEffect(() => {
    if (
      deletePurchaseItemResponse.message !== undefined &&
      purchaseItemAlertMsg === true
    ) {
      SuccessAlert("Purchase Item Deleted Successfully");
      dispatch(getPurchaseList(15, 0));
      setPurchaseItemAlertMsg(false);
    }
    if (
      deletePurchaseItemResponse.error !== undefined &&
      purchaseItemAlertMsg === true
    ) {
      setPurchaseItemAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [
    deletePurchaseItemResponse.message,
    deletePurchaseItemResponse.error,
    purchaseItemAlertMsg,
  ]);

  useEffect(() => {
    if (deleteVendorResponse.message !== undefined && vendorAlertMsg === true) {
      SuccessAlert("Vendor Deleted Successfully");
      setVendorAlertMsg(false);
      if (props.totalItems <= props.limit) {
        dispatch(getVendorList(props.limit, 0));
      } else if (
        props.totalItems > props.limit &&
        props.totalItems % props.limit === 1
      ) {
        dispatch(getVendorList(props.limit, 0));
      } else {
        dispatch(getVendorList(props.limit, props.offset));
      }
    }
    if (deleteVendorResponse.error !== undefined && vendorAlertMsg === true) {
      setVendorAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [
    deleteVendorResponse.message,
    deleteVendorResponse.error,
    vendorAlertMsg,
  ]);

  useEffect(() => {
    if (
      deleteSupplierResponse.message !== undefined &&
      supplierAlertMsg === true
    ) {
      SuccessAlert("Supplier Deleted Successfully");
      setSupplierAlertMsg(false);
      if (props.totalItems <= props.limit) {
        dispatch(getSupplierList(props.limit, 0));
      } else if (
        props.totalItems > props.limit &&
        props.totalItems % props.limit === 1
      ) {
        dispatch(getSupplierList(props.limit, 0));
      } else {
        dispatch(getSupplierList(props.limit, props.offset));
      }
    }
    if (
      deleteSupplierResponse.error !== undefined &&
      supplierAlertMsg === true
    ) {
      setSupplierAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [
    deleteSupplierResponse.message,
    deleteSupplierResponse.error,
    vendorAlertMsg,
  ]);

  useEffect(() => {
    if (deleteReturnResponse.message !== undefined && returnAlertMsg === true) {
      SuccessAlert("Return Deleted Successfully");
      dispatch(getReturnList(15, 0));
      setReturnAlertMsg(false);
    }
    if (deleteReturnResponse.error !== undefined && returnAlertMsg === true) {
      setReturnAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [
    deleteReturnResponse.message,
    deleteReturnResponse.error,
    returnAlertMsg,
  ]);

  useEffect(() => {
    if (
      deleteReturnItemResponse.message !== undefined &&
      returnItemAlertMsg === true
    ) {
      SuccessAlert("Return Item Deleted Successfully");
      dispatch(getReturnList(15, 0));
      setReturnItemAlertMsg(false);
    }
    if (
      deleteReturnItemResponse.error !== undefined &&
      returnItemAlertMsg === true
    ) {
      setReturnItemAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [
    deleteReturnItemResponse.message,
    deleteReturnItemResponse.error,
    returnItemAlertMsg,
  ]);

  useEffect(() => {
    if (
      deleteWarehouseResponse.message !== undefined &&
      warehouseAlertMsg === true
    ) {
      SuccessAlert("Warehouse Deleted Successfully");
      dispatch(getWarehouseList(15, 0));
      setWarehouseAlertMsg(false);
    }
    if (
      deleteWarehouseResponse.error !== undefined &&
      warehouseAlertMsg === true
    ) {
      setWarehouseAlertMsg(false);
      alert(deleteWarehouseResponse.error);
    }
  }, [
    deleteWarehouseResponse.message,
    deleteWarehouseResponse.error,
    warehouseAlertMsg,
  ]);

  const handleDelete = () => {
    if (props.item) {
      dispatch(deletePurchaseRequest(props.item.id));
      setAlertMsg(true);
      props.onHide();
      props.action();
      //console.log(message);
    } else if (props.product) {
      dispatch(deleteSingleProduct(props.product.id));
      setProductAlertMsg(true);
      props.onHide();
      props.action();
    } else if (props.subcategory) {
      dispatch(deleteSubcategory(props.subcategory.id));
      setSubcategoryAlertMsg(true);
      props.onHide();
      props.action();
    } else if (props.category) {
      dispatch(deleteCategory(props.category.id));
      setCategoryAlertMsg(true);
      props.onHide();
      props.action();
    } else if (props.purchaseProduct) {
      dispatch(deletePurchaseItem(props.purchaseProduct.id));
      setPurchaseItemAlertMsg(true);
      props.onHide();
      props.action();
    } else if (props.purchase) {
      dispatch(deletePurchase(props.purchase.id));
      setPurchaseAlertMsg(true);
      props.onHide();
      props.action();
    } else if (props.vendor) {
      dispatch(deleteVendor(props.vendor.id));
      setVendorAlertMsg(true);
      props.onHide();
      props.action();
    } else if (props.supplier) {
      dispatch(deleteSupplier(props.supplier.id));
      setSupplierAlertMsg(true);
      props.onHide();
      props.action();
    } else if (props.returnProduct) {
      dispatch(deleteReturnItem(props.returnProduct.id));
      setReturnItemAlertMsg(true);
      props.onHide();
      props.action();
    } else if (props.return) {
      dispatch(deleteReturn(props.return.uuid));
      setReturnAlertMsg(true);
      props.onHide();
      props.action();
    } else if (props.destination) {
      dispatch(deleteWarehouse(props.destination.id));
      setWarehouseAlertMsg(true);
      props.onHide();
      props.action();
    } else {
      props.onHide();
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Body className="p-4">
          <Row className="d-flex justify-content-center">
            {props.item
              ? `PO ID : "${props.item.id}"`
              : props.product
              ? `"${props.product.product_name}" product`
              : props.subcategory
              ? `"${props.subcategory.name}" Sub Category`
              : props.category
              ? `Deleting "${props.category.name}" category will also delete all its associated subcategories.
              If you proceed with deleting this category,
             all subcategories under it`
              : props.purchaseProduct
              ? `"${props.purchaseProduct.product_name}" purchase product`
              : props.purchase
              ? `Purchase Id:"${props.purchase.id}"`
              : props.vendor
              ? `"${props.vendor.vendor_name}" vendor`
              : props.supplier
              ? `"${props.supplier.supplier_name}" supplier`
              : props.returnProduct
              ? `"${props.returnProduct.product__product_name}" return product`
              : props.return
              ? `Return Id:"${props.return.uuid}"`
              : props.destination
              ? `"${props.destination.name}"`
              : "Data"}{" "}
            will be deleted Permanently!!
          </Row>
          <Row
            className="d-flex justify-content-center"
            style={{ fontWeight: "bold" }}
          >
            Are you sure??
          </Row>
          <Row className="mt-3">
            <div className="d-flex justify-content-center">
              <Button variant="danger" onClick={handleDelete}>
                Delete
              </Button>
              <Button
                className="ms-3"
                variant="secondary"
                onClick={props.onHide}
              >
                Cancel
              </Button>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteModal;
