import jsPDF from "jspdf";
import "jspdf-autotable";

const addFooters = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
  }
};

const SingleTransferPdf = (itemDetails) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });
  const date = new Date();
  const tableRows = itemDetails.transfer_product.map((item, i) => [
    i + 1,
    item.product.barcode,
    item.product.product_name,
    item.product.unit,
    item.product_batch,
    item.product.stock,
    item.product.wh_stock,
    item.product.retail_stock,
    item.transfer_quantity,
    item.product.new_product_price === "0.00"
      ? item.product.product_price
      : item.product.new_product_price,
    item.product.VAT ? item.product.VAT : "0",
    item.product.new_product_price === "0.00"
      ? (
          (item.product.product_price *
            (parseInt(item.product.VAT ? item.product.VAT : 0) / 100) +
            parseFloat(item.product.product_price)) *
          item.transfer_quantity
        ).toFixed(2)
      : (
          (item.product.new_product_price *
            (parseInt(item.product.VAT ? item.product.VAT : 0) / 100) +
            parseFloat(item.product.new_product_price)) *
          item.transfer_quantity
        ).toFixed(2),
  ]);
  const columnData = [
    "SL No",
    "BarCode",
    "Product",
    "Size",
    "Batch No.",
    "Total Stock",
    "Wh Stock",
    "Retail Stock",
    "Transfer Qty",
    "MRP",
    "Vat (%)",
    "SubTotal (TK)",
  ];
  var title = "Care-Box";
  var reportName = "Transfer Details";
  var titleWidth = doc.getTextDimensions(title).w;
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  var x = (pageWidth - titleWidth) / 2;
  doc.text(title, x, 14);
  doc.text(
    reportName,
    (pageWidth - doc.getTextDimensions(reportName).w) / 2,
    20
  );
  doc.setFontSize(8);
  doc.text(
    `Transfer ID: ${
      itemDetails.transfered_id ? itemDetails.transfered_id : itemDetails.id
    }`,
    16,
    24
  );
  doc.text(
    `Transfer Date: ${itemDetails.date}`,
    doc.internal.pageSize.width - 60,
    24
  );
  doc.setFontSize(8);
  doc.text(`Staff Remarks: ${itemDetails.staff_remark}`, 16, 28);
  doc.text(
    `Transfer From: ${itemDetails.transfer_from.name}`,
    doc.internal.pageSize.width - 60,
    28
  );
  doc.text(
    `Transfer Type: ${
      itemDetails.transfer_type === "purchase"
        ? "Purchase Wise Transfer"
        : "Product Wise Transfer"
    }`,
    16,
    32
  );
  doc.text(
    `Transfer To: ${itemDetails.transfer_to.name}`,
    doc.internal.pageSize.width - 60,
    32
  );
  doc.text(
    `Transfer Note: ${
      itemDetails.transfer_note ? itemDetails.transfer_note : "N/A"
    }`,
    16,
    36
  );
  doc.autoTable({
    //styles: { fillColor: [0, 0, 0] },
    startY: 40,
    columns: columnData,
    body: tableRows,
    theme: "plain",
    styles: {
      halign: "center",
      lineColor: "DCE0E4",
      lineWidth: 0.2,
    },
    headStyles: {
      textColor: "black",
      fillColor: "#fafbfe",
    },
  });

  doc.autoTable({
    columns: [
      "A",
      "Total Amount:",
      `${parseFloat(itemDetails.transfer_total) - itemDetails.shipping_cost}TK`,
    ],
    body: [
      ["B", "Shipping Cost:", `${itemDetails.shipping_cost}TK`],
      [
        ".......................",
        ".......................",
        ".......................",
      ],
      ["A - B", "Grand Total:", `${itemDetails.transfer_total}TK`],
    ],
    startY: doc.lastAutoTable.finalY + 10,
    //styles: { overflow: 'hidden' },
    theme: "plain",
    styles: {
      halign: "center",
      fontStyle: "bold",
    },
    margin: { right: doc.internal.pageSize.width / 2, left: 35 },
  });

  doc.setFontSize(10);
  doc.text(
    `${itemDetails.issued_by ? itemDetails.issued_by.Name : "N/A"}`,
    18,
    doc.lastAutoTable.finalY + 18
  );
  doc.line(
    12,
    doc.lastAutoTable.finalY + 20,
    40,
    doc.lastAutoTable.finalY + 20
  );
  doc.text("Transferred By", 16, doc.lastAutoTable.finalY + 25);
  doc.text(
    `${itemDetails.staff_remark ? itemDetails.staff_remark : "N/A"}`,
    (pageWidth - doc.getTextDimensions(`${itemDetails.staff_remark}`).w) / 2,
    doc.lastAutoTable.finalY + 18
  );
  doc.line(
    doc.internal.pageSize.width / 2 - 20,
    doc.lastAutoTable.finalY + 20,
    doc.internal.pageSize.width / 2 + 20,
    doc.lastAutoTable.finalY + 20
  );
  doc.text(
    "Received By",
    (pageWidth - doc.getTextDimensions("Received By").w) / 2,
    doc.lastAutoTable.finalY + 25
  );
  doc.line(
    doc.internal.pageSize.width - 45,
    doc.lastAutoTable.finalY + 20,
    doc.internal.pageSize.width - 10,
    doc.lastAutoTable.finalY + 20
  );
  doc.text(
    "Permitted By",
    doc.internal.pageSize.width - 38,
    doc.lastAutoTable.finalY + 25
  );

  addFooters(doc);
  doc.save(
    "Care-Box-Transfer-Details" +
      itemDetails.id +
      "_" +
      date.toLocaleTimeString()
  );
  // return (
  //   <div
  //     className="container"
  //     style={{
  //       marginTop: "1rem",
  //       marginLeft: "1rem",
  //       marginRight: "1rem",
  //       font: "Montserrat",
  //     }}
  //   >
  //     <div style={{ display: "flex", justifyContent: "center" }}>
  //       <p>Care-Box</p>
  //     </div>
  //   </div>
  // );
};

export default SingleTransferPdf;
