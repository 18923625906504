import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/finalLogo.png";
import dashboardLogo from "../../Assets/icons/Dashboard.png";
import productLogo from "../../Assets/icons/Box.png";
import supplierLogo from "../../Assets/icons/arcticons_company-portal.png";
import vendorLogo from "../../Assets/icons/arcticons_vendora.png";
import purchaseLogo from "../../Assets/icons/arcticons_purchased-apps.png";
import returnLogo from "../../Assets/icons/Return Delivery Box.png";
import warehouseLogo from "../../Assets/icons/warehouse.png";
import transferLogo from "../../Assets/icons/transfer.png";
import reportsLogo from "../../Assets/icons/mdi_report-finance.png";
import listLogo from "../../Assets/icons/Ellipse.png";
import selectedLogo from "../../Assets/icons/selected.png";
import posLogo from "../../Assets/icons/cashier.png";
import dueLogo from "../../Assets/icons/due.png";
import utilitiesLogo from "../../Assets/icons/utilities.png";
import voidLogo from "../../Assets/icons/void.png";
import "./Sidebar.css";
import { SidebarAction } from "../../Redux/actions/sidebarActions";
import SidebarItem from "./SidebarItem";

const SideBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeState, setActiveState] = useState();
  const { toggleClick } = useSelector((state) => state.sideBarState);
  const { user } = useSelector((state) => state.userDetails);
  const [voidUser, setVoidUser] = useState(false);
  //console.log(toggleClick);

  useEffect(() => {
    const matchedItem = sidebarItems.find(
      (item) =>
        item.childs.some((child) => child.path === location.pathname) ||
        item.path === location.pathname
    );
    if (matchedItem) {
      setActiveState(matchedItem.eventKey);
    } else {
      if (
        location.pathname.includes("/product/") ||
        location.pathname.includes("/updateProduct/")
      ) {
        setActiveState("2");
      } else if (
        location.pathname.includes("/supplier/") ||
        location.pathname.includes("/updateSupplier/")
      ) {
        setActiveState("3");
      } else if (
        location.pathname.includes("/vendor/") ||
        location.pathname.includes("/updateVendor/")
      ) {
        setActiveState("4");
      } else if (
        location.pathname.includes("/purchase/") ||
        location.pathname.includes("/purchaseOrder/")
      ) {
        setActiveState("5");
      } else if (
        location.pathname.includes("/destination/") ||
        location.pathname.includes("/updateDestination/")
      ) {
        setActiveState("7");
      } else if (location.pathname.includes("/transfer/")) {
        setActiveState("8");
      } else if (location.pathname.includes("/void/")) {
        setActiveState("10");
      } else {
        setActiveState("1");
      }
    }

    // console.log("matchedItem = ", matchedItem);
  }, []);

  useEffect(() => {
    if (user && user.can_perform_void) {
      setVoidUser(true);
    }
  }, [user]);
  // useEffect(() => {
  //   if (productId !== null && location.pathname === `/product/${productId}`) {
  //     console.log("second");
  //     setActiveState("second");
  //   }
  // }, [productId]);
  //console.log(activeState);

  // useEffect(() => {
  //   if (localStorage.getItem("menuState") === "true") {
  //     setSideMenuState(true);
  //   } else {
  //     setSideMenuState(false);
  //   }
  // }, [localStorage.getItem("menuState"), props]);

  const detectLocation = (pathname) => {
    if (location.pathname === pathname) {
      return "active";
    } else {
      return "";
    }
  };
  const handleChnageSidebar = () => {
    if (toggleClick === true) {
      dispatch(SidebarAction(false));
    } else {
      dispatch(SidebarAction(true));
    }
  };

  const sidebarItems = [
    {
      title: " Dashboard",
      path: "/",
      icon: dashboardLogo,
      eventKey: "1",
      permission: true,
      childs: [],
    },
    {
      title: "Products",
      icon: productLogo,
      eventKey: "2",
      childs: [
        {
          title: "Product List",
          path: "/productList",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Add Product",
          path: "/addProducts",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Category",
          path: "/category",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Sub Category",
          path: "/subCategory",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Generic Name",
          path: "/genericList",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
      ],
    },
    {
      title: "Supplier",
      icon: supplierLogo,
      eventKey: "3",
      childs: [
        {
          title: "Supplier List",
          path: "/supplierList",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Add Supplier",
          path: "/addSupplier",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
      ],
    },
    {
      title: "Vendor",
      icon: vendorLogo,
      eventKey: "4",
      childs: [
        {
          title: "Vendor List",
          path: "/vendorList",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Add Vendor",
          path: "/addVendor",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
      ],
    },
    {
      title: "Purchase",
      icon: purchaseLogo,
      eventKey: "5",
      childs: [
        {
          title: "Purchase List",
          path: "/purchaseList",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Prod. Rcv. (GRN)",
          path: "/addPurchase",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Purchase Req. List",
          path: "/requestPurchase",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Add Purchase Req.",
          path: "/addRequestPurchase",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Purchase Order List",
          path: "/purchaseOrderList",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Add Purchase Order",
          path: "/addPurchaseOrder",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
      ],
    },
    {
      title: "Return",
      icon: returnLogo,
      eventKey: "6",
      childs: [
        {
          title: "Return List",
          path: "/returnList",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Add Return",
          path: "/addReturn",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
      ],
    },
    {
      title: "Destination",
      icon: warehouseLogo,
      eventKey: "7",
      childs: [
        {
          title: "Destination List",
          path: "/destinationList",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Add Destination",
          path: "/addDestination",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
      ],
    },
    {
      title: "Transfer",
      icon: transferLogo,
      eventKey: "8",
      childs: [
        {
          title: "Transfer List",
          path: "/transferList",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Add Transfer",
          path: "/addTransfer",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
      ],
    },
    {
      title: " Due Details",
      path: "/due-details",
      icon: dueLogo,
      eventKey: "9",
      permission: true,
      childs: [],
    },
    {
      title: "Void",
      icon: voidLogo,
      eventKey: "10",
      childs: [
        {
          title: "Void List",
          path: "/voidList",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Add Void",
          path: "/addVoid",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: voidUser,
        },
      ],
    },
    {
      title: "Utilities",
      icon: utilitiesLogo,
      eventKey: "11",
      childs: [
        {
          title: "Product Biography",
          path: "/productBiography",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Create Inventory",
          path: "/createInventory",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Physical Inventory",
          path: "/physicalInventory",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Inventory Logs",
          path: "/inventoryLogs",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
      ],
    },
    {
      title: "Reports",
      icon: reportsLogo,
      eventKey: "12",
      childs: [
        {
          title: "Stock Report",
          path: "/stockReports",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Stock Analysis",
          path: "/stockAnalysis",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Return Report",
          path: "/returnReports",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Supplier Wise Rcv",
          path: "/supplierRcv",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
        {
          title: "Vendor Wise Rcv",
          path: "/vendorRcv",
          selectdIcon: selectedLogo,
          unselectdIcon: listLogo,
          permission: true,
        },
      ],
    },
  ];

  return (
    <div className={toggleClick === true ? "sidebar d-none" : "sidebar"}>
      <div className="d-flex justify-content-end m-2 d-md-none">
        <button
          type="button"
          className="btn-close"
          onClick={handleChnageSidebar}
        ></button>
      </div>
      <div className="mt-5 mb-5 d-flex justify-content-center">
        <img
          src={logo}
          alt="logo"
          style={{ maxHeight: "5.3vh", cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
      </div>
      {activeState !== undefined ? (
        <Accordion className="me-2 ms-2" defaultActiveKey={activeState}>
          {sidebarItems.map((item, index) => (
            <SidebarItem
              item={item}
              key={index}
              detectLocation={detectLocation}
            />
          ))}

          <Accordion.Item className="mb-1 manual-body" eventKey="13">
            <a href="https://pos.care-box.com/" target="_blank">
              <img
                src={posLogo}
                alt="posLogo"
                height={24}
                style={{ paddingRight: "1rem" }}
              />{" "}
              POS System
            </a>
          </Accordion.Item>
        </Accordion>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SideBar;
