import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Col, Container, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faPrint,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import { getVendorDetails } from "../../Redux/actions/vendorActions";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const EachVendor = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const { loading, vendor, error } = useSelector((state) => state.vendorDetail);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(getVendorDetails(param.id));
  }, [dispatch]);
  //console.log(error);
  return (
    <>
      {error && error.includes("Not found.") ? (
        <NotFoundPage />
      ) : (
        <div>
          <SideBar />
          <div className="mainContent">
            <Header />
            <Container fluid>
              <h4 className="product-heading container-view">Vendor Details</h4>
              <p className="product-paragraph container-view">
                Vendor <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
                Vendor List <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
                Full Details of Vendor
              </p>
              <div className="mb-4 p-3 card product-card container-view">
                <Row className="mb-4">
                  <Col lg={9} className="mb-2">
                    <Table className="mb-0" responsive="xl" bordered hover>
                      <tbody>
                        <tr>
                          <th className="col-4">Vendor Id</th>
                          <td>{vendor.id}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Category of Vendor</th>
                          <td>{vendor.category_of_vendor}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Vendor Name</th>
                          <td>{vendor.vendor_name}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Vendor Address</th>
                          <td>{vendor.vendor_address}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Contact Number</th>
                          <td>{vendor.contact_number}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Email Address</th>
                          <td>
                            {vendor.email_address
                              ? vendor.email_address
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Contact Person</th>
                          <td>{vendor.contact_Person}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Status</th>
                          <td>{vendor.status}</td>
                        </tr>
                        <tr>
                          <th className="col-4">Documents</th>
                          <td>
                            <a
                              href={vendor.Documents}
                              attributes-list="true"
                              download="optional-value"
                              target="_blank"
                            >
                              {vendor.Documents ? (
                                <>
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                    className="pe-2"
                                  />
                                  <u>{vendor.vendor_name}.document</u>
                                </>
                              ) : (
                                <u>N/A</u>
                              )}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <th className="col-4">Created Time</th>
                          <td>{vendor.created_at}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col lg={3} className="d-none d-flex justify-content-end">
                    <button className="pe-4 ps-4 border-0 print-button">
                      Print{" "}
                      <FontAwesomeIcon
                        icon={faPrint}
                        style={{ paddingLeft: "0.5rem" }}
                      />
                    </button>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default EachVendor;
