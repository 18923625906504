import {
  RETURN_STOCK_REPORT_REQUEST,
  RETURN_STOCK_REPORT_SUCCESS,
  RETURN_STOCK_REPORT_FAILURE,
} from "../type.js";

export const returnStockReportReducer = (
  state = { returnStockList: {} },
  action
) => {
  switch (action.type) {
    case RETURN_STOCK_REPORT_REQUEST:
      return { ...state, loading: true };
    case RETURN_STOCK_REPORT_SUCCESS:
      return { loading: false, returnStockList: action.payload };
    case RETURN_STOCK_REPORT_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
