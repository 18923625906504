import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Spinner, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getZeroStockReport } from "../../../Redux/actions/stockAnalysisActions";

const ZeroStockReport = (props) => {
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (props.zeroStockList?.data) {
      setTotalItems(props.zeroStockList.data.count);
    }
  }, [props.zeroStockList]);
  const pageCount = Math.ceil(totalItems / props.limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.limit) % totalItems;
    dispatch(
      getZeroStockReport(
        `?limit=${props.limit}&offset=${newOffset}&supplier=${props.supplierId}`
      )
    );
  };
  return (
    <>
      <div className="mt-2 transfer-table">
        <Row
          className="mx-0 py-2 table-header d-flex justify-content-between"
          //style={{ borderBottom: "1px solid #dee2e6" }}
        >
          <Col>
            <b>Supplier Name: </b>{" "}
            {props.selectedSupplier.length
              ? props.selectedSupplier[0].supplier_name
              : ""}
          </Col>
          <Col>
            <b>Total Products: </b>{" "}
            {props.zeroStockList.data ? props.zeroStockList.data.count : ""}
          </Col>
        </Row>
      </div>
      <Table bordered responsive>
        <thead className="text-center report-table-header report-border">
          <tr>
            <th>Barcode</th>
            <th>Category</th>
            <th>Product Information</th>
            <th>Receive Date</th>
            <th>Last Sale Date</th>
            <th>CPU</th>
            <th>RPU</th>
            <th>Pft %</th>
            <th>Received(Qty)</th>
            <th>Sold(Qty)</th>
            <th>Return(Qty)</th>
            <th>Balance(Qty)</th>
            <th>Total CPU</th>
          </tr>
        </thead>
        <tbody className="text-center report-table-body">
          {props.zeroStockList.data &&
          !props.zeroStockLoading &&
          props.zeroStockList.data.results.products &&
          props.zeroStockList.data.results.products.length ? (
            props.zeroStockList.data.results.products.map((prod, i) => (
              <tr key={i}>
                <td>{prod.barcode ? prod.barcode : "N/A"}</td>
                <td>{prod.product_category}</td>
                <td>
                  {prod.product_name} {prod.product_generic_name}{" "}
                  {prod.product_unit}
                </td>
                <td>
                  {prod.last_purchase_date
                    ? prod.last_purchase_date.split("T")[0]
                    : "N/A"}
                </td>
                <td>
                  {prod.last_sale_date
                    ? prod.last_sale_date.split("T")[0]
                    : "N/A"}
                </td>
                <td>{prod.cost_per_unit}</td>
                <td>{prod.retail_per_unit}</td>
                <td>
                  {prod.gross_profit_mergin ? prod.gross_profit_mergin : "N/A"}
                </td>
                <td>{prod.total_purchase_quantity}</td>
                <td>{prod.total_sold_quantity}</td>
                <td>{prod.total_returned_quantity}</td>
                <td>0</td>
                <td>{prod.total_purchase_cost}</td>
              </tr>
            ))
          ) : props.zeroStockLoading ? (
            <tr>
              <td colSpan={13}>
                <Spinner animation="border" variant="warning" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={13}>No Data Found</td>
            </tr>
          )}
          {props.zeroStockList.data &&
          !props.zeroStockLoading &&
          props.zeroStockList.data.results.products ? (
            <tr style={{ fontWeight: "700" }}>
              <td colSpan={8} className="text-start">
                Grand Total
              </td>
              <td>
                {props.zeroStockList.data.results.grand_purchase_quantity}
              </td>
              <td>{props.zeroStockList.data.results.grand_sold_quantity}</td>
              <td>0</td>
              <td>0</td>
              <td>{props.zeroStockList.data.results.grand_cost_price}</td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </Table>
      {props.zeroStockList.data &&
      !props.zeroStockLoading &&
      props.zeroStockList.data.results.products ? (
        <div className="p-3">
          <Row className="">
            <Col
              className="d-flex justify-content-end"
              style={{ height: "30px" }}
            >
              <p>
                <b>Total Received (Qty) : </b>{" "}
                {props.zeroStockList.data.results.grand_purchase_quantity}
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              className="d-flex justify-content-end"
              style={{ height: "30px" }}
            >
              <p>
                <b>Consumption in Quantity : </b> 100%
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              className="d-flex justify-content-end"
              style={{ height: "30px" }}
            >
              <p>
                <b>Total Cost(Tk) : </b>{" "}
                {props.zeroStockList.data.results.grand_cost_price}
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              className="d-flex justify-content-end"
              style={{ height: "30px" }}
            >
              <p>
                <b>Consumption in Cost : </b> 100%
              </p>
            </Col>
          </Row>
        </div>
      ) : (
        <></>
      )}
      {pageCount > 1 && !props.zeroStockLoading ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousClassName="hide"
          nextClassName="hide"
          activeLinkClassName="active"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ZeroStockReport;
