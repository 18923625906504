import {
  SUPPLIER_WISE_RECEIVE_REPORT_REQUEST,
  SUPPLIER_WISE_RECEIVE_REPORT_SUCCESS,
  SUPPLIER_WISE_RECEIVE_REPORT_FAILURE,
  SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_REQUEST,
  SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_SUCCESS,
  SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_FAILURE,
  VENDOR_WISE_RECEIVE_REPORT_REQUEST,
  VENDOR_WISE_RECEIVE_REPORT_SUCCESS,
  VENDOR_WISE_RECEIVE_REPORT_FAILURE,
  BARCODE_STOCK_RECEIPT_REPORT_REQUEST,
  BARCODE_STOCK_RECEIPT_REPORT_SUCCESS,
  BARCODE_STOCK_RECEIPT_REPORT_FAILURE,
} from "../type.js";

export const supplierWiseRcvReducer = (
  state = { supplierWiseRcvList: {} },
  action
) => {
  switch (action.type) {
    case SUPPLIER_WISE_RECEIVE_REPORT_REQUEST:
      return { ...state, loading: true };
    case SUPPLIER_WISE_RECEIVE_REPORT_SUCCESS:
      return { loading: false, supplierWiseRcvList: action.payload };
    case SUPPLIER_WISE_RECEIVE_REPORT_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const supplierRcvSummaryReducer = (
  state = { supplierRcvSummary: {} },
  action
) => {
  switch (action.type) {
    case SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_REQUEST:
      return { summaryLoading: true, ...state };
    case SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_SUCCESS:
      return { summaryLoading: false, supplierRcvSummary: action.payload };
    case SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_FAILURE:
      return { summaryLoading: false, summaryError: action.payload };
    default:
      return state;
  }
};

export const vendorWiseRcvReducer = (
  state = { vendorWiseRcvList: {} },
  action
) => {
  switch (action.type) {
    case VENDOR_WISE_RECEIVE_REPORT_REQUEST:
      return { ...state, loading: true };
    case VENDOR_WISE_RECEIVE_REPORT_SUCCESS:
      return { loading: false, vendorWiseRcvList: action.payload };
    case VENDOR_WISE_RECEIVE_REPORT_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const barcodeStockRcvReducer = (
  state = { barcodeRcvList: {} },
  action
) => {
  switch (action.type) {
    case BARCODE_STOCK_RECEIPT_REPORT_REQUEST:
      return { barcodeLoading: true, ...state };
    case BARCODE_STOCK_RECEIPT_REPORT_SUCCESS:
      return { barcodeLoading: false, barcodeRcvList: action.payload };
    case BARCODE_STOCK_RECEIPT_REPORT_FAILURE:
      return { barcodeLoading: false, barcodeError: action.payload };
    default:
      return state;
  }
};
