import { TOGGLE_SIDE_BAR, SET_PAGE_NUMBER } from "../type";

export const SidebarReducer = (state = { toggleClick: false }, action) => {
  switch (action.type) {
    case TOGGLE_SIDE_BAR:
      return {
        ...state,
        toggleClick: action.payload,
      };
    default:
      return state;
  }
};

export const SetPageNumberReducer = (
  state = {
    pageState: {
      pageNumber: 0,
      cacheData: false,
    },
  },
  action
) => {
  switch (action.type) {
    case SET_PAGE_NUMBER:
      return {
        ...state,
        pageState: action.payload,
      };
    default:
      return state;
  }
};
