import {
  PURCHASE_LIST_REQUEST,
  PURCHASE_LIST_SUCCESS,
  PURCHASE_LIST_FAILURE,
  PURCHASE_DELETE_REQUEST,
  PURCHASE_DELETE_SUCCESS,
  PURCHASE_DELETE_FAILURE,
  PURCHASED_ITEM_DELETE_REQUEST,
  PURCHASED_ITEM_DELETE_SUCCESS,
  PURCHASED_ITEM_DELETE_FAILURE,
  SEARCH_PURCHASE_LIST_REQUEST,
  SEARCH_PURCHASE_LIST_SUCCESS,
  SEARCH_PURCHASE_LIST_FAILURE,
  CREATE_PURCHASE_REQUEST,
  CREATE_PURCHASE_SUCCESS,
  CREATE_PURCHASE_FAILURE,
  PURCHASE_REQUEST_LIST_REQUEST,
  PURCHASE_REQUEST_LIST_SUCCESS,
  PURCHASE_REQUEST_LIST_FAILURE,
  SEARCH_PURCHASE_REQUEST_LIST_REQUEST,
  SEARCH_PURCHASE_REQUEST_LIST_SUCCESS,
  SEARCH_PURCHASE_REQUEST_LIST_FAILURE,
  CREATE_PURCHASE_REQUEST_REQUEST,
  CREATE_PURCHASE_REQUEST_SUCCESS,
  CREATE_PURCHASE_REQUEST_FAILURE,
  PURCHASE_REQUEST_DELETE_REQUEST,
  PURCHASE_REQUEST_DELETE_SUCCESS,
  PURCHASE_REQUEST_DELETE_FAILURE,
  PURCHASE_CREATE_PRODUCT_REQUEST,
  PURCHASE_CREATE_PRODUCT_SUCCESS,
  PURCHASE_CREATE_PRODUCT_FAILURE,
  PURCHASE_CREATE_PRODUCT_LIST_REQUEST,
  PURCHASE_CREATE_PRODUCT_LIST_SUCCESS,
  PURCHASE_CREATE_PRODUCT_LIST_FAILURE,
  PURCHASE_PRODUCT_DELETE_REQUEST,
  PURCHASE_PRODUCT_DELETE_SUCCESS,
  PURCHASE_PRODUCT_DELETE_FAILURE,
  EACH_PURCHASE_DETAIL_REQUEST,
  EACH_PURCHASE_DETAIL_SUCCESS,
  EACH_PURCHASE_DETAIL_FAILURE,
  UPDATE_PURCHASE_REQUEST,
  UPDATE_PURCHASE_SUCCESS,
  UPDATE_PURCHASE_FAILURE,
} from "../type.js";
import axios from "axios";

export const getPurchaseList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: PURCHASE_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/PurchaseProductListView/?limit=${limit}&offset=${offset}&ordering=-id`,
      config
    );

    dispatch({
      type: PURCHASE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PURCHASE_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deletePurchase = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PURCHASE_DELETE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/PurchaseOrderDeleteView/${id}/`,
      config
    );
    dispatch({
      type: PURCHASE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PURCHASE_DELETE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deletePurchaseItem = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PURCHASED_ITEM_DELETE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/DeletePurchaseProductView/${id}/`,
      config
    );
    dispatch({
      type: PURCHASED_ITEM_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PURCHASED_ITEM_DELETE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const searchPurhchaseList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_PURCHASE_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/PurchaseProductListView/?limit=15&offset=0&ordering=-id&search=${keyword}`,
      config
    );

    dispatch({
      type: SEARCH_PURCHASE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_PURCHASE_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createPurchase = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PURCHASE_REQUEST,
    });
    //const body = {};
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/CreatePurchaseProductView/`,
      body,
      config
    );

    dispatch({
      type: CREATE_PURCHASE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PURCHASE_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getPurchaseDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: EACH_PURCHASE_DETAIL_REQUEST });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/PurchaseProductListView/?Orderplaced=${id}`,
      config
    );
    dispatch({
      type: EACH_PURCHASE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EACH_PURCHASE_DETAIL_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

/** Purchare Request Related Actions */

export const getPurchaseRequestList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: PURCHASE_REQUEST_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/purchase-request-list/?limit=${limit}&offset=${offset}`,
      config
    );

    dispatch({
      type: PURCHASE_REQUEST_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PURCHASE_REQUEST_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSearchPurchaseRequestList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_PURCHASE_REQUEST_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/purchase-request-list/?query=${keyword}`,
      config
    );

    dispatch({
      type: SEARCH_PURCHASE_REQUEST_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_PURCHASE_REQUEST_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createPurchaseRequest = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PURCHASE_REQUEST_REQUEST,
    });
    //const body = {};
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/place-order-request/`,
      body,
      config
    );

    dispatch({
      type: CREATE_PURCHASE_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PURCHASE_REQUEST_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const deletePurchaseRequest = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PURCHASE_REQUEST_DELETE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/delete-purchase-order-request/${id}/`,
      config
    );
    dispatch({
      type: PURCHASE_REQUEST_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PURCHASE_REQUEST_DELETE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createProductRequest = (body) => async (dispatch) => {
  try {
    dispatch({
      type: PURCHASE_CREATE_PRODUCT_REQUEST,
    });
    //const body = {};
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/purchaseRequestProduct/`,
      body,
      config
    );

    dispatch({
      type: PURCHASE_CREATE_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PURCHASE_CREATE_PRODUCT_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getProductRequestList = () => async (dispatch) => {
  try {
    dispatch({
      type: PURCHASE_CREATE_PRODUCT_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/purchaseRequestProductList/`,
      config
    );

    dispatch({
      type: PURCHASE_CREATE_PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PURCHASE_CREATE_PRODUCT_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteRequestProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PURCHASE_PRODUCT_DELETE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/deletePurchaseRequestProduct/${id}/`,
      config
    );
    dispatch({
      type: PURCHASE_PRODUCT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PURCHASE_PRODUCT_DELETE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updatePurchaseRequest = (id, acceptStatus) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PURCHASE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const body = {
      accept_status: acceptStatus,
    };
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/purchase-request-change_status/${id}/`,
      body,
      config
    );

    dispatch({
      type: UPDATE_PURCHASE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PURCHASE_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
