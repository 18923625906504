import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenAlt, faTrashCan } from "@fortawesome/free-solid-svg-icons";

const VendorListTableRow = ({
  vendor,
  setDeleteModalShow,
  setSelectedItem,
}) => {
  const navigate = useNavigate();
  return (
    <tr key={vendor.id}>
      {/* <td>
                        <Form.Check type="checkbox" id="checkbox" />
                      </td> */}
      <td>{vendor.id}</td>
      <td>{vendor.category_of_vendor ? vendor.category_of_vendor : "N/A"}</td>
      <td>{vendor.vendor_name}</td>
      <td>{vendor.vendor_address}</td>
      <td>{vendor.contact_number}</td>
      <td>{vendor.contact_Person ? vendor.contact_Person : "N/A"}</td>
      <td>{vendor.status}</td>
      <td style={{ cursor: "pointer" }}>
        <FontAwesomeIcon
          icon={faEye}
          color="#637381"
          onClick={() => navigate(`/vendor/${vendor.id}`)}
        />
        <FontAwesomeIcon
          icon={faPenAlt}
          color="#637381"
          style={{ paddingLeft: "0.405rem" }}
          onClick={() => navigate(`/updateVendor/${vendor.id}`)}
        />
        {/* <FontAwesomeIcon
          icon={faTrashCan}
          color="#E40000"
          style={{ paddingLeft: "0.405rem" }}
          onClick={() => {
            setDeleteModalShow(true);
            setSelectedItem(vendor);
          }}
        /> */}
      </td>
    </tr>
  );
};

export default VendorListTableRow;
