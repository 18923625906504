import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../Products/Product.css";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import WarehouseForm from "./WarehouseForm";
import { ToastContainer } from "react-toastify";
import { SuccessAlert } from "../notify/notify";
import { createWarehouse } from "../../Redux/actions/warehouseActions";

const AddWarehouse = () => {
  const dispatch = useDispatch();
  const { loading, message, error } = useSelector(
    (state) => state.createWarehouse
  );

  const [destinationName, setDestinationName] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);

  useEffect(() => {
    if (alertMessage && message === undefined && error !== undefined) {
      console.log(error);
      alert("Something Went Wrong");
      setAlertMessage(false);
    }
    if (alertMessage && message !== undefined) {
      SuccessAlert("New Destination Created Successfully");
      setDestinationName("");
      setDestinationAddress("");
      setCountry("");
      setContactNumber("");
      setEmailAddress("");
      setZipcode("");
      setDescription("");
      setStatus("");
      setType("");
      setCity("");
      setAlertMessage(false);
    }
  }, [alertMessage, message, error]);
  const handleSubmitForm = (e) => {
    e.preventDefault();
    const body = {
      name: destinationName,
      contact_number: contactNumber,
      email: emailAddress,
      address: destinationAddress,
      city: city,
      country: country,
      zip_code: zipcode,
      description: description,
      warehouse_status: type,
      active_status: status,
    };
    dispatch(createWarehouse(body));
    setAlertMessage(true);
  };

  return (
    <div>
      <SideBar />
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <h4 className="product-heading container-view">Destination Add</h4>
          <p className="product-paragraph container-view">
            Destination <FontAwesomeIcon icon={faChevronRight} size="xs" /> Add
            Destination
          </p>
          <div className="mb-4 card product-card container-view">
            <WarehouseForm
              loading={loading}
              destinationName={destinationName}
              setDestinationName={setDestinationName}
              destinationAddress={destinationAddress}
              setDestinationAddress={setDestinationAddress}
              contactNumber={contactNumber}
              setContactNumber={setContactNumber}
              emailAddress={emailAddress}
              setEmailAddress={setEmailAddress}
              city={city}
              setCity={setCity}
              country={country}
              setCountry={setCountry}
              zipcode={zipcode}
              setZipcode={setZipcode}
              description={description}
              setDescription={setDescription}
              status={status}
              setStatus={setStatus}
              type={type}
              setType={setType}
              handleSubmitForm={handleSubmitForm}
            />
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AddWarehouse;
