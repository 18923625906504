import {
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_LIST_FAILURE,
  CREATE_SUPPLIER_REQUEST,
  CREATE_SUPPLIER_SUCCESS,
  CREATE_SUPPLIER_FAILURE,
  SUPPLIER_DELETE_REQUEST,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_DELETE_FAILURE,
  SUPPLIER_UPDATE_REQUEST,
  SUPPLIER_UPDATE_SUCCESS,
  SUPPLIER_UPDATE_FAILURE,
  EACH_SUPPLIER_DETAIL_REQUEST,
  EACH_SUPPLIER_DETAIL_SUCCESS,
  EACH_SUPPLIER_DETAIL_FAILURE,
  SEARCH_SUPPLIER_LIST_REQUEST,
  SEARCH_SUPPLIER_LIST_SUCCESS,
  SEARCH_SUPPLIER_LIST_FAILURE,
} from "../type.js";

export const supplierListReducer = (state = { supplierList: {} }, action) => {
  switch (action.type) {
    case SUPPLIER_LIST_REQUEST:
      return { ...state, loading: true };
    case SUPPLIER_LIST_SUCCESS:
      return { ...state, loading: false, supplierList: action.payload };
    case SUPPLIER_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createSupplierReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_SUPPLIER_REQUEST:
      return { loading: true };
    case CREATE_SUPPLIER_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case CREATE_SUPPLIER_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteSupplierReducer = (state = {}, action) => {
  switch (action.type) {
    case SUPPLIER_DELETE_REQUEST:
      return { loading: true };
    case SUPPLIER_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case SUPPLIER_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateSupplierReducer = (state = {}, action) => {
  switch (action.type) {
    case SUPPLIER_UPDATE_REQUEST:
      return { loading: true };
    case SUPPLIER_UPDATE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case SUPPLIER_UPDATE_FAILURE:
      return {
        loading: false,
        updateError: action.payload,
      };
    default:
      return state;
  }
};

export const SupplierDetailsReducers = (state = { supplier: {} }, action) => {
  switch (action.type) {
    case EACH_SUPPLIER_DETAIL_REQUEST:
      return { loading: true, ...state };
    case EACH_SUPPLIER_DETAIL_SUCCESS:
      return { loading: false, supplier: action.payload };
    case EACH_SUPPLIER_DETAIL_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchSupplierListReducer = (
  state = { searchSuppliers: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_SUPPLIER_LIST_REQUEST:
      return { searchLoading: true, ...state };
    case SEARCH_SUPPLIER_LIST_SUCCESS:
      return { searchLoading: false, searchSuppliers: action.payload };
    case SEARCH_SUPPLIER_LIST_FAILURE:
      return { searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};
