import {
  RETURN_LIST_REQUEST,
  RETURN_LIST_SUCCESS,
  RETURN_LIST_FAILURE,
  RETURN_DELETE_REQUEST,
  RETURN_DELETE_SUCCESS,
  RETURN_DELETE_FAILURE,
  RETURNED_ITEM_DELETE_REQUEST,
  RETURNED_ITEM_DELETE_SUCCESS,
  RETURNED_ITEM_DELETE_FAILURE,
  SEARCH_RETURN_LIST_REQUEST,
  SEARCH_RETURN_LIST_SUCCESS,
  SEARCH_RETURN_LIST_FAILURE,
  CREATE_RETURN_REQUEST,
  CREATE_RETURN_SUCCESS,
  CREATE_RETURN_FAILURE,
  PRODUCT_BATCH_DETAIL_REQUEST,
  PRODUCT_BATCH_DETAIL_SUCCESS,
  PRODUCT_BATCH_DETAIL_FAILURE,
} from "../type.js";

export const returnListReducer = (state = { returnList: {} }, action) => {
  switch (action.type) {
    case RETURN_LIST_REQUEST:
      return { loading: true, ...state };
    case RETURN_LIST_SUCCESS:
      return { loading: false, returnList: action.payload };
    case RETURN_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteReturnReducer = (state = {}, action) => {
  switch (action.type) {
    case RETURN_DELETE_REQUEST:
      return { loading: true };
    case RETURN_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case RETURN_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteReturnItemReducer = (state = {}, action) => {
  switch (action.type) {
    case RETURNED_ITEM_DELETE_REQUEST:
      return { loading: true };
    case RETURNED_ITEM_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case RETURNED_ITEM_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const searchReturnListReducer = (
  state = { searchReturn: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_RETURN_LIST_REQUEST:
      return { searchLoading: true, ...state };
    case SEARCH_RETURN_LIST_SUCCESS:
      return { searchLoading: false, searchReturn: action.payload };
    case SEARCH_RETURN_LIST_FAILURE:
      return { searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};

export const createReturnReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_RETURN_REQUEST:
      return { loading: true };
    case CREATE_RETURN_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case CREATE_RETURN_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const BatchDetailsReducers = (state = { batchList: {} }, action) => {
  switch (action.type) {
    case PRODUCT_BATCH_DETAIL_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_BATCH_DETAIL_SUCCESS:
      return { loading: false, batchList: action.payload };
    case PRODUCT_BATCH_DETAIL_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
