import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row, InputGroup, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import uploadIcon from "../../Assets//icons/upload-image.png";
import { getSupplierList } from "../../Redux/actions/supplierActions";
import { getSubcategoryList } from "../../Redux/actions/subcategoryActions";
import {
  getGenericList,
  getSearchGenericList,
} from "../../Redux/actions/genericActions";
import UpdateCategoryModal from "../PopupModal/UpdateCategoryModal";
import CreateGenericModal from "../PopupModal/CreateGenericModal";

const ProductForm = (props) => {
  const dispatch = useDispatch();
  const { supplierList } = useSelector((state) => state.supplierList);
  const { subcategoryList } = useSelector((state) => state.subcategoryList);
  const { searchLoading, searchGenericList, searchError } = useSelector(
    (state) => state.searchGeneric
  );

  const [genericModal, setGenericModal] = useState(false);
  const [genericFocus, setGenericFocus] = useState(false);
  const [showGenericList, setShowGenericList] = useState(false);
  const [genericKeyword, setGenericKeyword] = useState("");

  useEffect(() => {
    dispatch(getSupplierList(500, 0));
  }, []);
  useEffect(() => {
    dispatch(getSubcategoryList(500, 0));
  }, []);
  useEffect(() => {
    if (
      genericFocus &&
      props.genericName.length === 0 &&
      searchGenericList.data === undefined
    ) {
      dispatch(getSearchGenericList("a"));
      setShowGenericList(true);
    }
  }, [genericFocus]);

  useEffect(() => {
    if (genericKeyword.length) {
      dispatch(getSearchGenericList(genericKeyword));
      setShowGenericList(true);
    }
  }, [genericKeyword]);
  //console.log(genericList);
  useEffect(() => {
    if (props.productLocation === "medicare") {
      props.setRequiredPres("yes");
    } else if (props.productLocation !== "medicare" && props.productLocation) {
      props.setRequiredPres("no");
    } else {
      props.setRequiredPres("");
    }
  }, [props.productLocation]);
  const handleCancelClick = () => {
    window.location.reload();
  };

  const [suggestionSelect, setSuggestionSelect] = useState(null);
  const suggestRef = useRef(null);
  const [childOffsets, setChildOffsets] = useState([]);

  useEffect(() => {
    setSuggestionSelect(null);
  }, [searchGenericList]);

  useEffect(() => {
    if (
      showGenericList &&
      searchGenericList?.data !== undefined &&
      searchGenericList?.data.generic_names.results.length !== 0
    ) {
      //console.log("suggestRef = ", suggestRef.current);
      const childDivs = Array.from(suggestRef.current.children);
      // console.log('childDivs = ', childDivs);
      const offsets = childDivs.map((child) => child.offsetTop);
      // console.log('offsets', offsets);
      setChildOffsets(offsets);
    }
  }, [searchGenericList, showGenericList]);

  const searchInputSuggestionHendler = (e) => {
    if (
      showGenericList &&
      searchGenericList?.data !== undefined &&
      searchGenericList?.data.generic_names.results.length !== 0
    ) {
      if (e.key === "ArrowUp") {
        // console.log("In searchInputSuggestionHndler, you pressed = ", e.key);
        // console.log("searchProducts = ",searchProducts.data.results);
        if (suggestionSelect > 0) {
          setSuggestionSelect((previousState) => previousState - 1);
          // console.log("searchProducts = ",searchProducts.data.results);

          suggestRef.current.scrollTo({
            top: childOffsets[suggestionSelect - 1],
            behavior: "smooth",
          });
        }
      }
      if (e.key === "ArrowDown") {
        if (suggestionSelect || suggestionSelect === 0) {
          if (
            searchGenericList.data.generic_names.results.length >
            suggestionSelect + 1
          ) {
            setSuggestionSelect((previousState) => previousState + 1);
            // console.log("searchProducts = ",searchProducts.data.results);
            suggestRef.current.scrollTo({
              top: childOffsets[suggestionSelect],
              behavior: "smooth",
            });
          }
        } else {
          setSuggestionSelect(0);
          // console.log("searchProducts = ",searchProducts.data.results);
        }
      }
      if (e.key === "Enter") {
        e.preventDefault();
        if (suggestionSelect || suggestionSelect === 0) {
          props.setGenericName(
            searchGenericList?.data.generic_names.results[suggestionSelect]
              .generic_name
          );
          setShowGenericList(false);
          setSuggestionSelect(null);
        }
      }
    }
  };

  //console.log(genericModal);
  return (
    <Form className="p-4" onSubmit={props.handleSubmitForm}>
      <CreateGenericModal
        show={genericModal}
        onHide={() => setGenericModal(false)}
      />
      <Row>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Product Name<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="e.g:Tissue"
              onChange={(e) => props.setProductName(e.target.value)}
              value={props.productName}
              autoComplete="off"
              required
            />
          </Form.Group>
        </Col>
        <Col lg={4} style={{ position: "relative" }}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label className="d-flex justify-content-between">
              <div>
                Generic Name<span className="required-field">*</span>
              </div>
              <button
                className="purchase-price-btn active-btn"
                type="button"
                onClick={(e) => {
                  //console.log(e);
                  e.preventDefault();
                  setGenericModal(true);
                }}
              >
                Add
              </button>
            </Form.Label>
            <Form.Control
              onFocus={() => {
                setGenericFocus(true);
                setShowGenericList(true);
              }}
              onBlur={() => setGenericFocus(false)}
              type="text"
              placeholder="Generic Name"
              onChange={(e) => {
                props.setGenericName(e.target.value);
                if (e.target.value.length) {
                  setTimeout(() => {
                    //dispatch(getSearchGenericList(e.target.value));
                    //console.log(e.target.value);
                    setGenericKeyword(e.target.value);
                    //setShowGenericList(true);
                  }, 1000);
                }
              }}
              onKeyDown={(e) => {
                //console.log(e);
                searchInputSuggestionHendler(e);
              }}
              value={props.genericName}
              autoComplete="off"
              required
            />
            {showGenericList &&
            searchGenericList?.data !== undefined &&
            searchGenericList?.data.generic_names.results.length !== 0 ? (
              <div
                className="autocomplete_items shadow-lg"
                //onScroll={DivScroll}
                ref={suggestRef}
                style={{ zIndex: "2" }}
              >
                {searchGenericList?.data.generic_names.results.map(
                  (generic, i) => (
                    <div
                      //className="card shadow m-2 rounded border border-0 p-2"
                      className={`card shadow m-2 rounded border border-0 p-2 ${
                        suggestionSelect !== null && suggestionSelect === i
                          ? "selectedSearchItemHover"
                          : ""
                      }`}
                      key={i}
                      onClick={() => {
                        props.setGenericName(generic.generic_name);
                        //console.log("clicked", generic.generic_name);
                        setShowGenericList(false);
                      }}
                    >
                      <div>
                        <div className="d-flex align-content-center">
                          <div className="">{generic.generic_name}</div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : (
              <></>
            )}
            {/* <Form.Select
              aria-label="Default select example"
              onChange={(e) => props.setGenericName(e.target.value)}
              value={props.genericName}
              required
            >
              <option value="">Select Generic Name</option>
              {genericList.data?.generic_names.results.map((generic) => (
                <option key={generic.id} value={generic.generic_name}>
                  {generic.generic_name}
                </option>
              ))}
            </Form.Select> */}
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Size & Units<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="e.g: 20gm"
              onChange={(e) => props.setSizeUnits(e.target.value)}
              onFocus={() => setShowGenericList(false)}
              value={props.sizeUnits}
              autoComplete="off"
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Sub Category 1<span className="required-field">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => props.setSubcategoryName1(e.target.value)}
              onFocus={() => setShowGenericList(false)}
              value={props.subcategoryName1}
              required
            >
              <option value="">Select SubCategory1 Name</option>
              {subcategoryList.data?.results.map((subcategory) => (
                <option key={subcategory.id} value={subcategory.id}>
                  {subcategory.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Sub Category 2<span></span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => props.setSubcategoryName2(e.target.value)}
              value={props.subcategoryName2}
            >
              <option value="">Select SubCategory2 Name</option>
              {subcategoryList.data?.results.map((subcategory) => (
                <option key={subcategory.id} value={subcategory.id}>
                  {subcategory.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Sub Category 3<span></span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => props.setSubcategoryName3(e.target.value)}
              value={props.subcategoryName3}
            >
              <option value="">Select SubCategory3 Name</option>
              {subcategoryList.data?.results.map((subcategory) => (
                <option key={subcategory.id} value={subcategory.id}>
                  {subcategory.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Stocks<span></span>
            </Form.Label>
            <Row>
              {/* <Col xxl={2} xl={3} lg={4}>
                <Form.Check
                  type="checkbox"
                  id="checkbox"
                  label="Box"
                  value="Box"
                  onChange={(e) => props.setQuantityType(e.target.value)}
                  checked={props.quantityType === "Box" ? true : false}
                  //disabled={props.productId !== undefined ? true : false}
                />
                <Form.Check
                  type="checkbox"
                  id="checkbox"
                  label="Pcs"
                  value="Pcs"
                  onChange={(e) => props.setQuantityType(e.target.value)}
                  checked={props.quantityType === "Pcs" ? true : false}
                  //disabled={props.productId !== undefined ? true : false}
                />
              </Col> */}
              <Col lg={12}>
                <Form.Control
                  type="number"
                  placeholder="Quantity"
                  onChange={(e) => props.setQuantity(e.target.value)}
                  value={props.quantity}
                  //disabled={props.productId !== undefined ? true : false}
                  disabled
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Supplier Id<span></span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Auto Generate"
              value={props.supplier}
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Supplier Name<span className="required-field">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => props.setSupplier(e.target.value)}
              onFocus={() => setShowGenericList(false)}
              value={props.supplier}
              required
            >
              <option value="">Select Supplier Name</option>
              {supplierList.data?.results.map((supplier) => (
                <option key={supplier.id} value={supplier.id}>
                  {supplier.supplier_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>System Barcode</Form.Label>
            <Form.Control
              type="text"
              placeholder="Auto Generate"
              value={
                props.barcodeType === "auto generate" ? props.autoBarcode : ""
              }
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>Product Barcode</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Scan or Write"
                style={{ borderRight: "0px" }}
                onChange={(e) => props.setBarcode(e.target.value)}
                value={props.barcode}
                onFocus={() => setShowGenericList(false)}
                autoComplete="off"
              />
              <InputGroup.Text style={{ background: "white" }}>
                <FontAwesomeIcon icon={faBarcode} />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            Product Description<span className="required-field">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Product Description"
            style={{ height: "100px" }}
            onChange={(e) => props.setDescription(e.target.value)}
            value={props.description}
            onFocus={() => setShowGenericList(false)}
            required
            autoComplete="off"
          />
        </Form.Group>
      </Row>
      <Row>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>Pack Size</Form.Label>
            <Form.Control
              type="number"
              placeholder="Pack Size"
              onChange={(e) => props.setPackSize(e.target.value)}
              value={props.packSize}
              onFocus={() => setShowGenericList(false)}
              autoComplete="off"
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>Purchase Price</Form.Label>
            <Form.Control
              type="number"
              placeholder="BDT"
              onChange={(e) => props.setPurchasePrice(e.target.value)}
              value={props.purchasePrice}
              disabled
              autoComplete="off"
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>MRP</Form.Label>
            <Form.Control
              type="number"
              placeholder="BDT"
              onChange={(e) => props.setSalesPrice(e.target.value)}
              value={props.salesPrice}
              disabled
              autoComplete="off"
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>New MRP</Form.Label>
            <Form.Control
              type="number"
              placeholder="BDT"
              onChange={(e) => props.setNewSalesPrice(e.target.value)}
              value={props.newSalesPrice}
              disabled
              autoComplete="off"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>Profit in %</Form.Label>
            <Form.Control
              type="text"
              placeholder="Auto Generate"
              value={props.profitValue}
              disabled
              autoComplete="off"
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>Disc. in %</Form.Label>
            <Form.Control
              type="number"
              placeholder="discount"
              onChange={(e) => props.setDiscountPrice(e.target.value)}
              value={props.discountPrice}
              autoComplete="off"
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>Purchase Vat</Form.Label>
            <Form.Control
              type="number"
              placeholder="vat in %"
              onChange={(e) => props.setPurchaseVat(e.target.value)}
              value={props.purchaseVat}
              autoComplete="off"
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>Sale Vat</Form.Label>
            <Form.Control
              type="number"
              placeholder="vat in %"
              onChange={(e) => props.setSaleVat(e.target.value)}
              value={props.saleVat}
              autoComplete="off"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Product Type<span className="required-field">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => props.setProductType(e.target.value)}
              value={props.productType}
              onFocus={() => setShowGenericList(false)}
              required
            >
              <option value="">Local or foreign</option>
              <option value="Local">Local</option>
              <option value="Foreign">Foriegn</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Promotion Brand<span></span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Brand Name"
              onChange={(e) => props.setPromotionBrand(e.target.value)}
              value={props.promotionBrand}
              autoComplete="off"
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Product Location<span className="required-field">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => props.setProductLocation(e.target.value)}
              value={props.productLocation}
              onFocus={() => setShowGenericList(false)}
              required
            >
              <option value="">Choose location</option>
              <option value="medicare">Medicare</option>
              <option value="bodycare">Bodycare</option>
              <option value="privatebox">PrivateBox</option>
              <option value="food">Food</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Required Prescription<span className="required-field">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              //onChange={(e) => props.setRequiredPres(e.target.value)}
              value={props.requiredPres}
              disabled
              required
            >
              <option value="">Yes or no</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>
            Product Image<span className="required-field">*</span>
          </Form.Label>
          <br />
          <label
            className="pt-4 pb-4"
            htmlFor="inputTag"
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              border: "1px solid #DCE0E4",
              borderRadius: "5px",
            }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={uploadIcon} alt="uploadIcon" height={30} width={30} />
              <span className="image-upload-text">Upload Image</span>
              {props.productImage != null &&
              typeof props.productImage !== "string" ? (
                <span style={{ fontSize: "14px" }}>
                  <b>Chosen file:</b> {props.productImage.name}
                </span>
              ) : props.productImage !== null &&
                typeof props.productImage === "string" ? (
                <p>
                  <b>Uploaded file:</b> {props.productImage}
                </p>
              ) : (
                <span style={{ fontSize: "14px" }}>
                  <b>Chosen file:</b> No Image chosen yet
                </span>
              )}
            </div>
            <input
              id="inputTag"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => props.setProductImage(e.target.files[0])}
            />
          </label>
        </Form.Group>
      </Row>
      <Row>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>Promotion</Form.Label>
            <Form.Control
              type="text"
              placeholder="Promotion"
              onChange={(e) => props.setPromotion(e.target.value)}
              value={props.promotion}
              autoComplete="off"
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>Promotion1 </Form.Label>
            <Form.Control
              type="text"
              placeholder="Promotion1"
              onChange={(e) => props.setPromotion1(e.target.value)}
              value={props.promotion1}
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>App Promotion Field </Form.Label>
            <Form.Control
              type="text"
              placeholder="App Promotion"
              onChange={(e) => props.setAppPromotion(e.target.value)}
              value={props.appPromotion}
              autoComplete="off"
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>Product Search Tag </Form.Label>
            <Form.Control
              type="text"
              placeholder="Product Search Tag"
              onChange={(e) => props.setSearchTag(e.target.value)}
              value={props.searchTag}
              autoComplete="off"
            />
          </Form.Group>
        </Col>
      </Row>
      {props.loading === true ? (
        <Row className="mt-2">
          <Col xl={4} lg={12} className="d-flex justify-content-center">
            <Spinner animation="border" variant="warning" />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row className="mt-3">
        <Col xl={2} lg={6} className="mb-2">
          {props.loading === true ? (
            <Button className="disable-submit-btn" variant="secondary" disabled>
              Submit
            </Button>
          ) : (
            <Button className="submit-btn border-0" type="submit">
              Submit
            </Button>
          )}
        </Col>
        <Col xl={2} lg={6}>
          <Button className="cancel-btn border-0" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ProductForm;
