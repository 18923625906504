import React, { useEffect, useState } from "react";
import { Row, Col, Table, Modal, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

const AddReturnModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      fullscreen={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto" id="contained-modal-title-vcenter">
          Add Return Preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4" style={{ overflowY: "scroll" }}>
        {/* <Row>
          <Col sm={6}>
            <p>
              Vendor Name:
              {selectedVendorName ? ` ${selectedVendorName}` : " Not Selected"}
            </p>
          </Col>
          <Col sm={6}>
            <p>
              Purchase Date:
              {props.purchaseDate ? ` ${props.purchaseDate}` : " Not Selected"}
            </p>
          </Col>
        </Row> */}
        <div
          style={{
            fontSize: "16px",
            fontWeight: "600",
            marginBottom: "8px",
          }}
        >
          Product List
        </div>

        <Table bordered hover style={{ fontSize: "12px" }}>
          <thead className="text-center table-header">
            <tr>
              <th>SL</th>
              <th>Name</th>
              <th>Units</th>
              <th>Qty</th>
              <th>P. Price</th>
              <th>Vat</th>
              <th>Batch No.</th>
              <th>Subtotal</th>
              <th>Barcode</th>
              <th>S. Name</th>
              <th>Reasons</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {props.returnList.length > 0 ? (
              <>
                {props.listWithIndices
                  .sort((a, b) => b.index - a.index)
                  .map((list, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{list.value.name}</td>
                      <td>{list.value.units}</td>
                      <td>{list.value.quantity}</td>
                      <td>{list.value.purchase_price}</td>
                      <td>{list.value.vat}%</td>
                      <td>{list.value.batch_no}</td>
                      <td>{list.value.subtotal.toFixed(2)}</td>
                      <td>{list.value.barcode}</td>
                      <td>{list.value.supplierName}</td>
                      <td>{list.value.reasons}</td>
                    </tr>
                  ))}
                <tr className="fw-bold">
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>
                    {props.returnList.reduce((previousResult, currentValue) => {
                      // console.log(
                      //   "currentValue.quantity =",
                      //   currentValue.quantity
                      // );
                      return (previousResult += parseInt(
                        currentValue.quantity
                      ));
                    }, 0)}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan="13">No Product Added</td>
              </tr>
            )}
          </tbody>
        </Table>

        <Table
          //responsive
          bordered
          hover
          style={{ fontSize: "12px" }}
        >
          <thead>
            <tr>
              <th>Total Return Value</th>
              <th>Net Return Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.totalReturnValue}</td>
              <td>{props.netReturnValue}</td>
            </tr>
          </tbody>
        </Table>

        <Row className="mt-5 d-flex justify-content-center">
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button className="cancel-btn border-0" onClick={props.onHide}>
              Go Back
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AddReturnModal;
