import {
  VOID_LIST_REQUEST,
  VOID_LIST_SUCCESS,
  VOID_LIST_FAILURE,
  CREATE_VOID_REQUEST,
  CREATE_VOID_SUCCESS,
  CREATE_VOID_FAILURE,
  EACH_VOID_DETAIL_REQUEST,
  EACH_VOID_DETAIL_SUCCESS,
  EACH_VOID_DETAIL_FAILURE,
  SEARCH_VOID_LIST_REQUEST,
  SEARCH_VOID_LIST_SUCCESS,
  SEARCH_VOID_LIST_FAILURE,
  SEARCH_INVOICE_REQUEST,
  SEARCH_INVOICE_SUCCESS,
  SEARCH_INVOICE_FAILURE,
  SEARCH_INVOICE_DETAILS_REQUEST,
  SEARCH_INVOICE_DETAILS_SUCCESS,
  SEARCH_INVOICE_DETAILS_FAILURE,
} from "../type.js";
import axios from "axios";

export const getVoidList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: VOID_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/Void/void-list/?limit=${limit}&offset=${offset}`,
      config
    );

    dispatch({
      type: VOID_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VOID_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createVoid = (form_data) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_VOID_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/Void/create-void/`,
      form_data,
      config
    );

    dispatch({
      type: CREATE_VOID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_VOID_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getVoidDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: EACH_VOID_DETAIL_REQUEST });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/Void/void-details/${id}/`,
      config
    );
    dispatch({
      type: EACH_VOID_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EACH_VOID_DETAIL_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const searchVoidList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_VOID_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/Void/void-list/?query=${keyword}&limit=20&offset=0`,
      config
    );

    dispatch({
      type: SEARCH_VOID_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_VOID_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getInvoiceId = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_INVOICE_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/Void/Order-placed-list/?query=${keyword}`,
      config
    );

    dispatch({
      type: SEARCH_INVOICE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_INVOICE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getInvoiceDetails = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_INVOICE_DETAILS_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/Void/Order-placed-details/${keyword}/`,
      config
    );

    dispatch({
      type: SEARCH_INVOICE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_INVOICE_DETAILS_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
