import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSupplierList,
  searchSupplierList,
} from "../../Redux/actions/supplierActions";
import { getVoidList, searchVoidList } from "../../Redux/actions/voidActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEye,
  faPenAlt,
  faTrashCan,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import DeleteModal from "../PopupModal/DeleteModal";
import LoadingModal from "../PopupModal/LoadingModal";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import filter from "../../Assets/icons/filter.png";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import "../Products/Product.css";
import ReactPaginate from "react-paginate";
import axios from "axios";
import generatePDF from "../../Services/pdfGenerator";
import exportExcel from "../../Services/ExcelGenerator";

const VoidList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [exportData, setExportData] = useState(false);

  const { loading, voidList, error } = useSelector((state) => state.voidList);

  const { searchLoading, searchVoid, searchError } = useSelector(
    (state) => state.searchVoidList
  );

  useEffect(() => {
    dispatch(getVoidList(limit, offset));
  }, [dispatch, offset]);

  useEffect(() => {
    if (voidList?.data) {
      setTotalItems(voidList.data.void_list.count);
    }
  }, [voidList]);
  //console.log(voidList);
  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
  };

  const handleSearchResult = (e) => {
    if (e.target.value.length > 1) {
      setShowSearchResult(true);
      dispatch(searchVoidList(e.target.value));
    } else if (e.target.value.length === 0) {
      setShowSearchResult(false);
      setOffset(0);
    } else {
      setShowSearchResult(false);
    }
  };

  //console.log(error);
  /** Export All Data */
  const getDataFromApi = (type) => {
    setExportData(true);
    try {
      //const form_data = new FormData();
      //form_data.append("csv_file", filefield);
      //const body = data;
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const api = `${process.env.REACT_APP_BASE_URL}/api/v1/product/Void/void-list/?limit=${totalItems}&offset=0`;

      axios
        .get(api, settings)
        .then((res) => {
          if (type === "pdf") {
            setExportData(false);
            generatePDF(
              res.data.void_list.results,
              "Void",
              [
                "Void ID",
                "Invoice Number",
                "Void Qty",
                "Total Vat",
                "Total Discount",
                "Net Amount",
                "Void By",
              ],
              "Care-Box Void List",
              totalItems
            );
          } else if (type === "excel") {
            const exportData = [];
            res.data.void_list.results.map((each) => {
              let obj = {};
              obj["Void ID"] = each.void_id;
              obj["Invoice Number"] = each.invoice_number;
              obj["Void Qty"] = each.total_quantity;
              obj["Total Vat"] = each.vat_amount;
              obj["Total Discount"] = each.discount_amount;
              obj["Net Amount"] = each.net_amount;
              obj["Void By"] = each.voided_by;
              exportData.push(obj);
            });
            exportExcel(exportData, "Care_Box_Void_List.xlsx");
            setExportData(false);
          }
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };
  return (
    <div>
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Void List</h4>
              <p className="product-paragraph">
                {" "}
                Void <FontAwesomeIcon icon={faChevronRight} size="xs" /> Void
                List
              </p>
            </Col>
            <Col lg={6} className="d-flex justify-content-lg-end">
              <button
                className="d-none pe-3 ps-3 border-0 add-product-button"
                onClick={() => navigate("/addVoid")}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ paddingRight: "0.2rem" }}
                />{" "}
                Add Void
              </button>
            </Col>
          </Row>
          <div className="mb-4 p-3 card product-card container-view">
            <Row className="mb-4">
              <Col lg={6} className="mb-2 mb-lg-0">
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchResult}
                />
              </Col>
              <Col lg={6} className="d-flex justify-content-md-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as a pdf</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getDataFromApi("pdf")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as excel</Tooltip>}
                >
                  <img
                    //className="pe-3"
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getDataFromApi("excel")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            <Table responsive="xl" hover>
              <thead className="text-center table-header">
                <tr>
                  <th>Void Id</th>
                  <th>Invoice Number</th>
                  <th>Void Qty</th>
                  <th>Total Vat</th>
                  <th>Total Discount</th>
                  <th>Net Amount</th>
                  <th>Void By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {showSearchResult &&
                searchVoid &&
                searchVoid.data &&
                searchVoid.data.void_list &&
                searchVoid.data.void_list.results &&
                searchVoid.data.void_list.results.length !== 0 ? (
                  searchVoid.data.void_list.results.map((item) => (
                    <tr key={item.id}>
                      <td>{item.void_id}</td>
                      <td>{item.invoice_number}</td>
                      <td>{item.total_quantity}</td>
                      <td>{item.vat_amount}</td>
                      <td>{item.discount_amount}</td>
                      <td>{item.net_amount}</td>
                      <td>{item.voided_by}</td>
                      <td style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          icon={faEye}
                          color="#637381"
                          onClick={() => navigate(`/void/${item.id}`)}
                        />
                      </td>
                    </tr>
                  ))
                ) : !showSearchResult &&
                  voidList?.data !== undefined &&
                  voidList?.data.void_list.results.length !== 0 &&
                  !loading ? (
                  voidList?.data.void_list.results.map((item) => (
                    <tr key={item.id}>
                      <td>{item.void_id}</td>
                      <td>{item.invoice_number}</td>
                      <td>{item.total_quantity}</td>
                      <td>{item.vat_amount}</td>
                      <td>{item.discount_amount}</td>
                      <td>{item.net_amount}</td>
                      <td>{item.voided_by}</td>
                      <td style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          icon={faEye}
                          color="#637381"
                          onClick={() => navigate(`/void/${item.id}`)}
                        />
                      </td>
                    </tr>
                  ))
                ) : loading === true || searchLoading === true ? (
                  <tr>
                    <td colSpan="12">
                      <Spinner animation="border" variant="warning" />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="12">No Result Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {pageCount > 1 && !showSearchResult ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default VoidList;
