import {
  DASHBOARD_DETAIL_REQUEST,
  DASHBOARD_DETAIL_SUCCESS,
  DASHBOARD_DETAIL_FAILURE,
} from "../type.js";
import axios from "axios";

export const getDashboardList =
  (purchaseKeyword, returnKeyword) => async (dispatch) => {
    try {
      dispatch({
        type: DASHBOARD_DETAIL_REQUEST,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/dashboard/?purchase_analysis_timeframe=${purchaseKeyword}&return_analysis_timeframe=${returnKeyword}`,
        config
      );

      dispatch({
        type: DASHBOARD_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DASHBOARD_DETAIL_FAILURE,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
