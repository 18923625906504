import {
  VENDOR_LIST_REQUEST,
  VENDOR_LIST_SUCCESS,
  VENDOR_LIST_FAILURE,
  CREATE_VENDOR_REQUEST,
  CREATE_VENDOR_SUCCESS,
  CREATE_VENDOR_FAILURE,
  VENDOR_DELETE_REQUEST,
  VENDOR_DELETE_SUCCESS,
  VENDOR_DELETE_FAILURE,
  VENDOR_UPDATE_REQUEST,
  VENDOR_UPDATE_SUCCESS,
  VENDOR_UPDATE_FAILURE,
  EACH_VENDOR_DETAIL_REQUEST,
  EACH_VENDOR_DETAIL_SUCCESS,
  EACH_VENDOR_DETAIL_FAILURE,
  SEARCH_VENDOR_LIST_REQUEST,
  SEARCH_VENDOR_LIST_SUCCESS,
  SEARCH_VENDOR_LIST_FAILURE,
} from "../type.js";

export const vendorListReducer = (state = { vendorList: {} }, action) => {
  switch (action.type) {
    case VENDOR_LIST_REQUEST:
      return { loading: true, ...state };
    case VENDOR_LIST_SUCCESS:
      return { loading: false, vendorList: action.payload };
    case VENDOR_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createVendorReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_VENDOR_REQUEST:
      return { loading: true };
    case CREATE_VENDOR_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case CREATE_VENDOR_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteVendorReducer = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_DELETE_REQUEST:
      return { loading: true };
    case VENDOR_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case VENDOR_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateVendorReducer = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_UPDATE_REQUEST:
      return { loading: true };
    case VENDOR_UPDATE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case VENDOR_UPDATE_FAILURE:
      return {
        loading: false,
        updateError: action.payload,
      };
    default:
      return state;
  }
};

export const vendorDetailsReducers = (state = { vendor: {} }, action) => {
  switch (action.type) {
    case EACH_VENDOR_DETAIL_REQUEST:
      return { loading: true, ...state };
    case EACH_VENDOR_DETAIL_SUCCESS:
      return { loading: false, vendor: action.payload };
    case EACH_VENDOR_DETAIL_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchVendorListReducer = (
  state = { searchVendors: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_VENDOR_LIST_REQUEST:
      return { searchLoading: true, ...state };
    case SEARCH_VENDOR_LIST_SUCCESS:
      return { searchLoading: false, searchVendors: action.payload };
    case SEARCH_VENDOR_LIST_FAILURE:
      return { searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};
