import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  updateCategory,
  getCategoryList,
} from "../../Redux/actions/categoryActions";
import { SuccessAlert } from "../notify/notify";

const UpdateCategoryModal = (props) => {
  const dispatch = useDispatch();
  const [updateCategoryName, setUpdateCategoryName] = useState("");
  const [updateLocation, setUpdateLocation] = useState("");
  const [updateMsg, setUpdateMsg] = useState(false);

  const { loading, message, error } = useSelector(
    (state) => state.updateCategory
  );

  useEffect(() => {
    if (props.category !== "") {
      setUpdateCategoryName(props.category.name);
      setUpdateLocation(props.category.location);
    }
  }, [props.category]);

  useEffect(() => {
    if (message !== undefined && updateMsg === true) {
      //console.log("inside", deleteUserResponse.message);
      SuccessAlert("Category Updated Successfully");
      dispatch(getCategoryList());
      setUpdateMsg(false);
    }
    if (error !== undefined && updateMsg === true) {
      setUpdateMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [message, error]);

  const updateCategoryHnadle = (e) => {
    e.preventDefault();
    if (
      updateCategoryName !== props.category.name ||
      updateLocation !== props.category.location
    ) {
      const body = {
        name: updateCategoryName,
        location: updateLocation,
      };
      dispatch(updateCategory(props.category.id, body));
      setUpdateMsg(true);
      props.onHide();
    } else {
      props.onHide();
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto" id="contained-modal-title-vcenter">
          Update Category ({props.category.id})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <b>Category Name</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="e.g: Baby Food"
              value={updateCategoryName}
              onChange={(e) => setUpdateCategoryName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>Location</b>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => setUpdateLocation(e.target.value)}
              value={updateLocation}
              required
            >
              <option value="medicare">Medicare</option>
              <option value="bodycare">Body Care</option>
              <option value="food">Food</option>
              <option value="privatebox">Private Box</option>
            </Form.Select>
          </Form.Group>
        </Form>
        <Row className="mt-3 d-flex justify-content-center">
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button className="cancel-btn border-0" onClick={props.onHide}>
              Cancel
            </Button>
          </Col>
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button
              className="submit-btn border-0"
              onClick={updateCategoryHnadle}
            >
              Update
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateCategoryModal;
