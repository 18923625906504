import {
  WAREHOUSE_LIST_REQUEST,
  WAREHOUSE_LIST_SUCCESS,
  WAREHOUSE_LIST_FAILURE,
  WAREHOUSE_DELETE_REQUEST,
  WAREHOUSE_DELETE_SUCCESS,
  WAREHOUSE_DELETE_FAILURE,
  CREATE_WAREHOUSE_REQUEST,
  CREATE_WAREHOUSE_SUCCESS,
  CREATE_WAREHOUSE_FAILURE,
  WAREHOUSE_UPDATE_REQUEST,
  WAREHOUSE_UPDATE_SUCCESS,
  WAREHOUSE_UPDATE_FAILURE,
  EACH_WAREHOUSE_DETAIL_REQUEST,
  EACH_WAREHOUSE_DETAIL_SUCCESS,
  EACH_WAREHOUSE_DETAIL_FAILURE,
  SEARCH_WAREHOUSE_LIST_REQUEST,
  SEARCH_WAREHOUSE_LIST_SUCCESS,
  SEARCH_WAREHOUSE_LIST_FAILURE,
} from "../type.js";
import axios from "axios";

export const getWarehouseList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: WAREHOUSE_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/warehouse/?limit=${limit}&offset=${offset}`,
      config
    );

    dispatch({
      type: WAREHOUSE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WAREHOUSE_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteWarehouse = (id) => async (dispatch) => {
  try {
    dispatch({
      type: WAREHOUSE_DELETE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/warehouse/${id}/`,
      config
    );
    dispatch({
      type: WAREHOUSE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WAREHOUSE_DELETE_FAILURE,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const createWarehouse = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_WAREHOUSE_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/warehouse/`,
      body,
      config
    );

    dispatch({
      type: CREATE_WAREHOUSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_WAREHOUSE_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const updateWarehouse = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: WAREHOUSE_UPDATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/warehouse/${id}/`,
      body,
      config
    );

    dispatch({
      type: WAREHOUSE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WAREHOUSE_UPDATE_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getWarehouseDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: EACH_WAREHOUSE_DETAIL_REQUEST });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/warehouse/${id}/`,
      config
    );
    dispatch({
      type: EACH_WAREHOUSE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EACH_WAREHOUSE_DETAIL_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSearchWarehouseList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_WAREHOUSE_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/warehouse/v1/warehouse/?query=${keyword}&limit=20&offset=0`,
      config
    );

    dispatch({
      type: SEARCH_WAREHOUSE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_WAREHOUSE_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
