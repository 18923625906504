import {
  DUE_PAYMENT_LIST_REQUEST,
  DUE_PAYMENT_LIST_SUCCESS,
  DUE_PAYMENT_LIST_FAILURE,
  DUE_PAYMENT_UPDATE_REQUEST,
  DUE_PAYMENT_UPDATE_SUCCESS,
  DUE_PAYMENT_UPDATE_FAILURE,
  SEARCH_DUE_PAYMENT_LIST_REQUEST,
  SEARCH_DUE_PAYMENT_LIST_SUCCESS,
  SEARCH_DUE_PAYMENT_LIST_FAILURE,
} from "../type.js";

export const duePaymentListReducer = (
  state = { duePaymentList: {} },
  action
) => {
  switch (action.type) {
    case DUE_PAYMENT_LIST_REQUEST:
      return { loading: true, ...state };
    case DUE_PAYMENT_LIST_SUCCESS:
      return { loading: false, duePaymentList: action.payload };
    case DUE_PAYMENT_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateDuePaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case DUE_PAYMENT_UPDATE_REQUEST:
      return { loading: true };
    case DUE_PAYMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case DUE_PAYMENT_UPDATE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const searchDuePaymentListReducer = (
  state = { searchDuePayments: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_DUE_PAYMENT_LIST_REQUEST:
      return { searchLoading: true, ...state };
    case SEARCH_DUE_PAYMENT_LIST_SUCCESS:
      return { searchLoading: false, searchDuePayments: action.payload };
    case SEARCH_DUE_PAYMENT_LIST_FAILURE:
      return { searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};
