import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEye,
  faPenAlt,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";

const DueDetailsTableRow = ({
  list,
  selected,
  setSelected,
  handleUpdateOrder,
}) => {
  return (
    <>
      <tr className="accordion accordion-flush" id="accordionFlushExample">
        <td>{list.id}</td>
        <td>{list.user.Name}</td>
        <td>{list.user.Phone}</td>
        <td>{list.order_type}</td>
        <td>{list.paymentMethod}</td>
        <td>{list.totalVatAmount}</td>
        <td>{list.discountAmount}</td>
        <td>{list.totalPrice}</td>
        <td style={{ cursor: "pointer" }}>
          <FontAwesomeIcon
            icon={faEye}
            color="#637381"
            className="collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
            onClick={() => setSelected(selected !== list.id ? list.id : null)}
          />
          <FontAwesomeIcon
            icon={faPenAlt}
            color="#637381"
            style={{ paddingLeft: "0.4rem" }}
            onClick={() => handleUpdateOrder(list)}
          />
        </td>
      </tr>
      <tr
        id={selected === list.id ? "flush-collapseOne" : "collapse"}
        className={
          selected === list.id
            ? "accordion-collapse collapse show"
            : "accordion-collapse collapse"
        }
        aria-labelledby="flush-headingOne"
        data-bs-parent="#accordionFlushExample"
      >
        <td colSpan={12}>
          <Table className="mt-2 inside-table" responsive>
            <thead className="text-center">
              <tr>
                <th>Product Id</th>
                <th>Product Name</th>
                <th>Qty</th>
                <th>Each Price</th>
                <th>Discount</th>
                <th>Net Amount</th>
              </tr>
            </thead>
            <tbody>
              {list.product_details.map((item) => (
                <tr key={item.product_id}>
                  <td>{item.product_id}</td>
                  <td>{item.product_name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.each_price}</td>
                  <td>{item.discount}</td>
                  <td>{item.net_amount}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </td>
      </tr>
    </>
  );
};

export default DueDetailsTableRow;
