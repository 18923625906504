export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";

export const SUPPLIER_LIST_REQUEST = "SUPPLIER_LIST_REQUEST";
export const SUPPLIER_LIST_SUCCESS = "SUPPLIER_LIST_SUCCESS";
export const SUPPLIER_LIST_FAILURE = "SUPPLIER_LIST_FAILURE";
export const CREATE_SUPPLIER_REQUEST = "CREATE_SUPPLIER_REQUEST";
export const CREATE_SUPPLIER_SUCCESS = "CREATE_SUPPLIER_SUCCESS";
export const CREATE_SUPPLIER_FAILURE = "CREATE_SUPPLIER_FAILURE";
export const SUPPLIER_DELETE_REQUEST = "SUPPLIER_DELETE_REQUEST";
export const SUPPLIER_DELETE_SUCCESS = "SUPPLIER_DELETE_SUCCESS";
export const SUPPLIER_DELETE_FAILURE = "SUPPLIER_DELETE_FAILURE";
export const SUPPLIER_UPDATE_REQUEST = "SUPPLIER_UPDATE_REQUEST";
export const SUPPLIER_UPDATE_SUCCESS = "SUPPLIER_UPDATE_SUCCESS";
export const SUPPLIER_UPDATE_FAILURE = "SUPPLIER_UPDATE_FAILURE";
export const EACH_SUPPLIER_DETAIL_REQUEST = "EACH_SUPPLIER_DETAIL_REQUEST";
export const EACH_SUPPLIER_DETAIL_SUCCESS = "EACH_SUPPLIER_DETAIL_SUCCESS";
export const EACH_SUPPLIER_DETAIL_FAILURE = "EACH_SUPPLIER_DETAIL_FAILURE";
export const SEARCH_SUPPLIER_LIST_REQUEST = "SEARCH_SUPPLIER_LIST_REQUEST";
export const SEARCH_SUPPLIER_LIST_SUCCESS = "SEARCH_SUPPLIER_LIST_SUCCESS";
export const SEARCH_SUPPLIER_LIST_FAILURE = "SEARCH_SUPPLIER_LIST_FAILURE";

export const VENDOR_LIST_REQUEST = "VENDOR_LIST_REQUEST";
export const VENDOR_LIST_SUCCESS = "VENDOR_LIST_SUCCESS";
export const VENDOR_LIST_FAILURE = "VENDOR_LIST_FAILURE";
export const CREATE_VENDOR_REQUEST = "CREATE_VENDOR_REQUEST";
export const CREATE_VENDOR_SUCCESS = "CREATE_VENDOR_SUCCESS";
export const CREATE_VENDOR_FAILURE = "CREATE_VENDOR_FAILURE";
export const VENDOR_DELETE_REQUEST = "VENDOR_DELETE_REQUEST";
export const VENDOR_DELETE_SUCCESS = "VENDOR_DELETE_SUCCESS";
export const VENDOR_DELETE_FAILURE = "VENDOR_DELETE_FAILURE";
export const VENDOR_UPDATE_REQUEST = "VENDOR_UPDATE_REQUEST";
export const VENDOR_UPDATE_SUCCESS = "VENDOR_UPDATE_SUCCESS";
export const VENDOR_UPDATE_FAILURE = "VENDOR_UPDATE_FAILURE";
export const EACH_VENDOR_DETAIL_REQUEST = "EACH_VENDOR_DETAIL_REQUEST";
export const EACH_VENDOR_DETAIL_SUCCESS = "EACH_VENDOR_DETAIL_SUCCESS";
export const EACH_VENDOR_DETAIL_FAILURE = "EACH_VENDOR_DETAIL_FAILURE";
export const SEARCH_VENDOR_LIST_REQUEST = "SEARCH_VENDOR_LIST_REQUEST";
export const SEARCH_VENDOR_LIST_SUCCESS = "SEARCH_VENDOR_LIST_SUCCESS";
export const SEARCH_VENDOR_LIST_FAILURE = "SEARCH_VENDOR_LIST_FAILURE";

export const PURCHASE_LIST_REQUEST = "PURCHASE_LIST_REQUEST";
export const PURCHASE_LIST_SUCCESS = "PURCHASE_LIST_SUCCESS";
export const PURCHASE_LIST_FAILURE = "PURCHASE_LIST_FAILURE";
export const PURCHASE_DELETE_REQUEST = "PURCHASE_DELETE_REQUEST";
export const PURCHASE_DELETE_SUCCESS = "PURCHASE_DELETE_SUCCESS";
export const PURCHASE_DELETE_FAILURE = "PURCHASE_DELETE_FAILURE";
export const PURCHASED_ITEM_DELETE_REQUEST = "PURCHASED_ITEM_DELETE_REQUEST";
export const PURCHASED_ITEM_DELETE_SUCCESS = "PURCHASED_ITEM_DELETE_SUCCESS";
export const PURCHASED_ITEM_DELETE_FAILURE = "PURCHASED_ITEM_DELETE_FAILURE";
export const SEARCH_PURCHASE_LIST_REQUEST = "SEARCH_PURCHASE_LIST_REQUEST";
export const SEARCH_PURCHASE_LIST_SUCCESS = "SEARCH_PURCHASE_LIST_SUCCESS";
export const SEARCH_PURCHASE_LIST_FAILURE = "SEARCH_PURCHASE_LIST_FAILURE";
export const CREATE_PURCHASE_REQUEST = "CREATE_PURCHASE_REQUEST";
export const CREATE_PURCHASE_SUCCESS = "CREATE_PURCHASE_SUCCESS";
export const CREATE_PURCHASE_FAILURE = "CREATE_PURCHASE_FAILURE";
export const PURCHASE_REQUEST_LIST_REQUEST = "PURCHASE_REQUEST_LIST_REQUEST";
export const PURCHASE_REQUEST_LIST_SUCCESS = "PURCHASE_REQUEST_LIST_SUCCESS";
export const PURCHASE_REQUEST_LIST_FAILURE = "PURCHASE_REQUEST_LIST_FAILURE";
export const SEARCH_PURCHASE_REQUEST_LIST_REQUEST =
  "SEARCH_PURCHASE_REQUEST_LIST_REQUEST";
export const SEARCH_PURCHASE_REQUEST_LIST_SUCCESS =
  "SEARCH_PURCHASE_REQUEST_LIST_SUCCESS";
export const SEARCH_PURCHASE_REQUEST_LIST_FAILURE =
  "SEARCH_PURCHASE_REQUEST_LIST_FAILURE";
export const UPDATE_PURCHASE_REQUEST = "UPDATE_PURCHASE_REQUEST";
export const UPDATE_PURCHASE_SUCCESS = "UPDATE_PURCHASE_SUCCESS";
export const UPDATE_PURCHASE_FAILURE = "UPDATE_PURCHASE_FAILURE";
export const CREATE_PURCHASE_REQUEST_REQUEST =
  "CREATE_PURCHASE_REQUEST_REQUEST";
export const CREATE_PURCHASE_REQUEST_SUCCESS =
  "CREATE_PURCHASE_REQUEST_SUCCESS";
export const CREATE_PURCHASE_REQUEST_FAILURE =
  "CREATE_PURCHASE_REQUEST_FAILURE";
export const PURCHASE_REQUEST_DELETE_REQUEST =
  "PURCHASE_REQUEST_DELETE_REQUEST";
export const PURCHASE_REQUEST_DELETE_SUCCESS =
  "PURCHASE_REQUEST_DELETE_SUCCESS";
export const PURCHASE_REQUEST_DELETE_FAILURE =
  "PURCHASE_REQUEST_DELETE_FAILURE";
export const PURCHASE_CREATE_PRODUCT_REQUEST =
  "PURCHASE_CREATE_PRODUCT_REQUEST";
export const PURCHASE_CREATE_PRODUCT_SUCCESS =
  "PURCHASE_CREATE_PRODUCT_SUCCESS";
export const PURCHASE_CREATE_PRODUCT_FAILURE =
  "PURCHASE_CREATE_PRODUCT_FAILURE";
export const PURCHASE_CREATE_PRODUCT_LIST_REQUEST =
  "PURCHASE_CREATE_PRODUCT_LIST_REQUEST";
export const PURCHASE_CREATE_PRODUCT_LIST_SUCCESS =
  "PURCHASE_CREATE_PRODUCT_LIST_SUCCESS";
export const PURCHASE_CREATE_PRODUCT_LIST_FAILURE =
  "PURCHASE_CREATE_PRODUCT_LIST_FAILURE";
export const PURCHASE_PRODUCT_DELETE_REQUEST =
  "PURCHASE_PRODUCT_DELETE_REQUEST";
export const PURCHASE_PRODUCT_DELETE_SUCCESS =
  "PURCHASE_PRODUCT_DELETE_SUCCESS";
export const PURCHASE_PRODUCT_DELETE_FAILURE =
  "PURCHASE_PRODUCT_DELETE_FAILURE";
export const EACH_PURCHASE_DETAIL_REQUEST = "EACH_PURCHASE_DETAIL_REQUEST";
export const EACH_PURCHASE_DETAIL_SUCCESS = "EACH_PURCHASE_DETAIL_SUCCESS";
export const EACH_PURCHASE_DETAIL_FAILURE = "EACH_PURCHASE_DETAIL_FAILURE";

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAILURE = "PRODUCT_LIST_FAILURE";
export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";
export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAILURE = "PRODUCT_DELETE_FAILURE";
export const EACH_PRODUCT_DETAIL_REQUEST = "EACH_PRODUCT_DETAIL_REQUEST";
export const EACH_PRODUCT_DETAIL_SUCCESS = "EACH_PRODUCT_DETAIL_SUCCESS";
export const EACH_PRODUCT_DETAIL_FAILURE = "EACH_PRODUCT_DETAIL_FAILURE";
export const SEARCH_PRODUCT_LIST_REQUEST = "SEARCH_PRODUCT_LIST_REQUEST";
export const SEARCH_PRODUCT_LIST_SUCCESS = "SEARCH_PRODUCT_LIST_SUCCESS";
export const SEARCH_PRODUCT_LIST_FAILURE = "SEARCH_PRODUCT_LIST_FAILURE";
export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAILURE = "PRODUCT_UPDATE_FAILURE";

export const SUBCATEGORY_LIST_REQUEST = "SUBCATEGORY_LIST_REQUEST";
export const SUBCATEGORY_LIST_SUCCESS = "SUBCATEGORY_LIST_SUCCESS";
export const SUBCATEGORY_LIST_FAILURE = "SUBCATEGORY_LIST_FAILURE";
export const SUBCATEGORY_DELETE_REQUEST = "SUBCATEGORY_DELETE_REQUEST";
export const SUBCATEGORY_DELETE_SUCCESS = "SUBCATEGORY_DELETE_SUCCESS";
export const SUBCATEGORY_DELETE_FAILURE = "SUBCATEGORY_DELETE_FAILURE";
export const CREATE_SUBCATEGORY_REQUEST = "CREATE_SUBCATEGORY_REQUEST";
export const CREATE_SUBCATEGORY_SUCCESS = "CREATE_SUBCATEGORY_SUCCESS";
export const CREATE_SUBCATEGORY_FAILURE = "CREATE_SUBCATEGORY_FAILURE";
export const SUBCATEGORY_UPDATE_REQUEST = "SUBCATEGORY_UPDATE_REQUEST";
export const SUBCATEGORY_UPDATE_SUCCESS = "SUBCATEGORY_UPDATE_SUCCESS";
export const SUBCATEGORY_UPDATE_FAILURE = "SUBCATEGORY_UPDATE_FAILURE";
export const SEARCH_SUBCATEGORY_LIST_REQUEST =
  "SEARCH_SUBCATEGORY_LIST_REQUEST";
export const SEARCH_SUBCATEGORY_LIST_SUCCESS =
  "SEARCH_SUBCATEGORY_LIST_SUCCESS";
export const SEARCH_SUBCATEGORY_LIST_FAILURE =
  "SEARCH_SUBCATEGORY_LIST_FAILURE";

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAILURE = "CATEGORY_LIST_FAILURE";
export const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_FAILURE = "CATEGORY_DELETE_FAILURE";
export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";
export const CATEGORY_UPDATE_REQUEST = "CATEGORY_UPDATE_REQUEST";
export const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS";
export const CATEGORY_UPDATE_FAILURE = "CATEGORY_UPDATE_FAILURE";
export const SEARCH_CATEGORY_LIST_REQUEST = "SEARCH_CATEGORY_LIST_REQUEST";
export const SEARCH_CATEGORY_LIST_SUCCESS = "SEARCH_CATEGORY_LIST_SUCCESS";
export const SEARCH_CATEGORY_LIST_FAILURE = "SEARCH_CATEGORY_LIST_FAILURE";

export const RETURN_LIST_REQUEST = "RETURN_LIST_REQUEST";
export const RETURN_LIST_SUCCESS = "RETURN_LIST_SUCCESS";
export const RETURN_LIST_FAILURE = "RETURN_LIST_FAILURE";
export const RETURN_DELETE_REQUEST = "RETURN_DELETE_REQUEST";
export const RETURN_DELETE_SUCCESS = "RETURN_DELETE_SUCCESS";
export const RETURN_DELETE_FAILURE = "RETURN_DELETE_FAILURE";
export const RETURNED_ITEM_DELETE_REQUEST = "RETURNED_ITEM_DELETE_REQUEST";
export const RETURNED_ITEM_DELETE_SUCCESS = "RETURNED_ITEM_DELETE_SUCCESS";
export const RETURNED_ITEM_DELETE_FAILURE = "RETURNED_ITEM_DELETE_FAILURE";
export const SEARCH_RETURN_LIST_REQUEST = "SEARCH_RETURN_LIST_REQUEST";
export const SEARCH_RETURN_LIST_SUCCESS = "SEARCH_RETURN_LIST_SUCCESS";
export const SEARCH_RETURN_LIST_FAILURE = "SEARCH_RETURN_LIST_FAILURE";
export const CREATE_RETURN_REQUEST = "CREATE_RETURN_REQUEST";
export const CREATE_RETURN_SUCCESS = "CREATE_RETURN_SUCCESS";
export const CREATE_RETURN_FAILURE = "CREATE_RETURN_FAILURE";

export const DASHBOARD_DETAIL_REQUEST = "DASHBOARD_DETAIL_REQUEST";
export const DASHBOARD_DETAIL_SUCCESS = "DASHBOARD_DETAIL_SUCCESS";
export const DASHBOARD_DETAIL_FAILURE = "DASHBOARD_DETAIL_FAILURE";

export const PRODUCT_BATCH_DETAIL_REQUEST = "PRODUCT_BATCH_DETAIL_REQUEST";
export const PRODUCT_BATCH_DETAIL_SUCCESS = "PRODUCT_BATCH_DETAIL_SUCCESS";
export const PRODUCT_BATCH_DETAIL_FAILURE = "PRODUCT_BATCH_DETAIL_FAILURE";

export const WAREHOUSE_LIST_REQUEST = "WAREHOUSE_LIST_REQUEST";
export const WAREHOUSE_LIST_SUCCESS = "WAREHOUSE_LIST_SUCCESS";
export const WAREHOUSE_LIST_FAILURE = "WAREHOUSE_LIST_FAILURE";
export const WAREHOUSE_DELETE_REQUEST = "WAREHOUSE_DELETE_REQUEST";
export const WAREHOUSE_DELETE_SUCCESS = "WAREHOUSE_DELETE_SUCCESS";
export const WAREHOUSE_DELETE_FAILURE = "WAREHOUSE_DELETE_FAILURE";
export const CREATE_WAREHOUSE_REQUEST = "CREATE_WAREHOUSE_REQUEST";
export const CREATE_WAREHOUSE_SUCCESS = "CREATE_WAREHOUSE_SUCCESS";
export const CREATE_WAREHOUSE_FAILURE = "CREATE_WAREHOUSE_FAILURE";
export const WAREHOUSE_UPDATE_REQUEST = "WAREHOUSE_UPDATE_REQUEST";
export const WAREHOUSE_UPDATE_SUCCESS = "WAREHOUSE_UPDATE_SUCCESS";
export const WAREHOUSE_UPDATE_FAILURE = "WAREHOUSE_UPDATE_FAILURE";
export const EACH_WAREHOUSE_DETAIL_REQUEST = "EACH_WAREHOUSE_DETAIL_REQUEST";
export const EACH_WAREHOUSE_DETAIL_SUCCESS = "EACH_WAREHOUSE_DETAIL_SUCCESS";
export const EACH_WAREHOUSE_DETAIL_FAILURE = "EACH_WAREHOUSE_DETAIL_FAILURE";
export const SEARCH_WAREHOUSE_LIST_REQUEST = "SEARCH_WAREHOUSE_LIST_REQUEST";
export const SEARCH_WAREHOUSE_LIST_SUCCESS = "SEARCH_WAREHOUSE_LIST_SUCCESS";
export const SEARCH_WAREHOUSE_LIST_FAILURE = "SEARCH_WAREHOUSE_LIST_FAILURE";

export const TRANSFER_LIST_REQUEST = "TRANSFER_LIST_REQUEST";
export const TRANSFER_LIST_SUCCESS = "TRANSFER_LIST_SUCCESS";
export const TRANSFER_LIST_FAILURE = "TRANSFER_LIST_FAILURE";
export const CREATE_TRANSFER_REQUEST = "CREATE_TRANSFER_REQUEST";
export const CREATE_TRANSFER_SUCCESS = "CREATE_TRANSFER_SUCCESS";
export const CREATE_TRANSFER_FAILURE = "CREATE_TRANSFER_FAILURE";
export const SEARCH_TRANSFER_LIST_REQUEST = "SEARCH_TRANSFER_LIST_REQUEST";
export const SEARCH_TRANSFER_LIST_SUCCESS = "SEARCH_TRANSFER_LIST_SUCCESS";
export const SEARCH_TRANSFER_LIST_FAILURE = "SEARCH_TRANSFER_LIST_FAILURE";
export const EACH_TRANSFER_DETAIL_REQUEST = "EACH_TRANSFER_DETAIL_REQUEST";
export const EACH_TRANSFER_DETAIL_SUCCESS = "EACH_TRANSFER_DETAIL_SUCCESS";
export const EACH_TRANSFER_DETAIL_FAILURE = "EACH_TRANSFER_DETAIL_FAILURE";
export const SEARCH_TRANSFER_PURCHASE_LIST_REQUEST =
  "SEARCH_TRANSFER_PURCHASE_LIST_REQUEST";
export const SEARCH_TRANSFER_PURCHASE_LIST_SUCCESS =
  "SEARCH_TRANSFER_PURCHASE_LIST_SUCCESS";
export const SEARCH_TRANSFER_PURCHASE_LIST_FAILURE =
  "SEARCH_TRANSFER_PURCHASE_LIST_FAILURE";
export const SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_REQUEST =
  "SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_REQUEST";
export const SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_SUCCESS =
  "SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_SUCCESS";
export const SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_FAILURE =
  "SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_FAILURE";

export const DUE_PAYMENT_LIST_REQUEST = "DUE_PAYMENT_LIST_REQUEST";
export const DUE_PAYMENT_LIST_SUCCESS = "DUE_PAYMENT_LIST_SUCCESS";
export const DUE_PAYMENT_LIST_FAILURE = "DUE_PAYMENT_LIST_FAILURE";
export const DUE_PAYMENT_UPDATE_REQUEST = "DUE_PAYMENT_UPDATE_REQUEST";
export const DUE_PAYMENT_UPDATE_SUCCESS = "DUE_PAYMENT_UPDATE_SUCCESS";
export const DUE_PAYMENT_UPDATE_FAILURE = "DUE_PAYMENT_UPDATE_FAILURE";
export const SEARCH_DUE_PAYMENT_LIST_REQUEST =
  "SEARCH_DUE_PAYMENT_LIST_REQUEST";
export const SEARCH_DUE_PAYMENT_LIST_SUCCESS =
  "SEARCH_DUE_PAYMENT_LIST_SUCCESS";
export const SEARCH_DUE_PAYMENT_LIST_FAILURE =
  "SEARCH_DUE_PAYMENT_LIST_FAILURE";

export const PRODUCT_STATUS_UPDATE_REQUEST = "PRODUCT_STATUS_UPDATE_REQUEST";
export const PRODUCT_STATUS_UPDATE_SUCCESS = "PRODUCT_STATUS_UPDATE_SUCCESS";
export const PRODUCT_STATUS_UPDATE_FAILURE = "PRODUCT_STATUS_UPDATE_FAILURE";
export const SEARCH_PRODUCT_WITH_BARCODE_REQUEST =
  "SEARCH_PRODUCT_WITH_BARCODE_REQUEST";
export const SEARCH_PRODUCT_WITH_BARCODE_SUCCESS =
  "SEARCH_PRODUCT_WITH_BARCODE_SUCCESS";
export const SEARCH_PRODUCT_WITH_BARCODE_FAILURE =
  "SEARCH_PRODUCT_WITH_BARCODE_FAILURE";

export const PURCHASE_ORDER_LIST_REQUEST = "PURCHASE_ORDER_LIST_REQUEST";
export const PURCHASE_ORDER_LIST_SUCCESS = "PURCHASE_ORDER_LIST_SUCCESS";
export const PURCHASE_ORDER_LIST_FAILURE = "PURCHASE_ORDER_LIST_FAILURE";
export const CREATE_PURCHASE_ORDER_REQUEST = "CREATE_PURCHASE_ORDER_REQUEST";
export const CREATE_PURCHASE_ORDER_SUCCESS = "CREATE_PURCHASE_ORDER_SUCCESS";
export const CREATE_PURCHASE_ORDER_FAILURE = "CREATE_PURCHASE_ORDER_FAILURE";
export const SEARCH_PURCHASE_ORDER_LIST_REQUEST =
  "SEARCH_PURCHASE_ORDER_LIST_REQUEST";
export const SEARCH_PURCHASE_ORDER_LIST_SUCCESS =
  "SEARCH_PURCHASE_ORDER_LIST_SUCCESS";
export const SEARCH_PURCHASE_ORDER_LIST_FAILURE =
  "SEARCH_PURCHASE_ORDER_LIST_FAILURE";
export const EACH_PURCHASE_ORDER_DETAIL_REQUEST =
  "EACH_PURCHASE_ORDER_DETAIL_REQUEST";
export const EACH_PURCHASE_ORDER_DETAIL_SUCCESS =
  "EACH_PURCHASE_ORDER_DETAIL_SUCCESS";
export const EACH_PURCHASE_ORDER_DETAIL_FAILURE =
  "EACH_PURCHASE_ORDER_DETAIL_FAILURE";
export const PO_PRODUCT_FILTER_REQUEST = "PO_PRODUCT_FILTER_REQUEST";
export const PO_PRODUCT_FILTER_SUCCESS = "PO_PRODUCT_FILTER_SUCCESS";
export const PO_PRODUCT_FILTER_FAILURE = "PO_PRODUCT_FILTER_FAILURE";

export const ALL_SUPPLIER_WISE_STOCK_REQUEST =
  "ALL_SUPPLIER_WISE_STOCK_REQUEST";
export const ALL_SUPPLIER_WISE_STOCK_SUCCESS =
  "ALL_SUPPLIER_WISE_STOCK_SUCCESS";
export const ALL_SUPPLIER_WISE_STOCK_FAILURE =
  "ALL_SUPPLIER_WISE_STOCK_FAILURE";
export const SUPPLIER_WISE_STOCK_REQUEST = "SUPPLIER_WISE_STOCK_REQUEST";
export const SUPPLIER_WISE_STOCK_SUCCESS = "SUPPLIER_WISE_STOCK_SUCCESS";
export const SUPPLIER_WISE_STOCK_FAILURE = "SUPPLIER_WISE_STOCK_FAILURE";
export const SUBCATEGORY_STOCK_REPORT_REQUEST =
  "SUBCATEGORY_STOCK_REPORT_REQUEST";
export const SUBCATEGORY_STOCK_REPORT_SUCCESS =
  "SUBCATEGORY_STOCK_REPORT_SUCCESS";
export const SUBCATEGORY_STOCK_REPORT_FAILURE =
  "SUBCATEGORY_STOCK_REPORT_FAILURE";
export const CATEGORY_STOCK_REPORT_REQUEST = "CATEGORY_STOCK_REPORT_REQUEST";
export const CATEGORY_STOCK_REPORT_SUCCESS = "CATEGORY_STOCK_REPORT_SUCCESS";
export const CATEGORY_STOCK_REPORT_FAILURE = "CATEGORY_STOCK_REPORT_FAILURE";
export const SUB_CATEGORY_WISE_PRODUCT_REQUEST =
  "SUB_CATEGORY_WISE_PRODUCT_REQUEST";
export const SUB_CATEGORY_WISE_PRODUCT_SUCCESS =
  "SUB_CATEGORY_WISE_PRODUCT_SUCCESS";
export const SUB_CATEGORY_WISE_PRODUCT_FAILURE =
  "SUB_CATEGORY_WISE_PRODUCT_FAILURE";

export const SUPPLIER_WISE_ALL_STOCK_REQUEST =
  "SUPPLIER_WISE_ALL_STOCK_REQUEST";
export const SUPPLIER_WISE_ALL_STOCK_SUCCESS =
  "SUPPLIER_WISE_ALL_STOCK_SUCCESS";
export const SUPPLIER_WISE_ALL_STOCK_FAILURE =
  "SUPPLIER_WISE_ALL_STOCK_FAILURE";
export const SUPPLIER_WISE_FREQUENCY_ANALYSIS_REQUEST =
  "SUPPLIER_WISE_FREQUENCY_ANALYSIS_REQUEST";
export const SUPPLIER_WISE_FREQUENCY_ANALYSIS_SUCCESS =
  "SUPPLIER_WISE_FREQUENCY_ANALYSIS_SUCCESS";
export const SUPPLIER_WISE_FREQUENCY_ANALYSIS_FAILURE =
  "SUPPLIER_WISE_FREQUENCY_ANALYSIS_FAILURE";
export const SLOW_MOVING_PRODUCT_REQUEST = "SLOW_MOVING_PRODUCT_REQUEST";
export const SLOW_MOVING_PRODUCT_SUCCESS = "SLOW_MOVING_PRODUCT_SUCCESS";
export const SLOW_MOVING_PRODUCT_FAILURE = "SLOW_MOVING_PRODUCT_FAILURE";
export const FAST_MOVING_PRODUCT_REQUEST = "FAST_MOVING_PRODUCT_REQUEST";
export const FAST_MOVING_PRODUCT_SUCCESS = "FAST_MOVING_PRODUCT_SUCCESS";
export const FAST_MOVING_PRODUCT_FAILURE = "FAST_MOVING_PRODUCT_FAILURE";
export const NON_MOVING_PRODUCT_REQUEST = "NON_MOVING_PRODUCT_REQUEST";
export const NON_MOVING_PRODUCT_SUCCESS = "NON_MOVING_PRODUCT_SUCCESS";
export const NON_MOVING_PRODUCT_FAILURE = "NON_MOVING_PRODUCT_FAILURE";
export const STOCK_RETURN_REPORT_REQUEST = "STOCK_RETURN_REPORT_REQUEST";
export const STOCK_RETURN_REPORT_SUCCESS = "STOCK_RETURN_REPORT_SUCCESS";
export const STOCK_RETURN_REPORT_FAILURE = "STOCK_RETURN_REPORT_FAILURE";
export const ZERO_STOCK_REPORT_REQUEST = "ZERO_STOCK_REPORT_REQUEST";
export const ZERO_STOCK_REPORT_SUCCESS = "ZERO_STOCK_REPORT_SUCCESS";
export const ZERO_STOCK_REPORT_FAILURE = "ZERO_STOCK_REPORT_FAILURE";
export const GP_COMPARE_REPORT_REQUEST = "GP_COMPARE_REPORT_REQUEST";
export const GP_COMPARE_REPORT_SUCCESS = "GP_COMPARE_REPORT_SUCCESS";
export const GP_COMPARE_REPORT_FAILURE = "GP_COMPARE_REPORT_FAILURE";
export const COMPARE_PRODUCT_GP_REQUEST = "COMPARE_PRODUCT_GP_REQUEST";
export const COMPARE_PRODUCT_GP_SUCCESS = "COMPARE_PRODUCT_GP_SUCCESS";
export const COMPARE_PRODUCT_GP_FAILURE = "COMPARE_PRODUCT_GP_FAILURE";
export const NEAR_EXPIRY_PRODUCT_REQUEST = "NEAR_EXPIRY_PRODUCT_REQUEST";
export const NEAR_EXPIRY_PRODUCT_SUCCESS = "NEAR_EXPIRY_PRODUCT_SUCCESS";
export const NEAR_EXPIRY_PRODUCT_FAILURE = "NEAR_EXPIRY_PRODUCT_FAILURE";
export const EXPIRY_PRODUCT_REQUEST = "EXPIRY_PRODUCT_REQUEST";
export const EXPIRY_PRODUCT_SUCCESS = "EXPIRY_PRODUCT_SUCCESS";
export const EXPIRY_PRODUCT_FAILURE = "EXPIRY_PRODUCT_FAILURE";

export const RETURN_STOCK_REPORT_REQUEST = "RETURN_STOCK_REPORT_REQUEST";
export const RETURN_STOCK_REPORT_SUCCESS = "RETURN_STOCK_REPORT_SUCCESS";
export const RETURN_STOCK_REPORT_FAILURE = "RETURN_STOCK_REPORT_FAILURE";

export const PRODUCT_BIOGRAPHY_REQUEST = "PRODUCT_BIOGRAPHY_REQUEST";
export const PRODUCT_BIOGRAPHY_SUCCESS = "PRODUCT_BIOGRAPHY_SUCCESS";
export const PRODUCT_BIOGRAPHY_FAILURE = "PRODUCT_BIOGRAPHY_FAILURE";
export const INVENTORY_LIST_REQUEST = "INVENTORY_LIST_REQUEST";
export const INVENTORY_LIST_SUCCESS = "INVENTORY_LIST_SUCCESS";
export const INVENTORY_LIST_FAILURE = "INVENTORY_LIST_FAILURE";
export const CREATE_PHYSICAL_INVENTORY_REQUEST =
  "CREATE_PHYSICAL_INVENTORY_REQUEST";
export const CREATE_PHYSICAL_INVENTORY_SUCCESS =
  "CREATE_PHYSICAL_INVENTORY_SUCCESS";
export const CREATE_PHYSICAL_INVENTORY_FAILURE =
  "CREATE_PHYSICAL_INVENTORY_FAILURE";
export const PHYSICAL_INVENTORY_LIST_REQUEST =
  "PHYSICAL_INVENTORY_LIST_REQUEST";
export const PHYSICAL_INVENTORY_LIST_SUCCESS =
  "PHYSICAL_INVENTORY_LIST_SUCCESS";
export const PHYSICAL_INVENTORY_LIST_FAILURE =
  "PHYSICAL_INVENTORY_LIST_FAILURE";
export const PHYSICAL_INVENTORY_UPDATE_REQUEST =
  "PHYSICAL_INVENTORY_UPDATE_REQUEST";
export const PHYSICAL_INVENTORY_UPDATE_SUCCESS =
  "PHYSICAL_INVENTORY_UPDATE_SUCCESS";
export const PHYSICAL_INVENTORY_UPDATE_FAILURE =
  "PHYSICAL_INVENTORY_UPDATE_FAILURE";
export const INVENTORY_LOG_LIST_REQUEST = "INVENTORY_LOG_LIST_REQUEST";
export const INVENTORY_LOG_LIST_SUCCESS = "INVENTORY_LOG_LIST_SUCCESS";
export const INVENTORY_LOG_LIST_FAILURE = "INVENTORY_LOG_LIST_FAILURE";

export const SUPPLIER_WISE_RECEIVE_REPORT_REQUEST =
  "SUPPLIER_WISE_RECEIVE_REPORT_REQUEST";
export const SUPPLIER_WISE_RECEIVE_REPORT_SUCCESS =
  "SUPPLIER_WISE_RECEIVE_REPORT_SUCCESS";
export const SUPPLIER_WISE_RECEIVE_REPORT_FAILURE =
  "SUPPLIER_WISE_RECEIVE_REPORT_FAILURE";
export const SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_REQUEST =
  "SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_REQUEST";
export const SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_SUCCESS =
  "SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_SUCCESS";
export const SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_FAILURE =
  "SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_FAILURE";
export const VENDOR_WISE_RECEIVE_REPORT_REQUEST =
  "VENDOR_WISE_RECEIVE_REPORT_REQUEST";
export const VENDOR_WISE_RECEIVE_REPORT_SUCCESS =
  "VENDOR_WISE_RECEIVE_REPORT_SUCCESS";
export const VENDOR_WISE_RECEIVE_REPORT_FAILURE =
  "VENDOR_WISE_RECEIVE_REPORT_FAILURE";
export const BARCODE_STOCK_RECEIPT_REPORT_REQUEST =
  "BARCODE_STOCK_RECEIPT_REPORT_REQUEST";
export const BARCODE_STOCK_RECEIPT_REPORT_SUCCESS =
  "BARCODE_STOCK_RECEIPT_REPORT_SUCCESS";
export const BARCODE_STOCK_RECEIPT_REPORT_FAILURE =
  "BARCODE_STOCK_RECEIPT_REPORT_FAILURE";

export const VOID_LIST_REQUEST = "VOID_LIST_REQUEST";
export const VOID_LIST_SUCCESS = "VOID_LIST_SUCCESS";
export const VOID_LIST_FAILURE = "VOID_LIST_FAILURE";
export const EACH_VOID_DETAIL_REQUEST = "EACH_VOID_DETAIL_REQUEST";
export const EACH_VOID_DETAIL_SUCCESS = "EACH_VOID_DETAIL_SUCCESS";
export const EACH_VOID_DETAIL_FAILURE = "EACH_VOID_DETAIL_FAILURE";
export const SEARCH_VOID_LIST_REQUEST = "SEARCH_VOID_LIST_REQUEST";
export const SEARCH_VOID_LIST_SUCCESS = "SEARCH_VOID_LIST_SUCCESS";
export const SEARCH_VOID_LIST_FAILURE = "SEARCH_VOID_LIST_FAILURE";
export const CREATE_VOID_REQUEST = "CREATE_VOID_REQUEST";
export const CREATE_VOID_SUCCESS = "CREATE_VOID_SUCCESS";
export const CREATE_VOID_FAILURE = "CREATE_VOID_FAILURE";
export const SEARCH_INVOICE_REQUEST = "SEARCH_INVOICE_REQUEST";
export const SEARCH_INVOICE_SUCCESS = "SEARCH_INVOICE_SUCCESS";
export const SEARCH_INVOICE_FAILURE = "SEARCH_INVOICE_FAILURE";
export const SEARCH_INVOICE_DETAILS_REQUEST = "SEARCH_INVOICE_DETAILS_REQUEST";
export const SEARCH_INVOICE_DETAILS_SUCCESS = "SEARCH_INVOICE_DETAILS_SUCCESS";
export const SEARCH_INVOICE_DETAILS_FAILURE = "SEARCH_INVOICE_DETAILS_FAILURE";

export const IMPORT_FILE_REQUEST = "IMPORT_FILE_REQUEST";
export const IMPORT_FILE_SUCCESS = "IMPORT_FILE_SUCCESS";
export const IMPORT_FILE_FAILURE = "IMPORT_FILE_FAILURE";

export const GENERIC_LIST_REQUEST = "GENERIC_LIST_REQUEST";
export const GENERIC_LIST_SUCCESS = "GENERIC_LIST_SUCCESS";
export const GENERIC_LIST_FAILURE = "GENERIC_LIST_FAILURE";
export const CREATE_GENERIC_REQUEST = "CREATE_GENERIC_REQUEST";
export const CREATE_GENERIC_SUCCESS = "CREATE_GENERIC_SUCCESS";
export const CREATE_GENERIC_FAILURE = "CREATE_GENERIC_FAILURE";
export const SEARCH_GENERIC_LIST_REQUEST = "SEARCH_GENERIC_LIST_REQUEST";
export const SEARCH_GENERIC_LIST_SUCCESS = "SEARCH_GENERIC_LIST_SUCCESS";
export const SEARCH_GENERIC_LIST_FAILURE = "SEARCH_GENERIC_LIST_FAILURE";
