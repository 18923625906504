import {
  VOID_LIST_REQUEST,
  VOID_LIST_SUCCESS,
  VOID_LIST_FAILURE,
  CREATE_VOID_REQUEST,
  CREATE_VOID_SUCCESS,
  CREATE_VOID_FAILURE,
  EACH_VOID_DETAIL_REQUEST,
  EACH_VOID_DETAIL_SUCCESS,
  EACH_VOID_DETAIL_FAILURE,
  SEARCH_VOID_LIST_REQUEST,
  SEARCH_VOID_LIST_SUCCESS,
  SEARCH_VOID_LIST_FAILURE,
  SEARCH_INVOICE_REQUEST,
  SEARCH_INVOICE_SUCCESS,
  SEARCH_INVOICE_FAILURE,
  SEARCH_INVOICE_DETAILS_REQUEST,
  SEARCH_INVOICE_DETAILS_SUCCESS,
  SEARCH_INVOICE_DETAILS_FAILURE,
} from "../type.js";

export const voidListReducer = (state = { voidList: {} }, action) => {
  switch (action.type) {
    case VOID_LIST_REQUEST:
      return { ...state, loading: true };
    case VOID_LIST_SUCCESS:
      return { ...state, loading: false, voidList: action.payload };
    case VOID_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createVoidReducer = (state = { message: undefined }, action) => {
  switch (action.type) {
    case CREATE_VOID_REQUEST:
      return { ...state, loading: true, message: undefined };
    case CREATE_VOID_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case CREATE_VOID_FAILURE:
      return {
        ...state,
        loading: false,
        message: undefined,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const voidDetailsReducers = (state = { singleVoid: {} }, action) => {
  switch (action.type) {
    case EACH_VOID_DETAIL_REQUEST:
      return { ...state, loading: true, singleVoid: {} };
    case EACH_VOID_DETAIL_SUCCESS:
      return { ...state, loading: false, singleVoid: action.payload };
    case EACH_VOID_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchVoidListReducer = (state = { searchVoid: {} }, action) => {
  switch (action.type) {
    case SEARCH_VOID_LIST_REQUEST:
      return { ...state, searchLoading: true };
    case SEARCH_VOID_LIST_SUCCESS:
      return { ...state, searchLoading: false, searchVoid: action.payload };
    case SEARCH_VOID_LIST_FAILURE:
      return { ...state, searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};

export const searchInvoiceReducer = (state = { invoice: {} }, action) => {
  switch (action.type) {
    case SEARCH_INVOICE_REQUEST:
      return { ...state, invoiceLoading: true };
    case SEARCH_INVOICE_SUCCESS:
      return { ...state, invoiceLoading: false, invoice: action.payload };
    case SEARCH_INVOICE_FAILURE:
      return { ...state, invoiceLoading: false, invoiceError: action.payload };
    default:
      return state;
  }
};

export const searchInvoiceDetailsReducer = (
  state = { invoiceDetails: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_INVOICE_DETAILS_REQUEST:
      return { ...state, invoiceDetailsLoading: true };
    case SEARCH_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        invoiceDetailsLoading: false,
        invoiceDetails: action.payload,
      };
    case SEARCH_INVOICE_DETAILS_FAILURE:
      return {
        ...state,
        invoiceDetailsLoading: false,
        invoiceDetailsError: action.payload,
      };
    default:
      return state;
  }
};
