import {
  WAREHOUSE_LIST_REQUEST,
  WAREHOUSE_LIST_SUCCESS,
  WAREHOUSE_LIST_FAILURE,
  WAREHOUSE_DELETE_REQUEST,
  WAREHOUSE_DELETE_SUCCESS,
  WAREHOUSE_DELETE_FAILURE,
  CREATE_WAREHOUSE_REQUEST,
  CREATE_WAREHOUSE_SUCCESS,
  CREATE_WAREHOUSE_FAILURE,
  WAREHOUSE_UPDATE_REQUEST,
  WAREHOUSE_UPDATE_SUCCESS,
  WAREHOUSE_UPDATE_FAILURE,
  EACH_WAREHOUSE_DETAIL_REQUEST,
  EACH_WAREHOUSE_DETAIL_SUCCESS,
  EACH_WAREHOUSE_DETAIL_FAILURE,
  SEARCH_WAREHOUSE_LIST_REQUEST,
  SEARCH_WAREHOUSE_LIST_SUCCESS,
  SEARCH_WAREHOUSE_LIST_FAILURE,
} from "../type.js";

export const warehouseListReducer = (state = { warehouseList: {} }, action) => {
  switch (action.type) {
    case WAREHOUSE_LIST_REQUEST:
      return { loading: true, ...state };
    case WAREHOUSE_LIST_SUCCESS:
      return { loading: false, warehouseList: action.payload };
    case WAREHOUSE_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteWarehouseReducer = (state = {}, action) => {
  switch (action.type) {
    case WAREHOUSE_DELETE_REQUEST:
      return { loading: true };
    case WAREHOUSE_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case WAREHOUSE_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createWarehouseReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_WAREHOUSE_REQUEST:
      return { createLoading: true };
    case CREATE_WAREHOUSE_SUCCESS:
      return {
        createLoading: false,
        message: action.payload,
      };
    case CREATE_WAREHOUSE_FAILURE:
      return {
        createLoading: false,
        createError: action.payload,
      };
    default:
      return state;
  }
};

export const updateWarehouseReducer = (state = {}, action) => {
  switch (action.type) {
    case WAREHOUSE_UPDATE_REQUEST:
      return { updateLoading: true };
    case WAREHOUSE_UPDATE_SUCCESS:
      return {
        updateLoading: false,
        message: action.payload,
      };
    case WAREHOUSE_UPDATE_FAILURE:
      return {
        loading: false,
        updateError: action.payload,
      };
    default:
      return state;
  }
};

export const warehouseDetailsReducers = (state = { warehouse: {} }, action) => {
  switch (action.type) {
    case EACH_WAREHOUSE_DETAIL_REQUEST:
      return { loading: true, ...state };
    case EACH_WAREHOUSE_DETAIL_SUCCESS:
      return { loading: false, warehouse: action.payload };
    case EACH_WAREHOUSE_DETAIL_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchWarehouseListReducer = (
  state = { searchWarehouse: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_WAREHOUSE_LIST_REQUEST:
      return { searchLoading: true, ...state };
    case SEARCH_WAREHOUSE_LIST_SUCCESS:
      return { searchLoading: false, searchWarehouse: action.payload };
    case SEARCH_WAREHOUSE_LIST_FAILURE:
      return { searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};
