import React, { useEffect, useState } from "react";
import { Row, Col, Table, Modal, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

const AddRequestPurchaseModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      fullscreen={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto" id="contained-modal-title-vcenter">
          Add Purchase Request Preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4" style={{ overflowY: "scroll" }}>
        {/* <Row>
          <Col sm={6}>
            <p>
              Vendor Name:
              {selectedVendorName ? ` ${selectedVendorName}` : " Not Selected"}
            </p>
          </Col>
          <Col sm={6}>
            <p>
              Purchase Date:
              {props.purchaseDate ? ` ${props.purchaseDate}` : " Not Selected"}
            </p>
          </Col>
        </Row> */}
        <div
          style={{
            fontSize: "16px",
            fontWeight: "600",
            marginBottom: "8px",
          }}
        >
          Product List
        </div>

        <Table
          //responsive
          bordered
          hover
          style={{ fontSize: "12px" }}
        >
          <thead className="text-center table-header">
            <tr>
              <th>SL No.</th>
              <th>Name</th>
              <th>Quantity</th>
              <th>Unit of Measurement</th>
              <th>Justifications</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {props.productRequestResponse.productRequestList.data !==
              undefined &&
            props.productRequestResponse.productRequestList?.data.results
              .length ? (
              <>
                {props.productRequestResponse.productRequestList.data.results.map(
                  (item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.product_name}</td>
                      <td>{item.quantity}</td>
                      <td>{item.unit_or_size}</td>
                      <td>{item.justification}</td>
                    </tr>
                  )
                )}
                <tr className="fw-bold">
                  <td>Total</td>
                  <td></td>
                  <td>
                    {props.productRequestResponse.productRequestList.data.results.reduce(
                      (previousResult, currentValue) => {
                        // console.log(
                        //   "currentValue.quantity =",
                        //   currentValue.quantity
                        // );
                        return (previousResult += parseInt(
                          currentValue.quantity
                        ));
                      },
                      0
                    )}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </>
            ) : props.productRequestResponse.loading === true ? (
              <tr>
                <td colSpan="12">
                  <Spinner animation="border" variant="warning" />
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan="12">No Result Found</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Row className="mt-5 d-flex justify-content-center">
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button className="cancel-btn border-0" onClick={props.onHide}>
              Go Back
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AddRequestPurchaseModal;
