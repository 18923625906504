import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Navbar,
  Nav,
  Dropdown,
  ButtonGroup,
  NavDropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { SidebarAction } from "../../Redux/actions/sidebarActions";
import { logout } from "../../Redux/actions/userActions";
import userIcon from "../../Assets/icons/user.png";
import "./Header.css";
import NavTimer from "./NavTimer";
import toggleIcon from "../../Assets/icons/toggle.png";
import screenIcon from "../../Assets/icons/screen.png";
import { getUserDetails } from "../../Redux/actions/userActions";
import "../Footer/Footer.css";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const [date, setDate] = useState(new Date());
  const [fullScreen, setFullScreen] = useState(false);
  const { toggleClick } = useSelector((state) => state.sideBarState);
  const { loading, user, error } = useSelector((state) => state.userDetails);
  //console.log(error === Network Error);
  //console.log(toggleClick) !user.Name;
  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);
  //console.log(user);
  useEffect(() => {
    if (toggleClick === true) {
      document.documentElement.style.setProperty(
        "--main-content-margin",
        "0px"
      );
      document.documentElement.style.setProperty("--footer-content", "0px");
    } else {
      document.documentElement.style.setProperty(
        "--main-content-margin",
        "300px"
      );
      document.documentElement.style.setProperty("--footer-content", "300px");
    }
  }, [toggleClick]);
  const handleChnageSidebar = () => {
    if (toggleClick === true) {
      dispatch(SidebarAction(false));
      document.documentElement.style.setProperty("--footer-content", "300px");
      document.documentElement.style.setProperty(
        "--main-content-margin",
        "300px"
      );
    } else {
      dispatch(SidebarAction(true));
      document.documentElement.style.setProperty(
        "--main-content-margin",
        "0px"
      );
      document.documentElement.style.setProperty("--footer-content", "0px");
    }
  };

  const handleScreenSize = () => {
    if (fullScreen === false) {
      setFullScreen(true);
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        /* Safari */
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        /* IE11 */
        document.documentElement.msRequestFullscreen();
      }
    } else {
      setFullScreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };
  //const { toggleClick } = useSelector((state) => state.sideBarState);
  //console.log(toggleClick);
  // useEffect(() => {
  //   var timer = setInterval(() => setDate(new Date()), 1000);
  //   return function cleanup() {
  //     clearInterval(timer);
  //   };
  // }, []);
  //console.log(localStorage.getItem("menuState"));
  return (
    <>
      <Navbar className="main-content-header">
        <Container fluid className="header-container-view">
          <Navbar.Brand>
            <OverlayTrigger
              key="side-panel"
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  {!toggleClick ? "Close Side Panel" : "Open Side Panel"}
                </Tooltip>
              }
            >
              <img
                src={toggleIcon}
                alt="menuIcon"
                //height={40}
                className="brand-logo"
                onClick={handleChnageSidebar}
              />
            </OverlayTrigger>
            <OverlayTrigger
              key="screen"
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  {!fullScreen ? "Open Full Screen" : "Exit Full Screen"}
                </Tooltip>
              }
            >
              <img
                src={screenIcon}
                alt="screenIcon"
                //height={40}
                className="ms-2 ms-sm-4 brand-logo"
                onClick={handleScreenSize}
              />
            </OverlayTrigger>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Nav.Item
                className="p-2 p-md-3"
                style={{ borderRight: "2px solid #DCE0E4" }}
              >
                {" "}
                <NavTimer></NavTimer>
                {/* <Col>
                  <Row>
                    <span style={{ fontWeight: "600" }}>
                      {date.toLocaleTimeString()}
                    </span>
                  </Row>
                  <Row style={{}}>
                    <span style={{ fontWeight: "500" }}>
                      {date.toLocaleDateString()}
                    </span>{" "}
                  </Row>
                </Col> */}
              </Nav.Item>
              <Nav.Item className="p-md-2 d-flex justify-content-center align-items-center">
                {" "}
                <img
                  src={userIcon}
                  alt="user"
                  //height={65}
                  className="user-icon ps-1 ps-sm-3"
                  //style={{ paddingLeft: "1rem" }}
                />{" "}
                {/* <span
                  style={{
                    fontWeight: "600",
                    paddingLeft: "0.4rem",
                    paddingRight: "0rem",
                  }}
                >
                  {user.Name ? user.Name : "User Name"}
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle
                      split
                      className="bg-white border-0"
                      variant=""
                      id="dropdown-split-basic"
                    />

                    <Dropdown.Menu className="dropdown-menu-end">
                      <Dropdown.Item onClick={handleLogout}>
                        Log 0ut
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span> */}
              </Nav.Item>
              <Nav.Item
                className="d-flex justify-content-center align-items-center"
                //style={{ paddingRight: "2.5rem" }}
              >
                <NavDropdown
                  //id="nav-dropdown-dark-example"
                  className="nav-dropdown"
                  style={{
                    fontWeight: "600",
                    //fontSize: "20px",
                    //color: "#212529",
                    //paddingLeft: "0.4rem",
                    //paddingRight: "0rem",
                  }}
                  title={user && user.Name ? user.Name : "User Name"}
                  //menuVariant="dark"
                >
                  <NavDropdown.Item
                    onClick={handleLogout}
                    className="dropdown-item"
                  >
                    Log Out
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
