import {
  ALL_SUPPLIER_WISE_STOCK_REQUEST,
  ALL_SUPPLIER_WISE_STOCK_SUCCESS,
  ALL_SUPPLIER_WISE_STOCK_FAILURE,
  SUPPLIER_WISE_STOCK_REQUEST,
  SUPPLIER_WISE_STOCK_SUCCESS,
  SUPPLIER_WISE_STOCK_FAILURE,
  SUBCATEGORY_STOCK_REPORT_REQUEST,
  SUBCATEGORY_STOCK_REPORT_SUCCESS,
  SUBCATEGORY_STOCK_REPORT_FAILURE,
  CATEGORY_STOCK_REPORT_REQUEST,
  CATEGORY_STOCK_REPORT_SUCCESS,
  CATEGORY_STOCK_REPORT_FAILURE,
  SUB_CATEGORY_WISE_PRODUCT_REQUEST,
  SUB_CATEGORY_WISE_PRODUCT_SUCCESS,
  SUB_CATEGORY_WISE_PRODUCT_FAILURE,
} from "../type.js";
import axios from "axios";

export const getAllSupplierStockReport =
  (limit, offset, keyword) => async (dispatch) => {
    try {
      dispatch({
        type: ALL_SUPPLIER_WISE_STOCK_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/supplier/supplier-wise-stock-glance/?limit=${limit}&offset=${offset}&product_type=${keyword}`,
        config
      );

      dispatch({
        type: ALL_SUPPLIER_WISE_STOCK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_SUPPLIER_WISE_STOCK_FAILURE,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const getSupplierWiseStockReport = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SUPPLIER_WISE_STOCK_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/supplier/supplier-wise-stock${keyword}`,
      config
    );

    dispatch({
      type: SUPPLIER_WISE_STOCK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPLIER_WISE_STOCK_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSubcategoryStockReport = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SUBCATEGORY_STOCK_REPORT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/category/subCategory-stock-report${keyword}`,
      config
    );

    dispatch({
      type: SUBCATEGORY_STOCK_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUBCATEGORY_STOCK_REPORT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getCategoryStockReport = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_STOCK_REPORT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/category/category-stock-report${keyword}`,
      config
    );

    dispatch({
      type: CATEGORY_STOCK_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_STOCK_REPORT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSubcategoryWiseProduct = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SUB_CATEGORY_WISE_PRODUCT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/product/subcategory${keyword}`,
      config
    );

    dispatch({
      type: SUB_CATEGORY_WISE_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUB_CATEGORY_WISE_PRODUCT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
