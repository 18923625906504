import React, { useEffect, useState } from "react";
import { Row, Col, Table, Modal, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

const AddTransferModal = (props) => {
  const [location, setLocation] = useState({
    from: "Not Selected",
    to: "Not Selected",
  });

  const { loading, warehouseList, error } = useSelector(
    (state) => state.warehouseList
  );

  useEffect(() => {
    // console.log("props.transferFrom", props.transferFrom);

    if (props.transferFrom && warehouseList.data?.results) {
      warehouseList.data?.results.find((warehouse, index) => {
        if (warehouse.id == props.transferFrom) {
          setLocation({ ...location, from: warehouse.name });
        }
      });
    } else {
      // console.log("ypp");
      setLocation({ ...location, from: "Not Selected" });
    }
  }, [props.transferFrom, warehouseList]);

  useEffect(() => {
    // console.log("props.transferTo = ", props.transferTo);

    if (props.transferTo && warehouseList.data?.results) {
      warehouseList.data?.results.find((warehouse, index) => {
        // console.log("warehouse = ", warehouse);
        if (warehouse.id == props.transferTo) {
          setLocation({ ...location, to: warehouse.name });
        }
      });
    } else {
      setLocation({ ...location, to: "Not Selected" });
    }
  }, [props.transferTo, warehouseList]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      fullscreen={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto" id="contained-modal-title-vcenter">
          Add Transfer Preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4" style={{ overflowY: "scroll" }}>
        <Row>
          <Col sm={6}>
            <p>
              <span style={{ fontWeight: "600", fontSize: "16px" }}>
                From :{" "}
              </span>
              {location.from}
            </p>
          </Col>
          <Col sm={6}>
            <p>
              <span style={{ fontWeight: "600", fontSize: "16px" }}>To : </span>
              {location.to}
            </p>
          </Col>
        </Row>

        <div
          style={{
            fontSize: "16px",
            fontWeight: "600",
            marginBottom: "8px",
          }}
        >
          Product List
        </div>

        <Table
          //responsive
          bordered
          hover
          style={{ fontSize: "12px" }}
        >
          <thead className="text-center table-header">
            <tr>
              <th>SL</th>
              <th>Barcode</th>
              <th>Product Information</th>
              <th>Batch No.</th>
              <th>Total Stock</th>
              <th>Warehouse Stock</th>
              <th>Retail Stock</th>
              <th>Transfer Qty</th>
              <th>MRP</th>
              <th>Vat in %</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {props.transferList.length > 0 && !props.productLoading ? (
              <>
                {props.transferList.map((product, i) => (
                  <tr key={product.id}>
                    <td>{i + 1}</td>
                    <td>{product.barcode}</td>
                    <td>
                      {product.productName} {product.unitSize}
                    </td>
                    <td>{product.batch_name}</td>
                    <td>{product.totalStock}</td>
                    <td>{product.destinationStock}</td>
                    <td>{product.retailStock}</td>
                    <td>{product.transfer_quantity}</td>
                    <td>{product.salesPrice}</td>
                    <td>{product.vat}%</td>
                    <td>
                      {(
                        (product.salesPrice * (product.vat / 100) +
                          product.salesPrice) *
                        product.transfer_quantity
                      ).toFixed(2)}
                    </td>
                  </tr>
                ))}
                <tr className="fw-bold">
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {props.transferList.reduce(
                      (previousResult, currentValue) => {
                        // console.log(
                        //   "currentValue = ",
                        //   currentValue.destinationStock
                        // );

                        return (previousResult +=
                          parseInt(currentValue.destinationStock) +
                          parseInt(currentValue.retailStock));
                      },
                      0
                    )}
                  </td>
                  <td>
                    {props.transferList.reduce(
                      (previousResult, currentValue) => {
                        // console.log(
                        //   "currentValue = ",
                        //   currentValue.destinationStock
                        // );

                        return (previousResult += parseInt(
                          currentValue.destinationStock
                        ));
                      },
                      0
                    )}
                  </td>
                  <td>
                    {props.transferList.reduce(
                      (previousResult, currentValue) => {
                        // console.log(
                        //   "currentValue = ",
                        //   currentValue.retailStock
                        // );

                        return (previousResult += parseInt(
                          currentValue.retailStock
                        ));
                      },
                      0
                    )}
                  </td>
                  <td>
                    {props.transferList.reduce(
                      (previousResult, currentValue) => {
                        // console.log(
                        //   "currentValue = ",
                        //   currentValue.transfer_quantity
                        // );

                        return (previousResult += parseInt(
                          currentValue.transfer_quantity
                        ));
                      },
                      0
                    )}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </>
            ) : props.productLoading ? (
              <tr>
                <td colSpan="12">
                  <Spinner animation="border" variant="warning" />
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan="12">No Product Added</td>
              </tr>
            )}
          </tbody>
        </Table>

        <Table
          //responsive
          bordered
          hover
          style={{ fontSize: "12px" }}
        >
          <thead>
            <tr>
              <th>Total Amount</th>
              <th>Shipping Cost</th>
              <th>Grand Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.totalAmount}</td>
              <td>{props.shippingCost}</td>
              <td>{props.netReturnValue}</td>
            </tr>
          </tbody>
        </Table>

        <Row className="mt-5 d-flex justify-content-center">
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button className="cancel-btn border-0" onClick={props.onHide}>
              Go Back
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AddTransferModal;
