import { Row, Modal, Spinner } from "react-bootstrap";

const LoadingModal = (props) => {
  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        show={props.show}
        onHide={props.onHide}
      >
        {/**<Modal.Header className="modal-customize-header" closeButton></Modal.Header> */}
        <Modal.Body>
          {props.show === true ? (
            <Row className="mt-2 d-flex justify-content-center">
              <Spinner animation="border" variant="warning" />
              <p className="mt-3 text-center">
                <b>Data is Dowloading.Please Wait for a moment......</b>
              </p>
            </Row>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoadingModal;
