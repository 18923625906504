import jsPDF from "jspdf";
import "jspdf-autotable";

const addFooters = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
  }
};

const PurchaseListPdf = (data, dataType, columnData, pdfTitle, totalItems) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });
  const date = new Date();
  const tableRows = [];
  data.forEach((each) => {
    const rowData = [
      each.id,
      each.vendor_name,
      each.reference,
      each.purchase_date,
      each.total_purchase_value,
      each.additional_commission,
      each.total_vat,
      each.net_purchase_value,
      each.createdby_name[0].created_by__Name,
    ];
    // push each tickcet's info into a row
    tableRows.push(rowData);
    const extraTableHeader = [
      {
        content: "Product Name",
        styles: {
          textColor: "black",
          fillColor: "#fafbfe",
          valign: "middle",
          halign: "center",
        },
      },
      {
        content: "Qty",
        styles: {
          textColor: "black",
          fillColor: "#fafbfe",
          valign: "middle",
          halign: "center",
        },
      },
      {
        content: "CPU",
        styles: {
          textColor: "black",
          fillColor: "#fafbfe",
          valign: "middle",
          halign: "center",
        },
      },
      {
        content: "New CPU",
        styles: {
          textColor: "black",
          fillColor: "#fafbfe",
          valign: "middle",
          halign: "center",
        },
      },
      {
        content: "MRP",
        styles: {
          textColor: "black",
          fillColor: "#fafbfe",
          valign: "middle",
          halign: "center",
        },
      },
      {
        content: "New MRP",
        styles: {
          textColor: "black",
          fillColor: "#fafbfe",
          valign: "middle",
          halign: "center",
        },
      },
      {
        content: "Subtotal",
        styles: {
          textColor: "black",
          fillColor: "#fafbfe",
          valign: "middle",
          halign: "center",
        },
      },
      {
        content: "Batch No.",
        styles: {
          textColor: "black",
          fillColor: "#fafbfe",
          valign: "middle",
          halign: "center",
        },
      },
      {
        content: "Supplier Name",
        styles: {
          textColor: "black",
          fillColor: "#fafbfe",
          valign: "middle",
          halign: "center",
        },
      },
    ];
    tableRows.push(extraTableHeader);
    each.purchase.forEach((p) => {
      const extraRowData = [
        p.product_name,
        p.quantity_amount,
        p.purchase_price,
        p.new_purchase_price,
        p.sales_price,
        p.new_sales_price,
        !p.subtotal ? "N/A" : p.subtotal,
        !p.product__barcode ? "N/A" : p.product__barcode,
        p.batch_no,
        p.supplier_name,
      ];
      tableRows.push(extraRowData);
    });
  });
  //     i + 1,
  //     item.product__barcode,
  //     item.product_name,
  //     item.product__unit,
  //     item.supplier_name,
  //     item.batch_no,
  //     item.quantity_amount,
  //     item.new_purchase_price !== 0 && item.new_purchase_price !== ""
  //       ? item.new_purchase_price
  //       : item.purchase_price,
  //     item.new_sales_price !== 0 && item.new_sales_price !== ""
  //       ? item.new_sales_price
  //       : item.sales_price,
  //     item.gross_profit,
  //     item.new_purchase_price !== 0 && item.new_purchase_price !== ""
  //       ? item.subtotal - item.new_purchase_price * item.quantity_amount
  //       : item.subtotal - item.purchase_price * item.quantity_amount,
  //     item.subtotal,
  //   ]);

  var title = "Care-Box";
  var reportName = "Challan/Purchase List";
  var titleWidth = doc.getTextDimensions(title).w;
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  var x = (pageWidth - titleWidth) / 2;
  doc.text(title, x, 14);
  doc.text(
    reportName,
    (pageWidth - doc.getTextDimensions(reportName).w) / 2,
    20
  );
  doc.autoTable({
    //styles: { fillColor: [0, 0, 0] },
    startY: 30,
    columns: columnData,
    body: tableRows,
    theme: "plain",
    styles: {
      halign: "center",
      lineColor: "DCE0E4",
      lineWidth: 0.2,
    },
    headStyles: {
      textColor: "black",
      fillColor: "#fafbfe",
    },
  });

  addFooters(doc);
  doc.save("Care-Box-Purchase-List" + "_" + date.toLocaleTimeString());
};

export default PurchaseListPdf;
