import React from "react";

function VoidReasonComment({
  voidReasonDescription,
  setVoidReasonDescription,
}) {
  return (
    <textarea
      className="void-reasons-comment"
      placeholder="Write Reasons"
      value={voidReasonDescription}
      onChange={(e) => setVoidReasonDescription(e.target.value)}
      // disabled={isChecked ? false : true}
    ></textarea>
  );
}

export default VoidReasonComment;
