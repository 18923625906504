import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Modal, Button, Col } from "react-bootstrap";
import {
  updateDuePayment,
  getDuePaymentList,
} from "../../Redux/actions/duePaymentActions";
import { SuccessAlert } from "../notify/notify";

const DueUpdateModal = (props) => {
  const dispatch = useDispatch();
  const [updateCategoryName, setUpdateCategoryName] = useState("");
  const [updateLocation, setUpdateLocation] = useState("");
  const [updateMsg, setUpdateMsg] = useState(false);

  const { loading, message, error } = useSelector(
    (state) => state.updateDuePayment
  );

  useEffect(() => {
    if (message !== undefined && updateMsg === true) {
      //console.log("inside", deleteUserResponse.message);
      SuccessAlert("Invoice Payment Status Updated Successfully");
      dispatch(getDuePaymentList(20, 0));
      setUpdateMsg(false);
    }
    if (error !== undefined && updateMsg === true) {
      setUpdateMsg(false);
      alert(error.detail);
    }
  }, [message, error, updateMsg]);

  const handleUpdate = () => {
    if (props.selectedOrder) {
      dispatch(updateDuePayment(props.selectedOrder.id));
      setUpdateMsg(true);
      props.onHide();
      props.action();
      //console.log(message);
    } else {
      props.onHide();
    }
  };
  //console.log(props);
  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Body className="p-4">
          <Row className="d-flex justify-content-center">
            <p className="text-center">
              Update The Payment Status of{" "}
              <b>Invoice ID: {props.selectedOrder.id} !!</b>
            </p>
            <span
              className="text-center"
              style={{ fontSize: "14px", fontWeight: "bold" }}
            >
              ** Product Payment Status Will be Updated as Paid **
            </span>
          </Row>
          <Row
            className="d-flex justify-content-center"
            style={{ fontWeight: "bold" }}
          >
            Are you sure??
          </Row>
          <Row className="mt-3 d-flex justify-content-center">
            <Col xl={4} lg={6} className="mb-2 mb-lg-0">
              <Button
                className="cancel-btn border-0"
                onClick={props.onHide}
                style={{ height: "45px" }}
              >
                Cancel
              </Button>
            </Col>
            <Col xl={4} lg={6} className="mb-2 mb-lg-0">
              <Button
                className="submit-btn border-0"
                onClick={handleUpdate}
                style={{ height: "45px" }}
              >
                Update
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DueUpdateModal;
