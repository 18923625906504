import {
  PURCHASE_LIST_REQUEST,
  PURCHASE_LIST_SUCCESS,
  PURCHASE_LIST_FAILURE,
  PURCHASE_DELETE_REQUEST,
  PURCHASE_DELETE_SUCCESS,
  PURCHASE_DELETE_FAILURE,
  PURCHASED_ITEM_DELETE_REQUEST,
  PURCHASED_ITEM_DELETE_SUCCESS,
  PURCHASED_ITEM_DELETE_FAILURE,
  SEARCH_PURCHASE_LIST_REQUEST,
  SEARCH_PURCHASE_LIST_SUCCESS,
  SEARCH_PURCHASE_LIST_FAILURE,
  CREATE_PURCHASE_REQUEST,
  CREATE_PURCHASE_SUCCESS,
  CREATE_PURCHASE_FAILURE,
  PURCHASE_REQUEST_LIST_REQUEST,
  PURCHASE_REQUEST_LIST_SUCCESS,
  PURCHASE_REQUEST_LIST_FAILURE,
  SEARCH_PURCHASE_REQUEST_LIST_REQUEST,
  SEARCH_PURCHASE_REQUEST_LIST_SUCCESS,
  SEARCH_PURCHASE_REQUEST_LIST_FAILURE,
  CREATE_PURCHASE_REQUEST_REQUEST,
  CREATE_PURCHASE_REQUEST_SUCCESS,
  CREATE_PURCHASE_REQUEST_FAILURE,
  PURCHASE_REQUEST_DELETE_REQUEST,
  PURCHASE_REQUEST_DELETE_SUCCESS,
  PURCHASE_REQUEST_DELETE_FAILURE,
  PURCHASE_CREATE_PRODUCT_REQUEST,
  PURCHASE_CREATE_PRODUCT_SUCCESS,
  PURCHASE_CREATE_PRODUCT_FAILURE,
  PURCHASE_CREATE_PRODUCT_LIST_REQUEST,
  PURCHASE_CREATE_PRODUCT_LIST_SUCCESS,
  PURCHASE_CREATE_PRODUCT_LIST_FAILURE,
  PURCHASE_PRODUCT_DELETE_REQUEST,
  PURCHASE_PRODUCT_DELETE_SUCCESS,
  PURCHASE_PRODUCT_DELETE_FAILURE,
  EACH_PURCHASE_DETAIL_REQUEST,
  EACH_PURCHASE_DETAIL_SUCCESS,
  EACH_PURCHASE_DETAIL_FAILURE,
  UPDATE_PURCHASE_REQUEST,
  UPDATE_PURCHASE_SUCCESS,
  UPDATE_PURCHASE_FAILURE,
} from "../type.js";

export const purchaseListReducer = (state = { purchaseList: {} }, action) => {
  switch (action.type) {
    case PURCHASE_LIST_REQUEST:
      return { loading: true, ...state };
    case PURCHASE_LIST_SUCCESS:
      return { loading: false, purchaseList: action.payload };
    case PURCHASE_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deletePurchaseReducer = (state = {}, action) => {
  switch (action.type) {
    case PURCHASE_DELETE_REQUEST:
      return { loading: true };
    case PURCHASE_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case PURCHASE_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deletePurchaseItemReducer = (state = {}, action) => {
  switch (action.type) {
    case PURCHASED_ITEM_DELETE_REQUEST:
      return { loading: true };
    case PURCHASED_ITEM_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case PURCHASED_ITEM_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const searchPurchaseListReducer = (
  state = { searchPurchase: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_PURCHASE_LIST_REQUEST:
      return { searchLoading: true, ...state };
    case SEARCH_PURCHASE_LIST_SUCCESS:
      return { searchLoading: false, searchPurchase: action.payload };
    case SEARCH_PURCHASE_LIST_FAILURE:
      return { searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};

export const createPurchaseReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PURCHASE_REQUEST:
      return { loading: true };
    case CREATE_PURCHASE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case CREATE_PURCHASE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const purchaseDetailsReducers = (
  state = { purchaseDetails: {} },
  action
) => {
  switch (action.type) {
    case EACH_PURCHASE_DETAIL_REQUEST:
      return { ...state,loading: true };
    case EACH_PURCHASE_DETAIL_SUCCESS:
      return { ...state,loading: false, purchaseDetails: action.payload };
    case EACH_PURCHASE_DETAIL_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/** Purchare Request Related Reducers */

export const purchaseRequestListReducer = (
  state = { purchaseRequestList: {} },
  action
) => {
  switch (action.type) {
    case PURCHASE_REQUEST_LIST_REQUEST:
      return { loading: true, ...state };
    case PURCHASE_REQUEST_LIST_SUCCESS:
      return { loading: false, purchaseRequestList: action.payload };
    case PURCHASE_REQUEST_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchPurchaseRequestListReducer = (
  state = { searchPurchaseRequestList: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_PURCHASE_REQUEST_LIST_REQUEST:
      return { searchLoading: true, ...state };
    case SEARCH_PURCHASE_REQUEST_LIST_SUCCESS:
      return {
        searchLoading: false,
        searchPurchaseRequestList: action.payload,
      };
    case SEARCH_PURCHASE_REQUEST_LIST_FAILURE:
      return { searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};

export const createPurchaseRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PURCHASE_REQUEST_REQUEST:
      return { loading: true };
    case CREATE_PURCHASE_REQUEST_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case CREATE_PURCHASE_REQUEST_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deletePurchaseRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case PURCHASE_REQUEST_DELETE_REQUEST:
      return { loading: true };
    case PURCHASE_REQUEST_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case PURCHASE_REQUEST_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createProductRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case PURCHASE_CREATE_PRODUCT_REQUEST:
      return { loading: true };
    case PURCHASE_CREATE_PRODUCT_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case PURCHASE_CREATE_PRODUCT_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const productRequestListReducer = (
  state = { productRequestList: {} },
  action
) => {
  switch (action.type) {
    case PURCHASE_CREATE_PRODUCT_LIST_REQUEST:
      return { loading: true, ...state };
    case PURCHASE_CREATE_PRODUCT_LIST_SUCCESS:
      return { loading: false, productRequestList: action.payload };
    case PURCHASE_CREATE_PRODUCT_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteRequestProductReducer = (state = {}, action) => {
  switch (action.type) {
    case PURCHASE_PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case PURCHASE_PRODUCT_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case PURCHASE_PRODUCT_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updatePurchaseRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PURCHASE_REQUEST:
      return { updateLoading: true };
    case UPDATE_PURCHASE_SUCCESS:
      return {
        updateLoading: false,
        updateMessage: action.payload,
      };
    case UPDATE_PURCHASE_FAILURE:
      return {
        updateLoading: false,
        updateError: action.payload,
      };
    default:
      return state;
  }
};
