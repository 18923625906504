import React from "react";
import "./SingleVoidReason.css";

function SingleVoidReason({ reason, voidReasonOption, setVoidReasonOption }) {
  let isChecked = false;

  if (voidReasonOption.length) {
    let result = voidReasonOption.find((item) => item.id === reason.id);
    if (result) {
      isChecked = result;
    }
  }

  const onChangeHandler = () => {
    if (isChecked) {
      // setVoidReasonOption([...voidReasonOption, { ...reason }]);
      setVoidReasonOption(
        voidReasonOption.filter((item) => item.id !== reason.id)
      );
    } else {
      setVoidReasonOption([...voidReasonOption, { ...reason }]);
    }
  };

  return (
    <div className="col">
      <div className="single-void-reason-container">
        <input
          type="checkbox"
          className="single-void-reason-checkbox"
          checked={isChecked}
          onChange={onChangeHandler}
        />
        <div className="single-void-reason-content">{reason.value}</div>
      </div>
    </div>
  );
}

export default SingleVoidReason;
