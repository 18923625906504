import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ProductForm from "./ProductForm";
import "./Product.css";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { createProduct } from "../../Redux/actions/productActions";
import { ToastContainer } from "react-toastify";
import { SuccessAlert } from "../notify/notify";

const AddProduct = () => {
  const dispatch = useDispatch();
  const { loading, message, error } = useSelector(
    (state) => state.createProduct
  );
  const [productName, setProductName] = useState("");
  const [genericName, setGenericName] = useState("");
  const [subcategoryName1, setSubcategoryName1] = useState("");
  const [subcategoryName2, setSubcategoryName2] = useState("");
  const [subcategoryName3, setSubcategoryName3] = useState("");
  const [sizeUnits, setSizeUnits] = useState("");
  const [quantityType, setQuantityType] = useState("");
  const [quantity, setQuantity] = useState("");
  const [supplier, setSupplier] = useState("");
  const [barcode, setBarcode] = useState("");
  const [description, setDescription] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [salesPrice, setSalesPrice] = useState("");
  const [newSalesPrice, setNewSalesPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [purchaseVat, setPurchaseVat] = useState("");
  const [saleVat, setSaleVat] = useState("");
  const [productType, setProductType] = useState("");
  const [promotionBrand, setPromotionBrand] = useState("");
  const [productLocation, setProductLocation] = useState("");
  const [requiredPres, setRequiredPres] = useState("");
  const [productImage, setProductImage] = useState(null);
  const [promotion, setPromotion] = useState("");
  const [promotion1, setPromotion1] = useState("");
  const [appPromotion, setAppPromotion] = useState("");
  const [packSize, setPackSize] = useState(1);
  const [searchTag, setSearchTag] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);

  useEffect(() => {
    if (alertMessage && message === undefined && error !== undefined) {
      if (error.barcode) {
        alert(error.barcode);
        setAlertMessage(false);
      } else if (error.product_image) {
        alert(error.product_image);
        setAlertMessage(false);
      } else if (error.sale_price) {
        alert(`Sales Price: ${error.sale_price}`);
        setAlertMessage(false);
      } else if (error.purchase_price) {
        alert(`Purchase Price: ${error.purchase_price}`);
        setAlertMessage(false);
      } else if (error.new_sale_price) {
        alert(`New Sales Price: ${error.new_sale_price}`);
        setAlertMessage(false);
      } else if (error.discount) {
        alert(`Discount: ${error.discount}`);
        setAlertMessage(false);
      } else if (error.purchase_vat) {
        alert(`Purchase Vat: ${error.purchase_vat}`);
        setAlertMessage(false);
      } else if (error.sale_vat) {
        alert(`Sale Vat: ${error.sale_vat}`);
        setAlertMessage(false);
      } else if (error.warning) {
        alert(`${error.warning}`);
        setAlertMessage(false);
      } else {
        alert("Something Went Wrong");
        setAlertMessage(false);
      }
    }
    if (alertMessage && message !== undefined) {
      //console.log(message);
      SuccessAlert("New Product Created Successfully");
      setProductName("");
      setGenericName("");
      setSubcategoryName1("");
      setSubcategoryName2("");
      setSubcategoryName3("");
      setSizeUnits("");
      setQuantityType("");
      setQuantity("");
      setSupplier("");
      setBarcode("");
      setDescription("");
      setPurchasePrice("");
      setSalesPrice("");
      setNewSalesPrice("");
      setDiscountPrice("");
      setPurchaseVat("");
      setSaleVat("");
      setProductType("");
      setPromotionBrand("");
      setProductLocation("");
      setRequiredPres("");
      setPromotion("");
      setPromotion1("");
      setAppPromotion("");
      setSearchTag("");
      setPackSize(1);
      setProductImage(null);
      setAlertMessage(false);
    }
  }, [alertMessage, message, error]);

  const profitValue =
    salesPrice && purchasePrice
      ? ((salesPrice - purchasePrice) / purchasePrice).toFixed(1) * 100
      : "";

  const formData = [
    { key: "product_name", value: productName },
    { key: "generic_name", value: genericName },
    { key: "unit_size", value: sizeUnits },
    { key: "sub_catagory_1", value: subcategoryName1 },
    { key: "sub_catagory_2", value: subcategoryName2 },
    { key: "sub_catagory_3", value: subcategoryName3 },
    { key: "supplier", value: supplier },
    { key: "barcode", value: barcode },
    { key: "description", value: description },
    { key: "discount", value: discountPrice },
    { key: "purchase_vat", value: purchaseVat },
    { key: "sale_vat", value: saleVat },
    { key: "product_type", value: productType },
    { key: "brand_promotion", value: promotionBrand },
    { key: "promotion", value: promotion },
    { key: "promotion1", value: promotion1 },
    { key: "app_promotion", value: appPromotion },
    { key: "require_prescription", value: requiredPres },
    { key: "product_image", value: productImage },
    { key: "profit", value: profitValue },
    { key: "location", value: productLocation },
    { key: "product_search_tag", value: searchTag },
    { key: "pack_size", value: packSize ? packSize : 0 },
  ];

  //console.log(formData);

  const handleSubmitForm = (e) => {
    if (productImage === null) {
      e.preventDefault();
      alert("Please Upload Product Image");
    } else if (!productImage.type.includes("image")) {
      e.preventDefault();
      alert("Please Upload JPG/PNG/JPEG media type as Product Image");
    } else if (profitValue <= 0 && profitValue !== "") {
      e.preventDefault();
      alert("Sales Price Can not be less or equal than Purchase Price");
    } else {
      e.preventDefault();
      let form_data = new FormData();
      let post = formData.filter((data) => data.value !== "");
      post.map((data) => form_data.append(data.key, data.value));
      //console.log(post);
      //form_data.append("product_name", productName);
      //form_data.append("generic_name", genericName);
      //form_data.append("sub_catagory_1", subcategoryName1);
      //form_data.append("unit_size", sizeUnits);
      //form_data.append("supplier", supplier);
      //form_data.append("barcode", barcode);
      //form_data.append("description", description);
      //form_data.append("purchase_price", purchasePrice);
      //form_data.append("sale_price", salesPrice);
      //form_data.append("new_sale_price", newSalesPrice);
      //form_data.append("sub_catagory_2", subcategoryName2);
      //form_data.append("sub_catagory_3", subcategoryName3);
      //form_data.append("discount", discountPrice);
      //form_data.append("purchase_vat", purchaseVat);
      //form_data.append("sale_vat", saleVat);
      //form_data.append("product_type", productType);
      //form_data.append("brand_promotion", promotionBrand);
      //form_data.append("promotion", promotion);
      //form_data.append("promotion1", promotion1);
      //form_data.append("app_promotion", appPromotion);
      //form_data.append("require_prescription", requiredPres);
      //form_data.append("product_image", productImage);
      //form_data.append("profit", profitValue);
      // if (productLocation !== "") {
      //   form_data.append("location", productLocation);
      // }
      dispatch(createProduct(form_data));
      setAlertMessage(true);
    }
  };

  return (
    <div>
      <SideBar />
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <h4 className="product-heading container-view">Product Add</h4>
          <p className="product-paragraph container-view">
            Products <FontAwesomeIcon icon={faChevronRight} size="xs" /> Add
            Product
          </p>
          <div className="mb-4 card product-card container-view">
            <ProductForm
              loading={loading}
              productName={productName}
              setProductName={setProductName}
              genericName={genericName}
              setGenericName={setGenericName}
              subcategoryName1={subcategoryName1}
              setSubcategoryName1={setSubcategoryName1}
              subcategoryName2={subcategoryName2}
              setSubcategoryName2={setSubcategoryName2}
              subcategoryName3={subcategoryName3}
              setSubcategoryName3={setSubcategoryName3}
              sizeUnits={sizeUnits}
              setSizeUnits={setSizeUnits}
              quantityType={quantityType}
              setQuantityType={setQuantityType}
              quantity={quantity}
              setQuantity={setQuantity}
              supplier={supplier}
              setSupplier={setSupplier}
              barcode={barcode}
              setBarcode={setBarcode}
              description={description}
              setDescription={setDescription}
              purchasePrice={purchasePrice}
              setPurchasePrice={setPurchasePrice}
              salesPrice={salesPrice}
              setSalesPrice={setSalesPrice}
              newSalesPrice={newSalesPrice}
              setNewSalesPrice={setNewSalesPrice}
              discountPrice={discountPrice}
              setDiscountPrice={setDiscountPrice}
              purchaseVat={purchaseVat}
              setPurchaseVat={setPurchaseVat}
              saleVat={saleVat}
              setSaleVat={setSaleVat}
              productType={productType}
              setProductType={setProductType}
              promotionBrand={promotionBrand}
              setPromotionBrand={setPromotionBrand}
              productLocation={productLocation}
              setProductLocation={setProductLocation}
              requiredPres={requiredPres}
              setRequiredPres={setRequiredPres}
              productImage={productImage}
              setProductImage={setProductImage}
              promotion={promotion}
              setPromotion={setPromotion}
              promotion1={promotion1}
              setPromotion1={setPromotion1}
              appPromotion={appPromotion}
              setAppPromotion={setAppPromotion}
              searchTag={searchTag}
              setSearchTag={setSearchTag}
              handleSubmitForm={handleSubmitForm}
              profitValue={profitValue}
              packSize={packSize}
              setPackSize={setPackSize}
            />
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AddProduct;
