import {
  DASHBOARD_DETAIL_REQUEST,
  DASHBOARD_DETAIL_SUCCESS,
  DASHBOARD_DETAIL_FAILURE,
} from "../type.js";

export const dashboardDetailsReducers = (state = { dashboard: {} }, action) => {
  switch (action.type) {
    case DASHBOARD_DETAIL_REQUEST:
      return { loading: true, ...state };
    case DASHBOARD_DETAIL_SUCCESS:
      return { loading: false, dashboard: action.payload };
    case DASHBOARD_DETAIL_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
