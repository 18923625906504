import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Header/Header";
import SideBar from "../SideBar/Sidebar";
import Footer from "../Footer/Footer";
import pdfIcon from "../../Assets/icons/pdf.png";
import calender from "../../Assets/icons/calender.png";
import cancel from "../../Assets/icons/cancel.png";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import "./reports.css";
import { getVendorList } from "../../Redux/actions/vendorActions";
import LoadingModal from "../PopupModal/LoadingModal";
import {
  getVendorWiseRcvReport,
  getBarcodeWiseRcvReport,
} from "../../Redux/actions/receiveReportActions";
import VendorStockRcvReport from "./VendorWiseRcv/VendorStockRcvReport";
import BarcodeStockRcvReport from "./VendorWiseRcv/BarcodeStockRcvReport";
import VendorRcvReportPdf from "../../Services/VendorRcvReportPdf";
import StockReportPdf from "../../Services/stockReportPdf";

const VendorWiseRcv = () => {
  const dispatch = useDispatch();
  const [selectReport, setSelectReport] = useState("");
  const [productType, setProductType] = useState("");
  const [selectVendor, setSelectVendor] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [generateButton, setGenerateButton] = useState(false);
  const [filterDateButton, setFilterDateButton] = useState(false);
  const [exportData, setExportData] = useState(false);

  const { loading, vendorWiseRcvList, error } = useSelector(
    (state) => state.vendorWiseRcvItems
  );
  const { barcodeLoading, barcodeRcvList, barcodeError } = useSelector(
    (state) => state.barcodeStockRcvItems
  );

  const { vendorList } = useSelector((state) => state.vendorList);

  useEffect(() => {
    if (
      !vendorList.data &&
      selectReport === "Vendor Wise Stock Receipt Position Details"
    ) {
      dispatch(getVendorList(500, 0));
    }
  }, [vendorList.data, selectReport]);

  const handleGenerateReport = () => {
    if (
      selectReport === "Vendor Wise Stock Receipt Position Details" &&
      selectVendor === ""
    ) {
      alert("Please Select Vendor");
    } else if (
      selectReport === "All Vendor Wise Stock Receipt Position Details"
    ) {
      setGenerateButton(true);
      if (fromDate !== "" && toDate !== "") {
        dispatch(
          getVendorWiseRcvReport(`/?from_date=${fromDate}&to_date=${toDate}`)
        );
      } else {
        dispatch(getVendorWiseRcvReport(`/`));
      }
    } else if (
      selectReport === "Vendor Wise Stock Receipt Position Details" &&
      selectVendor !== ""
    ) {
      setGenerateButton(true);
      if (fromDate !== "" && toDate !== "") {
        dispatch(
          getVendorWiseRcvReport(
            `/?vendor_id=${selectVendor}&from_date=${fromDate}&to_date=${toDate}`
          )
        );
      } else {
        dispatch(getVendorWiseRcvReport(`/?vendor_id=${selectVendor}`));
      }
    } else if (
      selectReport === "Barcode Wise Vendor Stock Receipt Position Details"
    ) {
      if (searchProduct !== "") {
        setGenerateButton(true);
        if (fromDate !== "" && toDate !== "") {
          dispatch(
            getBarcodeWiseRcvReport(
              `/?limit=30&offset=0&barcode=${searchProduct}&from_date=${fromDate}&to_date=${toDate}`
            )
          );
        } else {
          dispatch(
            getBarcodeWiseRcvReport(
              `/?limit=30&offset=0&barcode=${searchProduct}`
            )
          );
        }
      } else {
        alert("Please Write Down Product Barcode or Name");
      }
    } else {
      setGenerateButton(false);
    }
  };

  const convertReportToPdf = () => {
    if (generateButton && selectReport !== "") {
      if (
        selectReport === "All Vendor Wise Stock Receipt Position Details" ||
        selectReport === "Vendor Wise Stock Receipt Position Details"
      ) {
        const tableData = vendorWiseRcvList.data.vendor_list.map((vendor) => ({
          vendor_name: vendor.vendor,
          purchase_details: vendorWiseRcvList.data.sub_totals.filter(
            (purchase) => purchase.vendor === vendor.vendor
          ),
          products_data: vendorWiseRcvList.data.purchase_data.filter(
            (product) => product.vendor_name === vendor.vendor
          ),
        }));
        VendorRcvReportPdf(tableData, selectReport, selectReport);
      } else if (
        selectReport === "Barcode Wise Vendor Stock Receipt Position Details"
      ) {
        var headerRows = [
          [
            "Category",
            "Product Information",
            "Purchase ID",
            "Vendor",
            "Reference",
            "Purchase Date",
            "Purchase Time",
            "CPU",
            "Total Received Qty",
            "Total Value at Cost",
            "Posted By",
          ],
        ];
        StockReportPdf(
          headerRows,
          barcodeRcvList.data.products_data.results,
          "Barcode Wise Vendor Stock Receipt Position Details",
          "Barcode Wise Vendor Stock Receipt Position Details"
        );
      }
    } else {
      alert("Please Generate Report First");
    }
  };

  //   if (selectReport !== "" && generateButton) {
  //     window.print();
  //   } else {
  //     alert("Please Generate a Report for Print");
  //   }
  // };
  // const convertReportToPdf = () => {
  //   if (generateButton && selectReport !== "") {
  //     if (selectReport === "All Vendor Wise Stock Receipt Position Details") {
  //       var headerRows = [
  //         [
  //           {
  //             content: "Supplier Name",
  //             rowSpan: 2,
  //             styles: { valign: "middle", halign: "center" },
  //           },
  //           {
  //             content: "Received",
  //             colSpan: 2,
  //             //styles: { halign: "center", fillColor: [241, 196, 15] },
  //           },
  //           {
  //             content: "Stock Return",
  //             colSpan: 3,
  //             //styles: { halign: "center", fillColor: [241, 196, 15] },
  //           },
  //           {
  //             content: "Sold",
  //             colSpan: 3,
  //             //styles: { halign: "center", fillColor: [241, 196, 15] },
  //           },
  //           {
  //             content: "Exchange/Void",
  //             colSpan: 3,
  //             //styles: { halign: "center", fillColor: [241, 196, 15] },
  //           },
  //           {
  //             content: "Balance",
  //             colSpan: 3,
  //             //styles: { halign: "center", fillColor: [241, 196, 15] },
  //           },
  //         ],
  //         [
  //           "Qty",
  //           "Value(Cost)",
  //           "Qty",
  //           "Value(Cost)",
  //           "Return Value",
  //           "Qty",
  //           "Value(Cost)",
  //           "Sale Value",
  //           "Qty",
  //           "Value(Cost)",
  //           "Sale Value",
  //           "Qty",
  //           "Value(Cost)",
  //           "Sale Value",
  //         ],
  //       ];
  //       if (
  //         allSupplierStockList.data.results.length ===
  //         allSupplierStockList.data.count
  //       ) {
  //         StockReportPdf(
  //           headerRows,
  //           allSupplierStockList.data.results,
  //           "All Vendor Wise Stock Receipt Position Details",
  //           "All_Supplier_Wise_Stock_Summary_Report"
  //         );
  //       } else if (
  //         allSupplierStockList.data.results.length !==
  //           allSupplierStockList.data.count &&
  //         allProductList.length === allSupplierStockList.data.count
  //       ) {
  //         StockReportPdf(
  //           headerRows,
  //           allProductList,
  //           "All Vendor Wise Stock Receipt Position Details",
  //           "All_Supplier_Wise_Stock_Summary_Report"
  //         );
  //       } else {
  //         setExportData(true);
  //         getDataFromApi(
  //           `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/supplier/supplier-wise-stock-glance/?limit=${allSupplierStockList.data.count}&offset=0&product_type=${productType}`
  //         );
  //       }
  //     } else if (
  //       selectReport === "Vendor Wise Stock Receipt Position Details"
  //     ) {
  //       var headerRows = [
  //         [
  //           "Barcode",
  //           "Category",
  //           "Supplier",
  //           "Product",
  //           "CPU",
  //           "RPU",
  //           "Pft(%)",
  //           "Total Received",
  //           "Sold to Customer",
  //           "Product Exchange",
  //           "Stock Return",
  //           "Balance Qty",
  //           "Balance Value at Sale",
  //         ],
  //       ];
  //       StockReportPdf(
  //         headerRows,
  //         supplierWiseStock.data.results,
  //         "Vendor Wise Stock Receipt Position Details",
  //         "Supplier_wise_stock_position"
  //       );
  //     } else if (selectReport === "Category/Sub Category wise stock Summary") {
  //       if (selectGroupType === "category") {
  //         var headerRows = [
  //           [
  //             "Category Name",
  //             "Location",
  //             "Total Received Qty",
  //             "Stock Return",
  //             "Total Sold",
  //             "Exchange/Void",
  //             "Balance Stock",
  //             "Total Balance (Cost)",
  //             "Total Balance (Sale Price)",
  //           ],
  //         ];
  //         StockReportPdf(
  //           headerRows,
  //           categoryStockList.data.results,
  //           "Category wise stock Summary",
  //           "Category_wise_stock_Summary"
  //         );
  //       } else if (selectGroupType === "sub_category") {
  //         var headerRows = [
  //           [
  //             "Sub Category",
  //             "Category",
  //             "Total Received Qty",
  //             "Stock Return",
  //             "Total Sold",
  //             "Exchange/Void",
  //             "Balance Stock",
  //             "Total Balance (Cost)",
  //             "Total Balance (Sale Price)",
  //           ],
  //         ];
  //         if (
  //           subcategoryStockList.data.results.length ===
  //           subcategoryStockList.data.count
  //         ) {
  //           StockReportPdf(
  //             headerRows,
  //             subcategoryStockList.data.results,
  //             "Sub Category wise stock Summary",
  //             "SubCategory_wise_stock_Summary"
  //           );
  //         } else if (
  //           subcategoryStockList.data.results.length !==
  //             subcategoryStockList.data.count &&
  //           allProductList.length === subcategoryStockList.data.count
  //         ) {
  //           StockReportPdf(
  //             headerRows,
  //             allProductList,
  //             "Sub Category wise stock Summary",
  //             "SubCategory_wise_stock_Summary"
  //           );
  //         } else {
  //           setExportData(true);
  //           if (fromDate !== "" && toDate !== "") {
  //             getDataFromApi(
  //               `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/category/subCategory-stock-report/?limit=${subcategoryStockList.data.count}&offset=0&from_date=${fromDate}&to_date=${toDate}`
  //             );
  //           } else {
  //             getDataFromApi(
  //               `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/category/subCategory-stock-report/?limit=${subcategoryStockList.data.count}&offset=0`
  //             );
  //           }
  //         }
  //       }
  //     } else if (
  //       selectReport === "Sub Category wise Product Information" ||
  //       selectReport === "Purchase Number Wise Supplier Stock Receipt Position"
  //     ) {
  //       var headerRows = [
  //         [
  //           "Product ",
  //           "Sub Category",
  //           "Barcode",
  //           "Total Received(Qty)",
  //           "Return(Qty)",
  //           "Exchange(Qty)",
  //           "Total Sold(Qty)",
  //           "Balance Qty",
  //           "Cost(Tk)",
  //           "Sale(Tk)",
  //         ],
  //       ];
  //       StockReportPdf(
  //         headerRows,
  //         subcategoryWiseProductList.data.results,
  //         selectReport,
  //         selectReport
  //       );
  //     }
  //   } else {
  //     alert("Please Generate Report First");
  //   }
  // };
  //console.log(vendorWiseRcvList, generateButton);
  return (
    <div>
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <Container fluid>
          <h4 className="product-heading container-view">
            Vendor Wise Receive at a Glance
          </h4>
          <p className="product-paragraph container-view">
            Reports <FontAwesomeIcon icon={faChevronRight} size="xs" /> Vendor
            Wise Receive
          </p>
          <div className="mb-4 report-page-card card product-card container-view">
            <div className="hide-print">
              <Row className="mb-2">
                <Col xl={9}>
                  <img
                    src={filterDateButton ? cancel : calender}
                    alt="calender"
                    height={45}
                    style={{ cursor: "pointer" }}
                    onClick={() => setFilterDateButton(!filterDateButton)}
                  />
                  <select
                    name="stock-report"
                    className="select-bar"
                    onChange={(e) => {
                      setSelectReport(e.target.value);
                      setGenerateButton(false);
                      setSelectVendor("");
                      setProductType("");
                      setSearchProduct("");
                      setFromDate("");
                      setToDate("");
                    }}
                    value={selectReport}
                  >
                    <option value="">Select Report Type</option>
                    <option value="All Vendor Wise Stock Receipt Position Details">
                      All Vendor Wise Stock Receipt Position Details
                    </option>
                    <option value="Vendor Wise Stock Receipt Position Details">
                      Vendor Wise Stock Receipt Position Details
                    </option>
                    <option value="Barcode Wise Vendor Stock Receipt Position Details">
                      Barcode Wise Vendor Stock Receipt Position Details
                    </option>
                  </select>
                  {selectReport !== "" ? (
                    <>
                      {selectReport ===
                      "All Vendor Wise Stock Receipt Position Details" ? (
                        <></>
                      ) : selectReport ===
                        "Barcode Wise Vendor Stock Receipt Position Details" ? (
                        <input
                          name="stock-report"
                          className="search-product"
                          placeholder="Search With Product Barcode"
                          onChange={(e) => setSearchProduct(e.target.value)}
                          value={searchProduct}
                        />
                      ) : (
                        <select
                          name="stock-report"
                          className="select-product-type"
                          onChange={(e) => {
                            setSelectVendor(e.target.value);
                            setGenerateButton(false);
                          }}
                          value={selectVendor}
                        >
                          <option value="">Select Vendor</option>
                          {vendorList.data?.results.map((vendor) => (
                            <option key={vendor.id} value={vendor.id}>
                              {vendor.vendor_name}
                            </option>
                          ))}
                        </select>
                      )}
                      <Button
                        className="generate-btn border-0"
                        type="submit"
                        onClick={handleGenerateReport}
                      >
                        Generate Report
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xl={3} className="d-flex justify-content-end">
                  <img
                    //className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={convertReportToPdf}
                    style={{ cursor: "pointer" }}
                  />
                </Col>
              </Row>
              {filterDateButton &&
              selectReport !==
                "Barcode Wise Vendor Stock Receipt Position Details" ? (
                <Row>
                  <Col lg={2} md={4} sm={6}>
                    <Form.Group
                      className="form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="report-form-label">
                        From Date
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="From Date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={4} sm={6}>
                    <Form.Group
                      className="form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="report-form-label">
                        To Date
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="To Date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        //style={{ height: "40px" }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </div>
            <div className="">
              <Row className="mt-3">
                <h4 className="product-heading">
                  {selectReport !== ""
                    ? selectReport
                    : "Select An Option to See Vendor Wise Receive at a Glance"}
                </h4>
              </Row>
            </div>
            {selectReport ===
              "All Vendor Wise Stock Receipt Position Details" &&
            generateButton ? (
              <VendorStockRcvReport
                vendorWiseRcvList={vendorWiseRcvList}
                loading={loading}
                fromDate={fromDate}
                toDate={toDate}
              />
            ) : selectReport === "Vendor Wise Stock Receipt Position Details" &&
              generateButton ? (
              <VendorStockRcvReport
                vendorWiseRcvList={vendorWiseRcvList}
                loading={loading}
                fromDate={fromDate}
                toDate={toDate}
              />
            ) : selectReport ===
                "Barcode Wise Vendor Stock Receipt Position Details" &&
              generateButton ? (
              <BarcodeStockRcvReport
                barcodeRcvList={barcodeRcvList}
                searchProduct={searchProduct}
                loading={barcodeLoading}
                fromDate={fromDate}
                toDate={toDate}
                limit={30}
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <div className="hide-print">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default VendorWiseRcv;
