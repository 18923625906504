import React from "react";
import { useNavigate } from "react-router-dom";
import serverErrorImage from "../../Assets/under maintenance-01.png";

const ServerErrorPage = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <img
            src={serverErrorImage}
            alt="serverErrorImage"
            className="img-fluid"
            style={{ height: "80vh" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ServerErrorPage;
