import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SupplierForm from "./SupplierForm";
import "../Products/Product.css";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { SuccessAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";
import {
  getSupplierDetails,
  updateSupplier,
} from "../../Redux/actions/supplierActions";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const UpdateSupplier = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const { supplier, error } = useSelector((state) => state.supplierDetail);
  const { loading, message, updateError } = useSelector(
    (state) => state.updateSupplier
  );
  const [supplierCategory, setSupplierCategory] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [supplierAddress, setSupplierAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [supplierProductCategory, setSupplierProductCategory] = useState("");
  const [status, setStatus] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);

  useEffect(() => {
    dispatch(getSupplierDetails(param.id));
  }, [dispatch]);

  useEffect(() => {
    if (supplier && supplier.id !== undefined) {
      setSupplierCategory(supplier.category_of_Supplier);
      setSupplierName(supplier.supplier_name);
      setSupplierAddress(supplier.supplier_address);
      setContactNumber(supplier.contact_number);
      setEmailAddress(supplier.email_address);
      setContactPerson(supplier.contact_person);
      setSupplierProductCategory(
        supplier.supplier_product_category === "Non-Pharmacy"
          ? "Other"
          : supplier.supplier_product_category
      );
      setStatus(supplier.status);
    }
  }, [supplier]);

  useEffect(() => {
    if (alertMessage && message === undefined && updateError !== undefined) {
      console.log(updateError);
      if (updateError.contact_number) {
        alert(updateError.contact_number);
      } else if (updateError.supplier_name) {
        alert(updateError.supplier_name);
      } else if (updateError.error) {
        alert(updateError.error);
      } else {
        alert("Something Went Wrong");
      }
      setAlertMessage(false);
    }
    if (alertMessage && message !== undefined) {
      SuccessAlert("Supplier Updated Successfully");
      setAlertMessage(false);
      dispatch(getSupplierDetails(param.id));
    }
  }, [alertMessage, message, updateError]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    let form_data = new FormData();
    form_data.append("category_of_Supplier", supplierCategory);
    form_data.append("supplier_name", supplierName);
    form_data.append("supplier_address", supplierAddress);
    form_data.append("email_address", emailAddress);
    form_data.append("contact_person", contactPerson);
    form_data.append("supplier_product_category", supplierProductCategory);
    form_data.append("status", status);
    if (contactNumber) {
      form_data.append("contact_number", contactNumber);
    }
    //console.log(form_data);
    dispatch(updateSupplier(supplier.id, form_data));
    setAlertMessage(true);
  };

  return (
    <>
      {error && error.includes("Not found.") ? (
        <NotFoundPage></NotFoundPage>
      ) : (
        <div>
          <SideBar />
          <div className="mainContent">
            <Header></Header>
            <ToastContainer></ToastContainer>
            <Container fluid>
              <h4 className="product-heading container-view">
                Supplier Details Update
              </h4>
              <p className="product-paragraph container-view">
                Supplier <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
                Supplier List{" "}
                <FontAwesomeIcon icon={faChevronRight} size="xs" /> Update
                Supplier Details
              </p>
              <div className="mb-4 card product-card container-view">
                <SupplierForm
                  loading={loading}
                  supplierId={supplier.id ? supplier.id : ""}
                  supplierCategory={supplierCategory}
                  setSupplierCategory={setSupplierCategory}
                  supplierName={supplierName}
                  setSupplierName={setSupplierName}
                  supplierAddress={supplierAddress}
                  setSupplierAddress={setSupplierAddress}
                  contactNumber={contactNumber}
                  setContactNumber={setContactNumber}
                  emailAddress={emailAddress}
                  setEmailAddress={setEmailAddress}
                  contactPerson={contactPerson}
                  setContactPerson={setContactPerson}
                  supplierProductCategory={supplierProductCategory}
                  setSupplierProductCategory={setSupplierProductCategory}
                  status={status}
                  setStatus={setStatus}
                  handleSubmitForm={handleSubmitForm}
                />
              </div>
            </Container>
          </div>
          <Footer></Footer>
        </div>
      )}
    </>
  );
};

export default UpdateSupplier;
