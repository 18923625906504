import React from "react";
import copyrightIcon from "../../Assets/icons/copyright.png";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-content">
      <p className="footer-text text-center">
        <img
          src={copyrightIcon}
          alt="copyrightIcon"
          height={20}
          style={{ paddingRight: "6px" }}
        />
        All rights reserved by Care-Box Limited - {new Date().getFullYear()}
      </p>
    </div>
  );
};
export default Footer;
