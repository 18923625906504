import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Col,
  Container,
  Row,
  Table,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import "../Transfer/transfer.css";
import "../Return/Return.css";
import {
  getPhysicalInventoryList,
  updatePhysicalInventory,
} from "../../Redux/actions/utilitiesAction";
import { SuccessAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";

const PhysicalInventory = () => {
  const dispatch = useDispatch();
  const [showResults, setShowResults] = useState(false);
  const [searchId, setSearchId] = useState("");
  const [inventoryProduct, setInventoryProduct] = useState([]);
  const [alertMessage, setAlertMessage] = useState(false);
  const { loading, physicalInventoryList, error } = useSelector(
    (state) => state.physicalInventoryList
  );
  const { updateLoading, message, updateError } = useSelector(
    (state) => state.updatePhysicalInventory
  );
  //console.log(updateError, message);

  useEffect(() => {
    if (
      physicalInventoryList &&
      physicalInventoryList.results &&
      physicalInventoryList.results.length
    ) {
      setInventoryProduct(
        physicalInventoryList.results[0].product_list.map((product) => ({
          ...product,
          retail_mismatch: 0,
          wh_mismatch: 0,
          batch: "",
        }))
      );
    }
  }, [physicalInventoryList]);

  useEffect(() => {
    if (alertMessage && updateError !== undefined) {
      console.log(updateError);
      setAlertMessage(false);
      if (updateError.error) {
        alert(updateError.error);
      } else if (updateError.log_id) {
        alert(updateError.log_id);
      } else {
        alert("Something Went Wrong");
      }
    }
    if (alertMessage && message !== undefined) {
      //console.log(message);
      setInventoryProduct([]);
      setSearchId("");
      SuccessAlert(`Physical Inventory Updated Successfully.`);
      setAlertMessage(false);
      setShowResults(false);
    }
  }, [alertMessage, message, updateError]);

  const generateInventoryList = () => {
    if (searchId.length) {
      setShowResults(true);
      dispatch(getPhysicalInventoryList(searchId));
    } else {
      alert("Insert Physcial Inventory ID");
    }
  };

  const updateWhMismatchQty = (event, id) => {
    setInventoryProduct(
      inventoryProduct.map((product) =>
        product.id === id
          ? {
              ...product,
              wh_mismatch:
                event.target.value.length === 0
                  ? 0
                  : parseInt(event.target.value),
            }
          : product
      )
    );
  };

  const updateRetailMismatchQty = (event, id) => {
    setInventoryProduct(
      inventoryProduct.map((product) =>
        product.id === id
          ? {
              ...product,
              retail_mismatch:
                event.target.value.length === 0
                  ? 0
                  : parseInt(event.target.value),
            }
          : product
      )
    );
  };

  const updateProductBatch = (event, id) => {
    setInventoryProduct(
      inventoryProduct.map((product) =>
        product.id === id
          ? {
              ...product,
              batch: event.target.value.length === 0 ? "" : event.target.value,
            }
          : product
      )
    );
  };

  const submitPhyscialInventory = () => {
    const bodyData = inventoryProduct.map((p) => ({
      product_id: p.id,
      retail_mismatch: p.retail_mismatch,
      wh_mismatch: p.wh_mismatch,
      batch: p.batch,
    }));

    const body = {
      log_id: physicalInventoryList.results[0].id,
      inventory_product: bodyData,
    };
    if (
      bodyData.filter(
        (prod) =>
          (prod.retail_mismatch !== 0 || prod.wh_mismatch !== 0) &&
          prod.batch === ""
      ).length
    ) {
      alert("Product Batch Name is missing");
    } else {
      dispatch(
        updatePhysicalInventory(physicalInventoryList.results[0].id, body)
      );
      setAlertMessage(true);
    }

    //console.log(bodyData);
  };

  return (
    <>
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer />
        <Container fluid>
          <h4 className="product-heading container-view">Physical Inventory</h4>
          <p className="product-paragraph container-view">
            {" "}
            Utilities <FontAwesomeIcon icon={faChevronRight} size="xs" /> Add
            Physical Inventory
          </p>
          <div
            className="card container-view"
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
          >
            <Row className="mb-4 pt-3" style={{ backgroundColor: "#DCE0E4" }}>
              <Col lg={8}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Search By Physical Inventory ID"
                    value={searchId}
                    onChange={(e) => setSearchId(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Button
                  className="report-btn border-0"
                  onClick={generateInventoryList}
                >
                  Generate
                </Button>
              </Col>
            </Row>
            {showResults ? (
              <>
                <div className="mt-3">
                  {loading ? (
                    <Row className="mt-2">
                      <Col
                        xl={4}
                        lg={12}
                        className="d-flex justify-content-center"
                      >
                        <Spinner animation="border" variant="warning" />
                      </Col>
                    </Row>
                  ) : (
                    <Table
                      responsive
                      bordered
                      hover
                      style={{ fontSize: "14px" }}
                      //onKeyDown={(e) => searchInputSuggestionHendler(e)}
                    >
                      <thead className="table-header">
                        <tr>
                          <th>Sl No.</th>
                          <th>Barcode</th>
                          <th>Product Information</th>
                          <th>CPU</th>
                          <th>RPU</th>
                          <th>Batch No.</th>
                          <th>Warehouse Qty</th>
                          <th>Mismatch Qty (Warehouse)</th>
                          <th>Outlet Qty</th>
                          <th>Mismatch Qty (Outlet)</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {searchId &&
                        physicalInventoryList.results &&
                        physicalInventoryList.results.length > 0 &&
                        inventoryProduct.length ? (
                          inventoryProduct.map((product, i) => (
                            <tr key={product.product_id}>
                              <td>{i + 1}</td>
                              <td>
                                {product.barcode ? product.barcode : "N/A"}
                              </td>
                              <td>
                                {product.product_name} {product.generic_name}{" "}
                                {product.unit}
                              </td>
                              <td>{product.product_cpu}</td>
                              <td>{product.product_rpu}</td>
                              <td>
                                <input
                                  className=""
                                  type="text"
                                  value={product.batch}
                                  onChange={(event) => {
                                    updateProductBatch(event, product.id);
                                  }}
                                  style={{
                                    borderRadius: "5px",
                                    paddingLeft: "5px",
                                  }}
                                />
                              </td>
                              <td>{product.wh_stock}</td>
                              <td>
                                <input
                                  className="table-data-input-field"
                                  type="number"
                                  value={product.wh_mismatch}
                                  onChange={(event) => {
                                    updateWhMismatchQty(event, product.id);
                                  }}
                                  min={product.wh_stock === 0 ? 0 : Infinity}
                                />
                              </td>
                              <td>{product.retail_stock}</td>
                              <td>
                                <input
                                  className="table-data-input-field"
                                  type="number"
                                  value={product.retail_mismatch}
                                  onChange={(event) => {
                                    updateRetailMismatchQty(event, product.id);
                                  }}
                                  min={
                                    product.retail_stock === 0 ? 0 : Infinity
                                  }
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan="10">No Product Found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                </div>
                <div>
                  <Row className="my-5">
                    <Col xl={2} lg={6} className="mb-2">
                      {updateLoading === true ? (
                        <Button
                          className="disable-submit-btn"
                          variant="secondary"
                          disabled
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          className="submit-btn border-0"
                          onClick={submitPhyscialInventory}
                        >
                          Submit
                        </Button>
                      )}
                    </Col>
                    <Col xl={2} lg={6}>
                      <Button
                        className="cancel-btn border-0"
                        //onClick={handleCancelClick}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <div className="mt-3 d-flex justify-content-center">
                <p style={{ fontWeight: 700, fontSize: "16px" }}>
                  Insert Physical Inventory ID To Show Details
                </p>
              </div>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </>
  );
};

export default PhysicalInventory;
