import React from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";

const SupplierForm = (props) => {
  const handleCancelClick = () => {
    window.location.reload();
    //successAlert();
  };
  return (
    <Form className="p-4" onSubmit={props.handleSubmitForm}>
      <Row>
        <Col lg={4}>
          <Form.Group className=" form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Category of Supplier<span className="required-field">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={props.supplierCategory}
              onChange={(e) => props.setSupplierCategory(e.target.value)}
              required
            >
              <option value="" disabled>
                None
              </option>
              <option value="local">Local</option>
              <option value="foreign">Foreign</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Supplier Id<span></span>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Auto Generate"
              value={props.supplierId}
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Supplier Name<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Supplier Name"
              value={props.supplierName}
              onChange={(e) => props.setSupplierName(e.target.value)}
              required
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Supplier Address<span></span>{" "}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Supplier Addres"
              value={props.supplierAddress}
              onChange={(e) => props.setSupplierAddress(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Contact Number<span></span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Contact Number"
              value={props.contactNumber}
              maxLength={14}
              onChange={(e) => props.setContactNumber(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Email Address<span></span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Email Address"
              value={props.emailAddress}
              onChange={(e) => props.setEmailAddress(e.target.value)}
              //required
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Contact Person<span></span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Contact Person Name"
              value={props.contactPerson}
              onChange={(e) => props.setContactPerson(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group className=" form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Supplier Product Category{" "}
              <span className="required-field">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={props.supplierProductCategory}
              onChange={(e) => props.setSupplierProductCategory(e.target.value)}
              required
            >
              <option value="" disabled>
                Pharmacy or Other
              </option>
              <option value="Pharmacy">Pharmacy</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group className=" form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              Status<span className="required-field">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={props.status}
              onChange={(e) => props.setStatus(e.target.value)}
              required
            >
              <option value="" disabled>
                None
              </option>
              <option value="Active">Active</option>
              <option value="Inactive">In Active</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      {props.loading === true ? (
        <Row className="mt-2">
          <Col xl={4} lg={12} className="d-flex justify-content-center">
            <Spinner animation="border" variant="warning" />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row className="mt-3">
        <Col xl={2} lg={6} className="mb-2">
          {props.loading === true ? (
            <Button className="disable-submit-btn" variant="secondary" disabled>
              Submit
            </Button>
          ) : (
            <Button className="submit-btn border-0" type="submit">
              Submit
            </Button>
          )}
        </Col>
        <Col xl={2} lg={6}>
          <Button className="cancel-btn border-0" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SupplierForm;
