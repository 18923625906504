import React, { Fragment } from "react";
import { Form, Dropdown, DropdownButton, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
const ReqPurchaseTableRow = ({
  item,
  handleClick,
  isCheck,
  user,
  updatePurchaseRequestStatus,
  selected,
  setSelected,
  handleDeletePurchaseRequest,
}) => {
  return (
    <Fragment key={item.id}>
      <tr>
        <td>
          <Form.Check
            key={item.id}
            type="checkbox"
            id={item.id}
            onChange={handleClick}
            checked={isCheck.includes(item.id)}
          />
        </td>
        <td>{item.id}</td>
        <td>{item.total_items}</td>
        <td>{item.created_at.split("T")[0]}</td>
        <td>{item.requested_by.Name}</td>
        <td>
          {user && user.can_view_procurement ? (
            <DropdownButton
              id="dropdown-basic-button"
              variant={
                item.accept_status === "pending"
                  ? "outline-warning"
                  : item.accept_status === "accepted"
                  ? "outline-success"
                  : item.accept_status === "rejected"
                  ? "outline-danger"
                  : "outline-info"
              }
              size="sm"
              title={
                item.accept_status === "pending"
                  ? "Pending"
                  : item.accept_status === "accepted"
                  ? "Accepted"
                  : item.accept_status === "rejected"
                  ? "Rejected"
                  : item.accept_status
              }
              className=""
            >
              <Dropdown.Item
                // onClick={() => {
                //   handleProductStatusUpdate(
                //     item.id,
                //     item.accept_status
                //   );
                // }}
                disabled={true}
              >
                Pending
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  updatePurchaseRequestStatus(item.id, "accepted");
                }}
                disabled={item.accept_status === "accepted"}
              >
                Accept
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  updatePurchaseRequestStatus(item.id, "rejected");
                }}
                disabled={item.accept_status === "rejected"}
              >
                Reject
              </Dropdown.Item>
            </DropdownButton>
          ) : (
            <Button
              variant={
                item.accept_status === "pending"
                  ? "outline-warning"
                  : item.accept_status === "accepted"
                  ? "outline-success"
                  : item.accept_status === "rejected"
                  ? "outline-danger"
                  : "outline-info"
              }
              size="sm"
            >
              {item.accept_status.charAt(0).toUpperCase() +
                item.accept_status.slice(1)}
            </Button>
          )}
        </td>
        <td style={{ cursor: "pointer" }}>
          <FontAwesomeIcon
            icon={faEye}
            color="#637381"
            className="collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
            onClick={() => {
              setSelected(selected !== item.id ? item.id : null);
            }}
          />
          <FontAwesomeIcon
            icon={faTrashCan}
            color="#E40000"
            style={{ paddingLeft: "0.4rem", display: "none" }}
            onClick={() => handleDeletePurchaseRequest(item)}
          />
        </td>
      </tr>
      <tr
        id={selected === item.id ? "flush-collapseOne" : "collapse"}
        className={
          selected === item.id
            ? "accordion-collapse collapse show"
            : "accordion-collapse collapse"
        }
        aria-labelledby="flush-headingOne"
        data-bs-parent="#accordionFlushExample"
      >
        <td colSpan={12}>
          <Table className="inside-table mt-2" responsive>
            <thead className="text-center">
              <tr>
                <th>SL No.</th>
                <th>Product Name</th>
                <th>Product Quantity</th>
                <th>Unit of Measurement</th>
                <th>Justifications</th>
              </tr>
            </thead>
            <tbody>
              {item.product_items.map((product) => (
                <tr key={product.id}>
                  <td>{product.id}</td>
                  <td>{product.product_name}</td>
                  <td>{product.quantity}</td>
                  <td>{product.unit_or_size}</td>
                  <td>{product.justification}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </td>
      </tr>
    </Fragment>
  );
};

export default ReqPurchaseTableRow;
