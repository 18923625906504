import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  createGeneric,
  getGenericList,
} from "../../Redux/actions/genericActions";
import { SuccessAlert } from "../notify/notify";

const CreateGenericModal = (props) => {
  const dispatch = useDispatch();
  const [genericName, setGenericName] = useState("");
  const [createMsg, setCreateMsg] = useState(false);

  const { createLoading, message, createError } = useSelector(
    (state) => state.createGeneric
  );

  useEffect(() => {
    if (message !== undefined && createMsg === true) {
      //console.log("inside", deleteUserResponse.message);
      SuccessAlert("Generic Created Successfully");
      //dispatch(getGenericList(500, 0));
      setGenericName("");
      setCreateMsg(false);
    }
    if (createError !== undefined && createMsg === true) {
      setCreateMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [message, createError]);

  const createGenericHandler = (e) => {
    e.preventDefault();
    if (genericName.length) {
      const body = {
        generic_name: genericName,
        description: "",
      };
      dispatch(createGeneric(body));
      setCreateMsg(true);
      props.onHide();
    } else {
      alert("Write down a generic name");
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto" id="contained-modal-title-vcenter">
          Create Generic Name
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <b>Generic Name</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="e.g: Paracetamol"
              value={genericName}
              onChange={(e) => setGenericName(e.target.value)}
              required
            />
          </Form.Group>
        </Form>
        <Row className="mt-3 d-flex justify-content-center">
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button className="cancel-btn border-0" onClick={props.onHide}>
              Cancel
            </Button>
          </Col>
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button
              className="submit-btn border-0"
              onClick={createGenericHandler}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CreateGenericModal;
