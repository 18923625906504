import {
  SUBCATEGORY_LIST_REQUEST,
  SUBCATEGORY_LIST_SUCCESS,
  SUBCATEGORY_LIST_FAILURE,
  SUBCATEGORY_DELETE_REQUEST,
  SUBCATEGORY_DELETE_SUCCESS,
  SUBCATEGORY_DELETE_FAILURE,
  CREATE_SUBCATEGORY_REQUEST,
  CREATE_SUBCATEGORY_SUCCESS,
  CREATE_SUBCATEGORY_FAILURE,
  SUBCATEGORY_UPDATE_REQUEST,
  SUBCATEGORY_UPDATE_SUCCESS,
  SUBCATEGORY_UPDATE_FAILURE,
  SEARCH_SUBCATEGORY_LIST_REQUEST,
  SEARCH_SUBCATEGORY_LIST_SUCCESS,
  SEARCH_SUBCATEGORY_LIST_FAILURE,
} from "../type.js";
import axios from "axios";

export const getSubcategoryList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: SUBCATEGORY_LIST_REQUEST,
    });

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/ListofSubCatagoryinventoryView/?order_by=name,ascending&limit=${limit}&offset=${offset}`
    );

    dispatch({
      type: SUBCATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUBCATEGORY_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteSubcategory = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SUBCATEGORY_DELETE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/polls/delete-sub-catagory/${id}/`,
      config
    );
    dispatch({
      type: SUBCATEGORY_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUBCATEGORY_DELETE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createSubcategory = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SUBCATEGORY_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Custom-User-Agent":
          "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/polls/subcatagory_post/`,
      body,
      config
    );

    dispatch({
      type: CREATE_SUBCATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_SUBCATEGORY_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const updateSubcategory = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: SUBCATEGORY_UPDATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/polls/update-sub-catagory/${id}/`,
      body,
      config
    );

    dispatch({
      type: SUBCATEGORY_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUBCATEGORY_UPDATE_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getSearchSubcategoryList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_SUBCATEGORY_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/ListofSubCatagoryinventoryView/?search=${keyword}&ordering=-id&limit=20&offset=0`,
      config
    );

    dispatch({
      type: SEARCH_SUBCATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_SUBCATEGORY_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
