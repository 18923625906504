import jsPDF from "jspdf";
import "jspdf-autotable";

const addFooters = (doc, date, time) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text("Date:" + date, 16, doc.internal.pageSize.height - 10);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
    doc.text(
      "Time:" + time,
      doc.internal.pageSize.width - 35,
      doc.internal.pageSize.height - 10
    );
  }
};

const PhysicalInventoryPdf = (itemDetails, createDetails) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });

  const date = new Date();
  const tableRows = itemDetails.map((item, i) => [
    i + 1,
    item.product_barcode ? item.product_barcode : "N/A",
    `${item.product_names} ${item.generic_name ? item.generic_name : ""} ${
      item.unit ? item.unit : ""
    }`,
    item.cpu,
    item.rpu,
    item.wh_stock,
    "",
    item.retail_stock,
    "",
    "",
  ]);
  const columnData = [
    "SL No",
    "Barcode",
    "Product Information",
    "CPU",
    "RPU",
    "Warehouse Qty",
    "Mismatch Qty(Wh)",
    "Outlet Qty",
    "Mismatch Qty (Outlet)",
    "Batch No",
  ];
  var title = "Care-Box";
  var reportName = "Physical Inventory Product Details";
  var address = "149/A Monipuri Para,Tejgaon, Dhaka-1215, Bangladesh";
  var titleWidth = doc.getTextDimensions(title).w;
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  var x = (pageWidth - titleWidth) / 2;
  doc.text(title, x, 14);
  doc.setFontSize(8);
  doc.text(address, (pageWidth - doc.getTextDimensions(address).w) / 2, 20);
  doc.setFontSize(12);
  doc.text(
    reportName,
    (pageWidth - doc.getTextDimensions(reportName).w) / 2,
    28
  );
  doc.setFontSize(8);
  doc.text(`Physical Inventory ID: ${createDetails.log_id}`, 16, 34);
  doc.text(
    `Generated Date: ${createDetails.generated_at?.split("T")[0]}`,
    doc.internal.pageSize.width - 48,
    34
  );
  doc.setFontSize(8);
  doc.text(`Supplier Name: ${createDetails.supplier_name}`, 16, 38);
  doc.text(
    `Generated By: ${createDetails.generated_by}`,
    doc.internal.pageSize.width - 48,
    38
  );
  doc.autoTable({
    startY: 42,
    columns: columnData,
    body: tableRows,
    theme: "plain",
    styles: {
      halign: "center",
      lineColor: "DCE0E4",
      lineWidth: 0.2,
    },
    headStyles: {
      textColor: "black",
      fillColor: "#fafbfe",
    },
  });

  addFooters(doc, date.toLocaleDateString(), date.toLocaleTimeString());
  doc.save("Care-Box-Physical-Inventory-Details" + "-" + createDetails.log_id);
};

export default PhysicalInventoryPdf;
