import {
  TRANSFER_LIST_REQUEST,
  TRANSFER_LIST_SUCCESS,
  TRANSFER_LIST_FAILURE,
  CREATE_TRANSFER_REQUEST,
  CREATE_TRANSFER_SUCCESS,
  CREATE_TRANSFER_FAILURE,
  EACH_TRANSFER_DETAIL_REQUEST,
  EACH_TRANSFER_DETAIL_SUCCESS,
  EACH_TRANSFER_DETAIL_FAILURE,
  SEARCH_TRANSFER_LIST_REQUEST,
  SEARCH_TRANSFER_LIST_SUCCESS,
  SEARCH_TRANSFER_LIST_FAILURE,
  SEARCH_TRANSFER_PURCHASE_LIST_REQUEST,
  SEARCH_TRANSFER_PURCHASE_LIST_SUCCESS, 
  SEARCH_TRANSFER_PURCHASE_LIST_FAILURE,
  SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_REQUEST,
  SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_SUCCESS,
  SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_FAILURE
} from "../type.js";

export const transferListReducer = (state = { transferList: {} }, action) => {
  switch (action.type) {
    case TRANSFER_LIST_REQUEST:
      return { loading: true, ...state };
    case TRANSFER_LIST_SUCCESS:
      return { loading: false, transferList: action.payload };
    case TRANSFER_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createTransferReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_TRANSFER_REQUEST:
      return { createLoading: true };
    case CREATE_TRANSFER_SUCCESS:
      return {
        createLoading: false,
        message: action.payload,
      };
    case CREATE_TRANSFER_FAILURE:
      return {
        createLoading: false,
        createError: action.payload,
      };
    default:
      return state;
  }
};

export const transferDetailsReducers = (state = { transfer: {} }, action) => {
  switch (action.type) {
    case EACH_TRANSFER_DETAIL_REQUEST:
      return {  ...state, loading: true };
    case EACH_TRANSFER_DETAIL_SUCCESS:
      return { ...state, loading: false, transfer: action.payload };
    case EACH_TRANSFER_DETAIL_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchTransferListReducer = (
  state = { searchTransfer: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_TRANSFER_LIST_REQUEST:
      return { searchLoading: true, ...state };
    case SEARCH_TRANSFER_LIST_SUCCESS:
      return { searchLoading: false, searchTransfer: action.payload };
    case SEARCH_TRANSFER_LIST_FAILURE:
      return { searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};

export const searchTransferPurchaseListReducer = (
  state = { searchPurchaseTransfer: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_TRANSFER_PURCHASE_LIST_REQUEST:
      return { ...state , purchaseLoading: true};
    case SEARCH_TRANSFER_PURCHASE_LIST_SUCCESS:
      return { ...state , purchaseLoading: false, searchPurchaseTransfer: action.payload };
    case SEARCH_TRANSFER_PURCHASE_LIST_FAILURE:
      return { ...state , purchaseLoading: false, purchaseError: action.payload };
    default:
      return state;
  }
};

export const searchPurchaseProductListReducer = (
  state = { searchPurchaseProducts: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_REQUEST:
      return { ...state , productLoading: true};
    case SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_SUCCESS:
      return { ...state , productLoading: false, searchPurchaseProducts: action.payload };
    case SEARCH_TRANSFER_PURCHASE_PRODUCT_LIST_FAILURE:
      return { ...state , productLoading: false, productError: action.payload };
    default:
      return state;
  }
};