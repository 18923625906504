import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const PoListTableRow = ({ purchase }) => {
  const navigate = useNavigate();

  return (
    <tr key={purchase.id}>
      <td>{purchase.po_id}</td>
      <td>{purchase.supplier.supplier_name}</td>
      <td>
        {purchase.vendor.vendor_name !== ""
          ? purchase.vendor.vendor_name
          : "N/A"}
      </td>
      <td>{purchase.total_product}</td>
      <td>{purchase.total_cost}</td>
      <td>{purchase.created_at.split("T")[0]}</td>
      <td>{purchase.ordered_by.Name}</td>
      <td style={{ cursor: "pointer" }}>
        <FontAwesomeIcon
          icon={faEye}
          color="#637381"
          onClick={() => navigate(`/purchaseOrder/${purchase.id}`)}
        />
      </td>
    </tr>
  );
};

export default PoListTableRow;
