import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import DeleteModal from "../PopupModal/DeleteModal";
import LoadingModal from "../PopupModal/LoadingModal";
import UpdateCategoryModal from "../PopupModal/UpdateCategoryModal";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faAdd } from "@fortawesome/free-solid-svg-icons";
import filter from "../../Assets/icons/filter.png";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import "./Product.css";
import ReactPaginate from "react-paginate";
import { SuccessAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";
import {
  getCategoryList,
  createCategory,
  getSearchCategoryList,
} from "../../Redux/actions/categoryActions";
import axios from "axios";
import generatePDF from "../../Services/pdfGenerator";
import exportExcel from "../../Services/ExcelGenerator";
import ImportFileModal from "../PopupModal/ImportFileModal";
import CategoryTableRow from "./reusable/CategoryTableRow";

const Category = () => {
  const dispatch = useDispatch();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [alertMessage, setAlertMessage] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryLocation, setCategoryLocation] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [exportData, setExportData] = useState(false);
  const [importCategoryModal, setImportCategoryModal] = useState(false);

  const { loading, categoryList, error } = useSelector(
    (state) => state.categoryList
  );

  const { createLoading, message, createError } = useSelector(
    (state) => state.createCategory
  );

  const { searchLoading, searchCategoryList, searchError } = useSelector(
    (state) => state.searchCategoryList
  );

  useEffect(() => {
    dispatch(getCategoryList(limit, offset));
  }, [dispatch, offset]);

  useEffect(() => {
    if (categoryList?.data) {
      setTotalItems(categoryList.data.count);
    }
  }, [categoryList]);

  useEffect(() => {
    if (alertMessage && message === undefined && createError !== undefined) {
      setAlertMessage(false);
      if (createError.name) {
        alert(createError.name);
      } else if (createError.location) {
        alert(createError.location);
      } else {
        alert("Something Went Wrong");
      }
    }
    if (alertMessage && message !== undefined) {
      //console.log(message);
      SuccessAlert("New Category Created Successfully");
      dispatch(getCategoryList(limit, 0));
      setCategoryLocation("");
      setCategoryName("");
      setAlertMessage(false);
    }
  }, [alertMessage, message, createError]);

  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
  };

  const handleSearchResult = (e) => {
    if (e.target.value.length > 1) {
      setShowSearchResult(true);
      //console.log(e.target.value);
      dispatch(getSearchCategoryList(e.target.value));
    } else {
      setShowSearchResult(false);
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    let form_data = new FormData();
    form_data.append("name", categoryName);
    form_data.append("location", categoryLocation);
    dispatch(createCategory(form_data));
    setAlertMessage(true);
  };
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, parseInt(id)]);
    setSelectedItems([
      ...selectedItems,
      categoryList?.data.results.find((item) => item.id === parseInt(id)),
    ]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
      setSelectedItems(
        selectedItems.filter((item) => item.id !== parseInt(id))
      );
      setIsCheckAll(false);
    }
    //console.log(typeof parseInt(id));
    //console.log(checked);
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setSelectedItems(categoryList?.data.results);
    setIsCheck(categoryList?.data.results.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
      setSelectedItems([]);
    }
  };

  const handleDeleteCategory = (item) => {
    setSelectedCategory(item);
    setDeleteModalShow(true);
  };

  const handleUpdateCategory = (item) => {
    setUpdateModalShow(true);
    setSelectedCategory(item);
  };
  /** Export All Data */
  const getDataFromApi = (type) => {
    setExportData(true);
    try {
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const api = `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/catagoryinventory/?ordering=id&limit=${totalItems}&offset=0`;

      axios
        .get(api, settings)
        .then((res) => {
          if (type === "pdf") {
            setExportData(false);
            generatePDF(
              res.data.results,
              "Category",
              ["Category ID", "Category Name", "Location"],
              "Care-Box Category List",
              totalItems
            );
          } else if (type === "excel") {
            const exportData = [];
            res.data.results.map((each) => {
              let obj = {};
              obj["Category ID"] = each.id;
              obj["Category Name"] = each.name;
              obj["Location"] = each.location;
              exportData.push(obj);
            });
            exportExcel(exportData, "Care_Box_Category_List.xlsx");
            setExportData(false);
          }
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };
  // console.log(isCheckAll);
  // console.log(isCheck);
  // console.log(selectedItems);
  return (
    <div>
      <UpdateCategoryModal
        show={updateModalShow}
        onHide={() => setUpdateModalShow(false)}
        category={selectedCategory}
      />
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        category={selectedCategory}
        action={() => setShowSearchResult(false)}
      />
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <ImportFileModal
        show={importCategoryModal}
        onHide={() => setImportCategoryModal(false)}
        title={`Category List`}
        importType="category"
      />
      <SideBar />
      <div className="mainContent">
        <Header />
        <ToastContainer></ToastContainer>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Category</h4>
              <p className="product-paragraph">
                Products <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
                Category
              </p>
            </Col>
            <Col lg={6} className="d-flex justify-content-lg-end">
              <button
                className="pe-3 ps-3 border-0 add-product-button"
                onClick={() => setImportCategoryModal(true)}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ paddingRight: "0.2rem" }}
                />{" "}
                Import Category File
              </button>
            </Col>
          </Row>
          <div className="mb-4 p-3 card product-card container-view">
            <Row className="mb-4">
              <Col lg={6} className="mb-2 mb-lg-0">
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchResult}
                />
              </Col>
              <Col lg={6} className="d-flex justify-content-md-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as a pdf</Tooltip>}
                >
                  <img
                    className="pe-2"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => {
                      if (selectedItems.length && !exportData) {
                        generatePDF(
                          selectedItems,
                          "Category",
                          ["Category ID", "Category Name", "Location"],
                          "Care-Box Category List (Selected)",
                          selectedItems.length
                        );
                      } else {
                        getDataFromApi("pdf");
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">Export as a excel</Tooltip>
                  }
                >
                  <img
                    className=""
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => {
                      if (selectedItems.length && !exportData) {
                        const exportData = [];
                        selectedItems.map((each) => {
                          let obj = {};
                          obj["Category ID"] = each.id;
                          obj["Category Name"] = each.name;
                          obj["Location"] = each.location;
                          exportData.push(obj);
                        });
                        exportExcel(
                          exportData,
                          "Care_Box_Category_List_Selected.xlsx"
                        );
                      } else {
                        getDataFromApi("excel");
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={4} className="mb-3">
                <h3 className="category-heading">Add Products Category</h3>
                <Form className="pt-4 pe-3" onSubmit={handleSubmitForm}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Category Name<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Category Name"
                      onChange={(e) => setCategoryName(e.target.value)}
                      value={categoryName}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    className=" form-group mb-5"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Category Location<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setCategoryLocation(e.target.value)}
                      value={categoryLocation}
                      required
                    >
                      <option value="">Choose Category Location</option>
                      <option value="medicare">Medicare</option>
                      <option value="bodycare">Body Care</option>
                      <option value="food">Food</option>
                      <option value="privatebox">Private Box</option>
                    </Form.Select>
                  </Form.Group>
                  {createLoading === true ? (
                    <Row className="mt-2 mb-2  d-flex justify-content-center">
                      <Spinner animation="border" variant="warning" />
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Button className="create-btn border-0" type="submit">
                    Create Category
                  </Button>
                </Form>
              </Col>
              <Col lg={8} className="mb-5">
                <Table responsive="xl" hover>
                  <thead className="text-center table-header">
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          name="selectAll"
                          id="selectAll"
                          onChange={handleSelectAll}
                          checked={isCheckAll}
                        />
                      </th>
                      <th>Category Id</th>
                      <th>Category Name</th>
                      <th>Location</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {showSearchResult &&
                    searchCategoryList?.data !== undefined &&
                    searchCategoryList?.data.catagory.length !== 0 ? (
                      searchCategoryList?.data.catagory.map((category) => (
                        <CategoryTableRow
                          key={category.id}
                          category={category}
                          handleClick={handleClick}
                          isCheck={isCheck}
                          handleUpdateCategory={handleUpdateCategory}
                          handleDeleteCategory={handleDeleteCategory}
                        />
                      ))
                    ) : categoryList?.data !== undefined &&
                      categoryList?.data.results.length !== 0 &&
                      !showSearchResult ? (
                      categoryList?.data.results.map((category) => (
                        <CategoryTableRow
                          key={category.id}
                          category={category}
                          handleClick={handleClick}
                          isCheck={isCheck}
                          handleUpdateCategory={handleUpdateCategory}
                          handleDeleteCategory={handleDeleteCategory}
                        />
                      ))
                    ) : loading === true ? (
                      <tr>
                        <td colSpan="12">
                          <Spinner animation="border" variant="warning" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="12">No Result Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
              {pageCount > 1 && !showSearchResult ? (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=""
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  pageCount={pageCount}
                  previousLabel=""
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousClassName="hide"
                  nextClassName="hide"
                  activeLinkClassName="active"
                />
              ) : (
                <></>
              )}
            </Row>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Category;
