import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Spinner, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getCompareProductGpReport } from "../../../Redux/actions/stockAnalysisActions";

const CompareProductGpReport = (props) => {
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (props.compareProductList?.data) {
      setTotalItems(props.compareProductList.data.count);
    }
  }, [props.compareProductList]);
  const pageCount = Math.ceil(totalItems / props.limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.limit) % totalItems;
    if (props.genericName !== "") {
      dispatch(
        getCompareProductGpReport(
          `&limit=${props.limit}&offset=${newOffset}&generic_name=${props.genericName}`
        )
      );
    }
  };
  return (
    <>
      <Table bordered responsive>
        <thead className="text-center report-table-header report-border">
          <tr>
            <th>Supplier Name</th>
            <th>Generic Name</th>
            <th>Product Information</th>
            <th>CPU</th>
            <th>RPU</th>
            <th>Pft%</th>
          </tr>
        </thead>
        <tbody className="text-center report-table-body">
          {props.compareProductList.data &&
          !props.compareProductLoading &&
          props.compareProductList.data.data ? (
            props.compareProductList.data.data.map((prod, i) => (
              <tr key={i}>
                <td>{prod.supplier_Name ? prod.supplier_Name : "N/A"}</td>
                <td>{prod.generic_name}</td>
                <td>
                  {prod.product_name} {prod.generic_name} {prod.unit}
                </td>
                <td>{prod.CPU}</td>
                <td>{prod.RPU}</td>
                <td>{prod.Profit_margin ? prod.Profit_margin : "N/A"}</td>
              </tr>
            ))
          ) : props.compareProductLoading ? (
            <tr>
              <td colSpan={12}>
                <Spinner animation="border" variant="warning" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={12}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
      {pageCount > 1 && !props.compareProductLoading ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousClassName="hide"
          nextClassName="hide"
          activeLinkClassName="active"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CompareProductGpReport;
