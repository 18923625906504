import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import VendorForm from "./VendorForm";
import { createVendor } from "../../Redux/actions/vendorActions";
import "../Products/Product.css";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { SuccessAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";

const AddVendor = () => {
  const dispatch = useDispatch();
  const { loading, message, error } = useSelector(
    (state) => state.createVendor
  );
  const [vendorProductCategory, setVendorProductCategory] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorAddress, setVendorAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [vendorDocument, setVendorDocument] = useState(null);
  const [status, setStatus] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);

  useEffect(() => {
    if (alertMessage && message === undefined && error !== undefined) {
      //console.log(error);
      if (error.error) {
        setAlertMessage(false);
        if (error.error === "Invalid phone number") {
          alert(error.error + ". Please Add +88");
        } else {
          alert(error.error);
        }
      } else {
        alert(error);
        setAlertMessage(false);
      }
    }
    if (alertMessage && message !== undefined) {
      //console.log(message);
      SuccessAlert("New Vendor Created Successfully");
      setVendorName("");
      setVendorAddress("");
      setContactNumber("");
      setEmailAddress("");
      setContactPerson("");
      setVendorProductCategory("");
      setVendorDocument("");
      setStatus("");
      setAlertMessage(false);
    }
  }, [alertMessage, message, error]);

  const handleSubmitForm = (e) => {
    if (vendorDocument === null) {
      e.preventDefault();
      let form_data = new FormData();
      form_data.append("category_of_vendor", vendorProductCategory);
      form_data.append("vendor_name", vendorName);
      form_data.append("vendor_address", vendorAddress);
      form_data.append("contact_number", contactNumber);
      form_data.append("email_address", emailAddress);
      form_data.append("contact_Person", contactPerson);
      form_data.append("status", status);
      dispatch(createVendor(form_data));
      setAlertMessage(true);
    } else {
      e.preventDefault();
      let form_data = new FormData();
      form_data.append("category_of_vendor", vendorProductCategory);
      form_data.append("vendor_name", vendorName);
      form_data.append("vendor_address", vendorAddress);
      form_data.append("contact_number", contactNumber);
      form_data.append("email_address", emailAddress);
      form_data.append("contact_Person", contactPerson);
      form_data.append("status", status);
      form_data.append("Documents", vendorDocument);

      dispatch(createVendor(form_data));
      setAlertMessage(true);
    }
  };

  return (
    <div>
      <SideBar />
      <div className="mainContent">
        <ToastContainer />
        <Header />
        <Container fluid>
          <h4 className="product-heading container-view">Vendor Add</h4>
          <p className="product-paragraph container-view">
            Vendor <FontAwesomeIcon icon={faChevronRight} size="xs" /> Add
            Vendor
          </p>
          <div className="mb-4 card product-card container-view">
            <VendorForm
              loading={loading}
              vendorProductCategory={vendorProductCategory}
              setVendorProductCategory={setVendorProductCategory}
              vendorName={vendorName}
              setVendorName={setVendorName}
              vendorAddress={vendorAddress}
              setVendorAddress={setVendorAddress}
              contactNumber={contactNumber}
              setContactNumber={setContactNumber}
              emailAddress={emailAddress}
              setEmailAddress={setEmailAddress}
              contactPerson={contactPerson}
              setContactPerson={setContactPerson}
              vendorDocument={vendorDocument}
              setVendorDocument={setVendorDocument}
              status={status}
              setStatus={setStatus}
              handleSubmitForm={handleSubmitForm}
            />
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default AddVendor;
