import {
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_LIST_FAILURE,
  CREATE_SUPPLIER_REQUEST,
  CREATE_SUPPLIER_SUCCESS,
  CREATE_SUPPLIER_FAILURE,
  SUPPLIER_DELETE_REQUEST,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_DELETE_FAILURE,
  SUPPLIER_UPDATE_REQUEST,
  SUPPLIER_UPDATE_SUCCESS,
  SUPPLIER_UPDATE_FAILURE,
  EACH_SUPPLIER_DETAIL_REQUEST,
  EACH_SUPPLIER_DETAIL_SUCCESS,
  EACH_SUPPLIER_DETAIL_FAILURE,
  SEARCH_SUPPLIER_LIST_REQUEST,
  SEARCH_SUPPLIER_LIST_SUCCESS,
  SEARCH_SUPPLIER_LIST_FAILURE,
} from "../type.js";
import axios from "axios";

export const getSupplierList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: SUPPLIER_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/SupplierListView/?limit=${limit}&offset=${offset}&order_by=supplier_name,ascending`,
      config
    );

    dispatch({
      type: SUPPLIER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPLIER_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createSupplier = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SUPPLIER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/CreateSupplierView/`,
      body,
      config
    );

    dispatch({
      type: CREATE_SUPPLIER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_SUPPLIER_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const deleteSupplier = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SUPPLIER_DELETE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/DeleteSupplierView/${id}/`,
      config
    );
    dispatch({
      type: SUPPLIER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPLIER_DELETE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateSupplier = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: SUPPLIER_UPDATE_REQUEST,
    });
    //console.log("body");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/UpdateSupplierView/${id}/`,
      body,
      config
    );

    dispatch({
      type: SUPPLIER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPLIER_UPDATE_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getSupplierDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: EACH_SUPPLIER_DETAIL_REQUEST });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/SupplierDetails/${id}/`,
      config
    );
    dispatch({
      type: EACH_SUPPLIER_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EACH_SUPPLIER_DETAIL_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const searchSupplierList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_SUPPLIER_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/SupplierListView/?query=${keyword}&limit=20&offset=0`,
      config
    );

    dispatch({
      type: SEARCH_SUPPLIER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_SUPPLIER_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
