import {
  GENERIC_LIST_REQUEST,
  GENERIC_LIST_SUCCESS,
  GENERIC_LIST_FAILURE,
  CREATE_GENERIC_REQUEST,
  CREATE_GENERIC_SUCCESS,
  CREATE_GENERIC_FAILURE,
  SEARCH_GENERIC_LIST_REQUEST,
  SEARCH_GENERIC_LIST_SUCCESS,
  SEARCH_GENERIC_LIST_FAILURE,
} from "../type.js";
import axios from "axios";

export const getGenericList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: GENERIC_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/generic-name/generic-name-list/?order_by=generic_name,ascending&limit=${limit}&offset=${offset}`,
      config
    );

    dispatch({
      type: GENERIC_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GENERIC_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createGeneric = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_GENERIC_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/generic-name/create-generic-name/`,
      body,
      config
    );

    dispatch({
      type: CREATE_GENERIC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_GENERIC_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getSearchGenericList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_GENERIC_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/generic-name/generic-name-list/?query=${keyword}&limit=50&offset=0&order_by=generic_name,ascending`,
      config
    );

    dispatch({
      type: SEARCH_GENERIC_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_GENERIC_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
