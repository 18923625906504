import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

const NavTimer = () => {
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <Col>
        <Row>
          <span style={{ fontWeight: "600" }}>{date.toLocaleTimeString()}</span>
        </Row>
        <Row style={{}}>
          <span style={{ fontWeight: "500" }}>{date.toLocaleDateString()}</span>{" "}
        </Row>
      </Col>
    </>
  );
};

export default NavTimer;
