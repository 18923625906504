import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div style={{ height: "100vh" , display:"flex" , justifyContent:"center", alignItems:"center"}}>
      <div>
        <div className="row">
          <h2 className="text-center" style={{ fontWeight: "600" }}>
            Error 404 : Page Not Found
          </h2>
        </div>
        <div className="d-flex justify-content-center">
          <Button onClick={() => navigate("/")}>Back to Home Page</Button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
