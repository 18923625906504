import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEye,
  faAdd,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import CommentModal from "../PopupModal/CommentModal";
import DeleteModal from "../PopupModal/DeleteModal";
import SinglePurchasePdf from "../../Services/SinglePurchasePdf";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import filter from "../../Assets/icons/filter.png";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import "../Products/Product.css";
import "../Return/Return.css";
import {
  getPurchaseList,
  searchPurhchaseList,
} from "../../Redux/actions/purchaseActions";
import { ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import LoadingModal from "../PopupModal/LoadingModal";
import axios from "axios";
import exportExcel from "../../Services/ExcelGenerator";
import PurchaseListPdf from "../../Services/PurchaseListPdf";
import PurchaseListTableRow from "./reusable/PurchaseListTableRow";

const PurchaseList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [commentModalShow, setCommentModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedPurchase, setSelectedPurchase] = useState("");
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [exportData, setExportData] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

  const { loading, purchaseList, error } = useSelector(
    (state) => state.purchaseList
  );

  const { searchLoading, searchPurchase, searchError } = useSelector(
    (state) => state.searchPurchaseList
  );

  useEffect(() => {
    dispatch(getPurchaseList(limit, offset));
  }, [dispatch, offset]);

  useEffect(() => {
    if (purchaseList?.data) {
      setTotalItems(purchaseList.data.count);
    }
  }, [purchaseList]);
  //console.log(purchaseList);

  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
  };

  const handleSearchResult = (e) => {
    //console.log(e.target.value);
    if (e.target.value.length > 1) {
      setShowSearchResult(true);
      dispatch(searchPurhchaseList(e.target.value));
    } else {
      setShowSearchResult(false);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, parseInt(id)]);
    setCheckedItems([
      ...checkedItems,
      purchaseList?.data.results.find((item) => item.id === parseInt(id)),
    ]);
    //setSelectedItems(array);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
      setCheckedItems(checkedItems.filter((item) => item.id !== parseInt(id)));
      setIsCheckAll(false);
    }
    //console.log(typeof parseInt(id));
    //console.log(checked);
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setCheckedItems(purchaseList?.data.results);
    setIsCheck(purchaseList?.data.results.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
      setCheckedItems([]);
    }
  };

  /** Export All Data */
  const getDataFromApi = (type) => {
    setExportData(true);
    try {
      //const form_data = new FormData();
      //form_data.append("csv_file", filefield);
      //const body = data;
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Custom-User-Agent":
            "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
        },
      };
      const api = `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/PurchaseProductListView/?limit=${totalItems}&offset=0&ordering=id`;

      axios
        .get(api, settings)
        .then((res) => {
          //console.log("success", res.data.results);
          //setAssetList([...assetList, ...res.data.results]);
          if (type === "pdf") {
            setExportData(false);
            PurchaseListPdf(
              res.data.results,
              "Purchase",
              [
                "Purchase ID",
                "Vendor Name",
                "Reference",
                "Purchase Date",
                "Total Purchase Value",
                "Additional Commission",
                "Total Vat",
                "Net Purchase Value",
                "Created By",
              ],
              "Care-Box Purchase List",
              totalItems
            );
          } else if (type === "excel") {
            const exportData = [];
            res.data.results.map((each) => {
              let obj = {};
              obj["Purchase ID"] = each.id;
              obj["Vendor Name"] = each.vendor_name;
              obj["Reference"] = each.reference;
              obj["Purchase Date"] = each.purchase_date;
              obj["Total Purchase Value"] = each.total_purchase_value;
              obj["Additional Commission"] = each.additional_commission;
              obj["Total Vat"] = each.total_vat;
              obj["Net Purchase Value"] = each.net_purchase_value;
              exportData.push(obj);
            });
            exportExcel(exportData, "Care_Box_Purchase_List.xlsx");
            setExportData(false);
          }
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };
  return (
    <div>
      <CommentModal
        show={commentModalShow}
        onHide={() => {
          setCommentModalShow(false);
          setSelectedItem("");
        }}
        product={selectedItem}
      />
      <DeleteModal
        show={deleteModalShow}
        onHide={() => {
          setDeleteModalShow(false);
          setSelectedPurchase("");
          setSelectedItem("");
        }}
        purchaseProduct={selectedItem}
        purchase={selectedPurchase}
        action={() => setShowSearchResult(false)}
      />
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Purchase List</h4>
              <p className="product-paragraph">
                {" "}
                Purchase <FontAwesomeIcon
                  icon={faChevronRight}
                  size="xs"
                />{" "}
                Purchase List
              </p>
            </Col>
            <Col lg={6} className="d-flex justify-content-lg-end">
              <button
                className="pe-3 ps-3 border-0 add-product-button"
                onClick={() => navigate("/addPurchase")}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ paddingRight: "0.4rem" }}
                />
                Add Purchase
              </button>
            </Col>
          </Row>
          <div className="mb-4 p-3 card product-card container-view">
            <Row className="mb-4">
              <Col lg={6} className="mb-2 mb-lg-0">
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchResult}
                />
              </Col>
              <Col lg={6} className="d-flex justify-content-md-end">
                {/* <img
                  className="pe-3"
                  src={pdfIcon}
                  alt="pdf"
                  height={45}
                  onClick={() => PurchasePdf()}
                /> */}
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as pdf</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => {
                      checkedItems.length === 1
                        ? SinglePurchasePdf(checkedItems[0])
                        : checkedItems.length > 1
                        ? PurchaseListPdf(
                            checkedItems,
                            "Purchase",
                            [
                              "Purchase ID",
                              "Vendor Name",
                              "Reference",
                              "Purchase Date",
                              "Total Purchase Value",
                              "Additional Commission",
                              "Total Vat",
                              "Net Purchase Value",
                              "Created By",
                            ],
                            "Care-Box Purchase List (Selected)",
                            checkedItems.length
                          )
                        : getDataFromApi("pdf");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as excel</Tooltip>}
                >
                  <img
                    //className="pe-3"
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getDataFromApi("excel")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            <Table responsive>
              <thead className="text-center table-header">
                <tr>
                  <th>
                    <Form.Check
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      onChange={handleSelectAll}
                      checked={isCheckAll}
                    />
                  </th>
                  <th>Purchase Id</th>
                  <th>Vendor Name</th>
                  <th>Reference</th>
                  <th>Purchase Date</th>
                  <th>Total Purchase Value</th>
                  <th>Additional Commission</th>
                  <th>Net Purchase Value</th>
                  <th>Total Vat</th>
                  <th>Created By</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {showSearchResult &&
                searchPurchase?.data !== undefined &&
                searchPurchase?.data.results.length !== 0 ? (
                  searchPurchase?.data.results.map((purchase) => (
                    <PurchaseListTableRow
                      key={purchase.id}
                      purchase={purchase}
                      handleClick={handleClick}
                      isCheck={isCheck}
                      selected={selected}
                      setCommentModalShow={setCommentModalShow}
                      setSelectedItem={setSelectedItem}
                    />
                  ))
                ) : !showSearchResult &&
                  purchaseList?.data !== undefined &&
                  purchaseList?.data.results.length !== 0 ? (
                  purchaseList?.data.results.map((purchase) => (
                    <PurchaseListTableRow
                      key={purchase.id}
                      purchase={purchase}
                      handleClick={handleClick}
                      isCheck={isCheck}
                      selected={selected}
                      setCommentModalShow={setCommentModalShow}
                      setSelectedItem={setSelectedItem}
                    />
                  ))
                ) : loading === true ? (
                  <tr>
                    <td colSpan="12">
                      <Spinner animation="border" variant="warning" />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="12">No Result Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {pageCount > 1 && !showSearchResult ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default PurchaseList;
