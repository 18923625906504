import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

const SidebarItem = ({ item, detectLocation }) => {
  let numberOfPermission = 0; //for item which has child
  if (item?.childs?.length) {
    // console.log("item.childs.length = ", item.childs.length);
    item.childs.map((child) => {
      if (child?.permission) {
        numberOfPermission++;
      }
    });
  }

  return item.childs?.length ? (
    numberOfPermission ? (
      <Accordion.Item eventKey={item.eventKey}>
        <Accordion.Header>
          <img className="accordion-img" src={item.icon} alt={item.title} />
          {item.title}
        </Accordion.Header>

        <Accordion.Body className="dropdown-body">
          {item.childs.map(
            (child, index) =>
              child.permission && (
                <Link
                  to={child.path}
                  className={detectLocation(child.path)}
                  key={`${item.eventKey}  ${index}`}
                  style={{ paddingTop: index === 0 ? "0px" : "16px" }}
                >
                  {detectLocation(child.path) === "active" ? (
                    <img
                      className="list-img"
                      src={child.selectdIcon}
                      alt="selectedLogo"
                    />
                  ) : (
                    <img
                      className="list-img"
                      src={child.unselectdIcon}
                      alt="listLogo"
                    />
                  )}
                  {child.title}
                </Link>
              )
          )}
        </Accordion.Body>
      </Accordion.Item>
    ) : (
      <></>
    )
  ) : item?.permission ? (
    <Accordion.Item className="mb-1 manual-body" eventKey={item.eventKey}>
      <Link to={item.path} className={detectLocation(item.path)}>
        <img
          src={item.icon}
          alt={item.title}
          height={24}
          style={{ paddingRight: "1rem" }}
        />
        {item.title}
      </Link>
    </Accordion.Item>
  ) : (
    <></>
  );
};

export default SidebarItem;
