import jsPDF from "jspdf";
import "jspdf-autotable";
import { convertTime } from "./ConvertDateTime";

const addFooters = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
  }
};

const SingleVoidPdf = (itemDetails) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });
  const date = new Date();
  const tableRows = itemDetails.products.map((item, i) => [
    i + 1,
    item.barcode ? item.barcode : "N/A",
    item.productName + " " + item.style_size + " " + item.product_sub_categorty,
    item.sold_quantity,
    item.sales_price,
    item.vat_amount,
    item.discount_amount,
    item.less_amount,
    item.net_amount,
    item.quantity,
  ]);
  const columnData = [
    "SL No",
    "BarCode",
    "Product",
    "Sold Qty",
    "RPU",
    "Vat",
    "Discount",
    "Less Amount",
    "Net Amount",
    "Void Qty",
  ];
  var title = "Care-Box";
  var reportName = "Void Details";
  var titleWidth = doc.getTextDimensions(title).w;
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  var x = (pageWidth - titleWidth) / 2;
  doc.text(title, x, 14);
  doc.text(
    reportName,
    (pageWidth - doc.getTextDimensions(reportName).w) / 2,
    20
  );
  doc.setFontSize(8);
  doc.text(`Void Number: ${itemDetails.void_id}`, 16, 24);
  doc.text(
    `Void By: ${itemDetails.void_by.Name}`,
    doc.internal.pageSize.width - 48,
    24
  );
  doc.setFontSize(8);
  doc.text(`Invoice Number: ${itemDetails.order_placed.invoice_id}`, 16, 28);
  doc.text(
    `Payment Method ${itemDetails.order_placed.paymentMethod}`,
    doc.internal.pageSize.width - 48,
    28
  );
  doc.text(
    `Invoice Date: ${itemDetails.order_placed.createdAt.split("T")[0]}`,
    16,
    32
  );
  doc.text(
    `Invoice Number ${convertTime(itemDetails.order_placed.createdAt)}`,
    doc.internal.pageSize.width - 48,
    32
  );
  doc.autoTable({
    //styles: { fillColor: [0, 0, 0] },
    startY: 38,
    columns: columnData,
    body: tableRows,
    theme: "plain",
    styles: {
      halign: "center",
      lineColor: "DCE0E4",
      lineWidth: 0.2,
      fontSize: 8,
    },
    headStyles: {
      textColor: "black",
      fillColor: "#fafbfe",
      fontSize: 10,
    },
  });

  doc.autoTable({
    columns: ["A", "Total Price:", `${itemDetails.total_price}TK`],
    body: [
      ["B", "Vat Amount:", `${itemDetails.vat_amount}TK`],
      ["C", "Discount Amount:", `${itemDetails.discount_amount}TK`],
      ["D", "Less Amount:", `${itemDetails.less_amount}TK`],
      [
        ".......................",
        ".......................",
        ".......................",
      ],
      ["A + B - C - D", "Net Amount:", `${itemDetails.net_amount}TK`],
    ],
    startY: doc.lastAutoTable.finalY + 10,
    //styles: { overflow: 'hidden' },
    theme: "plain",
    styles: {
      halign: "center",
      fontStyle: "bold",
      fontSize: 10,
      //textColor: "black",
      //lineColor: "DCE0E4",
      //lineWidth: 0.2,
    },
    margin: { right: doc.internal.pageSize.width / 2, left: 35 },
  });

  // my table

  // doc.text(`Void Reasons: ${itemDetails.void_reasons}`, 16, 36);
  doc.autoTable({
    columns: ["Void Reasons"],

    body: [
      [
        {
          content: `${itemDetails.void_reasons}`,
          styles: {
            halign: "left",
          },
        },
      ],
    ],

    startY: doc.lastAutoTable.finalY + 10,
    //styles: { overflow: 'hidden' },
    theme: "plain",
    styles: {
      halign: "center",
      lineColor: "DCE0E4",
      lineWidth: 0.2,
      fontSize: 8,
    },
    headStyles: {
      textColor: "black",
      fillColor: "#fafbfe",
      fontSize: 10,
    },
  });

  addFooters(doc);
  doc.save("Care-Box-Void-Details" + "_" + date.toLocaleTimeString());
};

export default SingleVoidPdf;
