import jsPDF from "jspdf";
import "jspdf-autotable";

const addFooters = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
  }
};

const SinglePurchasePdf = (itemDetails) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });
  const date = new Date();
  const tableRows = itemDetails.purchase.map((item, i) => [
    i + 1,
    item.product__barcode,
    item.product_name,
    item.product__unit,
    item.sub_category,
    item.supplier_name,
    item.batch_no,
    item.quantity_amount,
    item.bonus_quantity ? item.bonus_quantity : 0,
    item.new_purchase_price !== 0 && item.new_purchase_price !== ""
      ? item.new_purchase_price
      : item.purchase_price,
    item.new_sales_price !== 0 && item.new_sales_price !== ""
      ? item.new_sales_price
      : item.sales_price,
    item.gross_profit,
    item.new_purchase_price !== 0 && item.new_purchase_price !== ""
      ? (
          item.subtotal -
          item.new_purchase_price * item.quantity_amount
        ).toFixed(2)
      : (item.subtotal - item.purchase_price * item.quantity_amount).toFixed(2),
    item.subtotal,
  ]);
  const columnData = [
    "SL No",
    "BarCode",
    "Product",
    "Size",
    "Sub Category",
    "Supplier",
    "Batch No.",
    "Qty",
    "Bonus Qty",
    "CPU",
    "RPU",
    "GP(%)",
    "Vat (TK)",
    "Total (TK)",
  ];
  var title = "Care-Box Ltd";
  const address =
    "149/A Monipuripara, Baitus Sharaf Complex, Farmgate, Tejgaon, Dhaka-1215";
  var reportName = "Challan/Purchase Details";
  var titleWidth = doc.getTextDimensions(title).w;
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  var x = (pageWidth - titleWidth) / 2;
  doc.text(title, x, 14);
  doc.setFontSize(10);
  doc.text(address, (pageWidth - doc.getTextDimensions(address).w) / 2, 18);
  doc.text(
    reportName,
    (pageWidth - doc.getTextDimensions(reportName).w) / 2,
    22
  );
  doc.setFontSize(8);
  doc.text(
    `Challan/GRN Number: ${
      itemDetails.purchase_id ? itemDetails.purchase_id : itemDetails.id
    }`,
    16,
    28
  );
  doc.text(
    `Purchase Date: ${itemDetails.purchase_date}`,
    doc.internal.pageSize.width - 48,
    28
  );
  doc.setFontSize(8);
  doc.text(`Vendor Name: ${itemDetails.vendor_name}`, 16, 32);
  doc.text(
    `Reference: ${itemDetails.reference ? itemDetails.reference : "N/A"}`,
    doc.internal.pageSize.width - 48,
    32
  );
  doc.autoTable({
    //styles: { fillColor: [0, 0, 0] },
    startY: 40,
    columns: columnData,
    body: tableRows,
    theme: "plain",
    styles: {
      halign: "center",
      lineColor: "DCE0E4",
      lineWidth: 0.2,
      fontSize: 8,
    },
    headStyles: {
      textColor: "black",
      fillColor: "#fafbfe",
      fontSize: 10,
    },
  });

  doc.autoTable({
    columns: [
      "A",
      "Total Product Value:",
      `${itemDetails.total_purchase_value}TK`,
    ],
    body: [
      ["B", "Total Vat Amount:", `${itemDetails.total_vat}TK`],
      ["C", "Total Commision:", `${itemDetails.additional_commission}TK`],
      [
        ".......................",
        ".......................",
        ".......................",
      ],
      ["A + B - C", "Grand Total:", `${itemDetails.net_purchase_value}TK`],
    ],
    startY: doc.lastAutoTable.finalY + 10,
    //styles: { overflow: 'hidden' },
    theme: "plain",
    styles: {
      halign: "center",
      fontStyle: "bold",
      fontSize: 10,
      //textColor: "black",
      //lineColor: "DCE0E4",
      //lineWidth: 0.2,
    },
    margin: { right: doc.internal.pageSize.width / 2, left: 35 },
  });

  doc.setLineWidth(0.5);
  doc.line(
    12,
    doc.lastAutoTable.finalY + 20,
    40,
    doc.lastAutoTable.finalY + 20
  );
  doc.setFontSize(10);
  doc.text("Received By", 16, doc.lastAutoTable.finalY + 25);
  doc.line(
    doc.internal.pageSize.width / 2 - 20,
    doc.lastAutoTable.finalY + 20,
    doc.internal.pageSize.width / 2 + 20,
    doc.lastAutoTable.finalY + 20
  );
  doc.text(
    "Central-Posted By",
    (pageWidth - doc.getTextDimensions("Central-Posted By:").w) / 2,
    doc.lastAutoTable.finalY + 25
  );
  doc.line(
    doc.internal.pageSize.width - 45,
    doc.lastAutoTable.finalY + 20,
    doc.internal.pageSize.width - 10,
    doc.lastAutoTable.finalY + 20
  );
  doc.text(
    "Authorised By",
    doc.internal.pageSize.width - 38,
    doc.lastAutoTable.finalY + 25
  );
  addFooters(doc);
  doc.save(
    "Care-Box-Purchase-Details" +
      itemDetails.id +
      "_" +
      date.toLocaleTimeString()
  );
  // return (
  //   <div
  //     className="container"
  //     style={{
  //       marginTop: "1rem",
  //       marginLeft: "1rem",
  //       marginRight: "1rem",
  //       font: "Montserrat",
  //     }}
  //   >
  //     <div style={{ display: "flex", justifyContent: "center" }}>
  //       <p>Care-Box</p>
  //     </div>
  //   </div>
  // );
};

export default SinglePurchasePdf;
