import React, { Fragment } from "react";
import { Row, Col, Table, Spinner } from "react-bootstrap";

const VendorStockRcvReport = (props) => {
  return (
    <>
      {props.vendorWiseRcvList.data &&
      props.vendorWiseRcvList.data.purchase_data.length ? (
        props.vendorWiseRcvList.data.vendor_list.map((vendor, i) => (
          <Fragment key={i}>
            <div className="my-2">
              <Row
                className="mx-0 py-2 table-header"
                style={{
                  borderTop: "1px solid #dee2e6",
                  borderLeft: "1px solid #dee2e6",
                  borderRight: "1px solid #dee2e6",
                }}
              >
                <Col md={4}>
                  <b>Vendor Name: </b> {vendor.vendor}
                </Col>
                <Col className="d-flex justify-content-center" md={4}>
                  <b className="me-1">From Date: </b>{" "}
                  {props.fromDate ? props.fromDate : "N/A"}
                </Col>
                <Col className="d-flex justify-content-end" md={4}>
                  <b className="me-1">To Date: </b>
                  {props.toDate ? props.toDate : "N/A"}
                </Col>
              </Row>
              {props.vendorWiseRcvList.data.sub_totals.map((purchase, j) =>
                purchase.vendor === vendor.vendor ? (
                  <Fragment key={j + 500}>
                    <Table className="my-0" responsive bordered>
                      <thead className="text-center report-table-header">
                        <tr>
                          <th colSpan={3}>
                            Purchase ID:{" "}
                            {purchase.pop_id
                              ? purchase.pop_id
                              : purchase.purchase_id}
                          </th>
                          <th>
                            <b> Reference: </b>{" "}
                            {purchase.reference ? purchase.reference : "N/A"}
                          </th>
                          <th>
                            <b>Purchase Date: </b>
                            {purchase.purchase_date}
                          </th>
                          <th>
                            <b> Purchase Time: </b>{" "}
                            {purchase.purchase_time.slice(0, 8)}
                          </th>
                          <th>
                            <b> Posted By: </b> {purchase.posted_by}
                          </th>
                        </tr>
                        <tr>
                          <th>Category</th>
                          <th>Barcode</th>
                          <th>Product Information</th>
                          <th>Batch</th>
                          <th>CPU</th>
                          <th>Total Receive Qty</th>
                          <th>Total Value at Cost</th>
                        </tr>
                      </thead>
                      <tbody className="text-center report-table-body">
                        {props.vendorWiseRcvList.data ? (
                          props.vendorWiseRcvList.data.category_totals.map(
                            (cat, k) =>
                              cat.purchase_id === purchase.purchase_id ? (
                                <Fragment key={k}>
                                  {props.vendorWiseRcvList.data.purchase_data.map(
                                    (prod) =>
                                      prod.product_category ===
                                        cat.product_category &&
                                      prod.purchase_id ===
                                        purchase.purchase_id ? (
                                        <tr key={prod.id}>
                                          <td>{prod.product_category}</td>
                                          <td>
                                            {prod.barcode
                                              ? prod.barcode
                                              : "N/A"}
                                          </td>
                                          <td>
                                            {prod.product_name}{" "}
                                            {prod.product_unit}{" "}
                                            {prod.generic_name}
                                          </td>
                                          <td>{prod.batch_number}</td>
                                          <td>{prod.cpu}</td>
                                          <td>{prod.receive_qty}</td>
                                          <td>{prod.value_at_cost}</td>
                                        </tr>
                                      ) : (
                                        <></>
                                      )
                                  )}
                                  <tr className="report-table-header fw-semibold">
                                    <td colSpan={4}>
                                      Total of {cat.product_category}
                                    </td>
                                    <td>{cat.total_cpu}</td>
                                    <td>{cat.total_received}</td>
                                    <td>{cat.total_value_cost}</td>
                                  </tr>
                                </Fragment>
                              ) : (
                                <></>
                              )
                          )
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </Table>
                  </Fragment>
                ) : (
                  <></>
                )
              )}
            </div>
          </Fragment>
        ))
      ) : props.loading === true ? (
        <div className="mt-2 transfer-table">
          <Row className="p-2">
            <Col className="d-flex justify-content-center">
              <Spinner animation="border" variant="warning" />
            </Col>
          </Row>
        </div>
      ) : (
        <div className="mt-2 transfer-table">
          <Row className="p-2">
            <Col className="d-flex justify-content-center">No Data Found</Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default VendorStockRcvReport;
