import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Container, Row, Col, Table, Spinner } from "react-bootstrap";
import filter from "../../Assets/icons/filter.png";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import "../Products/Product.css";
import LoadingModal from "../PopupModal/LoadingModal";
import {
  getPurchaseOrderList,
  getSearchPurchaseOrderList,
} from "../../Redux/actions/purchaseOrderActions";
import PoListTableRow from "./reusable/PoListTableRow";

const PurchaseOrderList = () => {
  const dispatch = useDispatch();
  const limit = 20;
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [exportData, setExportData] = useState(false);

  const { loading, purchaseOrderList, error } = useSelector(
    (state) => state.purchaseOrderList
  );

  const { searchLoading, searchPurchaseOrder, searchError } = useSelector(
    (state) => state.searchPurchaseOrder
  );

  useEffect(() => {
    dispatch(getPurchaseOrderList(limit, offset));
  }, [dispatch, offset]);
  useEffect(() => {
    if (purchaseOrderList?.data) {
      setTotalItems(purchaseOrderList.data.count);
    }
  }, [purchaseOrderList]);

  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
  };

  const handleSearchResult = (e) => {
    //console.log(e.target.value);
    if (e.target.value.length > 0) {
      setShowSearchResult(true);
      dispatch(getSearchPurchaseOrderList(e.target.value));
    } else {
      setShowSearchResult(false);
    }
  };

  return (
    <div>
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Purchase Order List</h4>
              <p className="product-paragraph">
                {" "}
                Purchase <FontAwesomeIcon
                  icon={faChevronRight}
                  size="xs"
                />{" "}
                Purchase Order List
              </p>
            </Col>
          </Row>
          <div
            className="mb-4 p-3 card product-card container-view"
            style={{ minHeight: "50vh" }}
          >
            <Row className="mb-4">
              <Col lg={6}>
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchResult}
                />
              </Col>
            </Row>
            <Table responsive hover>
              <thead className="text-center table-header">
                <tr>
                  <th>PO ID</th>
                  <th>Supplier Name</th>
                  <th>Vendor Name</th>
                  <th>Total Product</th>
                  <th>Total CPU</th>
                  <th>Ordered Date</th>
                  <th>Ordered By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {showSearchResult &&
                searchPurchaseOrder?.data !== undefined &&
                searchPurchaseOrder?.data.results.length !== 0 ? (
                  searchPurchaseOrder?.data.results.map((purchase) => (
                    <PoListTableRow key={purchase.id} purchase={purchase} />
                  ))
                ) : !showSearchResult &&
                  purchaseOrderList?.data !== undefined &&
                  purchaseOrderList?.data.results.length !== 0 ? (
                  purchaseOrderList?.data.results.map((purchase) => (
                    <PoListTableRow key={purchase.id} purchase={purchase} />
                  ))
                ) : loading === true ? (
                  <tr>
                    <td colSpan="12">
                      <Spinner animation="border" variant="warning" />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="12">No Result Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {pageCount > 1 && !showSearchResult ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default PurchaseOrderList;
