import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Col,
  Container,
  Row,
  Table,
  Form,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import pdfIcon from "../../Assets/icons/pdf.png";
import "../Products/Product.css";
import "../Transfer/transfer.css";
import { getVoidDetails } from "../../Redux/actions/voidActions";
import { convertTime } from "../../Services/ConvertDateTime";
import SingleVoidPdf from "../../Services/SingleVoidPdf";

const EachVoid = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const { loading, singleVoid, error } = useSelector(
    (state) => state.voidDetails
  );
  useEffect(() => {
    dispatch(getVoidDetails(param.id));
  }, [dispatch, param.id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //console.log(singleVoid);
  return (
    <div>
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <Container fluid>
          <h4 className="product-heading container-view">Void Details</h4>
          <p className="product-paragraph container-view">
            Full Details of Void
          </p>
          <div className="mb-4 pt-3 card product-card container-view">
            <Row className="mb-4 px-3" style={{ fontSize: "14px" }}>
              <Col className="justify-content-start">
                <div>
                  <b>Void Number :</b> {singleVoid ? singleVoid.void_id : ""}
                </div>
                <div>
                  <b>Void By :</b>{" "}
                  {singleVoid && singleVoid.void_by
                    ? singleVoid.void_by.Name
                    : ""}
                </div>
              </Col>
              <Col className="d-flex justify-content-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as pdf</Tooltip>}
                >
                  <img
                    //className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => {
                      {
                        singleVoid
                          ? SingleVoidPdf(singleVoid)
                          : alert("Try Again");
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
            <div className="mt-3 mb-4 px-3">
              <Row
                className="mx-0 py-2 table-header"
                style={{
                  border: "1px solid #dee2e6",
                  fontSize: "14px",
                }}
              >
                <Col>
                  <b>Invoice Number:</b>{" "}
                  {singleVoid && singleVoid.order_placed
                    ? singleVoid.order_placed.invoice_id
                    : ""}
                </Col>
                <Col>
                  <b>Total Items:</b> {singleVoid ? singleVoid.total_items : ""}
                </Col>
                <Col>
                  <b>Total Qty:</b>{" "}
                  {singleVoid ? singleVoid.total_quantity : ""}
                </Col>
                <Col>
                  <b>Invoice Date:</b>{" "}
                  {singleVoid && singleVoid.order_placed
                    ? singleVoid.order_placed.createdAt.split("T")[0]
                    : ""}
                </Col>
                <Col>
                  <b>Invoice Time:</b>{" "}
                  {singleVoid && singleVoid.order_placed
                    ? convertTime(singleVoid.order_placed.createdAt)
                    : ""}
                </Col>
                <Col>
                  <b>Payment Method:</b>{" "}
                  {singleVoid && singleVoid.order_placed
                    ? singleVoid.order_placed.paymentMethod
                    : ""}
                </Col>
              </Row>
              <Table responsive bordered hover>
                <thead className="text-center table-header">
                  <tr>
                    <th>SL</th>
                    <th>Barcode</th>
                    <th>Product Information</th>
                    <th>Sold Qty</th>
                    <th>RPU</th>
                    <th>Vat(৳)</th>
                    <th>Discount(৳)</th>
                    <th>Less Amount</th>
                    <th>Net Amount</th>
                    <th>Void Qty</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {singleVoid.products && singleVoid.products.length ? (
                    singleVoid.products.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.barcode ? item.barcode : "N/A"}</td>
                        <td>
                          {item.productName +
                            " " +
                            item.style_size +
                            " " +
                            item.product_sub_categorty}
                        </td>
                        <td>{item.sold_quantity}</td>
                        <td>{item.sales_price}</td>
                        <td>{item.vat_amount}</td>
                        <td>{item.discount_amount}</td>
                        <td>{item.less_amount}</td>
                        <td>{item.net_amount}</td>
                        <td>{item.quantity}</td>
                      </tr>
                    ))
                  ) : loading === true ? (
                    <tr>
                      <td colSpan="12">
                        <Spinner animation="border" variant="warning" />
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="10">No Product Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <Row
              style={{ backgroundColor: "#DCE0E4" }}
              className="pt-3 px-1 mx-0"
            >
              <Col lg={12}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Void Reasons</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    placeholder="Write Void Reason"
                    style={{ minHeight: 60, maxHeight: 80 }}
                    //onChange={(e) => props.setDescription(e.target.value)}
                    value={singleVoid ? singleVoid.void_reasons : ""}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0 px-1" style={{ backgroundColor: "#DCE0E4" }}>
              <Col lg={2}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Total Price</Form.Label>
                  <Form.Control
                    type="text"
                    value={singleVoid.total_price ? singleVoid.total_price : ""}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Disc. Amount</Form.Label>
                  <Form.Control
                    type="text"
                    value={
                      singleVoid.discount_amount
                        ? singleVoid.discount_amount
                        : ""
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Less Amount</Form.Label>
                  <Form.Control
                    type="text"
                    value={singleVoid.less_amount ? singleVoid.less_amount : 0}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Subtotal</Form.Label>
                  <Form.Control
                    type="text"
                    value={
                      singleVoid.discount_amount
                        ? singleVoid.total_price -
                          singleVoid.discount_amount -
                          singleVoid.less_amount
                        : ""
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Vat</Form.Label>
                  <Form.Control
                    type="text"
                    value={singleVoid.vat_amount ? singleVoid.vat_amount : 0}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Net Amount</Form.Label>
                  <Form.Control
                    type="text"
                    value={singleVoid.net_amount ? singleVoid.net_amount : ""}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default EachVoid;
