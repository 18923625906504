import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const TransferListTableRow = ({ transfer }) => {
  const navigate = useNavigate();
  return (
    <tr key={transfer.id}>
      <td> {transfer.transfered_id ? transfer.transfered_id : transfer.id} </td>{" "}
      <td> {transfer.date} </td> <td> {transfer.transfer_from.name} </td>{" "}
      <td> {transfer.transfer_to.name} </td>{" "}
      <td> {transfer.transfer_total} </td> <td> {transfer.staff_remark} </td>{" "}
      <td> {transfer.issued_by.Name} </td>{" "}
      <td style={{ cursor: "pointer" }}>
        <FontAwesomeIcon
          icon={faEye}
          color="#637381"
          onClick={() => navigate(`/transfer/${transfer.id}`)}
        />{" "}
      </td>{" "}
    </tr>
  );
};

export default TransferListTableRow;
