import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAILURE,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_FAILURE,
  SEARCH_CATEGORY_LIST_REQUEST,
  SEARCH_CATEGORY_LIST_SUCCESS,
  SEARCH_CATEGORY_LIST_FAILURE,
} from "../type.js";

export const categoryListReducer = (state = { categoryList: {} }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { loading: true, ...state };
    case CATEGORY_LIST_SUCCESS:
      return { loading: false, categoryList: action.payload };
    case CATEGORY_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_DELETE_REQUEST:
      return { loading: true };
    case CATEGORY_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case CATEGORY_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CATEGORY_REQUEST:
      return { createLoading: true };
    case CREATE_CATEGORY_SUCCESS:
      return {
        createLoading: false,
        message: action.payload,
      };
    case CREATE_CATEGORY_FAILURE:
      return {
        createLoading: false,
        createError: action.payload,
      };
    default:
      return state;
  }
};

export const updateCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_UPDATE_REQUEST:
      return { loading: true };
    case CATEGORY_UPDATE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case CATEGORY_UPDATE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const searchCategoryListReducer = (
  state = { searchCategoryList: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_CATEGORY_LIST_REQUEST:
      return { searchLoading: true, ...state };
    case SEARCH_CATEGORY_LIST_SUCCESS:
      return { searchLoading: false, searchCategoryList: action.payload };
    case SEARCH_CATEGORY_LIST_FAILURE:
      return { searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};
