import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Col,
  Container,
  Row,
  Table,
  Form,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import "../Transfer/transfer.css";
import { getSupplierList } from "../../Redux/actions/supplierActions";
import {
  getInventoryList,
  createPhysicalInventory,
} from "../../Redux/actions/utilitiesAction";
import "../Return/Return.css";
import pdfIcon from "../../Assets/icons/pdf.png";
import PhysicalInventoryPdf from "../../Services/PhysicalInventoryPdf";
import { SuccessAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";
import LoadingModal from "../PopupModal/LoadingModal";

const CreateInventory = () => {
  const dispatch = useDispatch();
  const { supplierList } = useSelector((state) => state.supplierList);
  const { loading, inventoryList, error } = useSelector(
    (state) => state.inventoryList
  );
  const { createLoading, createInventory, createError } = useSelector(
    (state) => state.createPhysicalInventory
  );
  const [supplierId, setSupplierId] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [exportData, setExportData] = useState(false);

  useEffect(() => {
    if (!supplierList.data) {
      dispatch(getSupplierList(500, 0));
    }
  }, [supplierList?.data]);

  useEffect(() => {
    if (
      alertMessage &&
      createInventory === undefined &&
      createError !== undefined
    ) {
      //console.log(createError);
      setExportData(false);
      setAlertMessage(false);
      if (createError.message) {
        alert(createError.message);
      } else if (createError.Warning) {
        alert(createError.Warning);
      } else {
        alert("Something Went Wrong");
      }
    }
    if (alertMessage && createInventory.data !== undefined) {
      //console.log(createInventory);
      setExportData(false);
      SuccessAlert(
        `New Physical Inventory Created Successfully. Physical Inventory ID: ${createInventory?.data.log_id}`
      );
      PhysicalInventoryPdf(inventoryList.results, createInventory.data);
      setAlertMessage(false);
      setShowResults(false);
      setSupplierId("");
      setSearchProduct("");
      setTimeout(() => {
        window.location.reload();
      }, 2100);
    }
  }, [alertMessage, createInventory, createError]);
  //console.log(createInventory);
  const handleGenerateReport = () => {
    if (supplierId && !searchProduct.length) {
      setShowResults(true);
      dispatch(getInventoryList(supplierId));
    } else if (supplierId && searchProduct.length) {
      setShowResults(true);
      dispatch(getInventoryList(`${supplierId}&product_name=${searchProduct}`));
    } else {
      alert("Select Supplier To Generate Product List");
    }
  };

  const handleCreateInventory = () => {
    if (inventoryList && inventoryList.results.length) {
      const body = {
        supplier_id: supplierId,
        inventory_product: inventoryList.results.map((p) => ({
          product_id: p.products_id,
        })),
      };
      dispatch(createPhysicalInventory(body));
      setAlertMessage(true);
      setExportData(true);
    } else {
      alert("Please Generate Products First");
    }
  };
  //console.log(loading);

  return (
    <>
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer />
        <Container fluid>
          <h4 className="product-heading container-view">Create Inventory</h4>
          <p className="product-paragraph container-view">
            {" "}
            Utilities <FontAwesomeIcon icon={faChevronRight} size="xs" /> Add
            Create Inventory
          </p>
          <div
            className="mb-4 card container-view"
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
          >
            <Row className="mb-4 pt-3" style={{ backgroundColor: "#DCE0E4" }}>
              <Col lg={3}>
                <Form.Group className="form-group mb-3">
                  <Form.Label>
                    Select Supplier
                    <span className="required-field">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => setSupplierId(e.target.value)}
                    value={supplierId}
                    // required
                  >
                    <option value="">Select Supplier</option>
                    {supplierList.data?.results.map((supplier) => (
                      <option key={supplier.id} value={supplier.id}>
                        {supplier.supplier_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={1}>
                <Form.Group className=" form-group mb-3">
                  <Form.Label>
                    S. Id<span></span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Id"
                    value={supplierId}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Button
                  className="report-btn border-0"
                  onClick={handleGenerateReport}
                >
                  Generate
                </Button>
              </Col>
              <Col lg={4}>
                <Form.Group className="form-group mb-3">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Search By Barcode/Product Name"
                    value={searchProduct}
                    onChange={(e) => setSearchProduct(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                lg={1}
                className="d-flex justify-content-md-end align-items-center"
              >
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as pdf</Tooltip>}
                >
                  <img
                    className=""
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    style={{ cursor: "pointer" }}
                    onClick={handleCreateInventory}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
            {showResults ? (
              <>
                <div className="mt-3 mb-3">
                  {loading ? (
                    <Row className="mt-2">
                      <Col lg={12} className="d-flex justify-content-center">
                        <Spinner animation="border" variant="warning" />
                      </Col>
                    </Row>
                  ) : (
                    <Table
                      responsive
                      bordered
                      hover
                      style={{ fontSize: "14px" }}
                      //onKeyDown={(e) => searchInputSuggestionHendler(e)}
                    >
                      <thead className="table-header">
                        <tr>
                          <th>Sl No.</th>
                          <th>Barcode</th>
                          <th>Product Information</th>
                          <th>CPU</th>
                          <th>RPU</th>
                          <th>Warehouse Qty</th>
                          <th>Mismatch Qty (Warehouse)</th>
                          <th>Outlet Qty</th>
                          <th>Mismatch Qty (Outlet)</th>
                          <th>Batch No.</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {supplierId &&
                        inventoryList.results &&
                        inventoryList.results.length > 0 ? (
                          inventoryList.results.map((product, i) => (
                            <tr key={product.products_id}>
                              <td>{i + 1}</td>
                              <td>
                                {product.product_barcode
                                  ? product.product_barcode
                                  : "N/A"}
                              </td>
                              <td>
                                {product.product_names} {product.generic_name}{" "}
                                {product.unit}
                              </td>
                              <td>{product.cpu}</td>
                              <td>{product.rpu}</td>
                              <td>{product.wh_stock}</td>
                              <td>
                                <input disabled />
                              </td>
                              <td>{product.retail_stock}</td>
                              <td>
                                <input disabled />
                              </td>
                              <td>
                                <input disabled />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan="10">No Product Found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                </div>
              </>
            ) : (
              <div className="mt-3 d-flex justify-content-center">
                <p style={{ fontWeight: 700, fontSize: "16px" }}>
                  Select A Supplier or Search Product To Show Details
                </p>
              </div>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </>
  );
};

export default CreateInventory;
