import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getSupplierWiseStockReport } from "../../../Redux/actions/stockReportActions";

const SupplierWiseStock = (props) => {
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (props.supplierWiseStock?.data) {
      setTotalItems(props.supplierWiseStock.data.count);
    }
  }, [props.supplierWiseStock]);
  const pageCount = Math.ceil(totalItems / props.limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.limit) % totalItems;
    if (props.selectSupplier === "all") {
      if (props.fromDate !== "" && props.toDate !== "") {
        dispatch(
          getSupplierWiseStockReport(
            `/?limit=${props.limit}&offset=${newOffset}&date_range_after=${props.fromDate}&date_range_before=${props.toDate}`
          )
        );
      } else {
        dispatch(
          getSupplierWiseStockReport(
            `/?limit=${props.limit}&offset=${newOffset}`
          )
        );
      }
    } else {
      if (props.fromDate !== "" && props.toDate !== "") {
        dispatch(
          getSupplierWiseStockReport(
            `/?limit=${props.limit}&offset=${newOffset}&supplier=${props.selectSupplier}&date_range_after=${props.fromDate}&date_range_before=${props.toDate}`
          )
        );
      } else {
        dispatch(
          getSupplierWiseStockReport(
            `/?limit=${props.limit}&offset=${newOffset}&supplier=${props.selectSupplier}`
          )
        );
      }
    }
  };
  return (
    <>
      <Table className="mt-3" responsive bordered>
        <thead className="text-center report-table-header">
          <tr>
            <th>Barcode</th>
            <th>Category</th>
            <th>Supplier</th>
            <th>Product Information</th>
            <th>CPU</th>
            <th>RPU</th>
            <th>Pft %</th>
            <th>T. Received</th>
            <th>Sold</th>
            <th>Exchange</th>
            <th>Stock Return</th>
            <th>Balance Qty</th>
            <th>Balance Value at Sale</th>
          </tr>
        </thead>
        <tbody className="text-center report-table-body">
          {props.supplierWiseStock.data && !props.supplierWiseStockLoading ? (
            <>
              {props.supplierWiseStock.data.results.map((prod, i) => (
                <tr key={i}>
                  <td>{prod.product_barcode ? prod.product_barcode : "N/A"}</td>
                  <td>{prod.product_category}</td>
                  <td>{prod.supplier_name}</td>
                  <td>
                    {prod.product_name} {prod.product_generic_name}{" "}
                    {prod.product_unit}
                  </td>
                  <td>{prod.cost_per_unit}</td>
                  <td>{prod.retail_per_unit}</td>
                  <td>{prod.profit_mergin}</td>
                  <td>{prod.total_purchase_quantity}</td>
                  <td>{prod.total_sold_quantity}</td>
                  <td>{prod.total_exchange_quantity}</td>
                  <td>{prod.total_returned_quantity}</td>
                  <td>{prod.current_stock}</td>
                  <td>{prod.stock_sale_value}</td>
                </tr>
              ))}
              <tr className="grand-total-row">
                <td className="text-start">Grand Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {props.supplierWiseStock.data.results
                    .reduce((total, prod) => (total += prod.cost_per_unit), 0)
                    .toFixed(2)}
                </td>
                <td>
                  {props.supplierWiseStock.data.results
                    .reduce((total, prod) => (total += prod.retail_per_unit), 0)
                    .toFixed(2)}
                </td>
                <td></td>
                <td>
                  {props.supplierWiseStock.data.results.reduce(
                    (total, prod) => (total += prod.total_purchase_quantity),
                    0
                  )}
                </td>
                <td>
                  {props.supplierWiseStock.data.results.reduce(
                    (total, prod) => (total += prod.total_sold_quantity),
                    0
                  )}
                </td>
                <td>
                  {props.supplierWiseStock.data.results.reduce(
                    (total, prod) => (total += prod.total_exchange_quantity),
                    0
                  )}
                </td>
                <td>
                  {props.supplierWiseStock.data.results.reduce(
                    (total, prod) => (total += prod.total_returned_quantity),
                    0
                  )}
                </td>
                <td>
                  {props.supplierWiseStock.data.results.reduce(
                    (total, prod) => (total += prod.current_stock),
                    0
                  )}
                </td>
                <td>
                  {props.supplierWiseStock.data.results
                    .reduce(
                      (total, prod) => (total += prod.stock_sale_value),
                      0
                    )
                    .toFixed(2)}
                </td>
              </tr>
            </>
          ) : props.supplierWiseStockLoading ? (
            <tr>
              <td colSpan={12}>
                <Spinner animation="border" variant="warning" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={12}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
      {pageCount > 1 && !props.supplierWiseStockLoading ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousClassName="hide"
          nextClassName="hide"
          activeLinkClassName="active"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SupplierWiseStock;
