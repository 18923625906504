import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt, faTrashCan } from "@fortawesome/free-solid-svg-icons";

const SubcatgoryTableRow = ({
  subcategory,
  handleClick,
  isCheck,
  handleUpdateSubcategory,
  handleDeleteSubcategory,
}) => {
  return (
    <tr key={subcategory.id} style={{ verticalAlign: "middle" }}>
      <td>
        <Form.Check
          key={subcategory.id}
          name={subcategory.name}
          type="checkbox"
          id={subcategory.id}
          onChange={handleClick}
          checked={isCheck.includes(subcategory.id)}
        />
      </td>
      <td>{subcategory.id}</td>
      <td>
        <img
          src={subcategory.icon_image}
          alt={"Upload Image"}
          className="img-fluid product-img"
        />
      </td>
      <td>{subcategory.name}</td>
      <td>{subcategory.catagory_name}</td>
      <td style={{ cursor: "pointer" }}>
        <OverlayTrigger
          key="update"
          placement="top"
          overlay={<Tooltip id="tooltip-top">Update</Tooltip>}
        >
          <FontAwesomeIcon
            icon={faPenAlt}
            color="#637381"
            onClick={() => handleUpdateSubcategory(subcategory)}
          />
        </OverlayTrigger>
        <OverlayTrigger
          key="delete"
          placement="top"
          overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}
        >
          <FontAwesomeIcon
            icon={faTrashCan}
            color="#E40000"
            style={{ paddingLeft: "0.6rem" }}
            onClick={() => handleDeleteSubcategory(subcategory)}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default SubcatgoryTableRow;
