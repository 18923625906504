import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Spinner, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getSupplierRcvSummary } from "../../../Redux/actions/receiveReportActions";

const SupplierRcvSummary = (props) => {
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (props.supplierRcvSummary.data) {
      setTotalItems(props.supplierRcvSummary.data.products_data.count);
    }
  }, [props.supplierRcvSummary]);
  const pageCount = Math.ceil(totalItems / props.limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.limit) % totalItems;
    if (props.fromDate !== "" && props.toDate !== "") {
      dispatch(
        getSupplierRcvSummary(
          `/?limit=${props.limit}&offset=${newOffset}&supplier_id=${props.supplierId}&from_date=${props.fromDate}&to_date=${props.toDate}`
        )
      );
    } else {
      dispatch(
        getSupplierRcvSummary(
          `/?limit=${props.limit}&offset=${newOffset}&supplier_id=${props.supplierId}`
        )
      );
    }
  };
  return (
    <>
      <div className="mt-2 transfer-table">
        <Row
          className="mx-0 py-2 table-header"
          //style={{ borderBottom: "1px solid #dee2e6" }}
        >
          <Col lg={4}>
            <b>Supplier Name: </b>{" "}
            {props.supplierRcvSummary.data &&
            props.supplierRcvSummary.data.products_data &&
            props.supplierRcvSummary.data.products_data.results.length
              ? props.supplierRcvSummary.data.products_data.results[0].supplier
              : ""}
          </Col>
          <Col lg={4} className="d-flex justify-content-center">
            <b className="me-1">From Date: </b>{" "}
            {props.fromDate ? props.fromDate : "N/A"}
          </Col>
          <Col lg={4} className="d-flex justify-content-end">
            <b className="me-1">To Date: </b>
            {props.toDate ? props.toDate : "N/A"}
          </Col>
        </Row>
      </div>
      <Table responsive bordered>
        <thead className="text-center report-table-header report-border">
          <tr>
            <th>Purchase ID</th>
            <th>Purchase Reference</th>
            <th>Purchase Date</th>
            <th>Total Purchase Value</th>
            <th>Total Vat</th>
            <th>Net Purchase Value</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody className="text-center report-table-body">
          {props.supplierRcvSummary.data &&
          !props.summaryLoading &&
          props.supplierRcvSummary.data.products_data &&
          props.supplierRcvSummary.data.products_data.results.length ? (
            <>
              {props.supplierRcvSummary.data.products_data.results.map(
                (prod, i) => (
                  <tr key={i}>
                    <td>
                      {prod.purchase_orderplace
                        ? prod.purchase_orderplace
                        : prod.purchase_id}
                    </td>
                    <td>
                      {prod.purchase_reference
                        ? prod.purchase_reference
                        : "N/A"}
                    </td>
                    <td>{prod.purchase_date}</td>
                    <td>{prod.total_purchase_value}</td>
                    <td>{prod.total_vat}</td>
                    <td>{prod.net_purchase_value}</td>
                    <td>{prod.user}</td>
                  </tr>
                )
              )}
              <tr className="grand-total-row">
                <td>Grand Total</td>
                <td></td>
                <td></td>
                <td>
                  {props.supplierRcvSummary.data.grand_total.total_purchase}
                </td>
                <td>
                  {props.supplierRcvSummary.data.grand_total.total_vat_amount}
                </td>
                <td>
                  {props.supplierRcvSummary.data.grand_total.total_net_purchase}
                </td>
                <td></td>
              </tr>
            </>
          ) : props.summaryLoading ? (
            <tr>
              <td colSpan={12}>
                <Spinner animation="border" variant="warning" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={12}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
      {pageCount > 1 && !props.summaryLoading ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousClassName="hide"
          nextClassName="hide"
          activeLinkClassName="active"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SupplierRcvSummary;
