import jsPDF from "jspdf";
import "jspdf-autotable";
import { convertTime } from "./ConvertDateTime";

const addFooters = (doc, date, time) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text("Date:" + date, 16, doc.internal.pageSize.height - 10);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
    doc.text(
      "Time:" + time,
      doc.internal.pageSize.width - 35,
      doc.internal.pageSize.height - 10
    );
  }
};

const StockAnalysisReportPdf = (
  headerRows,
  itemDetails,
  reportTitle,
  pdfTitle
) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });
  var tableRows = [];
  const date = new Date();
  if (reportTitle === "All Stocks") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.category_name,
        item.barcode ? item.barcode : "N/A",
        item.product_name,
        item.cost_per_unit,
        item.retail_per_unit,
        item.gross_profit,
        item.received,
        item.returned,
        item.sold,
        item.wh_stock,
        item.retail_stock,
        item.balance,
        item.cost,
      ];

      tableRows.push(rowData);
    });
  } else if (reportTitle === "Frequency Analysis to Price Change") {
    itemDetails.forEach((item) => {
      const rowData = [
        {
          content: item.barcode ? item.barcode : "N/A",
          rowSpan: item.purchases.length,
          styles: { valign: "middle", halign: "center" },
        },
        {
          content: item.product_name,
          rowSpan: item.purchases.length,
          styles: { valign: "middle", halign: "center" },
        },
        item.purchases[0].created_at.split("T")[0],
        convertTime(item.purchases[0].created_at),
        item.purchases[0].new_purchase_price,
        item.purchases[0].purchase_price,
        item.purchases[0].new_sales_price,
        item.purchases[0].sales_price,
        item.purchases[0].quantity_amount,
        item.purchases[0].changed_by,
      ];

      tableRows.push(rowData);

      item.purchases.forEach((purchase, i) => {
        if (i > 0) {
          const extraRowData = [
            purchase.created_at.split("T")[0],
            convertTime(purchase.created_at),
            purchase.new_purchase_price,
            purchase.purchase_price,
            purchase.new_sales_price,
            purchase.sales_price,
            purchase.quantity_amount,
            purchase.changed_by,
          ];
          tableRows.push(extraRowData);
        }
      });
    });
  } else if (
    reportTitle === "Fast moving product" ||
    reportTitle === "Slow Moving product"
  ) {
    itemDetails.forEach((item) => {
      const rowData = [
        item.product_name,
        item.buy_quantity,
        item.sold_quantity,
        item.balance_quantity,
        item.buy_date,
        item.sale_date.split("T")[0],
        item.days_passed,
      ];

      tableRows.push(rowData);
    });
  } else if (reportTitle === "Non moving product") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.category_name ? item.category_name : "N/A",
        item.barcode,
        item.product_name,
        item.buy_quantity,
        item.buy_date,
        item.days_passed,
      ];
      tableRows.push(rowData);
    });
  } else if (reportTitle === "Zero Stock") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.barcode ? item.barcode : "N/A",
        item.product_category,
        item.product_name,
        item.last_purchase_date ? item.last_purchase_date.split("T")[0] : "N/A",
        item.last_sale_date ? item.last_sale_date.split("T")[0] : "N/A",
        item.cost_per_unit,
        item.retail_per_unit,
        item.gross_profit_mergin ? item.gross_profit_mergin : "N/A",
        item.total_purchase_quantity,
        item.total_sold_quantity,
        item.total_returned_quantity,
        0,
        item.total_purchase_cost,
      ];
      tableRows.push(rowData);
    });
  } else if (reportTitle === "Stock Returns") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.product_barcode ? item.product_barcode : "N/A",
        item.product_name,
        item.returned_quantity,
        item.cost_price,
        item.total_cost_price,
        item.returned_at ? item.returned_at : "N/A",
        item.returned_by,
      ];
      tableRows.push(rowData);
    });
  } else if (reportTitle === "High-Low GP% wise contribution") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.barcode ? item.barcode : "N/A",
        item.category_name,
        item.product_name,
        item.cost_per_unit,
        item.retail_per_unit,
        item.gross_profit,
      ];
      tableRows.push(rowData);
    });
  } else if (reportTitle === "Compare product GP %") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.supplier_Name ? item.supplier_Name : "N/A",
        item.generic_name,
        item.product_name,
        item.CPU,
        item.RPU,
        item.Profit_margin ? item.Profit_margin : "N/A",
      ];
      tableRows.push(rowData);
    });
  } else if (reportTitle === "Near Expiry Product") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.product__barcode ? item.product__barcode : "N/A",
        item.product__supplier_Name__supplier_name,
        item.product__Sub_catagory_1__catagory__name,
        item.product__product_name,
        item.batch_no,
        item.totalreceived,
        item.soldtocustomer,
        item.balanceQty,
        item.product__new_purchase_price
          ? item.product__new_purchase_price
          : item.product__purchase_price,
        item.product__new_product_price
          ? item.product__new_product_price
          : item.product__product_price,
        item.product__Profit_margin ? item.product__Profit_margin : "N/A",
        item.created_at__date,
        item.expire_date,
      ];
      tableRows.push(rowData);
    });
  } else if (reportTitle === "Expiry Product") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.product__barcode ? item.product__barcode : "N/A",
        item.product__supplier_Name,
        item.product__Sub_catagory_1__catagory__name,
        item.product__product_name,
        item.vendor_name.map(
          (vendor) => vendor.purchaseorderplaced__vendor_name
        ),
        item.batch__batch_name,
        item.recevied,
        item.sold,
        item.balance_quantity,
        item.batch__purchase_price,
        item.product__new_product_price
          ? item.product__new_product_price
          : item.product__product_price,
        item.batch__expire_date,
      ];
      tableRows.push(rowData);
    });
  }
  var title = "Care-Box";
  var reportName = reportTitle;
  var titleWidth = doc.getTextDimensions(title).w;
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  var x = (pageWidth - titleWidth) / 2;
  doc.text(title, x, 14);
  doc.text(
    reportName,
    (pageWidth - doc.getTextDimensions(reportName).w) / 2,
    20
  );

  doc.autoTable({
    //styles: { fillColor: [0, 0, 0] },
    startY: 30,
    head: headerRows,
    body: tableRows,
    theme: "plain",
    styles: {
      halign: "center",
      lineColor: "DCE0E4",
      lineWidth: 0.2,
    },
    headStyles: {
      textColor: "black",
      fillColor: "#fafbfe",
    },
  });
  addFooters(doc, date.toLocaleDateString(), date.toLocaleTimeString());
  doc.save(`${pdfTitle}_${date.toLocaleTimeString()}`);
};

export default StockAnalysisReportPdf;
