import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import SideBar from "../SideBar/Sidebar";
import Footer from "../Footer/Footer";
import pdfIcon from "../../Assets/icons/pdf.png";
import calender from "../../Assets/icons/calender.png";
import restrictCalender from "../../Assets/icons/restrict-filter.png";
import cancel from "../../Assets/icons/cancel.png";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import "./reports.css";
import { getSupplierList } from "../../Redux/actions/supplierActions";
import LoadingModal from "../PopupModal/LoadingModal";
import {
  getSupplierWiseRcvReport,
  getSupplierRcvSummary,
} from "../../Redux/actions/receiveReportActions";
import SupplierStockRcvReport from "./SupplierWiseRcv/SupplierStockRcvReport";
import PurchaseWiseStockRcvReport from "./SupplierWiseRcv/PurchaseWiseStockRcvReport";
import SupplierRcvSummary from "./SupplierWiseRcv/SupplierRcvSummary";
import SupplierRcvReportPdf from "../../Services/SupplierRcvReportPdf";
import StockReportPdf from "../../Services/stockReportPdf";

const SupplierWiseRcv = () => {
  const dispatch = useDispatch();
  const [selectReport, setSelectReport] = useState("");
  const [productType, setProductType] = useState("");
  const [selectSupplier, setSelectSupplier] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [purchaseId, setPurchaseId] = useState("");
  const [generateButton, setGenerateButton] = useState(false);
  const [filterDateButton, setFilterDateButton] = useState(false);
  const [exportData, setExportData] = useState(false);

  const { loading, supplierWiseRcvList, error } = useSelector(
    (state) => state.supplierWiseRcvItems
  );
  const { summaryLoading, supplierRcvSummary, summaryError } = useSelector(
    (state) => state.supplierRcvSummaryDetails
  );

  const { supplierList } = useSelector((state) => state.supplierList);

  useEffect(() => {
    if (
      !supplierList.data &&
      (selectReport === "Supplier Wise Stock Receipt Position Details" ||
        selectReport === "Supplier Wise Purchase Receiving Summary")
    ) {
      dispatch(getSupplierList(500, 0));
    }
  }, [supplierList.data, selectReport]);

  const handleGenerateReport = () => {
    if (
      selectReport === "All Supplier Wise Stock Receipt Position Details" &&
      productType === ""
    ) {
      alert("Please Select Product Type");
    } else if (
      selectReport === "Supplier Wise Stock Receipt Position Details" &&
      selectSupplier === ""
    ) {
      alert("Please Select Supplier");
    } else if (
      selectReport === "All Supplier Wise Stock Receipt Position Details" &&
      productType !== ""
    ) {
      setGenerateButton(true);
      if (productType === "all") {
        if (fromDate !== "" && toDate !== "") {
          dispatch(
            getSupplierWiseRcvReport(
              `/?from_date=${fromDate}&to_date=${toDate}`
            )
          );
        } else {
          dispatch(getSupplierWiseRcvReport(`/`));
        }
      } else {
        if (fromDate !== "" && toDate !== "") {
          dispatch(
            getSupplierWiseRcvReport(
              `/?product_type=${productType}&from_date=${fromDate}&to_date=${toDate}`
            )
          );
        } else {
          dispatch(getSupplierWiseRcvReport(`/?product_type=${productType}`));
        }
      }
    } else if (
      selectReport === "Supplier Wise Stock Receipt Position Details" &&
      selectSupplier !== ""
    ) {
      if (selectSupplier !== "") {
        setGenerateButton(true);
        if (fromDate !== "" && toDate !== "") {
          dispatch(
            getSupplierWiseRcvReport(
              `/?supplier_id=${selectSupplier}&from_date=${fromDate}&to_date=${toDate}`
            )
          );
        } else {
          dispatch(getSupplierWiseRcvReport(`/?supplier_id=${selectSupplier}`));
        }
      } else {
        alert("Please Select Supplier");
      }
    } else if (
      selectReport ===
      "Purchase Number Wise Supplier Stock Receipt Position Details"
    ) {
      if (purchaseId !== "") {
        setGenerateButton(true);
        dispatch(getSupplierWiseRcvReport(`/?purchase_id=${purchaseId}`));
      } else {
        alert("Please Write Down Purchase ID");
      }
    } else if (selectReport === "Supplier Wise Purchase Receiving Summary") {
      if (selectSupplier !== "") {
        setGenerateButton(true);
        if (fromDate !== "" && toDate !== "") {
          dispatch(
            getSupplierRcvSummary(
              `/?limit=30&offset=0&supplier_id=${selectSupplier}&from_date=${fromDate}&to_date=${toDate}`
            )
          );
        } else {
          dispatch(
            getSupplierRcvSummary(
              `/?limit=30&offset=0&supplier_id=${selectSupplier}`
            )
          );
        }
      } else {
        alert("Please Select Supplier");
      }
    } else {
      setGenerateButton(false);
    }
  };

  const convertReportToPdf = () => {
    if (generateButton && selectReport !== "") {
      if (
        selectReport === "All Supplier Wise Stock Receipt Position Details" ||
        selectReport === "Supplier Wise Stock Receipt Position Details" ||
        selectReport ===
          "Purchase Number Wise Supplier Stock Receipt Position Details"
      ) {
        const tableData = supplierWiseRcvList.data.supplier_list.map(
          (supplier) => ({
            supplier_name: supplier.supplier,
            purchase_details: supplierWiseRcvList.data.sub_total.filter(
              (purchase) => purchase.supplier === supplier.supplier
            ),
            products_data: supplierWiseRcvList.data.products_data.filter(
              (product) => product.supplier_name === supplier.supplier
            ),
          })
        );
        SupplierRcvReportPdf(tableData, selectReport, selectReport);
      } else if (selectReport === "Supplier Wise Purchase Receiving Summary") {
        var headerRows = [
          [
            "Purchase ID",
            "Supplier Name",
            "Purchase Reference",
            "Purchase Date",
            "Total Purchase Value",
            "Total Vat",
            "Net Purchase Value",
            "User",
          ],
        ];
        StockReportPdf(
          headerRows,
          supplierRcvSummary.data.products_data.results,
          "Supplier Wise Purchase Receiving Summary",
          "Supplier Wise Purchase Receiving Summary"
        );
      }
    } else {
      alert("Please Generate Report First");
    }
  };

  //   if (selectReport !== "" && generateButton) {
  //     window.print();
  //   } else {
  //     alert("Please Generate a Report for Print");
  //   }
  // };
  // const convertReportToPdf = () => {
  //   if (generateButton && selectReport !== "") {
  //     if (selectReport === "All Supplier Wise Stock Receipt Position Details") {
  //       var headerRows = [
  //         [
  //           {
  //             content: "Supplier Name",
  //             rowSpan: 2,
  //             styles: { valign: "middle", halign: "center" },
  //           },
  //           {
  //             content: "Received",
  //             colSpan: 2,
  //             //styles: { halign: "center", fillColor: [241, 196, 15] },
  //           },
  //           {
  //             content: "Stock Return",
  //             colSpan: 3,
  //             //styles: { halign: "center", fillColor: [241, 196, 15] },
  //           },
  //           {
  //             content: "Sold",
  //             colSpan: 3,
  //             //styles: { halign: "center", fillColor: [241, 196, 15] },
  //           },
  //           {
  //             content: "Exchange/Void",
  //             colSpan: 3,
  //             //styles: { halign: "center", fillColor: [241, 196, 15] },
  //           },
  //           {
  //             content: "Balance",
  //             colSpan: 3,
  //             //styles: { halign: "center", fillColor: [241, 196, 15] },
  //           },
  //         ],
  //         [
  //           "Qty",
  //           "Value(Cost)",
  //           "Qty",
  //           "Value(Cost)",
  //           "Return Value",
  //           "Qty",
  //           "Value(Cost)",
  //           "Sale Value",
  //           "Qty",
  //           "Value(Cost)",
  //           "Sale Value",
  //           "Qty",
  //           "Value(Cost)",
  //           "Sale Value",
  //         ],
  //       ];
  //       if (
  //         allSupplierStockList.data.results.length ===
  //         allSupplierStockList.data.count
  //       ) {
  //         StockReportPdf(
  //           headerRows,
  //           allSupplierStockList.data.results,
  //           "All Supplier Wise Stock Receipt Position Details",
  //           "All_Supplier_Wise_Stock_Summary_Report"
  //         );
  //       } else if (
  //         allSupplierStockList.data.results.length !==
  //           allSupplierStockList.data.count &&
  //         allProductList.length === allSupplierStockList.data.count
  //       ) {
  //         StockReportPdf(
  //           headerRows,
  //           allProductList,
  //           "All Supplier Wise Stock Receipt Position Details",
  //           "All_Supplier_Wise_Stock_Summary_Report"
  //         );
  //       } else {
  //         setExportData(true);
  //         getDataFromApi(
  //           `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/supplier/supplier-wise-stock-glance/?limit=${allSupplierStockList.data.count}&offset=0&product_type=${productType}`
  //         );
  //       }
  //     } else if (
  //       selectReport === "Supplier Wise Stock Receipt Position Details"
  //     ) {
  //       var headerRows = [
  //         [
  //           "Barcode",
  //           "Category",
  //           "Supplier",
  //           "Product",
  //           "CPU",
  //           "RPU",
  //           "Pft(%)",
  //           "Total Received",
  //           "Sold to Customer",
  //           "Product Exchange",
  //           "Stock Return",
  //           "Balance Qty",
  //           "Balance Value at Sale",
  //         ],
  //       ];
  //       StockReportPdf(
  //         headerRows,
  //         supplierWiseStock.data.results,
  //         "Supplier Wise Stock Receipt Position Details",
  //         "Supplier_wise_stock_position"
  //       );
  //     } else if (selectReport === "Category/Sub Category wise stock Summary") {
  //       if (selectGroupType === "category") {
  //         var headerRows = [
  //           [
  //             "Category Name",
  //             "Location",
  //             "Total Received Qty",
  //             "Stock Return",
  //             "Total Sold",
  //             "Exchange/Void",
  //             "Balance Stock",
  //             "Total Balance (Cost)",
  //             "Total Balance (Sale Price)",
  //           ],
  //         ];
  //         StockReportPdf(
  //           headerRows,
  //           categoryStockList.data.results,
  //           "Category wise stock Summary",
  //           "Category_wise_stock_Summary"
  //         );
  //       } else if (selectGroupType === "sub_category") {
  //         var headerRows = [
  //           [
  //             "Sub Category",
  //             "Category",
  //             "Total Received Qty",
  //             "Stock Return",
  //             "Total Sold",
  //             "Exchange/Void",
  //             "Balance Stock",
  //             "Total Balance (Cost)",
  //             "Total Balance (Sale Price)",
  //           ],
  //         ];
  //         if (
  //           subcategoryStockList.data.results.length ===
  //           subcategoryStockList.data.count
  //         ) {
  //           StockReportPdf(
  //             headerRows,
  //             subcategoryStockList.data.results,
  //             "Sub Category wise stock Summary",
  //             "SubCategory_wise_stock_Summary"
  //           );
  //         } else if (
  //           subcategoryStockList.data.results.length !==
  //             subcategoryStockList.data.count &&
  //           allProductList.length === subcategoryStockList.data.count
  //         ) {
  //           StockReportPdf(
  //             headerRows,
  //             allProductList,
  //             "Sub Category wise stock Summary",
  //             "SubCategory_wise_stock_Summary"
  //           );
  //         } else {
  //           setExportData(true);
  //           if (fromDate !== "" && toDate !== "") {
  //             getDataFromApi(
  //               `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/category/subCategory-stock-report/?limit=${subcategoryStockList.data.count}&offset=0&from_date=${fromDate}&to_date=${toDate}`
  //             );
  //           } else {
  //             getDataFromApi(
  //               `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/category/subCategory-stock-report/?limit=${subcategoryStockList.data.count}&offset=0`
  //             );
  //           }
  //         }
  //       }
  //     } else if (
  //       selectReport === "Sub Category wise Product Information" ||
  //       selectReport === "Purchase Number Wise Supplier Stock Receipt Position"
  //     ) {
  //       var headerRows = [
  //         [
  //           "Product ",
  //           "Sub Category",
  //           "Barcode",
  //           "Total Received(Qty)",
  //           "Return(Qty)",
  //           "Exchange(Qty)",
  //           "Total Sold(Qty)",
  //           "Balance Qty",
  //           "Cost(Tk)",
  //           "Sale(Tk)",
  //         ],
  //       ];
  //       StockReportPdf(
  //         headerRows,
  //         subcategoryWiseProductList.data.results,
  //         selectReport,
  //         selectReport
  //       );
  //     }
  //   } else {
  //     alert("Please Generate Report First");
  //   }
  // };
  //console.log(supplierWiseRcvList, generateButton);
  return (
    <div>
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <Container fluid>
          <h4 className="product-heading container-view">
            Supplier Wise Receive at a Glance
          </h4>
          <p className="product-paragraph container-view">
            Reports <FontAwesomeIcon icon={faChevronRight} size="xs" /> Supplier
            Wise Receive
          </p>
          <div className="mb-4 report-page-card card product-card container-view">
            <div className="hide-print">
              <Row className="mb-2">
                <Col xl={9}>
                  {selectReport ===
                  "Purchase Number Wise Supplier Stock Receipt Position Details" ? (
                    <img
                      src={restrictCalender}
                      alt="restrictCalender"
                      height={45}
                      style={{ cursor: "not-allowed" }}
                    />
                  ) : (
                    <img
                      src={filterDateButton ? cancel : calender}
                      alt="calender"
                      height={45}
                      style={{ cursor: "pointer" }}
                      onClick={() => setFilterDateButton(!filterDateButton)}
                    />
                  )}
                  <select
                    name="stock-report"
                    className="select-bar"
                    onChange={(e) => {
                      setSelectReport(e.target.value);
                      setGenerateButton(false);
                      setSelectSupplier("");
                      setProductType("");
                      setPurchaseId("");
                      setFromDate("");
                      setToDate("");
                    }}
                    value={selectReport}
                  >
                    <option value="">Select Report Type</option>
                    <option value="All Supplier Wise Stock Receipt Position Details">
                      All Supplier Wise Stock Receipt Position Details
                    </option>
                    <option value="Supplier Wise Stock Receipt Position Details">
                      Supplier Wise Stock Receipt Position Details
                    </option>
                    <option value="Purchase Number Wise Supplier Stock Receipt Position Details">
                      Purchase Number Wise Supplier Stock Receipt Position
                      Details
                    </option>
                    <option value="Supplier Wise Purchase Receiving Summary">
                      Supplier Wise Purchase Receiving Summary
                    </option>
                  </select>
                  {selectReport !== "" ? (
                    <>
                      {selectReport ===
                      "All Supplier Wise Stock Receipt Position Details" ? (
                        <select
                          name="stock-report"
                          className="select-product-type"
                          onChange={(e) => {
                            setProductType(e.target.value);
                            setGenerateButton(false);
                          }}
                          value={productType}
                        >
                          <option value="">Select Product Type</option>
                          <option value="all">All</option>
                          <option value="Local">Local</option>
                          <option value="Foreign">Foreign</option>
                        </select>
                      ) : selectReport ===
                        "Purchase Number Wise Supplier Stock Receipt Position Details" ? (
                        <input
                          name="stock-report"
                          className="search-product"
                          placeholder="Search With Purchase Id"
                          onChange={(e) => setPurchaseId(e.target.value)}
                          value={purchaseId}
                        />
                      ) : (
                        <select
                          name="stock-report"
                          className="select-product-type"
                          onChange={(e) => {
                            setSelectSupplier(e.target.value);
                            setGenerateButton(false);
                          }}
                          value={selectSupplier}
                        >
                          <option value="">Select Supplier</option>
                          {supplierList.data?.results.map((supplier) => (
                            <option key={supplier.id} value={supplier.id}>
                              {supplier.supplier_name}
                            </option>
                          ))}
                        </select>
                      )}
                      <Button
                        className="generate-btn border-0"
                        type="submit"
                        onClick={handleGenerateReport}
                      >
                        Generate Report
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xl={3} className="d-flex justify-content-end">
                  <img
                    //className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={convertReportToPdf}
                    style={{ cursor: "pointer" }}
                  />
                </Col>
              </Row>
              {filterDateButton &&
              selectReport !==
                "Purchase Number Wise Supplier Stock Receipt Position Details" ? (
                <Row>
                  <Col lg={2} md={4} sm={6}>
                    <Form.Group
                      className="form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="report-form-label">
                        From Date
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="From Date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={4} sm={6}>
                    <Form.Group
                      className="form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="report-form-label">
                        To Date
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="To Date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        //style={{ height: "40px" }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </div>
            <div className="">
              <Row className="mt-3">
                <h4 className="product-heading">
                  {selectReport !== ""
                    ? selectReport
                    : "Select An Option to See Supplier Wise Receive at a Glance"}
                </h4>
              </Row>
            </div>
            {selectReport ===
              "All Supplier Wise Stock Receipt Position Details" &&
            generateButton ? (
              <SupplierStockRcvReport
                supplierWiseRcvList={supplierWiseRcvList}
                loading={loading}
                fromDate={fromDate}
                toDate={toDate}
              />
            ) : selectReport ===
                "Supplier Wise Stock Receipt Position Details" &&
              generateButton ? (
              <SupplierStockRcvReport
                supplierWiseRcvList={supplierWiseRcvList}
                loading={loading}
                fromDate={fromDate}
                toDate={toDate}
              />
            ) : selectReport ===
                "Purchase Number Wise Supplier Stock Receipt Position Details" &&
              generateButton ? (
              <PurchaseWiseStockRcvReport
                supplierWiseRcvList={supplierWiseRcvList}
                loading={loading}
                fromDate={fromDate}
                toDate={toDate}
              />
            ) : selectReport === "Supplier Wise Purchase Receiving Summary" &&
              generateButton ? (
              <SupplierRcvSummary
                supplierRcvSummary={supplierRcvSummary}
                summaryLoading={summaryLoading}
                fromDate={fromDate}
                toDate={toDate}
                limit={30}
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <div className="hide-print">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default SupplierWiseRcv;
