import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import SideBar from "../SideBar/Sidebar";
import Footer from "../Footer/Footer";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import calender from "../../Assets/icons/calender.png";
import cancel from "../../Assets/icons/cancel.png";
import restrictCalender from "../../Assets/icons/restrict-filter.png";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import "./reports.css";
import { getSupplierList } from "../../Redux/actions/supplierActions";
import {
  getAllStockList,
  getFrequencyAnalysisList,
  getSlowMovingProducts,
  getFastMovingProducts,
  getNonMovingProducts,
  getStockReturnReport,
  getZeroStockReport,
  getGpCompareReport,
  getCompareProductGpReport,
  nearExpiryProductReport,
  expiryProductReport,
} from "../../Redux/actions/stockAnalysisActions";
import { getCategoryList } from "../../Redux/actions/categoryActions";
import { Fragment } from "react";
import { convertTime } from "../../Services/ConvertDateTime";
import MovingProducts from "./StockAnalysis/MovingProducts";
import NonMovingProducts from "./StockAnalysis/NonMovingProducts";
import StockReturnReport from "./StockAnalysis/StockReturnReport";
import GpWiseProductReport from "./StockAnalysis/GpWiseReport";
import ZeroStockReport from "./StockAnalysis/ZeroStockReport";
import CompareProductGpReport from "./StockAnalysis/CompareProductGpReport";
import NearExpiryReport from "./StockAnalysis/NearExpiryProduct";
import ExpiryPorductReport from "./StockAnalysis/ExpiryProductReport";
import StockAnalysisReportPdf from "../../Services/StockAnalysisReportPdf";

const StockAnalysis = () => {
  const dispatch = useDispatch();
  const stockAnalysisSelections = [
    {
      id: 1,
      value: "All Stocks",
      title: "All Stock Product Information",
    },
    {
      id: 2,
      value: "Frequency Analysis to Price Change",
      title: "Change of Prices - Change Frequency Analysis",
    },
    {
      id: 3,
      value: "Slow Moving product",
      title: "Slow Moving Product Information",
    },
    {
      id: 4,
      value: "Fast moving product",
      title: "Fast Moving Product Information",
    },
    {
      id: 5,
      value: "Non moving product",
      title: "Non Moving/Dead Stock Product Information",
    },
    {
      id: 6,
      value: "Stock Returns",
      title: "Statement of Product Return Purchase",
    },
    {
      id: 7,
      value: "Zero Stock",
      title: "Supplier Details Stock Position-Zero Stock",
    },
    {
      id: 8,
      value: "High-Low GP% wise contribution",
      title: "High-Low GP% Wise Contribution",
    },
    {
      id: 9,
      value: "Compare product GP %",
      title: "Compare Product GP % With Other Supplier",
    },
    {
      id: 10,
      value: "Near Expiry Product",
      title: "Near Expiry Product Information",
    },
    {
      id: 11,
      value: "Expiry Product",
      title: "Expiry Product Information",
    },
  ];
  const [selectReport, setSelectReport] = useState("");
  const { supplierList } = useSelector((state) => state.supplierList);
  const [supplierId, setSupplierId] = useState("");
  const [filterDateButton, setFilterDateButton] = useState(false);
  const [searchResult, setSearchResult] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromPercentage, setFromPercentage] = useState("");
  const [toPercentage, setToPercentage] = useState("");
  const [filterAnalysisList, setFilterAnalysisList] = useState([]);
  const [genericName, setGenericName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const limit = 30;
  const offset = 0;

  const { loading, stockList, error } = useSelector((state) => state.allStock);
  const { analysisLoading, analysisList, analysisError } = useSelector(
    (state) => state.frequencyAnalysis
  );
  const { slowMovingLoading, slowMovingList, slowMovingError } = useSelector(
    (state) => state.slowMovingProductList
  );
  const { fastMovingLoading, fastMovingList, fastMovingError } = useSelector(
    (state) => state.fastMovingProductList
  );
  const { nonMovingLoading, nonMovingList, nonMovingError } = useSelector(
    (state) => state.nonMovingProuctList
  );
  const { stockReturnLoading, stockReturnList, stockReturnError } = useSelector(
    (state) => state.stockReturnList
  );
  const { zeroStockLoading, zeroStockList, zeroStockError } = useSelector(
    (state) => state.zeroStockProducts
  );
  const { gpCompareLoading, gpCompareList, gpCompareError } = useSelector(
    (state) => state.gpCompareProducts
  );
  const { compareProductLoading, compareProductList, compareProductError } =
    useSelector((state) => state.compareProductGp);

  const {
    nearExpiryProductLoading,
    nearExpiryProductList,
    nearExpiryProductError,
  } = useSelector((state) => state.nearExpiryProduct);

  const { expiryProductLoading, expiryProductList, expiryProductError } =
    useSelector((state) => state.expiryProduct);

  const { categoryList } = useSelector((state) => state.categoryList);

  useEffect(() => {
    if (!supplierList.data) {
      dispatch(getSupplierList(500, 0));
    }
  }, [supplierList.data]);

  useEffect(() => {
    if (
      !categoryList.data &&
      (selectReport === "Near Expiry Product" ||
        selectReport === "Expiry Product")
    ) {
      dispatch(getCategoryList());
    }
  }, [categoryList.data, selectReport]);

  useEffect(() => {
    if (analysisList.data && analysisList.data.results) {
      setFilterAnalysisList(
        analysisList.data.results.filter((data) => data.purchases.length)
      );
    }
  }, [analysisList.data]);

  const handleGenerateReport = () => {
    if (
      supplierId === "" &&
      selectReport !== "Compare product GP %" &&
      selectReport !== "Near Expiry Product" &&
      selectReport !== "Expiry Product"
    ) {
      alert("Please Select Supplier");
    } else if (supplierId && selectReport === "") {
      alert("Please Select Report Type");
    } else if (supplierId && selectReport) {
      setSearchResult(true);
      if (selectReport === "All Stocks") {
        if (fromDate !== "" && toDate !== "") {
          dispatch(
            getAllStockList(
              `?supplier_id=${supplierId}&from_date=${fromDate}&to_date=${toDate}`
            )
          );
        } else {
          dispatch(getAllStockList(`?supplier_id=${supplierId}`));
        }
      } else if (selectReport === "Frequency Analysis to Price Change") {
        if (fromDate !== "" && toDate !== "") {
          dispatch(
            getFrequencyAnalysisList(
              `?supplier_id=${supplierId}&from_date=${fromDate}&to_date=${toDate}`
            )
          );
        } else {
          dispatch(getFrequencyAnalysisList(`?supplier_id=${supplierId}`));
        }
      } else if (selectReport === "Slow Moving product") {
        if (fromDate !== "" && toDate !== "") {
          dispatch(
            getSlowMovingProducts(
              `?limit=${limit}&offset=${offset}&supplier_id=${supplierId}&from_date=${fromDate}&to_date=${toDate}`
            )
          );
        } else {
          dispatch(
            getSlowMovingProducts(
              `?limit=${limit}&offset=${offset}&supplier_id=${supplierId}`
            )
          );
        }
      } else if (selectReport === "Fast moving product") {
        if (fromDate !== "" && toDate !== "") {
          dispatch(
            getFastMovingProducts(
              `?limit=${limit}&offset=${offset}&supplier_id=${supplierId}&from_date=${fromDate}&to_date=${toDate}`
            )
          );
        } else {
          dispatch(
            getFastMovingProducts(
              `?limit=${limit}&offset=${offset}&supplier_id=${supplierId}`
            )
          );
        }
      } else if (selectReport === "Non moving product") {
        if (fromDate !== "" && toDate !== "") {
          dispatch(
            getNonMovingProducts(
              `?limit=${limit}&offset=${offset}&supplier_id=${supplierId}&from_date=${fromDate}&to_date=${toDate}`
            )
          );
        } else {
          dispatch(
            getNonMovingProducts(
              `?limit=${limit}&offset=${offset}&supplier_id=${supplierId}`
            )
          );
        }
      } else if (selectReport === "Stock Returns") {
        if (fromDate !== "" && toDate !== "") {
          dispatch(
            getStockReturnReport(
              `?limit=${limit}&offset=${offset}&supplier=${supplierId}&date_range_after=${fromDate}&date_range_before=${toDate}`
            )
          );
        } else {
          dispatch(
            getStockReturnReport(
              `?limit=${limit}&offset=${offset}&supplier=${supplierId}`
            )
          );
        }
      } else if (selectReport === "Zero Stock") {
        dispatch(
          getZeroStockReport(
            `?limit=${limit}&offset=${offset}&supplier=${supplierId}`
          )
        );
      } else if (selectReport === "High-Low GP% wise contribution") {
        if (fromPercentage !== "" || toPercentage !== "") {
          dispatch(
            getGpCompareReport(
              `?limit=${limit}&offset=${offset}&supplier_id=${supplierId}&from_percentage=${fromPercentage}&to_percentage=${toPercentage}`
            )
          );
        } else {
          dispatch(
            getGpCompareReport(
              `?limit=${limit}&offset=${offset}&supplier_id=${supplierId}`
            )
          );
        }
      }
    } else if (
      supplierId === "" &&
      (selectReport !== "Compare product GP %" ||
        selectReport !== "Near Expiry Product" ||
        selectReport !== "Expiry Product")
    ) {
      if (selectReport === "Compare product GP %") {
        if (genericName !== "") {
          setSearchResult(true);
          dispatch(
            getCompareProductGpReport(
              `&limit=${limit}&offset=${offset}&generic_name=${genericName}`
            )
          );
        } else {
          alert("Please insert Generic Name");
        }
      } else if (selectReport === "Near Expiry Product") {
        if (categoryId !== "") {
          if (fromDate !== "" && toDate !== "") {
            setSearchResult(true);
            dispatch(
              nearExpiryProductReport(
                `&limit=${limit}&offset=${offset}&Catagory=${categoryId}&From=${fromDate}&To=${toDate}`
              )
            );
          } else {
            alert("Please Select From Data & To Date");
          }
        } else {
          alert("Select Category");
        }
      } else if (selectReport === "Expiry Product") {
        if (categoryId !== "") {
          setSearchResult(true);
          dispatch(
            expiryProductReport(
              `&limit=${limit}&offset=${offset}&catagory=${categoryId}`
            )
          );
        } else {
          alert("Select Category");
        }
      }
    }
  };
  const convertReportToPdf = () => {
    if (searchResult && selectReport !== "") {
      if (selectReport === "All Stocks") {
        var headerRows = [
          [
            {
              content: `Supplier Name: ${selectedSupplier[0].supplier_name}`,
              colSpan: 9,
              styles: { halign: "start" },
            },
            {
              content: `From Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 2,
            },
            {
              content: `To Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 2,
            },
          ],
          [
            "Category",
            "Barcode",
            "Product",
            "CPU",
            "RPU",
            "Pft%",
            "Total Received",
            "Total Return",
            "Total Sold(Qty)",
            "W.Stock(Qty)",
            "D.Stock(Qty)",
            "Balance(Qty)",
            "Cost",
          ],
        ];
        StockAnalysisReportPdf(
          headerRows,
          stockList.data.products_data.results,
          selectReport,
          "All_Stocks"
        );
      } else if (selectReport === "Frequency Analysis to Price Change") {
        var headerRows = [
          [
            {
              content: `Supplier Name: ${selectedSupplier[0].supplier_name}`,
              colSpan: 4,
              styles: { halign: "start" },
            },
            {
              content: `From Date : ${fromDate !== "" ? fromDate : "N/A"}`,
              colSpan: 3,
            },
            {
              content: `To Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 3,
            },
          ],
          [
            {
              content: "Barcode",
              rowSpan: 2,
              styles: { valign: "middle", halign: "center" },
            },
            {
              content: "Product",
              rowSpan: 2,
              styles: { valign: "middle", halign: "center" },
            },
            {
              content: "Date",
              rowSpan: 2,
              styles: { valign: "middle", halign: "center" },
            },
            {
              content: "Time",
              rowSpan: 2,
              styles: { valign: "middle", halign: "center" },
            },
            {
              content: "Cost Price",
              colSpan: 2,
              //styles: { halign: "center", fillColor: [241, 196, 15] },
            },
            {
              content: "Sale Price",
              colSpan: 2,
              //styles: { halign: "center", fillColor: [241, 196, 15] },
            },
            {
              content: "Stock",
              rowSpan: 2,
              styles: { valign: "middle", halign: "center" },
            },
            {
              content: "Change By",
              rowSpan: 2,
              styles: { valign: "middle", halign: "center" },
            },
          ],
          ["Present", "Previous", "Present", "Previous"],
        ];
        StockAnalysisReportPdf(
          headerRows,
          filterAnalysisList,
          selectReport,
          "Frequency_Analysis_to_Price_Change_Report"
        );
      } else if (selectReport === "Slow Moving product") {
        var headerRows = [
          [
            {
              content: `Supplier Name: ${selectedSupplier[0].supplier_name}`,
              colSpan: 4,
              styles: { halign: "start" },
            },
            {
              content: `Total Products : ${slowMovingList.data.results.length}`,
              colSpan: 1,
            },
            {
              content: `From Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 1,
            },
            {
              content: `To Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 1,
            },
          ],
          [
            "Product",
            "Buy(Qty)",
            "Sold(Qty)",
            "Balance(Qty)",
            "Buy Date",
            "Last Sale Date",
            "Days Passed",
          ],
        ];
        StockAnalysisReportPdf(
          headerRows,
          slowMovingList.data.results,
          selectReport,
          selectReport
        );
      } else if (selectReport === "Fast moving product") {
        var headerRows = [
          [
            {
              content: `Supplier Name: ${selectedSupplier[0].supplier_name}`,
              colSpan: 4,
              styles: { halign: "start" },
            },
            {
              content: `Total Products : ${fastMovingList.data.results.length}`,
              colSpan: 1,
            },
            {
              content: `From Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 1,
            },
            {
              content: `To Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 1,
            },
          ],
          [
            "Product",
            "Buy(Qty)",
            "Sold(Qty)",
            "Balance(Qty)",
            "Buy Date",
            "Last Sale Date",
            "Days Passed",
          ],
        ];
        StockAnalysisReportPdf(
          headerRows,
          fastMovingList.data.results,
          selectReport,
          selectReport
        );
      } else if (selectReport === "Non moving product") {
        var headerRows = [
          [
            {
              content: `Supplier Name: ${selectedSupplier[0].supplier_name}`,
              colSpan: 3,
              styles: { halign: "start" },
            },
            {
              content: `Total Products : ${nonMovingList.data.results.length}`,
              colSpan: 1,
            },
            {
              content: `From Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 1,
            },
            {
              content: `To Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 1,
            },
          ],
          [
            "Category",
            "Barcode",
            "Product",
            "Buy(Qty)",
            "Buy Date",
            "Days Passed",
          ],
        ];
        StockAnalysisReportPdf(
          headerRows,
          nonMovingList.data.results,
          selectReport,
          selectReport
        );
      } else if (selectReport === "Zero Stock") {
        var headerRows = [
          [
            {
              content: `Supplier Name: ${selectedSupplier[0].supplier_name}`,
              colSpan: 7,
              styles: { halign: "start" },
            },
            {
              content: `Total Products : ${zeroStockList.data.results.products.length}`,
              colSpan: 2,
            },
            {
              content: `From Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 2,
            },
            {
              content: `To Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 2,
            },
          ],
          [
            "Barcode",
            "Category",
            "Product",
            "Receive Date",
            "Last Sale Date",
            "CPU",
            "RPU",
            "Pft%",
            "Received(Qty)",
            "Sold(Qty)",
            "Return(Qty)",
            "Balance(Qty)",
            "Total CPU",
          ],
        ];
        StockAnalysisReportPdf(
          headerRows,
          zeroStockList.data.results.products,
          selectReport,
          selectReport
        );
      } else if (selectReport === "Stock Returns") {
        var headerRows = [
          [
            {
              content: `Supplier Name: ${selectedSupplier[0].supplier_name}`,
              colSpan: 4,
              styles: { halign: "start" },
            },
            {
              content: `Total Products : ${stockReturnList.data.results.products.length}`,
              colSpan: 1,
            },
            {
              content: `From Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 1,
            },
            {
              content: `To Date : ${toDate !== "" ? toDate : "N/A"}`,
              colSpan: 1,
            },
          ],
          [
            "Barcode",
            "Product",
            "Return(Qty)",
            "Cost Price",
            "Total Cost Price",
            "Return Date",
            "Returned By",
          ],
        ];
        StockAnalysisReportPdf(
          headerRows,
          stockReturnList.data.results.products,
          selectReport,
          selectReport
        );
      } else if (selectReport === "High-Low GP% wise contribution") {
        var headerRows = [
          [
            {
              content: `Supplier Name: ${selectedSupplier[0].supplier_name}`,
              colSpan: 4,
              styles: { halign: "start" },
            },
            {
              content: `Total Products : ${gpCompareList.data.results.length}`,
              colSpan: 2,
            },
          ],
          ["Barcode", "Category", "Product", "CPU", "RPU", "Pft%"],
        ];
        StockAnalysisReportPdf(
          headerRows,
          gpCompareList.data.results,
          selectReport,
          selectReport
        );
      } else if (selectReport === "Compare product GP %") {
        var headerRows = [
          ["Supplier Name", "Generic Name", "Product", "CPU", "RPU", "Pft%"],
        ];
        StockAnalysisReportPdf(
          headerRows,
          compareProductList.data.data,
          selectReport,
          selectReport
        );
      } else if (selectReport === "Near Expiry Product") {
        var headerRows = [
          [
            "Barcode",
            "Supplier Name",
            "Category",
            "Product",
            "Batch No.",
            "Received(Qty)",
            "Sold(Qty)",
            "Balance(Qty)",
            "CPU",
            "RPU",
            "Pft%",
            "Purchase Date",
            "Expiry Date",
          ],
        ];
        StockAnalysisReportPdf(
          headerRows,
          nearExpiryProductList.data.data,
          selectReport,
          selectReport
        );
      } else if (selectReport === "Expiry Product") {
        var headerRows = [
          [
            "Barcode",
            "Supplier Name",
            "Category",
            "Product",
            "Vendor Name",
            "Batch No.",
            "Received(Qty)",
            "Sold(Qty)",
            "Balance(Qty)",
            "CPU",
            "RPU",
            "Expiry Date",
          ],
        ];
        StockAnalysisReportPdf(
          headerRows,
          expiryProductList.data.data1,
          selectReport,
          selectReport
        );
      }
    } else {
      alert("Please Generate Report First");
    }
  };
  //console.log(fromDate);
  return (
    <div>
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <Container fluid>
          <h4 className="product-heading container-view">
            Stock Report at a Glance
          </h4>
          <p className="product-paragraph container-view">
            Reports <FontAwesomeIcon icon={faChevronRight} size="xs" /> Stock
            Analysis
          </p>
          <div
            className="pb-4 card product-card container-view"
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
          >
            {selectReport !== "Compare product GP %" &&
            selectReport !== "Near Expiry Product" &&
            selectReport !== "Expiry Product" ? (
              <Row className="mb-4 pt-3" style={{ backgroundColor: "#DCE0E4" }}>
                <Col lg={3}>
                  <Form.Group
                    className=" form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Select Supplier
                      <span className="required-field">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => {
                        setSupplierId(e.target.value);
                        setSelectedSupplier(
                          supplierList.data.results.filter(
                            (s) => s.id === parseInt(e.target.value)
                          )
                        );
                        setSearchResult(false);
                        //console.log(e.target.value);
                      }}
                      value={supplierId}
                    >
                      <option value="">Select Supplier</option>
                      {supplierList.data?.results.map((supplier) => (
                        <option key={supplier.id} value={supplier.id}>
                          {supplier.supplier_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className=" form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Supplier Id<span></span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Supplier Id"
                      value={supplierId}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className=" form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Address<span></span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Supplier Address"
                      value={
                        selectedSupplier.length
                          ? selectedSupplier[0].supplier_address
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group
                    className=" form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Contact Number<span></span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Contact Number"
                      value={
                        selectedSupplier.length
                          ? selectedSupplier[0].contact_number
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : (
              <Row className="p-2"></Row>
            )}
            <div className="">
              <Row className="py-2">
                <Col xl={9}>
                  {selectReport === "Zero Stock" ||
                  selectReport === "High-Low GP% wise contribution" ||
                  selectReport === "Compare product GP %" ||
                  selectReport === "Expiry Product" ? (
                    <img
                      src={restrictCalender}
                      alt="restrictCalender"
                      height={45}
                      style={{ cursor: "not-allowed" }}
                    />
                  ) : (
                    <img
                      src={filterDateButton ? cancel : calender}
                      alt="calender"
                      height={45}
                      style={{ cursor: "pointer" }}
                      onClick={() => setFilterDateButton(!filterDateButton)}
                    />
                  )}
                  <select
                    name="stock-report"
                    className="ms-3 select-bar"
                    onChange={(e) => {
                      setSelectReport(e.target.value);
                      setFromDate("");
                      setToDate("");
                      setSearchResult(false);
                    }}
                    onClick={() => {
                      if (
                        supplierId !== "" &&
                        (selectReport === "Compare product GP %" ||
                          selectReport === "Near Expiry Product" ||
                          selectReport === "Expiry Product")
                      ) {
                        setSupplierId("");
                      }
                    }}
                    value={selectReport}
                  >
                    <option value="">Select Report Type</option>
                    {stockAnalysisSelections.map((report) => (
                      <option key={report.id} value={report.value}>
                        {report.value}
                      </option>
                    ))}
                  </select>
                  {selectReport === "High-Low GP% wise contribution" ? (
                    <>
                      <input
                        className="percentage-input ms-2"
                        type="text"
                        placeholder="From Percentage"
                        value={fromPercentage}
                        onChange={(e) => setFromPercentage(e.target.value)}
                      />
                      <input
                        className="percentage-input ms-2"
                        type="text"
                        placeholder="To Percentage"
                        value={toPercentage}
                        onChange={(e) => setToPercentage(e.target.value)}
                      />
                    </>
                  ) : selectReport === "Compare product GP %" ? (
                    <>
                      <input
                        className="generic-input ms-3"
                        type="text"
                        placeholder="Search By Generic Name"
                        value={genericName}
                        onChange={(e) => setGenericName(e.target.value)}
                      />
                    </>
                  ) : selectReport === "Near Expiry Product" ||
                    selectReport === "Expiry Product" ? (
                    <>
                      <select
                        className="select-product-type ms-3"
                        onChange={(e) => {
                          setCategoryId(e.target.value);
                          setSearchResult(false);
                          //console.log(e.target.value);
                        }}
                        value={categoryId}
                      >
                        <option value="">Select Category</option>
                        <option value="all">All</option>
                        {categoryList.data?.results.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : (
                    <></>
                  )}
                  <Button
                    className="border-0 ms-3"
                    style={{
                      height: "45px",
                      //width: "100%",
                      backgroundColor: "#ff9900",
                      fontWeight: "700",
                      fontSize: "16px",
                    }}
                    onClick={handleGenerateReport}
                  >
                    Generate Report
                  </Button>
                </Col>
                <Col xl={3} className="d-flex justify-content-end">
                  <img
                    className=""
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    style={{ cursor: "pointer" }}
                    onClick={convertReportToPdf}
                  />
                </Col>
              </Row>
            </div>
            {filterDateButton &&
            selectReport !== "Zero Stock" &&
            selectReport !== "High-Low GP% wise contribution" &&
            selectReport !== "Compare product GP %" &&
            selectReport !== "Expiry Product" ? (
              <Row className="">
                <Col lg={2} md={4} sm={6}>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label className="report-form-label">
                      From Date
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="From Date"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={2} md={4} sm={6}>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label className="report-form-label">
                      To Date
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="To Date"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                      //style={{ height: "40px" }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row className="mt-3">
              <h4 className="product-heading show-print">
                {selectReport !== ""
                  ? selectReport
                  : "Select An Option to See Details About Stock Analysis"}
              </h4>
            </Row>
            {selectReport === "All Stocks" && searchResult ? (
              <>
                <div className="mt-2 transfer-table">
                  <Row
                    className="mx-0 py-2 table-header"
                    //style={{ borderBottom: "1px solid #dee2e6" }}
                  >
                    <Col lg={4}>
                      <b>Supplier Name: </b>{" "}
                      {selectedSupplier.length
                        ? selectedSupplier[0].supplier_name
                        : ""}
                    </Col>
                    <Col className="d-flex justify-content-center" lg={4}>
                      <b>From Date: </b> {fromDate ? fromDate : "N/A"}
                    </Col>
                    <Col className="d-flex justify-content-end" lg={4}>
                      <b>To Date: </b>
                      {toDate ? toDate : "N/A"}
                    </Col>
                  </Row>
                </div>
                <Table responsive bordered>
                  <thead className="text-center report-table-header report-border">
                    <tr>
                      <th>Category</th>
                      <th>Barcode</th>
                      <th>Product Information</th>
                      <th>CPU (Avg.)</th>
                      <th>RPU (Avg.)</th>
                      <th>Pft %</th>
                      <th>Total Received(Qty)</th>
                      <th>Total Return(Qty)</th>
                      <th>Total Sold</th>
                      <th>W.stock(Qty)</th>
                      <th>R.stock(Qty)</th>
                      <th>Balance(Qty)</th>
                      <th>Cost(Tk Exp.)</th>
                    </tr>
                  </thead>
                  <tbody className="text-center report-table-body">
                    {stockList.data &&
                    !loading &&
                    stockList.data.category_data &&
                    stockList.data.category_data.length ? (
                      stockList.data?.category_data.map((cat, i) => (
                        <Fragment key={i}>
                          {stockList.data.products_data.results.map((prod, j) =>
                            prod.category_name === cat.category_name ? (
                              <tr key={prod.id}>
                                <td>{prod.category_name}</td>
                                <td>{prod.barcode ? prod.barcode : "N/A"}</td>
                                <td>{prod.product_name}</td>
                                <td>{prod.cost_per_unit}</td>
                                <td>{prod.retail_per_unit}</td>
                                <td>{prod.gross_profit}</td>
                                <td>{prod.received}</td>
                                <td>{prod.returned}</td>
                                <td>{prod.sold}</td>
                                <td>{prod.wh_stock}</td>
                                <td>{prod.retail_stock}</td>
                                <td>{prod.balance}</td>
                                <td>{prod.cost}</td>
                              </tr>
                            ) : (
                              <Fragment key={j}></Fragment>
                            )
                          )}
                          <tr>
                            <td colSpan={6} className="text-start">
                              <b>Sub Total of {cat.category_name}</b>
                            </td>
                            <td>{cat.total_received}</td>
                            <td>{cat.total_returned}</td>
                            <td>{cat.total_sold}</td>
                            <td>{cat.total_wh}</td>
                            <td>{cat.total_retail}</td>
                            <td>{cat.total_balance}</td>
                            <td>{cat.total_cost}</td>
                          </tr>
                        </Fragment>
                      ))
                    ) : loading ? (
                      <tr>
                        <td colSpan={13}>
                          <Spinner animation="border" variant="warning" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={13}>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {!loading && stockList.data ? (
                  <div className="p-3">
                    <Row className="">
                      <Col
                        className="d-flex justify-content-end"
                        style={{ height: "30px" }}
                      >
                        <p>
                          <b>Total Stock Received (Qty) : </b>{" "}
                          {stockList.data.grand_total.total_stock_received}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="d-flex justify-content-end"
                        style={{ height: "30px" }}
                      >
                        <p>
                          <b>Total Stock Return(Qty) : </b>{" "}
                          {stockList.data.grand_total.total_stock_returned}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="d-flex justify-content-end"
                        style={{ height: "30px" }}
                      >
                        <p>
                          <b>Net Stock Received(Qty) : </b>{" "}
                          {stockList.data.grand_total.net_stock_received}
                        </p>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col
                        className="d-flex justify-content-end"
                        style={{ height: "30px" }}
                      >
                        <p>
                          <b>Net Balance (Qty) : </b>{" "}
                          {stockList.data.grand_total.net_balance}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="d-flex justify-content-end"
                        style={{ height: "30px" }}
                      >
                        <p>
                          <b>Total Consumption% : </b>{" "}
                          {stockList.data.grand_total.total_consumption} %
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="d-flex justify-content-end"
                        style={{ height: "30px" }}
                      >
                        <p>
                          <b>Total Cost of Balance Qty(TK) : </b>{" "}
                          {stockList.data.grand_total.total_cost_balance} ৳
                        </p>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : selectReport === "Frequency Analysis to Price Change" &&
              searchResult ? (
              <>
                <div className="mt-2 transfer-table">
                  <Row className="mx-0 py-2 table-header">
                    <Col lg={4}>
                      <b>Supplier Name: </b>{" "}
                      {selectedSupplier.length
                        ? selectedSupplier[0].supplier_name
                        : ""}
                    </Col>
                    <Col className="d-flex justify-content-center" lg={4}>
                      <b>From Date: </b> {fromDate ? fromDate : "N/A"}
                    </Col>
                    <Col className="d-flex justify-content-end" lg={4}>
                      <b>To Date: </b> {toDate ? toDate : "N/A"}
                    </Col>
                  </Row>
                </div>
                <Table responsive bordered>
                  <thead className="text-center report-table-header">
                    <tr>
                      <th rowSpan={2} style={{ verticalAlign: "middle" }}>
                        Barcode
                      </th>
                      <th rowSpan={2} style={{ verticalAlign: "middle" }}>
                        Product Information
                      </th>
                      <th rowSpan={2} style={{ verticalAlign: "middle" }}>
                        Date
                      </th>
                      <th rowSpan={2} style={{ verticalAlign: "middle" }}>
                        Time
                      </th>
                      <th colSpan={2}>Cost Price</th>
                      <th colSpan={2}>Sale Price</th>
                      <th rowSpan={2} style={{ verticalAlign: "middle" }}>
                        Stock
                      </th>
                      <th rowSpan={2} style={{ verticalAlign: "middle" }}>
                        Change By
                      </th>
                    </tr>
                    <tr>
                      <th>Present</th>
                      <th>Old</th>
                      <th>Present</th>
                      <th>Old</th>
                    </tr>
                  </thead>
                  <tbody className="text-center report-table-body">
                    {filterAnalysisList.length && !analysisLoading ? (
                      filterAnalysisList.map((data, i) => (
                        <Fragment key={i}>
                          <tr>
                            <td
                              rowSpan={data.purchases.length}
                              style={{ verticalAlign: "middle" }}
                            >
                              {data.barcode ? data.barcode : "N/A"}
                            </td>
                            <td
                              rowSpan={data.purchases.length}
                              style={{ verticalAlign: "middle" }}
                            >
                              {data.product_name} {data.generic_name}{" "}
                              {data.unit}
                            </td>
                            <td>
                              {data.purchases[0].created_at.split("T")[0]}
                            </td>
                            <td>{convertTime(data.purchases[0].created_at)}</td>
                            <td>{data.purchases[0].new_purchase_price}</td>
                            <td>{data.purchases[0].purchase_price}</td>
                            <td>{data.purchases[0].new_sales_price}</td>
                            <td>{data.purchases[0].sales_price}</td>
                            <td>{data.purchases[0].quantity_amount}</td>
                            <td>{data.purchases[0].changed_by}</td>
                          </tr>
                          {data.purchases.map((purchase, j) =>
                            j > 0 ? (
                              <tr key={j}>
                                <td>{purchase.created_at.split("T")[0]}</td>
                                <td>{convertTime(purchase.created_at)}</td>
                                <td>{purchase.new_purchase_price}</td>
                                <td>{purchase.purchase_price}</td>
                                <td>{purchase.new_sales_price}</td>
                                <td>{purchase.sales_price}</td>
                                <td>{purchase.quantity_amount}</td>
                                <td>{purchase.changed_by}</td>
                              </tr>
                            ) : (
                              <></>
                            )
                          )}
                        </Fragment>
                      ))
                    ) : analysisLoading === true ? (
                      <tr>
                        <td colSpan={13}>
                          <Spinner animation="border" variant="warning" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={13}>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </>
            ) : selectReport === "Slow Moving product" && searchResult ? (
              <MovingProducts
                slowMovingList={slowMovingList}
                limit={limit}
                selectReport={selectReport}
                fromDate={fromDate}
                toDate={toDate}
                selectedSupplier={selectedSupplier}
                supplierId={supplierId}
                slowMovingLoading={slowMovingLoading}
              />
            ) : selectReport === "Fast moving product" && searchResult ? (
              <MovingProducts
                fastMovingList={fastMovingList}
                limit={limit}
                selectReport={selectReport}
                fromDate={fromDate}
                toDate={toDate}
                selectedSupplier={selectedSupplier}
                supplierId={supplierId}
                fastMovingLoading={fastMovingLoading}
              />
            ) : selectReport === "Non moving product" && searchResult ? (
              <NonMovingProducts
                nonMovingList={nonMovingList}
                limit={limit}
                fromDate={fromDate}
                toDate={toDate}
                selectedSupplier={selectedSupplier}
                supplierId={supplierId}
                nonMovingLoading={nonMovingLoading}
              />
            ) : selectReport === "Stock Returns" && searchResult ? (
              <StockReturnReport
                stockReturnList={stockReturnList}
                limit={limit}
                fromDate={fromDate}
                toDate={toDate}
                selectedSupplier={selectedSupplier}
                supplierId={supplierId}
                stockReturnLoading={stockReturnLoading}
              />
            ) : selectReport === "Zero Stock" && searchResult ? (
              <ZeroStockReport
                zeroStockList={zeroStockList}
                limit={limit}
                selectedSupplier={selectedSupplier}
                supplierId={supplierId}
                zeroStockLoading={zeroStockLoading}
              />
            ) : selectReport === "High-Low GP% wise contribution" &&
              searchResult ? (
              <GpWiseProductReport
                gpCompareList={gpCompareList}
                limit={limit}
                fromPercentage={fromPercentage}
                toPercentage={toPercentage}
                selectedSupplier={selectedSupplier}
                supplierId={supplierId}
                gpCompareLoading={gpCompareLoading}
              />
            ) : selectReport === "Compare product GP %" && searchResult ? (
              <CompareProductGpReport
                compareProductList={compareProductList}
                limit={limit}
                genericName={genericName}
                compareProductLoading={compareProductLoading}
              />
            ) : selectReport === "Near Expiry Product" && searchResult ? (
              <NearExpiryReport
                nearExpiryProductList={nearExpiryProductList}
                limit={limit}
                categoryId={categoryId}
                nearExpiryProductLoading={nearExpiryProductLoading}
                fromDate={fromDate}
                toDate={toDate}
              />
            ) : selectReport === "Expiry Product" && searchResult ? (
              <ExpiryPorductReport
                expiryProductList={expiryProductList}
                limit={limit}
                categoryId={categoryId}
                expiryProductLoading={expiryProductLoading}
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default StockAnalysis;
/**
 * {data.purchases.map((purchase, i) => (
                            <tr key={i}>
                              <td>{purchase.created_at.split("T")[0]}</td>
                              <td>{convertTime(purchase.created_at)}</td>
                              <td>{purchase.new_purchase_price}</td>
                              <td>{purchase.purchase_price}</td>
                              <td>{purchase.new_sales_price}</td>
                              <td>{purchase.sales_price}</td>
                              <td>{purchase.quantity_amount}</td>
                              <td>{purchase.changed_by}</td>
                            </tr>
                          ))}
 */
