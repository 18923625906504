import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Placeholder,
} from "react-bootstrap";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import "../Products/Product.css";
import "../Transfer/transfer.css";
import { getPurchaseOrderDetails } from "../../Redux/actions/purchaseOrderActions";
import SinglePurchaseOrderPdf from "../../Services/SinglePurchaseOrderPdf";

const EachPurchaseOrder = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const { loading, purchaseOrder, error } = useSelector(
    (state) => state.purchaseOrderDetails
  );
  useEffect(() => {
    dispatch(getPurchaseOrderDetails(param.id));
  }, [dispatch, param.id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //console.log(purchaseOrder);
  return (
    <div>
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <Container fluid>
          <h4 className="product-heading container-view">
            Purchase Order Details
          </h4>
          <p className="product-paragraph container-view">
            Full Details of Purchase Order List
          </p>
          <div className="mb-4 p-3 card product-card container-view">
            <Row className="mb-4">
              <Col className="d-flex justify-content-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as pdf</Tooltip>}
                >
                  <img
                    //className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => SinglePurchaseOrderPdf(purchaseOrder)}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img
                  className="d-none pe-3"
                  src={excelIcon}
                  alt="pdf"
                  height={45}
                />
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            {loading ? (
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
            ) : (
              <Row>
                <Col lg={6}>
                  <p>
                    <b>PO ID:</b> {purchaseOrder.po_id}
                  </p>
                </Col>
                <Col lg={6}>
                  <p>
                    <b>Vendor Name:</b>{" "}
                    {purchaseOrder.vendor
                      ? purchaseOrder.vendor.vendor_name
                      : "N/A"}
                  </p>
                </Col>
              </Row>
            )}

            {loading ? (
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
            ) : (
              <Row>
                <Col lg={6}>
                  <p>
                    <b>Supplier Name:</b>{" "}
                    {purchaseOrder.supplier?.supplier_name}
                  </p>
                </Col>
                <Col lg={6}>
                  <p>
                    <b>PO Date:</b> {purchaseOrder.created_at?.split("T")[0]}
                  </p>
                </Col>
              </Row>
            )}

            <Table bordered responsive hover>
              <thead className="text-center table-header">
                <tr>
                  <th>SL</th>
                  <th>Barcode</th>
                  <th>Product Name</th>
                  <th>Style & Size</th>
                  <th>Sub Category</th>
                  <th>Generic Name</th>
                  <th>CPU</th>
                  <th>PO Quantity</th>
                  <th>Total Cost</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {!loading && purchaseOrder?.purchase_products ? (
                  <>
                    {purchaseOrder.purchase_products.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {item.product.barcode ? item.product.barcode : "N/A"}
                        </td>
                        <td>{item.product.product_name} </td>
                        <td>{item.product.unit}</td>
                        <td>{item.product.sub_category}</td>
                        <td>{item.product.generic_name} </td>
                        <td>{item.cpu} </td>
                        <td>{item.order_quantity}</td>
                        <td>{item.order_amount}</td>
                      </tr>
                    ))}
                    <tr style={{ fontWeight: "600" }}>
                      <td className="text-start" colSpan={7}>
                        Grand Total
                      </td>
                      <td>{purchaseOrder.total_product}</td>
                      <td>{purchaseOrder.total_cost}৳</td>
                    </tr>
                  </>
                ) : !loading && !purchaseOrder?.purchase_products.length ? (
                  <tr>
                    <td colSpan="12">No Product Found</td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="12">
                      <Spinner animation="border" variant="warning" />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default EachPurchaseOrder;
