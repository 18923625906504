import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Spinner, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { expiryProductReport } from "../../../Redux/actions/stockAnalysisActions";

const ExpiryPorductReport = (props) => {
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (props.expiryProductList?.data) {
      setTotalItems(props.expiryProductList.data.count);
    }
  }, [props.expiryProductList]);
  const pageCount = Math.ceil(totalItems / props.limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.limit) % totalItems;
    if (props.categoryId !== "") {
      dispatch(
        expiryProductReport(
          `?limit=${props.limit}&offset=${newOffset}&catagory=${props.categoryId}`
        )
      );
    }
  };
  return (
    <>
      <Table bordered responsive>
        <thead className="text-center report-table-header report-border">
          <tr>
            <th>Barcode</th>
            <th>Supplier Name</th>
            <th>Category</th>
            <th>Product Information</th>
            <th>Vendor Name</th>
            <th>Batch No.</th>
            <th>Received (Qty)</th>
            <th>Sold (Qty)</th>
            <th>Balance (Qty)</th>
            <th>CPU</th>
            <th>RPU</th>
            <th>Expiry Date</th>
          </tr>
        </thead>
        <tbody className="text-center report-table-body">
          {props.expiryProductList.data &&
          !props.expiryProductLoading &&
          props.expiryProductList.data.data1.length ? (
            <>
              {props.expiryProductList.data.data1.map((prod, i) => (
                <tr key={i}>
                  <td>
                    {prod.product__barcode ? prod.product__barcode : "N/A"}
                  </td>
                  <td>
                    {prod.product__supplier_Name__supplier_name
                      ? prod.product__supplier_Name__supplier_name
                      : "N/A"}
                  </td>
                  <td>{prod.product__Sub_catagory_1__catagory__name}</td>
                  <td>
                    {prod.product__product_name} {prod.product__unit}
                  </td>
                  <td>
                    {prod.vendor_name.map((vendor, j) =>
                      prod.vendor_name.length - 1 === j
                        ? vendor.purchaseorderplaced__vendor_name
                        : vendor.purchaseorderplaced__vendor_name + " , "
                    )}
                  </td>
                  <td>{prod.batch__batch_name}</td>
                  <td>{prod.recevied}</td>
                  <td>{prod.sold}</td>
                  <td>{prod.balance_quantity}</td>
                  <td>{prod.batch__purchase_price}</td>
                  <td>
                    {prod.product__new_product_price
                      ? prod.product__new_product_price
                      : prod.product__product_price}
                  </td>
                  <td>{prod.batch__expire_date}</td>
                </tr>
              ))}
              <tr className="grand-total-row">
                <td>Grand Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {props.expiryProductList.data.data1.reduce(
                    (total, prod) => (total += prod.recevied),
                    0
                  )}
                </td>
                <td>
                  {props.expiryProductList.data.data1.reduce(
                    (total, prod) => (total += prod.sold),
                    0
                  )}
                </td>
                <td>
                  {props.expiryProductList.data.data1.reduce(
                    (total, prod) => (total += prod.balance_quantity),
                    0
                  )}
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </>
          ) : props.expiryProductLoading ? (
            <tr>
              <td colSpan={12}>
                <Spinner animation="border" variant="warning" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={12}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
      {pageCount > 1 && !props.expiryProductLoading ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousClassName="hide"
          nextClassName="hide"
          activeLinkClassName="active"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ExpiryPorductReport;
