import { TOGGLE_SIDE_BAR, SET_PAGE_NUMBER } from "../type";

export const SidebarAction = (changeState) => async (dispatch) => {
  dispatch({
    type: TOGGLE_SIDE_BAR,
    payload: changeState,
  });
  localStorage.setItem("menuState", changeState);
};

export const setPageNumberAction =
  (pageNumber, cacheData) => async (dispatch) => {
    dispatch({
      type: SET_PAGE_NUMBER,
      payload: {
        pageNumber: pageNumber,
        cacheData: cacheData,
      },
    });
  };
