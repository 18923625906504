import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Spinner, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getStockReturnReport } from "../../../Redux/actions/stockAnalysisActions";

const StockReturnReport = (props) => {
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (props.stockReturnList?.data) {
      setTotalItems(props.stockReturnList.data.count);
    }
  }, [props.stockReturnList]);
  const pageCount = Math.ceil(totalItems / props.limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.limit) % totalItems;
    if (props.fromDate !== "" && props.toDate !== "") {
      dispatch(
        getStockReturnReport(
          `?limit=${props.limit}&offset=${newOffset}&supplier=${props.supplierId}&date_range_after=${props.fromDate}&date_range_before=${props.toDate}`
        )
      );
    } else {
      dispatch(
        getStockReturnReport(
          `?limit=${props.limit}&offset=${newOffset}&supplier=${props.supplierId}`
        )
      );
    }
  };
  return (
    <>
      <div className="mt-2 transfer-table">
        <Row
          className="mx-0 py-2 table-header"
          //style={{ borderBottom: "1px solid #dee2e6" }}
        >
          <Col lg={4}>
            <b>Supplier Name: </b>{" "}
            {props.selectedSupplier.length
              ? props.selectedSupplier[0].supplier_name
              : ""}
          </Col>
          <Col lg={2}>
            <b>Total Products: </b>{" "}
            {props.stockReturnList.data
              ? props.stockReturnList.data.results.total_products
              : ""}
          </Col>
          <Col lg={3} className="d-flex justify-content-center">
            <b>From Date: </b>{" "}
            {props.stockReturnList.data
              ? props.stockReturnList.data.results.date_range_after
              : "N/A"}
          </Col>
          <Col lg={3} className="d-flex justify-content-end">
            <b>To Date: </b>
            {props.stockReturnList.data
              ? props.stockReturnList.data.results.date_range_before
              : "N/A"}
          </Col>
        </Row>
      </div>
      <Table bordered responsive>
        <thead className="text-center report-table-header report-border">
          <tr>
            <th>Barcode</th>
            <th>Product Information</th>
            <th>Return (Qty)</th>
            <th>Cost Price</th>
            <th>Total Cost Price</th>
            <th>Return Date</th>
            <th>Return By</th>
          </tr>
        </thead>
        <tbody className="text-center report-table-body">
          {props.stockReturnList.data &&
          !props.stockReturnLoading &&
          props.stockReturnList.data.results.products &&
          props.stockReturnList.data.results.products.length ? (
            props.stockReturnList.data.results.products.map((prod, i) => (
              <tr key={i}>
                <td>{prod.product_barcode ? prod.product_barcode : "N/A"}</td>
                <td>
                  {prod.product_name} {prod.product_generic_name}{" "}
                  {prod.product_unit}
                </td>
                <td>{prod.returned_quantity}</td>
                <td>{prod.cost_price}</td>
                <td>{prod.total_cost_price}</td>
                <td>{prod.returned_at ? prod.returned_at : "N/A"}</td>
                <td>{prod.returned_by}</td>
              </tr>
            ))
          ) : props.stockReturnLoading ? (
            <tr>
              <td colSpan={12}>
                <Spinner animation="border" variant="warning" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={12}>No Data Found</td>
            </tr>
          )}
          {props.stockReturnList.data &&
          !props.stockReturnLoading &&
          props.stockReturnList.data.results.products ? (
            <tr style={{ fontWeight: "700" }}>
              <td colSpan={2} className="text-start">
                Grand Total
              </td>
              <td>{props.stockReturnList.data.results.grand_quantity}</td>
              <td>{props.stockReturnList.data.results.grand_cost_price}</td>
              <td>
                {props.stockReturnList.data.results.grand_total_cost_price}
              </td>
              <td colSpan={2}></td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </Table>
      {pageCount > 1 && !props.stockReturnLoading ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousClassName="hide"
          nextClassName="hide"
          activeLinkClassName="active"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default StockReturnReport;
