import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEye,
  faPenAlt,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import filter from "../../Assets/icons/filter.png";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import "../Products/Product.css";
import {
  getDuePaymentList,
  searchDuePaymentList,
} from "../../Redux/actions/duePaymentActions";
import DueUpdateModal from "../PopupModal/DueUpdateModal";
import LoadingModal from "../PopupModal/LoadingModal";
import axios from "axios";
import generatePDF from "../../Services/pdfGenerator";
import exportExcel from "../../Services/ExcelGenerator";
import DueDetailsTableRow from "./DueDetailsTableRow";

const DueDetailList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [selected, setSelected] = useState("");
  const [exportData, setExportData] = useState(false);

  const { loading, duePaymentList, error } = useSelector(
    (state) => state.duePaymentList
  );

  const { searchLoading, searchDuePayments, searchError } = useSelector(
    (state) => state.searchDuePayment
  );

  useEffect(() => {
    dispatch(getDuePaymentList(limit, offset));
  }, [dispatch, offset]);
  useEffect(() => {
    if (duePaymentList?.data) {
      setTotalItems(duePaymentList.data.count);
    }
  }, [duePaymentList]);
  //console.log(duePaymentList);
  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
  };

  const handleSearchResult = (e) => {
    //console.log(e.target.value);
    if (e.target.value.length > 1) {
      setShowSearchResult(true);
      dispatch(searchDuePaymentList(e.target.value));
    } else {
      setShowSearchResult(false);
    }
  };

  const handleUpdateOrder = (item) => {
    setSelectedItem(item);
    setUpdateModalShow(true);
  };

  /** Export All Data */
  const getDataFromApi = (type) => {
    setExportData(true);
    try {
      //const form_data = new FormData();
      //form_data.append("csv_file", filefield);
      //const body = data;
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const api = `${process.env.REACT_APP_BASE_URL}/api/v1/product/polls/due-payment-list/?limit=${totalItems}&offset=0`;

      axios
        .get(api, settings)
        .then((res) => {
          //console.log("success", res.data.results);
          //setAssetList([...assetList, ...res.data.results]);
          if (type === "pdf") {
            setExportData(false);
            generatePDF(
              res.data.results,
              "Due Detail",
              [
                "Invoice ID",
                "Customer Name",
                "Phone",
                "Order Type",
                "Payment Type",
                "Vat",
                "Discount",
                "Net Amount",
              ],
              "Care-Box Due Details List",
              totalItems
            );
          } else if (type === "excel") {
            const exportData = [];
            res.data.results.map((each) => {
              let obj = {};
              obj["Invoice ID"] = each.id;
              obj["Customer Name"] = each.user.Name;
              obj["Phone"] = each.Phone;
              obj["Order Type"] = each.order_type;
              obj["Payment Type"] = each.paymentMethod;
              obj["Vat"] = each.totalVatAmount;
              obj["Discount"] = each.discountAmount;
              obj["Net Amount"] = each.totalPrice;
              exportData.push(obj);
            });
            exportExcel(exportData, "Care_Box_Due Details_List.xlsx");
            setExportData(false);
          }
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };

  return (
    <div>
      <DueUpdateModal
        show={updateModalShow}
        onHide={() => {
          setUpdateModalShow(false);
          setSelectedItem("");
        }}
        selectedOrder={selectedItem}
        action={() => setShowSearchResult(false)}
      />
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Due Details List</h4>
              <p className="product-paragraph">
                {" "}
                Due Details <FontAwesomeIcon
                  icon={faChevronRight}
                  size="xs"
                />{" "}
                Due Details List
              </p>
            </Col>
          </Row>
          <div
            className="mb-4 p-3 card product-card container-view"
            style={{ minHeight: "50vh" }}
          >
            <Row className="mb-4">
              <Col lg={6}>
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchResult}
                />
              </Col>
              <Col lg={6} className="d-flex justify-content-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as pdf</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getDataFromApi("pdf")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as excel</Tooltip>}
                >
                  <img
                    //className="pe-3"
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getDataFromApi("excel")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            <Table responsive hover>
              <thead className="text-center table-header">
                <tr>
                  <th>Invoice Id</th>
                  <th>Customer Name</th>
                  <th>Phone</th>
                  <th>Order Type</th>
                  <th>Payment Type</th>
                  <th>Vat</th>
                  <th>Discount</th>
                  <th>Net Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {showSearchResult &&
                searchDuePayments?.data !== undefined &&
                searchDuePayments?.data.results.length !== 0 ? (
                  searchDuePayments?.data.results.map((list) => (
                    <DueDetailsTableRow
                      list={list}
                      selected={selected}
                      setSelected={setSelected}
                      handleUpdateOrder={handleUpdateOrder}
                      key={list.id}
                    />
                  ))
                ) : !showSearchResult &&
                  duePaymentList?.data !== undefined &&
                  duePaymentList?.data.results.length !== 0 ? (
                  duePaymentList?.data.results.map((list) => (
                    <DueDetailsTableRow
                      list={list}
                      selected={selected}
                      setSelected={setSelected}
                      handleUpdateOrder={handleUpdateOrder}
                      key={list.id}
                    />
                  ))
                ) : loading === true ? (
                  <tr>
                    <td colSpan="12">
                      <Spinner animation="border" variant="warning" />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="12">No Result Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {pageCount > 1 && !showSearchResult ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default DueDetailList;
