import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../Assets/carebox-logo.png";

const addFooters = (doc, date, time) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text("Date:" + date, 16, doc.internal.pageSize.height - 10);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
    doc.text(
      "Time:" + time,
      doc.internal.pageSize.width - 35,
      doc.internal.pageSize.height - 10
    );
  }
};

const SinglePurchaseOrderPdf = (itemDetails) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });
  var img = new Image();
  img.src = "../Assets/carebox-logo.png";
  //var imageUrl = "../Assets/care-box-logo.png";
  const date = new Date();
  const tableRows = itemDetails.purchase_products.map((item, i) => [
    i + 1,
    item.product.barcode ? item.product.barcode : "N/A",
    item.product.product_name,
    item.product.unit,
    item.product.sub_category,
    item.product.generic_name ? item.product.generic_name : "N/A",
    item.cpu,
    item.order_quantity,
    item.order_amount,
  ]);
  const columnData = [
    "SL No",
    "Barcode",
    "Product Name",
    "Style & Size",
    "Sub Category",
    "Generic Name",
    "CPU",
    "PO Qty",
    "Total Cost",
  ];
  var title = "Care-Box";
  var reportName = "Purchase Order Details";
  var address = "149/A Monipuri Para,Tejgaon, Dhaka-1215, Bangladesh";
  var titleWidth = doc.getTextDimensions(title).w;
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  var x = (pageWidth - titleWidth) / 2;
  doc.addImage(logo, "PNG", 16, 6, 45, 10);
  doc.text(title, x, 14);
  doc.setFontSize(8);
  doc.text(address, (pageWidth - doc.getTextDimensions(address).w) / 2, 20);
  doc.setFontSize(12);
  doc.text(
    reportName,
    (pageWidth - doc.getTextDimensions(reportName).w) / 2,
    28
  );
  doc.setFontSize(8);
  doc.text(`PO ID: ${itemDetails.po_id}`, 16, 34);
  doc.text(
    `PO Date: ${itemDetails.created_at?.split("T")[0]}`,
    doc.internal.pageSize.width - 48,
    34
  );
  doc.setFontSize(8);
  doc.text(`Supplier Name: ${itemDetails.supplier?.supplier_name}`, 16, 38);
  doc.text(
    `Vendor Name: ${itemDetails.vendor?.vendor_name}`,
    doc.internal.pageSize.width - 48,
    38
  );
  doc.autoTable({
    startY: 42,
    columns: columnData,
    body: tableRows,
    theme: "plain",
    styles: {
      halign: "center",
      lineColor: "DCE0E4",
      lineWidth: 0.2,
    },
    headStyles: {
      textColor: "black",
      fillColor: "#fafbfe",
    },
  });

  doc.autoTable({
    columns: ["Total Quantity:", `${itemDetails.total_product}`],
    body: [["Grand Total:", `${itemDetails.total_cost}TK`]],
    startY: doc.lastAutoTable.finalY + 10,
    //styles: { overflow: 'hidden' },
    theme: "plain",
    styles: {
      halign: "center",
      fontStyle: "bold",
    },
    margin: { right: doc.internal.pageSize.width / 2, left: 35 },
  });

  addFooters(doc, date.toLocaleDateString(), date.toLocaleTimeString());
  doc.save("Care-Box-Purchase-Order-Details" + "-" + itemDetails.po_id);
};

export default SinglePurchaseOrderPdf;
