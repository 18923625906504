import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faFile } from "@fortawesome/free-solid-svg-icons";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import "../Products/Product.css";
import "../Transfer/transfer.css";
import { getPurchaseDetails } from "../../Redux/actions/purchaseActions";
import SinglePurchasePdf from "../../Services/SinglePurchasePdf";
import CommentModal from "../PopupModal/CommentModal";

const EachPurchase = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const { loading, purchaseDetails, error } = useSelector(
    (state) => state.purchaseDetail
  );
  const [commentModalShow, setCommentModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  useEffect(() => {
    dispatch(getPurchaseDetails(param.id));
  }, [dispatch, param.id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //console.log(purchaseDetails.results[0]);
  return (
    <div>
      <CommentModal
        show={commentModalShow}
        onHide={() => {
          setCommentModalShow(false);
          setSelectedItem("");
        }}
        product={selectedItem}
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <Container fluid>
          <h4 className="product-heading container-view">Purchase Details</h4>
          <p className="product-paragraph container-view">
            Full Details of Purchase
          </p>
          <div className="mb-4 p-3 card product-card container-view">
            <Row className="mb-4">
              <Col className="d-flex justify-content-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as pdf</Tooltip>}
                >
                  <img
                    //className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => {
                      {
                        purchaseDetails.results
                          ? SinglePurchasePdf(purchaseDetails.results[0])
                          : alert("Try Again");
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img
                  className="d-none pe-3"
                  src={excelIcon}
                  alt="pdf"
                  height={45}
                />
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <p>
                  <b>Purchase ID:</b>{" "}
                  {purchaseDetails.results &&
                  purchaseDetails.results[0].purchase_id
                    ? purchaseDetails.results[0].purchase_id
                    : purchaseDetails.results
                    ? purchaseDetails.results[0].id
                    : ""}
                </p>
              </Col>
              <Col lg={6}>
                <p>
                  <b>Purchase Date:</b>{" "}
                  {purchaseDetails.results
                    ? purchaseDetails.results[0].purchase_date
                    : ""}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <p>
                  <b>Vendor Name:</b>{" "}
                  {purchaseDetails.results
                    ? purchaseDetails.results[0].vendor_name
                    : ""}
                </p>
              </Col>
              <Col lg={6}>
                <p>
                  <b>Reference:</b>{" "}
                  {purchaseDetails.results &&
                  purchaseDetails.results[0].reference
                    ? purchaseDetails.results[0].reference
                    : "N/A"}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <p>
                  <b>Created By:</b>{" "}
                  {purchaseDetails.results
                    ? purchaseDetails.results[0].createdby_name[0]
                        .created_by__Name
                    : ""}
                </p>
              </Col>
              <Col lg={6}>
                <p>
                  <b>Purchase Document:</b>{" "}
                  {purchaseDetails.results ? (
                    <a
                      href={purchaseDetails.results[0].document}
                      attributes-list="true"
                      download="optional-value"
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        className="ps-1"
                        icon={faFile}
                        color="#637381"
                      ></FontAwesomeIcon>
                    </a>
                  ) : (
                    ""
                  )}
                </p>
              </Col>
            </Row>
            <div className="transfer-table">
              <Table responsive bordered hover>
                <thead className="text-center table-header">
                  <tr>
                    <th>SL</th>
                    <th>Product Name</th>
                    <th>Sub Category</th>
                    <th>Style & Size</th>
                    <th>Qty</th>
                    <th>Bonus Qty</th>
                    <th>P.Price</th>
                    <th>Vat%</th>
                    <th>New P.Price</th>
                    <th>MRP</th>
                    <th>New MRP</th>
                    <th>GP%</th>
                    <th>SubTotal</th>
                    <th>Barcode</th>
                    <th>Batch No.</th>
                    <th>Supplier Name</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {purchaseDetails.results &&
                  purchaseDetails.results.length &&
                  !loading ? (
                    purchaseDetails.results[0].purchase.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.product_name}</td>
                        <td>{item.sub_category}</td>
                        <td>{item.product__unit}</td>
                        <td>{item.quantity_amount}</td>
                        <td>{item.bonus_quantity ? item.bonus_quantity : 0}</td>
                        <td>{item.purchase_price}</td>
                        <td>{item.purchase_vat}%</td>
                        <td>{item.new_purchase_price}</td>
                        <td>{item.sales_price}</td>
                        <td>{item.new_sales_price}</td>
                        <td>{item.gross_profit}</td>
                        <td>{!item.subtotal ? "N/A" : item.subtotal}</td>
                        <td>
                          {!item.product__barcode
                            ? "N/A"
                            : item.product__barcode}
                        </td>
                        <td>{item.batch_no}</td>
                        <td>{item.supplier_name}</td>
                        <td style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            icon={faComments}
                            color="#833586"
                            onClick={() => {
                              setCommentModalShow(true);
                              setSelectedItem(item);
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : purchaseDetails.results &&
                    !purchaseDetails.results.length &&
                    !loading ? (
                    <tr>
                      <td colSpan="15">No Product Found</td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="15">
                        <Spinner animation="border" variant="warning" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="p-3">
                <Row className="">
                  <Col
                    className="d-flex justify-content-end"
                    style={{ height: "30px" }}
                  >
                    <p>
                      <b>Total Purchase Value: </b>{" "}
                      {purchaseDetails.results
                        ? purchaseDetails.results[0].total_purchase_value
                        : ""}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="d-flex justify-content-end"
                    style={{ height: "30px" }}
                  >
                    <p>
                      <b>Total Vat Amount: </b>{" "}
                      {purchaseDetails.results
                        ? purchaseDetails.results[0].total_vat
                        : ""}
                      ৳
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="d-flex justify-content-end"
                    style={{ height: "30px" }}
                  >
                    <p>
                      <b>Total Commision: </b>{" "}
                      {purchaseDetails.results
                        ? purchaseDetails.results[0].additional_commission
                        : ""}
                      ৳
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="d-flex justify-content-end"
                    style={{ height: "30px" }}
                  >
                    <p>
                      <b>Net Purchase Value: </b>{" "}
                      {purchaseDetails.results
                        ? purchaseDetails.results[0].net_purchase_value
                        : ""}
                      ৳
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default EachPurchase;
