import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAILURE,
  EACH_PRODUCT_DETAIL_REQUEST,
  EACH_PRODUCT_DETAIL_SUCCESS,
  EACH_PRODUCT_DETAIL_FAILURE,
  SEARCH_PRODUCT_LIST_REQUEST,
  SEARCH_PRODUCT_LIST_SUCCESS,
  SEARCH_PRODUCT_LIST_FAILURE,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAILURE,
  PRODUCT_STATUS_UPDATE_REQUEST,
  PRODUCT_STATUS_UPDATE_SUCCESS,
  PRODUCT_STATUS_UPDATE_FAILURE,
  SEARCH_PRODUCT_WITH_BARCODE_REQUEST,
  SEARCH_PRODUCT_WITH_BARCODE_SUCCESS,
  SEARCH_PRODUCT_WITH_BARCODE_FAILURE,
} from "../type.js";

export const productListReducer = (state = { productList: {} }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, productList: action.payload };
    case PRODUCT_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createProductReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PRODUCT_REQUEST:
      return { loading: true };
    case CREATE_PRODUCT_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case CREATE_PRODUCT_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteProductReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case PRODUCT_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const productDetailsReducers = (state = { product: {} }, action) => {
  switch (action.type) {
    case EACH_PRODUCT_DETAIL_REQUEST:
      return { loading: true, ...state };
    case EACH_PRODUCT_DETAIL_SUCCESS:
      return { loading: false, product: action.payload };
    case EACH_PRODUCT_DETAIL_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchProductListReducer = (
  state = { searchProducts: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_PRODUCT_LIST_REQUEST:
      return { ...state , searchLoading: true};
    case SEARCH_PRODUCT_LIST_SUCCESS:
      return { ...state , searchLoading: false, searchProducts: action.payload };
    case SEARCH_PRODUCT_LIST_FAILURE:
      return { searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};

export const updateProductReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true };
    case PRODUCT_UPDATE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case PRODUCT_UPDATE_FAILURE:
      return {
        loading: false,
        updateError: action.payload,
      };
    default:
      return state;
  }
};

export const updateProductStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_STATUS_UPDATE_REQUEST:
      return { updateloading: true };
    case PRODUCT_STATUS_UPDATE_SUCCESS:
      return {
        updateloading: false,
        message: action.payload,
      };
    case PRODUCT_STATUS_UPDATE_FAILURE:
      return {
        updateloading: false,
        updateError: action.payload,
      };
    default:
      return state;
  }
};

export const searchProductWithBarcodeReducer = (
  state = { searchBarcodeProduct: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_PRODUCT_WITH_BARCODE_REQUEST:
      return { searchBarcodeLoading: true, ...state };
    case SEARCH_PRODUCT_WITH_BARCODE_SUCCESS:
      return {
        searchBarcodeLoading: false,
        searchBarcodeProduct: action.payload,
      };
    case SEARCH_PRODUCT_WITH_BARCODE_FAILURE:
      return {
        searchBarcodeLoading: false,
        searchBarcodeError: action.payload,
      };
    default:
      return state;
  }
};
