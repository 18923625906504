import {
  DUE_PAYMENT_LIST_REQUEST,
  DUE_PAYMENT_LIST_SUCCESS,
  DUE_PAYMENT_LIST_FAILURE,
  DUE_PAYMENT_UPDATE_REQUEST,
  DUE_PAYMENT_UPDATE_SUCCESS,
  DUE_PAYMENT_UPDATE_FAILURE,
  SEARCH_DUE_PAYMENT_LIST_REQUEST,
  SEARCH_DUE_PAYMENT_LIST_SUCCESS,
  SEARCH_DUE_PAYMENT_LIST_FAILURE,
} from "../type.js";
import axios from "axios";

export const getDuePaymentList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: DUE_PAYMENT_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/polls/due-payment-list/?limit=${limit}&offset=${offset}`,
      config
    );

    dispatch({
      type: DUE_PAYMENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DUE_PAYMENT_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateDuePayment = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DUE_PAYMENT_UPDATE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const body = {};
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/polls/due-payment-update/${id}/`,
      body,
      config
    );

    dispatch({
      type: DUE_PAYMENT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DUE_PAYMENT_UPDATE_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const searchDuePaymentList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SEARCH_DUE_PAYMENT_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/product/polls/due-payment-list/?query=${keyword}&limit=30&offset=0`,
      config
    );

    dispatch({
      type: SEARCH_DUE_PAYMENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_DUE_PAYMENT_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
