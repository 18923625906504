import jsPDF from "jspdf";
import "jspdf-autotable";

const addFooters = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
  }
};

const SingleReturnPdf = (itemDetails) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });
  const date = new Date();
  const tableRows = itemDetails.returnitem.map((item, i) => [
    i + 1,
    item.product__barcode,
    item.product__product_name,
    item.product__unit,
    item.supplier__supplier_name,
    item.batch_no,
    item.quantity,
    item.new_purchaseprice !== 0 &&
    item.new_purchaseprice !== "" &&
    item.new_productprice !== null
      ? item.new_purchaseprice
      : item.purchase_price,
    item.new_productprice !== 0 &&
    item.new_productprice !== "" &&
    item.new_productprice !== null
      ? item.new_productprice
      : item.product_price,
    item.subtotal,
    item.reasons,
  ]);
  const columnData = [
    "SL No",
    "BarCode",
    "Product",
    "Size",
    "Supplier",
    "Batch No.",
    "Qty",
    "CPU",
    "RPU",
    "Total (TK)",
    "Reasons",
  ];
  var title = "Care-Box";
  var reportName = "Challan/Purchase Return Details";
  var titleWidth = doc.getTextDimensions(title).w;
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  var x = (pageWidth - titleWidth) / 2;
  doc.text(title, x, 14);
  doc.text(
    reportName,
    (pageWidth - doc.getTextDimensions(reportName).w) / 2,
    20
  );
  doc.setFontSize(8);
  doc.text(`Return ID: ${itemDetails.uuid}`, 16, 24);
  doc.text(
    `Return Date: ${itemDetails.created_at.split("T")[0]}`,
    doc.internal.pageSize.width - 48,
    24
  );
  doc.setFontSize(8);
  doc.text(
    `Vendor Name: ${itemDetails.vendor_name} (${itemDetails.vendor})`,
    16,
    28
  );
  doc.text(
    `Returned By: ${itemDetails.createdby_name}`,
    doc.internal.pageSize.width - 48,
    28
  );
  doc.autoTable({
    //styles: { fillColor: [0, 0, 0] },
    startY: 40,
    columns: columnData,
    body: tableRows,
    theme: "plain",
    styles: {
      halign: "center",
      lineColor: "DCE0E4",
      lineWidth: 0.2,
    },
    headStyles: {
      textColor: "black",
      fillColor: "#fafbfe",
    },
  });

  doc.autoTable({
    columns: ["A", "Total Return Value:", `${itemDetails.totalreturnvalue}TK`],
    body: [
      [
        "B",
        "Total Vat Amount:",
        `${itemDetails.totalreturnvalue - itemDetails.netreturnvalue}TK`,
      ],
      [
        ".......................",
        ".......................",
        ".......................",
      ],
      ["A - B", "Net Return Value:", `${itemDetails.netreturnvalue}TK`],
    ],
    startY: doc.lastAutoTable.finalY + 10,
    //styles: { overflow: 'hidden' },
    theme: "plain",
    styles: {
      halign: "center",
      fontStyle: "bold",
      //textColor: "black",
      //lineColor: "DCE0E4",
      //lineWidth: 0.2,
    },
    margin: { right: doc.internal.pageSize.width / 2, left: 35 },
  });

  doc.setLineWidth(0.5);
  doc.line(
    12,
    doc.lastAutoTable.finalY + 20,
    40,
    doc.lastAutoTable.finalY + 20
  );
  doc.setFontSize(10);
  doc.text("Received By", 16, doc.lastAutoTable.finalY + 25);
  doc.line(
    doc.internal.pageSize.width / 2 - 20,
    doc.lastAutoTable.finalY + 20,
    doc.internal.pageSize.width / 2 + 20,
    doc.lastAutoTable.finalY + 20
  );
  doc.text(
    "Central-Posted By",
    (pageWidth - doc.getTextDimensions("Central-Posted By:").w) / 2,
    doc.lastAutoTable.finalY + 25
  );
  doc.line(
    doc.internal.pageSize.width - 45,
    doc.lastAutoTable.finalY + 20,
    doc.internal.pageSize.width - 10,
    doc.lastAutoTable.finalY + 20
  );
  doc.text(
    "Authorised By",
    doc.internal.pageSize.width - 38,
    doc.lastAutoTable.finalY + 25
  );
  addFooters(doc);
  doc.save(
    "Care-Box-Purchase-Return-Details" + "_" + date.toLocaleTimeString()
  );
  // return (
  //   <div
  //     className="container"
  //     style={{
  //       marginTop: "1rem",
  //       marginLeft: "1rem",
  //       marginRight: "1rem",
  //       font: "Montserrat",
  //     }}
  //   >
  //     <div style={{ display: "flex", justifyContent: "center" }}>
  //       <p>Care-Box</p>
  //     </div>
  //   </div>
  // );
};

export default SingleReturnPdf;
