import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import DeleteModal from "../PopupModal/DeleteModal";
import {
  Container,
  Row,
  Col,
  Form,
  Spinner,
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import filter from "../../Assets/icons/filter.png";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import "../Products/Product.css";
import {
  getPurchaseRequestList,
  getSearchPurchaseRequestList,
  updatePurchaseRequest,
} from "../../Redux/actions/purchaseActions";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import LoadingModal from "../PopupModal/LoadingModal";
import axios from "axios";
import generatePDF from "../../Services/pdfGenerator";
import exportExcel from "../../Services/ExcelGenerator";
import SingleRequestPurchasePdf from "../../Services/SinglePurchaseRequestPdf";
import { SuccessAlert } from "../notify/notify";
import ReqPurchaseTableRow from "./reusable/ReqPurchaseTableRow";

const PurchaseRequest = () => {
  const dispatch = useDispatch();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selected, setSelected] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [exportData, setExportData] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState(false);

  const { loading, purchaseRequestList, error } = useSelector(
    (state) => state.purchaseRequestList
  );

  const { searchLoading, searchPurchaseRequestList, searchError } = useSelector(
    (state) => state.searchPurchaseRequestList
  );

  const { updateLoading, updateMessage, updateError } = useSelector(
    (state) => state.updatePurchaseRequest
  );

  const { user } = useSelector((state) => state.userDetails);

  useEffect(() => {
    dispatch(getPurchaseRequestList(limit, offset));
  }, [dispatch, offset]);

  useEffect(() => {
    if (purchaseRequestList?.data) {
      setTotalItems(purchaseRequestList.data.count);
    }
  }, [purchaseRequestList]);

  useEffect(() => {
    if (
      alertMessage &&
      updateMessage === undefined &&
      updateError !== undefined
    ) {
      //console.log(updateError);
      if (updateError.message) {
        alert(updateError.message);
        setAlertMessage(false);
      } else {
        alert("Something Went Wrong");
        setAlertMessage(false);
      }
    }
    if (alertMessage && updateMessage !== undefined) {
      SuccessAlert("Purchase Status Updated Successfully");
      setAlertMessage(false);
      if (showSearchResult) {
        dispatch(getPurchaseRequestList(limit, offset));
      } else {
        dispatch(getPurchaseRequestList(limit, offset));
      }
    }
  }, [alertMessage, updateMessage, updateError]);
  //console.log(purchaseRequestList);
  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
  };
  const handleSearchResult = (e) => {
    //setSearchValue(e.target.value);
    if (e.target.value.length > 0) {
      setShowSearchResult(true);
      dispatch(getSearchPurchaseRequestList(e.target.value));
    } else {
      setShowSearchResult(false);
    }
  };
  const handleDeletePurchaseRequest = (item) => {
    setSelectedItem(item);
    setDeleteModalShow(true);
    //deletePurchaseRequest(id);
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, parseInt(id)]);
    setCheckedItems([
      ...checkedItems,
      purchaseRequestList?.data.results.find(
        (item) => item.id === parseInt(id)
      ),
    ]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
      setCheckedItems(checkedItems.filter((item) => item.id !== parseInt(id)));
      setIsCheckAll(false);
    }
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setCheckedItems(purchaseRequestList?.data.results);
    setIsCheck(purchaseRequestList?.data.results.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
      setCheckedItems([]);
    }
  };

  /** Export All Data */
  const getDataFromApi = (type) => {
    setExportData(true);
    try {
      //const form_data = new FormData();
      //form_data.append("csv_file", filefield);
      //const body = data;
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Custom-User-Agent":
            "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
        },
      };
      const api = `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/purchase-request-list/?limit=${totalItems}&offset=0&ordering=id`;

      axios
        .get(api, settings)
        .then((res) => {
          //console.log("success", res.data.results);
          //setAssetList([...assetList, ...res.data.results]);
          if (type === "pdf") {
            setExportData(false);
            generatePDF(
              res.data.results,
              "Purchase_Request",
              ["ID", "No. of Products", "Created Date", "Requested By"],
              "Care-Box Purchase Request List",
              totalItems
            );
          } else if (type === "excel") {
            const exportData = [];
            res.data.results.map((each) => {
              let obj = {};
              obj["ID"] = each.id;
              obj["No. of Products"] = each.total_items;
              obj["Created Date"] = each.created_at.split("T")[0];
              obj["Requested By"] = each.requested_by.Name;
              exportData.push(obj);
            });
            exportExcel(exportData, "Care_Box_Purchase_Request_List.xlsx");
            setExportData(false);
          }
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };

  const updatePurchaseRequestStatus = (id, status) => {
    dispatch(updatePurchaseRequest(id, status));
    setAlertMessage(true);
  };
  // console.log(user.groups.includes(29));
  return (
    <div>
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        item={selectedItem}
        action={() => setShowSearchResult(false)}
      />
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <Modal backdrop="static" keyboard={false} show={updateLoading}>
        <Modal.Body>
          <Row className="mt-2 d-flex justify-content-center">
            <Spinner animation="border" variant="warning" />
            <p className="mt-3 text-center">
              <b>Please Wait for a moment......</b>
            </p>
          </Row>
        </Modal.Body>
      </Modal>
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <h4 className="product-heading container-view">
            Purchase Request List
          </h4>
          <p className="product-paragraph container-view">
            Purchase <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
            Purchase Request List
          </p>
          <div className="mb-4 p-3 card product-card container-view">
            <Row className="mt-2 mb-4">
              <Col lg={6} className="mb-2 mb-lg-0">
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchResult}
                />
              </Col>
              <Col lg={6} className="d-flex justify-content-md-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as pdf</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => {
                      checkedItems.length === 1
                        ? SingleRequestPurchasePdf(checkedItems[0])
                        : checkedItems.length > 1
                        ? generatePDF(
                            checkedItems,
                            "Purchase_Request",
                            [
                              "ID",
                              "No. of Products",
                              "Created Date",
                              "Requested By",
                            ],
                            "Care-Box Purchase Request List",
                            checkedItems.length
                          )
                        : getDataFromApi("pdf");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as excel</Tooltip>}
                >
                  <img
                    //className="pe-3"
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getDataFromApi("excel")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            <Row>
              <h4 style={{ fontSize: "20px", fontWeight: "600" }}>
                Purchase Request List:
              </h4>
            </Row>
            <Row className="mt-2 mb-2">
              <Table responsive="xl" hover>
                <thead className="text-center table-header">
                  <tr>
                    <th>
                      <Form.Check
                        ype="checkbox"
                        name="selectAll"
                        id="selectAll"
                        onChange={handleSelectAll}
                        checked={isCheckAll}
                      />
                    </th>
                    <th>Id</th>
                    <th>No. of Products</th>
                    <th>Created At</th>
                    <th>Requested By</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {showSearchResult &&
                  searchPurchaseRequestList?.data !== undefined &&
                  searchPurchaseRequestList?.data.results.length !== 0 ? (
                    searchPurchaseRequestList?.data.results.map((item) => (
                      <ReqPurchaseTableRow
                        key={item.id}
                        item={item}
                        handleClick={handleClick}
                        isCheck={isCheck}
                        user={user}
                        updatePurchaseRequestStatus={
                          updatePurchaseRequestStatus
                        }
                        selected={selected}
                        setSelected={setSelected}
                        handleDeletePurchaseRequest={
                          handleDeletePurchaseRequest
                        }
                      />
                    ))
                  ) : purchaseRequestList?.data !== undefined &&
                    purchaseRequestList?.data.results.length !== 0 &&
                    !showSearchResult ? (
                    purchaseRequestList.data.results.map((item) => (
                      <ReqPurchaseTableRow
                        key={item.id}
                        item={item}
                        handleClick={handleClick}
                        isCheck={isCheck}
                        user={user}
                        updatePurchaseRequestStatus={
                          updatePurchaseRequestStatus
                        }
                        selected={selected}
                        setSelected={setSelected}
                        handleDeletePurchaseRequest={
                          handleDeletePurchaseRequest
                        }
                      />
                    ))
                  ) : loading === true ? (
                    <tr>
                      <td colSpan="12">
                        <Spinner animation="border" variant="warning" />
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="12">No Result Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {pageCount > 1 && !showSearchResult ? (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=""
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  pageCount={pageCount}
                  previousLabel=""
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousClassName="hide"
                  nextClassName="hide"
                  activeLinkClassName="active"
                />
              ) : (
                <></>
              )}
            </Row>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default PurchaseRequest;
