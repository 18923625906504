import {
  SUPPLIER_WISE_ALL_STOCK_REQUEST,
  SUPPLIER_WISE_ALL_STOCK_SUCCESS,
  SUPPLIER_WISE_ALL_STOCK_FAILURE,
  SUPPLIER_WISE_FREQUENCY_ANALYSIS_REQUEST,
  SUPPLIER_WISE_FREQUENCY_ANALYSIS_SUCCESS,
  SUPPLIER_WISE_FREQUENCY_ANALYSIS_FAILURE,
  SLOW_MOVING_PRODUCT_REQUEST,
  SLOW_MOVING_PRODUCT_SUCCESS,
  SLOW_MOVING_PRODUCT_FAILURE,
  FAST_MOVING_PRODUCT_REQUEST,
  FAST_MOVING_PRODUCT_SUCCESS,
  FAST_MOVING_PRODUCT_FAILURE,
  NON_MOVING_PRODUCT_REQUEST,
  NON_MOVING_PRODUCT_SUCCESS,
  NON_MOVING_PRODUCT_FAILURE,
  STOCK_RETURN_REPORT_REQUEST,
  STOCK_RETURN_REPORT_SUCCESS,
  STOCK_RETURN_REPORT_FAILURE,
  ZERO_STOCK_REPORT_REQUEST,
  ZERO_STOCK_REPORT_SUCCESS,
  ZERO_STOCK_REPORT_FAILURE,
  GP_COMPARE_REPORT_REQUEST,
  GP_COMPARE_REPORT_SUCCESS,
  GP_COMPARE_REPORT_FAILURE,
  COMPARE_PRODUCT_GP_REQUEST,
  COMPARE_PRODUCT_GP_SUCCESS,
  COMPARE_PRODUCT_GP_FAILURE,
  NEAR_EXPIRY_PRODUCT_REQUEST,
  NEAR_EXPIRY_PRODUCT_SUCCESS,
  NEAR_EXPIRY_PRODUCT_FAILURE,
  EXPIRY_PRODUCT_REQUEST,
  EXPIRY_PRODUCT_SUCCESS,
  EXPIRY_PRODUCT_FAILURE,
} from "../type.js";
import axios from "axios";

export const getAllStockList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SUPPLIER_WISE_ALL_STOCK_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_analysis/stock/all_stock/${keyword}`,
      config
    );

    dispatch({
      type: SUPPLIER_WISE_ALL_STOCK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPLIER_WISE_ALL_STOCK_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getFrequencyAnalysisList = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SUPPLIER_WISE_FREQUENCY_ANALYSIS_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_analysis/price_chanages/price_chanages_analysis/${keyword}`,
      config
    );

    dispatch({
      type: SUPPLIER_WISE_FREQUENCY_ANALYSIS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPLIER_WISE_FREQUENCY_ANALYSIS_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSlowMovingProducts = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SLOW_MOVING_PRODUCT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_analysis/moving_product/slow_moving_product/${keyword}`,
      config
    );

    dispatch({
      type: SLOW_MOVING_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SLOW_MOVING_PRODUCT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getFastMovingProducts = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: FAST_MOVING_PRODUCT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_analysis/moving_product/fast_moving_product/${keyword}`,
      config
    );

    dispatch({
      type: FAST_MOVING_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FAST_MOVING_PRODUCT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getNonMovingProducts = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: NON_MOVING_PRODUCT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_analysis/moving_product/non_moving_product/${keyword}`,
      config
    );

    dispatch({
      type: NON_MOVING_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NON_MOVING_PRODUCT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getStockReturnReport = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: STOCK_RETURN_REPORT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_analysis/stock_return_sw/${keyword}`,
      config
    );

    dispatch({
      type: STOCK_RETURN_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STOCK_RETURN_REPORT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getZeroStockReport = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: ZERO_STOCK_REPORT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_analysis/zero_stock/${keyword}`,
      config
    );

    dispatch({
      type: ZERO_STOCK_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ZERO_STOCK_REPORT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getGpCompareReport = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: GP_COMPARE_REPORT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_analysis/gp_contribution/gp_contribution_product/${keyword}`,
      config
    );

    dispatch({
      type: GP_COMPARE_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GP_COMPARE_REPORT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getCompareProductGpReport = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: COMPARE_PRODUCT_GP_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_analysis/GpcompareproductView/?type=gp_compare_product${keyword}`,
      config
    );

    dispatch({
      type: COMPARE_PRODUCT_GP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMPARE_PRODUCT_GP_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const nearExpiryProductReport = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: NEAR_EXPIRY_PRODUCT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_analysis/NearExpiryproductView/?type=Near_Expiry_Product${keyword}`,
      config
    );

    dispatch({
      type: NEAR_EXPIRY_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEAR_EXPIRY_PRODUCT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const expiryProductReport = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: EXPIRY_PRODUCT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_analysis/expiry_product-catagory/?type=expiry_product${keyword}`,
      config
    );

    dispatch({
      type: EXPIRY_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXPIRY_PRODUCT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
