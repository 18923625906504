import jsPDF from "jspdf";
import "jspdf-autotable";

const addFooters = (doc, date, time) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text("Date:" + date, 16, doc.internal.pageSize.height - 10);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
    doc.text(
      "Time:" + time,
      doc.internal.pageSize.width - 35,
      doc.internal.pageSize.height - 10
    );
  }
};

const StockReportPdf = (headerRows, itemDetails, reportTitle, pdfTitle) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });
  const tableRows = [];
  const date = new Date();
  if (reportTitle === "All Supplier Wise Stock Summary at a glance") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.supplier_name,
        item.received_quantity,
        item.received_cost,
        item.return_quantity,
        item.return_cost,
        item.return_price,
        item.sold_quantity,
        item.sold_cost,
        item.sold_price,
        item.exchange_quantity,
        item.exchange_cost,
        item.exchange_price,
        item.blance_quantity,
        item.blance_cost,
        item.blance_price,
      ];
      tableRows.push(rowData);
    });
  } else if (reportTitle === "Supplier wise stock position") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.product_barcode ? item.product_barcode : "N/A",
        item.product_category,
        item.supplier_name,
        item.product_name,
        item.cost_per_unit,
        item.retail_per_unit,
        item.profit_mergin,
        item.total_purchase_quantity,
        item.total_sold_quantity,
        item.total_exchange_quantity,
        item.total_returned_quantity,
        item.current_stock,
        item.stock_sale_value,
      ];
      tableRows.push(rowData);
    });
  } else if (reportTitle === "Category wise stock Summary") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.category_name,
        item.category_location,
        item.total_received,
        item.total_returned,
        item.sold,
        item.total_exchanged,
        item.stock,
        item.cost_price,
        item.sale_price,
      ];
      tableRows.push(rowData);
    });
  } else if (reportTitle === "Sub Category wise stock Summary") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.subcategory_name,
        item.category_name,
        item.total_received,
        item.total_returned,
        item.sold,
        item.total_exchanged,
        item.stock,
        item.cost_price,
        item.sale_price,
      ];
      tableRows.push(rowData);
    });
  } else if (
    reportTitle === "Sub Category wise Product Information" ||
    reportTitle === "Single Product Information"
  ) {
    itemDetails.forEach((item) => {
      const rowData = [
        item.product_name,
        item.sub_category,
        item.barcode ? item.barcode : "N/A",
        item.total_purchased ? item.total_purchased : 0,
        item.total_returned ? item.total_returned : 0,
        item.total_exchanged ? item.total_exchanged : 0,
        item.sold ? item.sold : 0,
        item.stock,
        item.purchase_price,
        item.sell_price,
      ];
      tableRows.push(rowData);
    });
  } else if (reportTitle === "Supplier Wise Return") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.product_barcode ? item.product_barcode : "N/A",
        item.product_name,
        item.total_returned_quantity,
        item.avg_cost_price,
        item.total_cost_price,
        item.last_returned_date ? item.last_returned_date : "N/A",
        item.last_returned_by ? item.last_returned_by : "N/A",
      ];
      tableRows.push(rowData);
    });
  } else if (reportTitle === "Product Wise Return") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.returned_id,
        item.product_barcode ? item.product_barcode : "N/A",
        item.supplier_name ? item.supplier_name : "N/A",
        item.product_name,
        item.returned_quantity,
        item.cost_price,
        item.total_cost_price,
        item.returned_at ? item.returned_at.split("T")[0] : "N/A",
        item.returned_by,
      ];
      tableRows.push(rowData);
    });
  } else if (reportTitle === "Supplier Wise Purchase Receiving Summary") {
    itemDetails.forEach((item) => {
      const rowData = [
        item.purchase_orderplace ? item.purchase_orderplace : item.purchase_id,
        item.supplier,
        item.purchase_reference ? item.purchase_reference : "N/A",
        item.purchase_date,
        item.total_purchase_value,
        item.total_vat,
        item.net_purchase_value,
        item.user,
      ];
      tableRows.push(rowData);
    });
  } else if (
    reportTitle === "Barcode Wise Vendor Stock Receipt Position Details"
  ) {
    itemDetails.forEach((item) => {
      const rowData = [
        item.product_category,
        `${item.product_name} ${item.product_unit}
        ${item.generic_name}`,
        item.purchase_orderplace ? item.purchase_orderplace : item.purchase_id,
        item.vendor_name,
        item.purchase_reference ? item.purchase_reference : "N/A",
        item.purchase_date,
        item.purchase_time.slice(0, 8),
        item.cpu,
        item.total_received,
        item.net_purchase_value,
        item.user,
      ];
      tableRows.push(rowData);
    });
  }

  var title = "Care-Box";
  var reportName = reportTitle;
  var titleWidth = doc.getTextDimensions(title).w;
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  var x = (pageWidth - titleWidth) / 2;
  doc.text(title, x, 14);
  doc.text(
    reportName,
    (pageWidth - doc.getTextDimensions(reportName).w) / 2,
    20
  );

  doc.autoTable({
    //styles: { fillColor: [0, 0, 0] },
    startY: 30,
    head: headerRows,
    body: tableRows,
    theme: "plain",
    styles: {
      halign: "center",
      lineColor: "DCE0E4",
      lineWidth: 0.2,
    },
    headStyles: {
      textColor: "black",
      fillColor: "#fafbfe",
    },
  });
  addFooters(doc, date.toLocaleDateString(), date.toLocaleTimeString());
  //     columns: [
  //       "A",
  //       "Total Product Value:",
  //       `${itemDetails.total_purchase_value}TK`,
  //     ],
  //     body: [
  //       ["B", "Total Vat Amount:", `${itemDetails.total_vat}TK`],
  //       ["C", "Total Commision:", `${itemDetails.additional_commission}TK`],
  //       [
  //         ".......................",
  //         ".......................",
  //         ".......................",
  //       ],
  //       ["A + B - C", "Grand Total:", `${itemDetails.net_purchase_value}TK`],
  //     ],
  //     startY: doc.lastAutoTable.finalY + 10,
  //     //styles: { overflow: 'hidden' },
  //     theme: "plain",
  //     styles: {
  //       halign: "center",
  //       fontStyle: "bold",
  //       //textColor: "black",
  //       //lineColor: "DCE0E4",
  //       //lineWidth: 0.2,
  //     },
  //     margin: { right: doc.internal.pageSize.width / 2, left: 35 },
  //   });

  //   doc.setLineWidth(0.5);
  //   doc.line(
  //     12,
  //     doc.lastAutoTable.finalY + 20,
  //     40,
  //     doc.lastAutoTable.finalY + 20
  //   );
  //   doc.setFontSize(10);
  //   doc.text("Received By", 16, doc.lastAutoTable.finalY + 25);
  //   doc.line(
  //     doc.internal.pageSize.width / 2 - 20,
  //     doc.lastAutoTable.finalY + 20,
  //     doc.internal.pageSize.width / 2 + 20,
  //     doc.lastAutoTable.finalY + 20
  //   );
  //   doc.text(
  //     "Central-Posted By",
  //     (pageWidth - doc.getTextDimensions("Central-Posted By:").w) / 2,
  //     doc.lastAutoTable.finalY + 25
  //   );
  //   doc.line(
  //     doc.internal.pageSize.width - 45,
  //     doc.lastAutoTable.finalY + 20,
  //     doc.internal.pageSize.width - 10,
  //     doc.lastAutoTable.finalY + 20
  //   );
  //   doc.text(
  //     "Authorised By",
  //     doc.internal.pageSize.width - 38,
  //     doc.lastAutoTable.finalY + 25
  //   );
  //   addFooters(doc);
  doc.save(`${pdfTitle}_${date.toLocaleTimeString()}`);
};

export default StockReportPdf;
