import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import SideBar from "../SideBar/Sidebar";
import Footer from "../Footer/Footer";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import calender from "../../Assets/icons/calender.png";
import restrictCalender from "../../Assets/icons/restrict-filter.png";
import cancel from "../../Assets/icons/cancel.png";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import "./reports.css";
import StockReportPdf from "../../Services/stockReportPdf";
import {
  getAllSupplierStockReport,
  getSupplierWiseStockReport,
  getSubcategoryStockReport,
  getCategoryStockReport,
  getSubcategoryWiseProduct,
} from "../../Redux/actions/stockReportActions";
import { getSubcategoryList } from "../../Redux/actions/subcategoryActions";
import { getSupplierList } from "../../Redux/actions/supplierActions";
import CategoryStockReport from "./StockReports/CategoryStockReport";
import SubcategoryStockReport from "./StockReports/SubCategoryStockReport";
import SubcategoryWiseProduct from "./StockReports/SubcategoryWiseProduct";
import AllSupplierStock from "./StockReports/AllSupplierStock";
import SupplierWiseStock from "./StockReports/SupplierWiseStock";
import LoadingModal from "../PopupModal/LoadingModal";
import axios from "axios";

const StockReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectReport, setSelectReport] = useState("");
  const [productType, setProductType] = useState("");
  const [selectSupplier, setSelectSupplier] = useState("");
  const [selectGroupType, setSelectGroupType] = useState("");
  const [selectSubcategory, setSelectSubcategory] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectProduct, setSelectProduct] = useState("");
  const [generateButton, setGenerateButton] = useState(false);
  const [filterDateButton, setFilterDateButton] = useState(false);
  const [exportData, setExportData] = useState(false);
  const [allProductList, setAllProductList] = useState([]);

  const { loading, subcategoryList, error } = useSelector(
    (state) => state.subcategoryList
  );
  const {
    allSupplierStockLoading,
    allSupplierStockList,
    allSupplierStockError,
  } = useSelector((state) => state.allSupplierStock);
  const {
    supplierWiseStockLoading,
    supplierWiseStock,
    supplierWiseStockError,
  } = useSelector((state) => state.supplierWiseStock);
  const { supplierList } = useSelector((state) => state.supplierList);
  const { subCategoryLoading, subcategoryStockList, subCategoryError } =
    useSelector((state) => state.subCategoryStock);
  const { categoryLoading, categoryStockList, categoryError } = useSelector(
    (state) => state.categoryStock
  );
  const { productLoading, subcategoryWiseProductList, productError } =
    useSelector((state) => state.subCategoryWiseProducts);
  const limit = 30;
  const offset = 0;

  useEffect(() => {
    if (
      !subcategoryList.data &&
      selectReport === "Sub Category wise Product Information"
    ) {
      dispatch(getSubcategoryList(500, 0));
    }
  }, [subcategoryList, selectReport]);

  useEffect(() => {
    if (!supplierList.data && selectReport === "Supplier wise stock position") {
      dispatch(getSupplierList(500, 0));
    }
  }, [supplierList.data, selectReport]);

  useEffect(() => {
    if (selectReport === "All Supplier Wise Stock Summary at a glance") {
      if (
        exportData &&
        allProductList.length === allSupplierStockList.data.count
      ) {
        setExportData(false);
        StockReportPdf(
          [
            [
              {
                content: "Supplier Name",
                rowSpan: 2,
                styles: { valign: "middle", halign: "center" },
              },
              {
                content: "Received",
                colSpan: 2,
                //styles: { halign: "center", fillColor: [241, 196, 15] },
              },
              {
                content: "Stock Return",
                colSpan: 3,
                //styles: { halign: "center", fillColor: [241, 196, 15] },
              },
              {
                content: "Sold",
                colSpan: 3,
                //styles: { halign: "center", fillColor: [241, 196, 15] },
              },
              {
                content: "Exchange/Void",
                colSpan: 3,
                //styles: { halign: "center", fillColor: [241, 196, 15] },
              },
              {
                content: "Balance",
                colSpan: 3,
                //styles: { halign: "center", fillColor: [241, 196, 15] },
              },
            ],
            [
              "Qty",
              "Value(Cost)",
              "Qty",
              "Value(Cost)",
              "Return Value",
              "Qty",
              "Value(Cost)",
              "Sale Value",
              "Qty",
              "Value(Cost)",
              "Sale Value",
              "Qty",
              "Value(Cost)",
              "Sale Value",
            ],
          ],
          allProductList,
          "All Supplier Wise Stock Summary at a glance",
          "All_Supplier_Wise_Stock_Summary_Report"
        );
      }
    } else if (selectReport === "Category/Sub Category wise stock Summary") {
      if (
        exportData &&
        allProductList.length === subcategoryStockList.data.count
      ) {
        setExportData(false);
        StockReportPdf(
          [
            [
              "Product ",
              "Sub Category",
              "Barcode",
              "Total Received(Qty)",
              "Return(Qty)",
              "Exchange(Qty)",
              "Total Sold(Qty)",
              "Balance Qty",
              "Cost(Tk)",
              "Sale(Tk)",
            ],
          ],
          allProductList,
          "Sub Category wise stock Summary",
          "SubCategory_wise_stock_Summary"
        );
      }
    }
  }, [exportData, allProductList, selectReport, allSupplierStockList]);
  //console.log(allProductList.length , allSupplierStockList.data.count);
  //console.log(subCategoryLoading, subcategoryStockList, subCategoryError);
  const handleGenerateReport = () => {
    if (
      selectReport === "All Supplier Wise Stock Summary at a glance" &&
      productType === ""
    ) {
      alert("Please Select Product Type");
    } else if (
      selectReport === "Supplier wise stock position" &&
      selectSupplier === ""
    ) {
      alert("Please Select Supplier");
    } else if (
      selectReport === "All Supplier Wise Stock Summary at a glance" &&
      productType !== ""
    ) {
      setGenerateButton(true);
      dispatch(getAllSupplierStockReport(limit, offset, productType));
    } else if (
      selectReport === "Supplier wise stock position" &&
      selectSupplier !== ""
    ) {
      if (selectSupplier === "all") {
        setGenerateButton(true);
        if (fromDate !== "" && toDate !== "") {
          dispatch(
            getSupplierWiseStockReport(
              `/?limit=${limit}&offset=${offset}&date_range_after=${fromDate}&date_range_before=${toDate}`
            )
          );
        } else {
          dispatch(
            getSupplierWiseStockReport(`/?limit=${limit}&offset=${offset}`)
          );
        }
      } else if (selectSupplier !== "all" && selectSupplier !== "") {
        setGenerateButton(true);
        if (fromDate !== "" && toDate !== "") {
          dispatch(
            getSupplierWiseStockReport(
              `/?limit=${limit}&offset=${offset}&supplier=${selectSupplier}&date_range_after=${fromDate}&date_range_before=${toDate}`
            )
          );
        } else {
          dispatch(
            getSupplierWiseStockReport(
              `/?limit=${limit}&offset=${offset}&supplier=${selectSupplier}`
            )
          );
        }
      } else {
        alert("Please Select Supplier");
      }
    } else if (selectReport === "Category/Sub Category wise stock Summary") {
      if (selectGroupType === "category") {
        if (fromDate !== "" && toDate !== "") {
          setGenerateButton(true);
          dispatch(
            getCategoryStockReport(
              `/?limit=${limit}&offset=${offset}&from_date=${fromDate}&to_date=${toDate}`
            )
          );
        } else {
          setGenerateButton(true);
          dispatch(getCategoryStockReport(`/?limit=${limit}&offset=${offset}`));
        }
      } else if (selectGroupType === "sub_category") {
        if (fromDate !== "" && toDate !== "") {
          setGenerateButton(true);
          dispatch(
            getSubcategoryStockReport(
              `/?limit=${limit}&offset=${offset}&from_date=${fromDate}&to_date=${toDate}`
            )
          );
        } else {
          setGenerateButton(true);
          dispatch(
            getSubcategoryStockReport(`/?limit=${limit}&offset=${offset}`)
          );
        }
      } else {
        alert("Please Select Category or SubCategory");
      }
    } else if (selectReport === "Sub Category wise Product Information") {
      if (selectSubcategory === "all") {
        setGenerateButton(true);
        dispatch(
          getSubcategoryWiseProduct(`/?limit=${limit}&offset=${offset}`)
        );
      } else if (selectSubcategory !== "all" && selectSubcategory !== "") {
        setGenerateButton(true);
        dispatch(
          getSubcategoryWiseProduct(
            `/?limit=${limit}&offset=${offset}&sub_category=${selectSubcategory}`
          )
        );
      } else {
        alert("Please Select Sub Category");
      }
    } else if (selectReport === "Single Product Information") {
      if (selectProduct !== "") {
        setGenerateButton(true);
        dispatch(
          getSubcategoryWiseProduct(
            `/?limit=${limit}&offset=${offset}&search=${selectProduct}`
          )
        );
      } else {
        alert("Please Select Product");
      }
    } else {
      setGenerateButton(true);
    }
  };

  const handleClickPrint = () => {
    if (selectReport !== "" && generateButton) {
      window.print();
    } else {
      alert("Please Generate a Report for Print");
    }
  };
  const getDataFromApi = (url) => {
    try {
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const api = url;

      axios
        .get(api, settings)
        .then((res) => {
          setAllProductList(res.data.results);
          //setAllProductList((prevData) => [...prevData, ...res.data.results]);
          //console.log(allProductList.length);
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };
  const convertReportToPdf = () => {
    if (generateButton && selectReport !== "") {
      if (selectReport === "All Supplier Wise Stock Summary at a glance") {
        var headerRows = [
          [
            {
              content: "Supplier Name",
              rowSpan: 2,
              styles: { valign: "middle", halign: "center" },
            },
            {
              content: "Received",
              colSpan: 2,
              //styles: { halign: "center", fillColor: [241, 196, 15] },
            },
            {
              content: "Stock Return",
              colSpan: 3,
              //styles: { halign: "center", fillColor: [241, 196, 15] },
            },
            {
              content: "Sold",
              colSpan: 3,
              //styles: { halign: "center", fillColor: [241, 196, 15] },
            },
            {
              content: "Exchange/Void",
              colSpan: 3,
              //styles: { halign: "center", fillColor: [241, 196, 15] },
            },
            {
              content: "Balance",
              colSpan: 3,
              //styles: { halign: "center", fillColor: [241, 196, 15] },
            },
          ],
          [
            "Qty",
            "Value(Cost)",
            "Qty",
            "Value(Cost)",
            "Return Value",
            "Qty",
            "Value(Cost)",
            "Sale Value",
            "Qty",
            "Value(Cost)",
            "Sale Value",
            "Qty",
            "Value(Cost)",
            "Sale Value",
          ],
        ];
        if (
          allSupplierStockList.data.results.length ===
          allSupplierStockList.data.count
        ) {
          StockReportPdf(
            headerRows,
            allSupplierStockList.data.results,
            "All Supplier Wise Stock Summary at a glance",
            "All_Supplier_Wise_Stock_Summary_Report"
          );
        } else if (
          allSupplierStockList.data.results.length !==
            allSupplierStockList.data.count &&
          allProductList.length === allSupplierStockList.data.count
        ) {
          StockReportPdf(
            headerRows,
            allProductList,
            "All Supplier Wise Stock Summary at a glance",
            "All_Supplier_Wise_Stock_Summary_Report"
          );
        } else {
          setExportData(true);
          getDataFromApi(
            `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/supplier/supplier-wise-stock-glance/?limit=${allSupplierStockList.data.count}&offset=0&product_type=${productType}`
          );
        }
      } else if (selectReport === "Supplier wise stock position") {
        var headerRows = [
          [
            "Barcode",
            "Category",
            "Supplier",
            "Product",
            "CPU",
            "RPU",
            "Pft(%)",
            "Total Received",
            "Sold to Customer",
            "Product Exchange",
            "Stock Return",
            "Balance Qty",
            "Balance Value at Sale",
          ],
        ];
        StockReportPdf(
          headerRows,
          supplierWiseStock.data.results,
          "Supplier wise stock position",
          "Supplier_wise_stock_position"
        );
      } else if (selectReport === "Category/Sub Category wise stock Summary") {
        if (selectGroupType === "category") {
          var headerRows = [
            [
              "Category Name",
              "Location",
              "Total Received Qty",
              "Stock Return",
              "Total Sold",
              "Exchange/Void",
              "Balance Stock",
              "Total Balance (Cost)",
              "Total Balance (Sale Price)",
            ],
          ];
          StockReportPdf(
            headerRows,
            categoryStockList.data.results,
            "Category wise stock Summary",
            "Category_wise_stock_Summary"
          );
        } else if (selectGroupType === "sub_category") {
          var headerRows = [
            [
              "Sub Category",
              "Category",
              "Total Received Qty",
              "Stock Return",
              "Total Sold",
              "Exchange/Void",
              "Balance Stock",
              "Total Balance (Cost)",
              "Total Balance (Sale Price)",
            ],
          ];
          if (
            subcategoryStockList.data.results.length ===
            subcategoryStockList.data.count
          ) {
            StockReportPdf(
              headerRows,
              subcategoryStockList.data.results,
              "Sub Category wise stock Summary",
              "SubCategory_wise_stock_Summary"
            );
          } else if (
            subcategoryStockList.data.results.length !==
              subcategoryStockList.data.count &&
            allProductList.length === subcategoryStockList.data.count
          ) {
            StockReportPdf(
              headerRows,
              allProductList,
              "Sub Category wise stock Summary",
              "SubCategory_wise_stock_Summary"
            );
          } else {
            setExportData(true);
            if (fromDate !== "" && toDate !== "") {
              getDataFromApi(
                `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/category/subCategory-stock-report/?limit=${subcategoryStockList.data.count}&offset=0&from_date=${fromDate}&to_date=${toDate}`
              );
            } else {
              getDataFromApi(
                `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_report/category/subCategory-stock-report/?limit=${subcategoryStockList.data.count}&offset=0`
              );
            }
          }
        }
      } else if (
        selectReport === "Sub Category wise Product Information" ||
        selectReport === "Single Product Information"
      ) {
        var headerRows = [
          [
            "Product ",
            "Sub Category",
            "Barcode",
            "Total Received(Qty)",
            "Return(Qty)",
            "Exchange(Qty)",
            "Total Sold(Qty)",
            "Balance Qty",
            "Cost(Tk)",
            "Sale(Tk)",
          ],
        ];
        StockReportPdf(
          headerRows,
          subcategoryWiseProductList.data.results,
          selectReport,
          selectReport
        );
      }
    } else {
      alert("Please Generate Report First");
    }
  };
  return (
    <div>
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <div className="hide-print">
        <SideBar></SideBar>
      </div>
      <div className="mainContent">
        <div className="hide-print">
          <Header></Header>
        </div>
        <Container fluid>
          <div className="hide-print">
            <h4 className="product-heading container-view">
              Stock Report at a Glance
            </h4>
            <p className="product-paragraph container-view">
              Reports <FontAwesomeIcon icon={faChevronRight} size="xs" /> Stock
              Report
            </p>
          </div>
          <div className="mb-4 report-page-card card product-card container-view">
            <div className="hide-print">
              <Row className="mb-2">
                <Col xl={9}>
                  {selectReport ===
                    "All Supplier Wise Stock Summary at a glance" ||
                  selectReport === "Sub Category wise Product Information" ||
                  selectReport === "Single Product Information" ? (
                    <img
                      src={restrictCalender}
                      alt="restrictCalender"
                      height={45}
                      style={{ cursor: "not-allowed" }}
                    />
                  ) : (
                    <img
                      src={filterDateButton ? cancel : calender}
                      alt="calender"
                      height={45}
                      style={{ cursor: "pointer" }}
                      onClick={() => setFilterDateButton(!filterDateButton)}
                    />
                  )}
                  <select
                    name="stock-report"
                    className="select-bar"
                    onChange={(e) => {
                      setSelectReport(e.target.value);
                      setGenerateButton(false);
                      setSelectGroupType("");
                      setSelectSupplier("");
                      setProductType("");
                      setSelectSubcategory("");
                      setSelectProduct("");
                      setFromDate("");
                      setToDate("");
                    }}
                    value={selectReport}
                  >
                    <option value="">Select Report Type</option>
                    <option value="All Supplier Wise Stock Summary at a glance">
                      All Supplier Wise Stock Summary at a glance
                    </option>
                    <option value="Supplier wise stock position">
                      Supplier wise stock position
                    </option>
                    <option value="Category/Sub Category wise stock Summary">
                      Category/Sub Category wise stock Summary
                    </option>
                    <option value="Sub Category wise Product Information">
                      Sub Category wise Product Information
                    </option>
                    <option value="Single Product Information">
                      Single Product Information
                    </option>
                  </select>
                  {selectReport !== "" ? (
                    <>
                      {selectReport ===
                      "All Supplier Wise Stock Summary at a glance" ? (
                        <select
                          name="stock-report"
                          className="select-product-type"
                          onChange={(e) => {
                            setProductType(e.target.value);
                            setGenerateButton(false);
                          }}
                          value={productType}
                        >
                          <option value="">Select Product Type</option>
                          <option value="all">All</option>
                          <option value="Local">Local</option>
                          <option value="Foreign">Foreign</option>
                        </select>
                      ) : selectReport === "Supplier wise stock position" ? (
                        <select
                          name="stock-report"
                          className="select-product-type"
                          onChange={(e) => {
                            setSelectSupplier(e.target.value);
                            setGenerateButton(false);
                          }}
                          value={selectSupplier}
                        >
                          <option value="">Select Supplier</option>
                          <option value="all">All</option>
                          {supplierList.data?.results.map((supplier) => (
                            <option key={supplier.id} value={supplier.id}>
                              {supplier.supplier_name}
                            </option>
                          ))}
                        </select>
                      ) : selectReport ===
                        "Category/Sub Category wise stock Summary" ? (
                        <select
                          name="stock-report"
                          className="select-product-type"
                          onChange={(e) => {
                            setSelectGroupType(e.target.value);
                            setGenerateButton(false);
                          }}
                          value={selectGroupType}
                        >
                          <option value="">
                            Select Category / Sub Category
                          </option>
                          <option value="category">Category</option>
                          <option value="sub_category">Sub Category</option>
                        </select>
                      ) : selectReport ===
                        "Sub Category wise Product Information" ? (
                        <select
                          name="stock-report"
                          className="select-product-type"
                          onChange={(e) => setSelectSubcategory(e.target.value)}
                          value={selectSubcategory}
                        >
                          <option value="">Select Sub Category</option>
                          <option value="all">All</option>
                          {subcategoryList.data?.results.map((subcategory) => (
                            <option
                              key={subcategory.id}
                              value={subcategory.name}
                            >
                              {subcategory.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          name="stock-report"
                          className="search-product"
                          placeholder="Search product"
                          onChange={(e) => setSelectProduct(e.target.value)}
                          value={selectProduct}
                        />
                      )}
                      <Button
                        className="generate-btn border-0"
                        type="submit"
                        onClick={handleGenerateReport}
                      >
                        Generate Report
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xl={3} className="d-flex justify-content-end">
                  <img
                    //className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={convertReportToPdf}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    className="d-none pe-3"
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                  />
                  <img
                    src={printIcon}
                    alt="pdf"
                    height={45}
                    style={{ cursor: "pointer" }}
                    className="d-none"
                    //onClick={handleClickPrint}
                  />
                </Col>
              </Row>
              {filterDateButton &&
              selectReport !== "All Supplier Wise Stock Summary at a glance" &&
              selectReport !== "Sub Category wise Product Information" &&
              selectReport !== "Single Product Information" ? (
                <Row>
                  <Col lg={2} md={4} sm={6}>
                    <Form.Group
                      className="form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="report-form-label">
                        From Date
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="From Date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={4} sm={6}>
                    <Form.Group
                      className="form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="report-form-label">
                        To Date
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="To Date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        //style={{ height: "40px" }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </div>
            <div className="">
              <Row className="mt-3">
                <h4 className="product-heading show-print">
                  {selectReport !== "" && selectGroupType === ""
                    ? selectReport
                    : selectReport ===
                        "Category/Sub Category wise stock Summary" &&
                      selectGroupType === "category"
                    ? "Category wise stock Summary"
                    : selectReport ===
                        "Category/Sub Category wise stock Summary" &&
                      selectGroupType === "sub_category"
                    ? "Sub Category wise stock Summary"
                    : "Select An Option to See Details About Stock Report"}
                </h4>
              </Row>
            </div>
            {selectReport === "All Supplier Wise Stock Summary at a glance" &&
            generateButton ? (
              <AllSupplierStock
                allSupplierStockList={allSupplierStockList}
                limit={limit}
                productType={productType}
                allSupplierStockLoading={allSupplierStockLoading}
              />
            ) : selectReport === "Supplier wise stock position" &&
              generateButton ? (
              <SupplierWiseStock
                supplierWiseStock={supplierWiseStock}
                limit={limit}
                fromDate={fromDate}
                toDate={toDate}
                selectSupplier={selectSupplier}
                supplierWiseStockLoading={supplierWiseStockLoading}
              />
            ) : selectReport === "Category/Sub Category wise stock Summary" &&
              selectGroupType === "category" &&
              generateButton ? (
              <CategoryStockReport
                categoryStockList={categoryStockList}
                limit={limit}
                fromDate={fromDate}
                toDate={toDate}
                categoryLoading={categoryLoading}
              />
            ) : selectReport === "Category/Sub Category wise stock Summary" &&
              selectGroupType === "sub_category" &&
              generateButton ? (
              <SubcategoryStockReport
                subcategoryStockList={subcategoryStockList}
                limit={limit}
                fromDate={fromDate}
                toDate={toDate}
                subCategoryLoading={subCategoryLoading}
              />
            ) : selectReport === "Sub Category wise Product Information" &&
              generateButton ? (
              <SubcategoryWiseProduct
                subcategoryWiseProductList={subcategoryWiseProductList}
                selectReport={selectReport}
                selectSubcategory={selectSubcategory}
                limit={limit}
                productLoading={productLoading}
              />
            ) : selectReport === "Single Product Information" &&
              generateButton ? (
              <SubcategoryWiseProduct
                subcategoryWiseProductList={subcategoryWiseProductList}
                selectReport={selectReport}
                selectSubcategory={selectSubcategory}
                limit={limit}
                productLoading={productLoading}
                selectProduct={selectProduct}
              />
            ) : (
              ""
            )}
          </div>
        </Container>
      </div>
      <div className="hide-print">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default StockReport;
