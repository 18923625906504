import React, { useEffect, useState } from "react";
import { Modal, Form, Row, Button, Spinner } from "react-bootstrap";
import { importFileAction } from "../../Redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { SuccessAlert } from "../notify/notify";
import JsonToTable from "../../Services/JsonToTable";

const ImportFileModal = (props) => {
  const dispatch = useDispatch();

  const { loading, message, error } = useSelector((state) => state.importFile);

  const [filefield, setFilefield] = useState(null);
  const [uploadFile, setUploadFile] = useState(false);
  const [errorData, setErrorData] = useState(null);

  const handleFileUpload = (e) => {
    //console.log(e.target);
    setFilefield(e.target.files[0]);
  };

  //console.log("file", filefield);

  useEffect(() => {
    if (!loading && uploadFile) {
      if (message) {
        if (message.result) {
          SuccessAlert(message.result);
        }
        if (message.message) {
          SuccessAlert(message.message);
        }
        setUploadFile(false);
        setTimeout(() => {
          handleCancelClick();
        }, 2000);
      }
      if (error) {
        setUploadFile(false);
        if (error.detail) {
          alert(error.detail);
        } else if (error.message) {
          SuccessAlert(
            `${error.message}. Because Uploaded Generics Already Exist.`
          );
          setTimeout(() => {
            handleCancelClick();
          }, 2000);
        } else if (error.length && error[0].msg) {
          alert(error[0].msg);
        } else if (error.length && error[0].errors && error[0].errors.length) {
          setErrorData(error);
          let err = error[0].errors[0];
          let row = error[0].row;
          for (let key in err) {
            if (err.hasOwnProperty(key)) {
              alert(
                `In Row No: ${row} , ${key} : ${
                  err[key].length && err[key][0].details
                    ? err[key][0].details
                    : err[key]
                }`
              );
            } else {
              alert("Something Went Wrong");
            }
          }
        } else {
          alert("Something Went Wrong");
        }
      }
    }
  }, [loading, uploadFile]);

  // error.detail -> Inventory Admin User
  // error[0].msg -> Unsupported File
  // message.result -> Upload Success

  //console.log(error);

  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (filefield === null) {
      alert("Must Upload CSV File");
    }
    if (filefield !== null && filefield !== undefined) {
      const form_data = new FormData();
      form_data.append("file", filefield);
      form_data.append("data_table", props.importType);
      if (props.importType === "generic_name") {
        dispatch(importFileAction(form_data, props.url));
        setUploadFile(true);
      } else {
        dispatch(importFileAction(form_data, "/"));
        setUploadFile(true);
      }
    }
  };

  const handleCancelClick = () => {
    props.onHide();
    if (filefield) {
      setFilefield(null);
      window.location.reload();
    }
  };
  //console.log(filefield);
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={props.show}
      onHide={props.onHide}
    >
      {/**<Modal.Header className="modal-customize-header" closeButton></Modal.Header> */}
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Import {props.title} as a CSV file</Form.Label>
            <label
              className="pt-4 pb-4"
              htmlFor="inputTag"
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                border: "1px solid #DCE0E4",
                borderRadius: "5px",
              }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <span className="image-upload-text">+ Upload File</span>
              </div>
              <input
                id="inputTag"
                type={"file"}
                //accept={".csv"}
                style={{ display: "none" }}
                required
                onChange={handleFileUpload}
              />
            </label>
          </Form.Group>
          {filefield != null ? (
            <p>
              <b>Chosen file:</b> {filefield.name}
            </p>
          ) : (
            <p>
              <b>Chosen file:</b> No file chosen yet
            </p>
          )}
        </Form>
        {loading === true ? (
          <Row className="mt-2 d-flex justify-content-center">
            <Spinner animation="border" variant="warning" />
          </Row>
        ) : (
          <></>
        )}
        <Row className="mt-3">
          <div className="d-flex justify-content-center">
            <Button
              //type="submit"
              className="border-0"
              onClick={handleFileSubmit}
              style={{ color: "white", background: "#ff9900" }}
              //onClick={() => setImportAssetModal(false)}
              disabled={loading}
            >
              Submit
            </Button>
            <Button
              className="ms-3"
              variant="secondary"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
          </div>
        </Row>
        {/* {errorData && (
          <div>
            <JsonToTable data={errorData} />
          </div>
        )} */}
      </Modal.Body>
    </Modal>
  );
};

export default ImportFileModal;
