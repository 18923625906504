import {
  SUPPLIER_WISE_RECEIVE_REPORT_REQUEST,
  SUPPLIER_WISE_RECEIVE_REPORT_SUCCESS,
  SUPPLIER_WISE_RECEIVE_REPORT_FAILURE,
  SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_REQUEST,
  SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_SUCCESS,
  SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_FAILURE,
  VENDOR_WISE_RECEIVE_REPORT_REQUEST,
  VENDOR_WISE_RECEIVE_REPORT_SUCCESS,
  VENDOR_WISE_RECEIVE_REPORT_FAILURE,
  BARCODE_STOCK_RECEIPT_REPORT_REQUEST,
  BARCODE_STOCK_RECEIPT_REPORT_SUCCESS,
  BARCODE_STOCK_RECEIPT_REPORT_FAILURE,
} from "../type.js";
import axios from "axios";

export const getSupplierWiseRcvReport = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SUPPLIER_WISE_RECEIVE_REPORT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_receipt/all-supplier-stock-receipt-position${keyword}`,
      config
    );

    dispatch({
      type: SUPPLIER_WISE_RECEIVE_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPLIER_WISE_RECEIVE_REPORT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSupplierRcvSummary = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_receipt/all-supplier-purchase-receiving-summary${keyword}`,
      config
    );

    dispatch({
      type: SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPLIER_WISE_PURCHASE_RECEIVE_SUMMARY_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getVendorWiseRcvReport = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_WISE_RECEIVE_REPORT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_receipt/vendor-stock-receipt-position${keyword}`,
      config
    );

    dispatch({
      type: VENDOR_WISE_RECEIVE_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VENDOR_WISE_RECEIVE_REPORT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getBarcodeWiseRcvReport = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: BARCODE_STOCK_RECEIPT_REPORT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_receipt/barcode-stock-receipt-position${keyword}`,
      config
    );

    dispatch({
      type: BARCODE_STOCK_RECEIPT_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BARCODE_STOCK_RECEIPT_REPORT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
