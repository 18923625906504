import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import DeleteModal from "../PopupModal/DeleteModal";
import LoadingModal from "../PopupModal/LoadingModal";
import UpdateSubCategoryModal from "../PopupModal/UpdateSubcategoryModal";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faAdd } from "@fortawesome/free-solid-svg-icons";
import filter from "../../Assets/icons/filter.png";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import printIcon from "../../Assets/icons/print.png";
import uploadIcon from "../../Assets//icons/upload-image.png";
import "./Product.css";
import {
  getSubcategoryList,
  createSubcategory,
  getSearchSubcategoryList,
} from "../../Redux/actions/subcategoryActions";
import { getCategoryList } from "../../Redux/actions/categoryActions";
import ReactPaginate from "react-paginate";
import { SuccessAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import generatePDF from "../../Services/pdfGenerator";
import exportExcel from "../../Services/ExcelGenerator";
import ImportFileModal from "../PopupModal/ImportFileModal";
import SubcatgoryTableRow from "./reusable/SubcatTableRow";

const SubCategory = () => {
  const dispatch = useDispatch();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [subcategoryName, setSubcategoryName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subcategoryIcon, setSubcategoryIcon] = useState(null);
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [alertMessage, setAlertMessage] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [exportData, setExportData] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [importSubCategoryModal, setImportSubCategoryModal] = useState(false);

  const { loading, subcategoryList, error } = useSelector(
    (state) => state.subcategoryList
  );

  const { createLoading, message, createError } = useSelector(
    (state) => state.createSubcategory
  );

  const { searchLoading, searchSubcategoryList, searchError } = useSelector(
    (state) => state.searchSubcategoryList
  );

  const getCategoryResponse = useSelector((state) => state.categoryList);

  useEffect(() => {
    dispatch(getSubcategoryList(limit, offset));
  }, [dispatch, offset]);

  useEffect(() => {
    if (subcategoryList?.data) {
      setTotalItems(subcategoryList.data.count);
    }
  }, [subcategoryList]);

  useEffect(() => {
    dispatch(getCategoryList());
  }, []);

  useEffect(() => {
    if (alertMessage && message === undefined && createError !== undefined) {
      if (createError.name) {
        setAlertMessage(false);
        alert(createError.name);
      } else {
        alert("Something Went Wrong");
      }
    }
    if (alertMessage && message !== undefined) {
      //console.log(message);
      SuccessAlert("New Sub Category Created Successfully");
      dispatch(getSubcategoryList(limit, 0));
      setSubcategoryName("");
      setCategoryName("");
      setSubcategoryIcon(null);
      setAlertMessage(false);
    }
  }, [alertMessage, message, createError]);
  //console.log(searchSubcategoryList?.data?.results);
  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
  };
  const handleSubmitForm = (e) => {
    if (subcategoryIcon === null) {
      e.preventDefault();
      alert("Please Upload Sub Categort Icon Image");
    } else if (!subcategoryIcon.type.includes("image")) {
      e.preventDefault();
      alert("Please Upload JPG/PNG/JPEG media type as Sub Category Icon Image");
    } else if (subcategoryIcon && subcategoryIcon.size > 1048576) {
      e.preventDefault();
      alert("Image Size Can't be greater than 1MB");
    } else {
      e.preventDefault();
      let form_data = new FormData();
      form_data.append("name", subcategoryName);
      form_data.append("icon_image", subcategoryIcon);
      form_data.append("catagory", categoryName);
      dispatch(createSubcategory(form_data));
      setAlertMessage(true);
    }
  };

  const handleSearchResult = (e) => {
    //setSearchValue(e.target.value);
    if (e.target.value.length > 1) {
      setShowSearchResult(true);
      dispatch(getSearchSubcategoryList(e.target.value));
    } else {
      setShowSearchResult(false);
    }
  };
  const handleDeleteSubcategory = (item) => {
    setSelectedItem(item);
    setDeleteModalShow(true);
  };

  const handleUpdateSubcategory = (item) => {
    setSelectedItem(item);
    setUpdateModalShow(true);
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, parseInt(id)]);
    setSelectedItems([
      ...selectedItems,
      subcategoryList?.data.results.find((item) => item.id === parseInt(id)),
    ]);
    //setSelectedItems(array);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
      setSelectedItems(
        selectedItems.filter((item) => item.id !== parseInt(id))
      );
      setIsCheckAll(false);
    }
    //console.log(typeof parseInt(id));
    //console.log(checked);
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setSelectedItems(subcategoryList?.data.results);
    setIsCheck(subcategoryList?.data.results.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
      setSelectedItems([]);
    }
  };
  /** Export All Data */
  const getDataFromApi = (type) => {
    setExportData(true);
    try {
      //const form_data = new FormData();
      //form_data.append("csv_file", filefield);
      //const body = data;
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const api = `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/product/ListofSubCatagoryinventoryView/?ordering=id&limit=${totalItems}&offset=0`;

      axios
        .get(api, settings)
        .then((res) => {
          //console.log("success", res.data.results);
          //setAssetList([...assetList, ...res.data.results]);
          if (type === "pdf") {
            setExportData(false);
            generatePDF(
              res.data.results,
              "SubCategory",
              ["Sub Category ID", "Sub Category Name", "Category Name"],
              "Care-Box Sub Category List",
              totalItems
            );
          } else if (type === "excel") {
            const exportData = [];
            res.data.results.map((each) => {
              let obj = {};
              obj["Sub Category ID"] = each.id;
              obj["Sub Category Name"] = each.name;
              obj["Category Name"] = each.catagory_name;
              exportData.push(obj);
            });
            exportExcel(exportData, "Care_Box_SubCategory_List.xlsx");
            setExportData(false);
          }
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };
  return (
    <div>
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        subcategory={selectedItem}
        action={() => setShowSearchResult(false)}
      />
      <UpdateSubCategoryModal
        show={updateModalShow}
        onHide={() => setUpdateModalShow(false)}
        categories={getCategoryResponse.categoryList.data}
        subcategory={selectedItem}
      />
      <LoadingModal
        show={exportData}
        onHide={() => {
          setExportData(false);
        }}
      />
      <ImportFileModal
        show={importSubCategoryModal}
        onHide={() => setImportSubCategoryModal(false)}
        title={`SubCategory List`}
        importType="sub_category"
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <ToastContainer />
        <Header></Header>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Sub Category</h4>
              <p className="product-paragraph">
                Products <FontAwesomeIcon icon={faChevronRight} size="xs" /> Sub
                Category
              </p>
            </Col>
            <Col lg={6} className="d-flex justify-content-lg-end">
              <button
                className="pe-3 ps-3 border-0 add-product-button"
                onClick={() => setImportSubCategoryModal(true)}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ paddingRight: "0.2rem" }}
                />{" "}
                Import Sub-Category File
              </button>
            </Col>
          </Row>
          <div className="mb-4 p-3 card product-card container-view">
            <Row className="mb-4">
              <Col lg={6} className="mb-2 mb-lg-0">
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={handleSearchResult}
                />
              </Col>
              <Col lg={6} className="d-flex justify-content-md-end">
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as a pdf</Tooltip>}
                >
                  <img
                    className="pe-2"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => {
                      if (selectedItems.length && !exportData) {
                        generatePDF(
                          selectedItems,
                          "SubCategory",
                          [
                            "Sub Category ID",
                            "Sub Category Name",
                            "Category Name",
                          ],
                          "Care-Box Sub Category List (Selected)",
                          selectedItems.length
                        );
                      } else {
                        getDataFromApi("pdf");
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">Export as a excel</Tooltip>
                  }
                >
                  <img
                    className=""
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => {
                      if (selectedItems.length && !exportData) {
                        const exportData = [];
                        selectedItems.map((each) => {
                          let obj = {};
                          obj["Sub Category ID"] = each.id;
                          obj["Sub Category Name"] = each.name;
                          obj["Category Name"] = each.catagory_name;
                          exportData.push(obj);
                        });
                        exportExcel(
                          exportData,
                          "Care_Box_SubCategory_List_Selected.xlsx"
                        );
                      } else {
                        getDataFromApi("excel");
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <img className="d-none" src={printIcon} alt="pdf" height={45} />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={4} className="mb-3">
                <h3 className="category-heading">Add Products Sub Category</h3>
                <div>
                  <Form className="pt-4 pe-3" onSubmit={handleSubmitForm}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Sub Category Name
                        <span className="required-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Sub Category Name"
                        onChange={(e) => setSubcategoryName(e.target.value)}
                        value={subcategoryName}
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      className=" form-group mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Category Name<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => setCategoryName(e.target.value)}
                        value={categoryName}
                        required
                      >
                        <option value="">Choose Category Name</option>
                        {getCategoryResponse.categoryList.data?.results.map(
                          (category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          )
                        )}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-5">
                      <Form.Label>
                        Sub Category Icon Image
                        <span className="required-field">*</span>
                      </Form.Label>
                      <br />
                      <label
                        className="pt-4 pb-4"
                        htmlFor="inputImg"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          border: "1px solid #DCE0E4",
                          borderRadius: "5px",
                        }}
                      >
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img
                            src={uploadIcon}
                            alt="uploadIcon"
                            height={30}
                            width={30}
                          />
                          <span className="image-upload-text">Upload Icon</span>
                          {subcategoryIcon != null ? (
                            <span style={{ fontSize: "14px" }}>
                              <b>Chosen file:</b> {subcategoryIcon.name}
                            </span>
                          ) : (
                            <span style={{ fontSize: "14px" }}>
                              <b>Chosen file:</b> No file chosen yet
                            </span>
                          )}
                        </div>
                        <br />
                        <input
                          id="inputImg"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            setSubcategoryIcon(e.target.files[0])
                          }
                        />
                      </label>
                    </Form.Group>
                    {createLoading === true ? (
                      <Row className="mt-2 d-flex justify-content-center">
                        <Spinner animation="border" variant="warning" />
                      </Row>
                    ) : (
                      <></>
                    )}
                    <Button className="create-btn border-0" type="submit">
                      Create Sub Category
                    </Button>
                  </Form>
                </div>
              </Col>
              <Col lg={8} className="mb-5">
                <Table responsive="xl" hover>
                  <thead className="text-center table-header">
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          name="selectAll"
                          id="selectAll"
                          onChange={handleSelectAll}
                          checked={isCheckAll}
                        />
                      </th>
                      <th>Sub Category Id</th>
                      <th>Image</th>
                      <th>Sub Category Name</th>
                      <th>Category Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {showSearchResult &&
                    searchSubcategoryList?.data !== undefined &&
                    searchSubcategoryList?.data.results.length !== 0 ? (
                      searchSubcategoryList?.data.results.map((subcategory) => (
                        <SubcatgoryTableRow
                          key={subcategory.id}
                          subcategory={subcategory}
                          handleClick={handleClick}
                          isCheck={isCheck}
                          handleUpdateSubcategory={handleUpdateSubcategory}
                          handleDeleteSubcategory={handleDeleteSubcategory}
                        />
                      ))
                    ) : subcategoryList?.data !== undefined &&
                      subcategoryList?.data.results.length !== 0 &&
                      !showSearchResult ? (
                      subcategoryList?.data.results.map((subcategory) => (
                        <SubcatgoryTableRow
                          key={subcategory.id}
                          subcategory={subcategory}
                          handleClick={handleClick}
                          isCheck={isCheck}
                          handleUpdateSubcategory={handleUpdateSubcategory}
                          handleDeleteSubcategory={handleDeleteSubcategory}
                        />
                      ))
                    ) : loading === true ? (
                      <tr>
                        <td colSpan="12">
                          <Spinner animation="border" variant="warning" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="12">No Result Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
              {pageCount > 1 && !showSearchResult ? (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=""
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  pageCount={pageCount}
                  previousLabel=""
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousClassName="hide"
                  nextClassName="hide"
                  activeLinkClassName="active"
                />
              ) : (
                <></>
              )}
            </Row>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default SubCategory;
