import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Col,
  Container,
  Row,
  Table,
  Form,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import "../Transfer/transfer.css";
import { searchProductList } from "../../Redux/actions/productActions";
import { getProductBiography } from "../../Redux/actions/utilitiesAction";
import "../Return/Return.css";
import pdfIcon from "../../Assets/icons/pdf.png";
import ProductBiographyPdf from "../../Services/ProductBiographyPdf";

const ProductBiography = () => {
  const dispatch = useDispatch();
  const [showResults, setShowResults] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [selectProduct, setSelectProduct] = useState({});
  const [searchApiCall, setSearchApiCall] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const { searchLoading, searchProducts } = useSelector(
    (state) => state.searchProductList
  );
  const { loading, product, error } = useSelector(
    (state) => state.productBiography
  );

  // Hasan involvement
  const [searchText, setSearchText] = useState("");

  // const handleSearchResult = (e) => {
  //   //console.log(e.target.value);
  //   if (e.target.value.length > 1) {
  //     //console.log("inside");
  //     setShowSearchResult(true);
  //     dispatch(searchProductList(e.target.value));
  //   } else {
  //     setShowSearchResult(false);
  //   }
  // };

  useEffect(() => {
    if (searchProducts?.data?.waiting) {
      setSearchApiCall(false);
    } else {
      setSearchApiCall(true);
    }
  }, [searchProducts]);

  const doSearch = () => {
    if (
      searchText &&
      searchText !== "" &&
      searchText.length > 1 &&
      searchApiCall
    ) {
      // console.log("doSearch");
      setShowSearchResult(true);
      dispatch(searchProductList(searchText));
    }
  };

  useEffect(() => {
    if (searchText === "") {
      setShowSearchResult(false);
    }
  }, [searchText]);

  useEffect(() => {
    const debounceDelay = 500; // Adjust this delay as needed

    const debounceHandler = setTimeout(() => {
      doSearch();
    }, debounceDelay);

    return () => {
      // Cleanup the previous timeout when the input value changes
      clearTimeout(debounceHandler);
    };
  }, [searchText]);

  // end of Hasan involvement

  // My Custom Code (Hasan)
  const [suggestionSelect, setSuggestionSelect] = useState(null);
  const containerRef = useRef(null);
  const [childOffsets, setChildOffsets] = useState([]);

  useEffect(() => {
    setSuggestionSelect(null);
  }, [searchProducts]);

  useEffect(() => {
    if (
      showSearchResult &&
      searchProducts?.data !== undefined &&
      searchProducts?.data.results &&
      searchProducts?.data.results.length !== 0
    ) {
      // console.log("containerRef = ", containerRef.current);
      const childDivs = Array.from(containerRef.current.children);
      // console.log('childDivs = ', childDivs);
      const offsets = childDivs.map((child) => child.offsetTop);
      // console.log('offsets', offsets);
      setChildOffsets(offsets);
    }
  }, [searchProducts, showSearchResult]);

  const searchInputSuggestionHendler = (e) => {
    if (
      showSearchResult &&
      searchProducts?.data !== undefined &&
      searchProducts?.data.results?.length !== 0
    ) {
      if (e.key === "ArrowUp") {
        // console.log("In searchInputSuggestionHndler, you pressed = ", e.key);
        // console.log("searchProducts = ",searchProducts.data.results);
        if (suggestionSelect > 0) {
          setSuggestionSelect((previousState) => previousState - 1);
          // console.log("searchProducts = ",searchProducts.data.results);

          containerRef.current.scrollTo({
            top: childOffsets[suggestionSelect - 1],
            behavior: "smooth",
          });
        }
      }
      if (e.key === "ArrowDown") {
        if (suggestionSelect || suggestionSelect === 0) {
          if (searchProducts.data.results.length > suggestionSelect + 1) {
            setSuggestionSelect((previousState) => previousState + 1);
            // console.log("searchProducts = ",searchProducts.data.results);
            containerRef.current.scrollTo({
              top: childOffsets[suggestionSelect],
              behavior: "smooth",
            });
          }
        } else {
          setSuggestionSelect(0);
          // console.log("searchProducts = ",searchProducts.data.results);
        }
      }
      if (e.key === "Enter") {
        if (suggestionSelect || suggestionSelect === 0) {
          // console.log("Dispatch your action");
          setSelectProduct(searchProducts.data.results[suggestionSelect]);
          setShowSearchResult(false);
          setShowResults(false);
          setSuggestionSelect(null);
        }
      }
    }
  };

  const generateProductBiography = () => {
    if (selectProduct.id) {
      setShowResults(true);
      dispatch(getProductBiography(selectProduct.id));
    } else {
      alert("Select Product First");
    }
  };

  const exportPdf = () => {
    if (
      showResults &&
      product.biography_data &&
      product.biography_data.results.length
    ) {
      ProductBiographyPdf(product.biography_data.results);
    } else {
      alert("Select A Product First");
    }
  };
  //console.log(selectProduct);

  return (
    <>
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <Container fluid>
          <h4 className="product-heading container-view">Product Biography</h4>
          <p className="product-paragraph container-view">
            {" "}
            Utilities <FontAwesomeIcon icon={faChevronRight} size="xs" /> Show
            Product Biography
          </p>
          <div
            className="card container-view"
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
          >
            <Row className="mb-4 pt-3" style={{ backgroundColor: "#DCE0E4" }}>
              <Col lg={6} style={{ position: "relative" }}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="search"
                    placeholder="Search By Barcode/Product Name"
                    // onChange={handleSearchResult}

                    onChange={(e) => setSearchText(e.target.value)}
                    autoComplete="off"
                    onKeyDown={(e) => searchInputSuggestionHendler(e)}
                  />
                  {showSearchResult &&
                  searchProducts?.data !== undefined &&
                  searchProducts?.data.results?.length ? (
                    <div
                      className="autocomplete_items shadow-lg"
                      //onScroll={DivScroll}
                      ref={containerRef}
                      style={{ zIndex: "2" }}
                    >
                      {searchProducts?.data.results.map((product, i) => (
                        <div
                          className={`card shadow m-2 rounded border border-0 p-2 ${
                            suggestionSelect !== null && suggestionSelect === i
                              ? "selectedSearchItemHover"
                              : ""
                          }`}
                          key={i}
                          onClick={() => {
                            setSelectProduct(product);
                            setShowSearchResult(false);
                            setShowResults(false);
                          }}
                        >
                          <div>
                            <div className="d-flex align-content-center">
                              <div className="">
                                {product.product_name} {product.generic} (
                                {product.unit_size}) - ({product.sub_catagory_1}
                                )
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : showSearchResult &&
                    !searchLoading &&
                    searchProducts?.data !== undefined &&
                    searchProducts?.data.results?.length === 0 ? (
                    <div
                      className="shadow-lg"
                      //onScroll={DivScroll}
                      ref={containerRef}
                      style={{ zIndex: "2", backgroundColor: "white" }}
                    >
                      <div className="m-2 p-2">
                        <div>
                          <div className="d-flex justify-content-center">
                            <div>No Product Found</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : showSearchResult &&
                    (searchLoading || searchProducts?.data?.waiting) ? (
                    <div
                      className="shadow-lg"
                      //onScroll={DivScroll}
                      ref={containerRef}
                      style={{ zIndex: "2", backgroundColor: "white" }}
                    >
                      <div className="m-2 p-2 ">
                        <div>
                          <div className="d-flex align-content-center justify-content-center">
                            <div>
                              <Spinner animation="border" variant="warning" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Button
                  className="report-btn border-0"
                  onClick={generateProductBiography}
                >
                  Generate
                </Button>
              </Col>
              <Col
                lg={3}
                className="d-flex justify-content-md-end align-items-center"
              >
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as pdf</Tooltip>}
                >
                  <img
                    className=""
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    style={{ cursor: "pointer" }}
                    onClick={exportPdf}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
            {showResults ? (
              <>
                <div className="mt-3">
                  <Table responsive bordered hover style={{ fontSize: "14px" }}>
                    <thead className="table-header">
                      <tr>
                        <th colSpan={3}>
                          Barcode:{" "}
                          {selectProduct.barcode
                            ? selectProduct.barcode
                            : "N/A"}
                        </th>
                        <th colSpan={4}>
                          Product Information: {selectProduct.product_name}{" "}
                          {selectProduct.generic} {selectProduct.unit_size}
                        </th>
                      </tr>
                      <tr>
                        <th>Date</th>
                        <th>Quantity Inn</th>
                        <th>Quantity Out</th>
                        <th>CPU</th>
                        <th>RPU</th>
                        <th>Steps</th>
                        <th>User</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {product.biography_data &&
                      product.biography_data.results.length ? (
                        <>
                          {product.biography_data.results.map((p) => (
                            <tr key={p.biography_id}>
                              <td>{p.date}</td>
                              <td>{p.quantity_in}</td>
                              <td>{p.quantity_out}</td>
                              <td>{p.cpu}</td>
                              <td>{p.rpu}</td>
                              <td>{p.steps}</td>
                              <td>{p.user}</td>
                            </tr>
                          ))}

                          <tr>
                            <td className="fw-bold">Total</td>
                            <td className="fw-bold">
                              {product.biography_data.results.reduce(
                                (previousResult, currentValue) => {
                                  return (
                                    previousResult + currentValue.quantity_in
                                  );
                                },
                                0
                              )}
                            </td>
                            <td className="fw-bold">
                              {product.biography_data.results.reduce(
                                (previousResult, currentValue) => {
                                  return (
                                    previousResult + currentValue.quantity_out
                                  );
                                },
                                0
                              )}
                            </td>
                            {/* <td colSpan={4}></td> */}
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </>
                      ) : loading ? (
                        <tr className="text-center">
                          <td colSpan={7}>
                            <Spinner animation="border" variant="warning" />
                          </td>
                        </tr>
                      ) : (
                        <tr className="text-center">
                          <td colSpan={7}>No Biography Found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                {/* <Row className="p-3" style={{ fontSize: "11px" }}>
                  {product.sub_total && product.sub_total.length ? (
                    product.sub_total.map((p, index) => (
                      <Col key={index}>
                        <Row>Grand Total of {p.steps}</Row>
                        <Row>Quantity Inn: {p.total_in}</Row>
                        <Row>Quantity Out: {p.total_out}</Row>
                        <Row>Total Cost(In): {p.total_in_cost}</Row>
                        <Row>Total Sale(In): {p.total_in_sale}</Row>
                        <Row>Total Cost(Out): {p.total_out_cost}</Row>
                        <Row>Total Sale(Out): {p.total_out_sale}</Row>
                      </Col>
                    ))
                  ) : (
                    <></>
                  )}
                </Row> */}
              </>
            ) : selectProduct.product_name && !showResults ? (
              <div className="mt-3 d-flex justify-content-center">
                <p style={{ fontWeight: 700, fontSize: "16px" }}>
                  Selected Product Name : {selectProduct.product_name}{" "}
                  {selectProduct.generic} {selectProduct.unit_size}
                </p>
              </div>
            ) : (
              <div className="mt-3 d-flex justify-content-center">
                <p style={{ fontWeight: 700, fontSize: "16px" }}>
                  Select A Product To Show Product Biography
                </p>
              </div>
            )}
          </div>
        </Container>
      </div>

      <div className="mt-4">
        <Footer></Footer>
      </div>
    </>
  );
};

export default ProductBiography;
