import React, { useEffect, useState } from "react";
import { Row, Col, Table, Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const AddPurchaseModal = (props) => {
  let [selectedVendorName, setSelectedVendorName] = useState("");
  const { vendorList } = useSelector((state) => state.vendorList);

  useEffect(() => {
    if (vendorList.data?.results) {
      // console.log("vendorList = ", vendorList.data.results);
      // console.log("props.vendorId = ", typeof props.vendorId);

      let selectedVendorInfo = vendorList.data.results.find((vendor) => {
        if (parseInt(props.vendorId) === vendor.id) {
          return vendor.vendor_name;
        }
      });

      // console.log("vendor = ", selectedVendorInfo);
      if (selectedVendorInfo) {
        setSelectedVendorName(selectedVendorInfo.vendor_name);
      }
    }
  }, [vendorList, props.vendorId]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      fullscreen={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto" id="contained-modal-title-vcenter">
          Product Receiving (GRN) Preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4" style={{ overflowY: "scroll" }}>
        <Row>
          <Col sm={6}>
            <p>
              Vendor Name:
              {selectedVendorName ? ` ${selectedVendorName}` : " Not Selected"}
            </p>
          </Col>
          <Col sm={6}>
            <p>
              Purchase Date:
              {props.purchaseDate ? ` ${props.purchaseDate}` : " Not Selected"}
            </p>
          </Col>
        </Row>

        <Table bordered hover style={{ fontSize: "12px" }}>
          <thead className="text-center table-header">
            <tr>
              <th>SL</th>
              <th>Barcode</th>
              <th>Information</th>
              <th>Units</th>
              <th>Qty</th>
              <th>Bonus Qty</th>
              <th>P.Price</th>
              <th>P.Vat</th>
              <th>New P.Price</th>
              <th>S.Price</th>
              <th>New S.Price</th>
              <th>GP%</th>
              <th>Subtotal</th>
              <th>Batch No.</th>
              <th>Supplier Name</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody className="text-center">
            {props.productList.length > 0 ? (
              <>
                {props.listWithIndices
                  .sort((a, b) => b.index - a.index)
                  .map((list, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{list.value.barcode}</td>
                      <td>{list.value.name}</td>
                      <td>{list.value.units}</td>
                      <td>{list.value.quantity_amount}</td>
                      <td>{list.value.bonus_quantity}</td>
                      <td>{list.value.purchase_price}</td>
                      <td>{list.value.purchase_vat}%</td>
                      <td>{list.value.new_purchase_price}</td>
                      <td>{list.value.sales_price}</td>
                      <td>{list.value.new_sales_price}</td>
                      <td>{list.value.gross_profit}%</td>
                      <td>{list.value.subtotal.toFixed(2)}</td>
                      <td>{list.value.batch_no}</td>
                      <td>{list.value.supplierName}</td>
                      {/* <td style={{ cursor: "pointer" }}>
                            <OverlayTrigger
                              key="export2"
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">Remove</Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                color="#E40000"
                                style={{ paddingLeft: "0.4rem" }}
                                onClick={() =>
                                  removeProduct(list.value.product_id)
                                }
                              />
                            </OverlayTrigger>
                          </td> */}
                    </tr>
                  ))}
                <tr>
                  <td colSpan={2} className="fw-bold">
                    Total
                  </td>
                  <td></td>
                  <td></td>
                  <td className="fw-bold">
                    {props.productList.reduce(
                      (previousResult, currentValue) => {
                        return (
                          previousResult +
                          parseInt(currentValue.quantity_amount)
                        );
                        // console.log("currentValue = ", currentValue);
                        // console.log(
                        //   "currentValue.quantity_amount = ",
                        //   currentValue.quantity_amount
                        // );
                      },
                      0
                    )}
                  </td>

                  <td className="fw-bold">
                    {props.productList.reduce(
                      (previousResult, currentValue) => {
                        return (
                          previousResult + parseInt(currentValue.bonus_quantity)
                        );
                        // console.log("currentValue = ", currentValue);
                        // console.log(
                        //   "currentValue.bonus_quantity = ",
                        //   currentValue.bonus_quantity
                        // );
                      },
                      0
                    )}
                  </td>

                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  {/* <td>Action</td> */}
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan="17">No Product Added</td>
              </tr>
            )}
          </tbody>
        </Table>

        <Table
          //responsive
          bordered
          hover
          style={{ fontSize: "12px" }}
        >
          <thead>
            <tr>
              <th>Total Purchase Value</th>
              <th>Additional Commission</th>
              <th>Total Vat (৳)</th>
              <th>Net Purchase Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.totalPurchaseValue}</td>
              <td>{props.commission}</td>
              <td>{props.totalVat}</td>
              <td>{props.netPurchase}</td>
            </tr>
          </tbody>
        </Table>

        <Row className="mt-5 d-flex justify-content-center">
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button className="cancel-btn border-0" onClick={props.onHide}>
              Go Back
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AddPurchaseModal;
