import {
  PURCHASE_ORDER_LIST_REQUEST,
  PURCHASE_ORDER_LIST_SUCCESS,
  PURCHASE_ORDER_LIST_FAILURE,
  CREATE_PURCHASE_ORDER_REQUEST,
  CREATE_PURCHASE_ORDER_SUCCESS,
  CREATE_PURCHASE_ORDER_FAILURE,
  EACH_PURCHASE_ORDER_DETAIL_REQUEST,
  EACH_PURCHASE_ORDER_DETAIL_SUCCESS,
  EACH_PURCHASE_ORDER_DETAIL_FAILURE,
  SEARCH_PURCHASE_ORDER_LIST_REQUEST,
  SEARCH_PURCHASE_ORDER_LIST_SUCCESS,
  SEARCH_PURCHASE_ORDER_LIST_FAILURE,
  PO_PRODUCT_FILTER_REQUEST,
  PO_PRODUCT_FILTER_SUCCESS,
  PO_PRODUCT_FILTER_FAILURE,
} from "../type.js";

export const purchaseOrderListReducer = (
  state = { purchaseOrderList: {} },
  action
) => {
  switch (action.type) {
    case PURCHASE_ORDER_LIST_REQUEST:
      return { loading: true, ...state };
    case PURCHASE_ORDER_LIST_SUCCESS:
      return { loading: false, purchaseOrderList: action.payload };
    case PURCHASE_ORDER_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createPurchaseOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PURCHASE_ORDER_REQUEST:
      return { createLoading: true };
    case CREATE_PURCHASE_ORDER_SUCCESS:
      return {
        createLoading: false,
        message: action.payload,
      };
    case CREATE_PURCHASE_ORDER_FAILURE:
      return {
        createLoading: false,
        createError: action.payload,
      };
    default:
      return state;
  }
};

export const purchaseOrderDetailsReducers = (
  state = { loading: true, purchaseOrder: {} },
  action
) => {
  switch (action.type) {
    case EACH_PURCHASE_ORDER_DETAIL_REQUEST:
      return { ...state, loading: true };
    case EACH_PURCHASE_ORDER_DETAIL_SUCCESS:
      return { ...state, loading: false, purchaseOrder: action.payload };
    case EACH_PURCHASE_ORDER_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchPurchaseOrderListReducer = (
  state = { searchPurchaseOrder: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_PURCHASE_ORDER_LIST_REQUEST:
      return { searchLoading: true, ...state };
    case SEARCH_PURCHASE_ORDER_LIST_SUCCESS:
      return { searchLoading: false, searchPurchaseOrder: action.payload };
    case SEARCH_PURCHASE_ORDER_LIST_FAILURE:
      return { searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};

export const poProductFilterReducer = (
  state = { productFilterList: {} },
  action
) => {
  switch (action.type) {
    case PO_PRODUCT_FILTER_REQUEST:
      return { ...state, loading: true };
    case PO_PRODUCT_FILTER_SUCCESS:
      return { ...state, loading: false, productFilterList: action.payload };
    case PO_PRODUCT_FILTER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
