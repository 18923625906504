import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import logo from "../../Assets/care-box-logo.png";
import loginImage from "../../Assets/Illustration.svg";
import loginSideImage from "../../Assets/login.png";
import { login } from "../../Redux/actions/userActions";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { loading, userInfo, error } = useSelector((state) => state.userLogin);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);

  const onSignInSubmit = (e) => {
    e.preventDefault();
    dispatch(login(name, password));
  };

  useEffect(() => {
    if (error) {
      navigate("/login");
      localStorage.setItem("url", location.pathname);
      //window.location.reload();
      setErrorAlert(true);
      //console.log(error);
    } else if (userInfo) {
      navigate("/", { replace: true });
      //console.log(userInfo);
    } else {
      // history.push("/home");
      // history.replace(from);
      // dispatch(getUserDetails());
      setErrorAlert(false);
    }
  }, [error, userInfo]);
  //console.log(error);

  //console.log(userInfo);
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Row>
        <Col className="d-none d-lg-block" lg={5} md={0}>
          <img
            src={loginSideImage}
            alt="login"
            style={{ height: "100vh", width: "42vw" }}
          />
        </Col>
        <Col
          lg={7}
          md={12}
          className="login-container d-flex flex-column justify-content-center align-items-center"
        >
          {errorAlert ? (
            <div className="alert alert-danger fade show" role="alert">
              <p className="text-center">
                <i className="bi bi-exclamation-triangle-fill"></i> Request
                Failed!! Give Correct Informations !!
              </p>
            </div>
          ) : (
            <div></div>
          )}
          <p
            className="mt-2 text-center"
            style={{ fontWeight: "700", fontSize: "36px", wordSpacing: "5px" }}
          >
            Inventory System Login
          </p>
          <form className="form_container" onSubmit={onSignInSubmit}>
            <div className="pt-3 pb-3 text-center">
              <input
                type="text"
                placeholder="User Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="pb-3 text-center">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {loading === true ? (
              <>
                <Row className="mb-2 d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="warning" />
                </Row>
                <Button variant="secondary" disabled>
                  Login
                </Button>
              </>
            ) : (
              <button
                className="login-button mb-2"
                type="submit"
                //onClick={() => navigate("/")}
              >
                LOGIN
              </button>
            )}
          </form>
          <div style={{ marginTop: "100px" }}>
            <p
              className="text-center"
              style={{
                letterSpacing: "0px",
                fontSize: "0.75rem",
                marginBottom: "3px",
              }}
            >
              Powered By
            </p>
            <img className="" src={logo} alt="logo" height={60} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;

/** <div>
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-8">
            <h2 className="pt-4 pb-4 text-center" style={{ fontWeight: "600" }}>
              Inventory Management System
            </h2>
            <div className="row p-5 form_container">
              <div className="col-lg-6">
                <img
                  //style={{ height: "40vh" , width:"25vw" }}
                  src={loginImage}
                  alt="loginImage"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6">
                <p
                  className="pt-4 text-center"
                  style={{ fontWeight: "700", fontSize: "5vh" }}
                >
                  Login
                </p>
                <form className="text-center">
                  <div className="pt-3 pb-3 text-center">
                    <input type="text" placeholder="User Name" required />
                  </div>
                  <div className="pb-3 text-center">
                    <input type="text" placeholder="Password" required />
                  </div>
                  <button
                    className="mb-2"
                    type="submit"
                    onClick={() => navigate("/")}
                  >
                    LOGIN
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */
