import {
  PRODUCT_BIOGRAPHY_REQUEST,
  PRODUCT_BIOGRAPHY_SUCCESS,
  PRODUCT_BIOGRAPHY_FAILURE,
  INVENTORY_LIST_REQUEST,
  INVENTORY_LIST_SUCCESS,
  INVENTORY_LIST_FAILURE,
  CREATE_PHYSICAL_INVENTORY_REQUEST,
  CREATE_PHYSICAL_INVENTORY_SUCCESS,
  CREATE_PHYSICAL_INVENTORY_FAILURE,
  PHYSICAL_INVENTORY_LIST_REQUEST,
  PHYSICAL_INVENTORY_LIST_SUCCESS,
  PHYSICAL_INVENTORY_LIST_FAILURE,
  PHYSICAL_INVENTORY_UPDATE_REQUEST,
  PHYSICAL_INVENTORY_UPDATE_SUCCESS,
  PHYSICAL_INVENTORY_UPDATE_FAILURE,
  INVENTORY_LOG_LIST_REQUEST,
  INVENTORY_LOG_LIST_SUCCESS,
  INVENTORY_LOG_LIST_FAILURE,
} from "../type";
import axios from "axios";

export const getProductBiography = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_BIOGRAPHY_REQUEST });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/biography_report/product_biography_report/?product_id=${id}`,
      config
    );
    dispatch({
      type: PRODUCT_BIOGRAPHY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_BIOGRAPHY_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getInventoryList = (id) => async (dispatch) => {
  try {
    dispatch({ type: INVENTORY_LIST_REQUEST });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/physicla-inventory-generate-report-product-list/?limit=500&offset=0&supplier_id=${id}`,
      config
    );
    dispatch({
      type: INVENTORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INVENTORY_LIST_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createPhysicalInventory = (body) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PHYSICAL_INVENTORY_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/create-physical-inventory-log/`,
      body,
      config
    );

    dispatch({
      type: CREATE_PHYSICAL_INVENTORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PHYSICAL_INVENTORY_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getPhysicalInventoryList = (id) => async (dispatch) => {
  try {
    dispatch({ type: PHYSICAL_INVENTORY_LIST_REQUEST });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/physical-inventory-list/?log_id=${id}`,
      config
    );
    dispatch({
      type: PHYSICAL_INVENTORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PHYSICAL_INVENTORY_LIST_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePhysicalInventory = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: PHYSICAL_INVENTORY_UPDATE_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/physical-Scanned-Update/${id}/`,
      body,
      config
    );

    dispatch({
      type: PHYSICAL_INVENTORY_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PHYSICAL_INVENTORY_UPDATE_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getInventoryLogList = (keyword) => async (dispatch) => {
  try {
    dispatch({ type: INVENTORY_LOG_LIST_REQUEST });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/inventory/v1/inventory_log/${keyword}`,
      config
    );
    dispatch({
      type: INVENTORY_LOG_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INVENTORY_LOG_LIST_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
