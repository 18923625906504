import jsPDF from "jspdf";
import "jspdf-autotable";

const addFooters = (doc, date, time) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text("Date:" + date, 16, doc.internal.pageSize.height - 10);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
    doc.text(
      "Time:" + time,
      doc.internal.pageSize.width - 35,
      doc.internal.pageSize.height - 10
    );
  }
};

const SupplierRcvReportPdf = (tableData, reportTitle, pdfTitle) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });

  const date = new Date();
  var title = "Care-Box";
  var reportName = reportTitle;
  var titleWidth = doc.getTextDimensions(title).w;
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  var x = (pageWidth - titleWidth) / 2;
  doc.text(title, x, 14);
  doc.text(
    reportName,
    (pageWidth - doc.getTextDimensions(reportName).w) / 2,
    20
  );

  tableData.forEach((data, index) => {
    doc.autoTable({
      startY: index === 0 ? 30 : doc.autoTable.previous.finalY + 10, // Adjust starting Y position
      head: [
        [
          {
            content: `Supplier Name : ${data.supplier_name}`,
            colSpan: 7,
            styles: { valign: "middle", halign: "center" },
          },
        ],
        [
          "Purchase Id",
          "Category",
          "Barcode",
          "Product Information",
          "CPU",
          "Batch",
          "Total Received Qty",
          "Total Value at Cost",
        ],
      ],
      body: data.products_data.map((product) => [
        product.pop_id ? product.pop_id : product.purchase_id,
        product.product_category,
        product.barcode ? product.barcode : "N/A",
        `${product.product_name} ${product.generic_name} ${product.product_unit}`,
        product.cpu,
        product.product_batch,
        product.receive_qty,
        product.value_at_cost,
      ]),
      theme: "plain",
      styles: {
        halign: "center",
        lineColor: "DCE0E4",
        lineWidth: 0.2,
      },
      headStyles: {
        textColor: "black",
        fillColor: "#fafbfe",
      },
    });
  });

  addFooters(doc, date.toLocaleDateString(), date.toLocaleTimeString());
  doc.save(`${pdfTitle}_${date.toLocaleTimeString()}`);
};

export default SupplierRcvReportPdf;
