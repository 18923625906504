import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Spinner, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import {
  getFastMovingProducts,
  getSlowMovingProducts,
} from "../../../Redux/actions/stockAnalysisActions";

const MovingProducts = (props) => {
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (props.slowMovingList?.data) {
      setTotalItems(props.slowMovingList.data.count);
    }
  }, [props.slowMovingList]);
  const pageCount = Math.ceil(totalItems / props.limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.limit) % totalItems;
    if (props.selectReport === "Slow Moving product") {
      if (props.fromDate !== "" && props.toDate !== "") {
        dispatch(
          getSlowMovingProducts(
            `?limit=${props.limit}&offset=${newOffset}&supplier_id=${props.supplierId}&from_date=${props.fromDate}&to_date=${props.toDate}`
          )
        );
      } else {
        dispatch(
          getSlowMovingProducts(
            `?limit=${props.limit}&offset=${newOffset}&supplier_id=${props.supplierId}`
          )
        );
      }
    } else if (props.selectReport === "Fast moving product") {
      if (props.fromDate !== "" && props.toDate !== "") {
        dispatch(
          getFastMovingProducts(
            `?limit=${props.limit}&offset=${newOffset}&supplier_id=${props.supplierId}&from_date=${props.fromDate}&to_date=${props.toDate}`
          )
        );
      } else {
        dispatch(
          getFastMovingProducts(
            `?limit=${props.limit}&offset=${newOffset}&supplier_id=${props.supplierId}`
          )
        );
      }
    }
  };
  return (
    <>
      <div className="mt-2 transfer-table">
        <Row
          className="mx-0 py-2 table-header"
          //style={{ borderBottom: "1px solid #dee2e6" }}
        >
          <Col lg={4}>
            <b>Supplier Name: </b>{" "}
            {props.selectedSupplier.length
              ? props.selectedSupplier[0].supplier_name
              : ""}
          </Col>
          <Col lg={2}>
            <b>Total Products: </b>{" "}
            {props.selectReport === "Slow Moving product" &&
            props.slowMovingList.data
              ? props.slowMovingList.data.results.length
              : props.selectReport === "Fast moving product" &&
                props.fastMovingList.data
              ? props.fastMovingList.data.results.length
              : ""}
          </Col>
          <Col lg={3} className="d-flex justify-content-center">
            <b>From Date: </b> {props.fromDate ? props.fromDate : "N/A"}
          </Col>
          <Col lg={3} className="d-flex justify-content-end">
            <b>To Date: </b>
            {props.toDate ? props.toDate : "N/A"}
          </Col>
        </Row>
      </div>
      <Table bordered responsive>
        <thead className="text-center report-table-header report-border">
          <tr>
            <th>Product Information</th>
            <th>Buy (Qty)</th>
            <th>Sold (Qty)</th>
            <th>Balance(Qty)</th>
            <th>Buy Date</th>
            <th>Last Sale Date</th>
            <th>Days Passed</th>
          </tr>
        </thead>
        <tbody className="text-center report-table-body">
          {props.selectReport === "Slow Moving product" &&
          props.slowMovingList.data &&
          !props.slowMovingLoading &&
          props.slowMovingList.data.results.length ? (
            <>
              {props.slowMovingList.data.results.map((prod, i) => (
                <tr key={i}>
                  <td>
                    {prod.product_name} {prod.generic_name} {prod.unit}
                  </td>
                  <td>{prod.buy_quantity}</td>
                  <td>{prod.sold_quantity}</td>
                  <td>{prod.balance_quantity}</td>
                  <td>{prod.buy_date}</td>
                  <td>{prod.sale_date.split("T")[0]}</td>
                  <td>{prod.days_passed}</td>
                </tr>
              ))}
              <tr className="grand-total-row">
                <td>Grand Total</td>
                <td>
                  {props.slowMovingList.data.results.reduce(
                    (total, prod) => (total += prod.buy_quantity),
                    0
                  )}
                </td>
                <td>
                  {props.slowMovingList.data.results.reduce(
                    (total, prod) => (total += prod.sold_quantity),
                    0
                  )}
                </td>
                <td>
                  {props.slowMovingList.data.results.reduce(
                    (total, prod) => (total += prod.balance_quantity),
                    0
                  )}
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </>
          ) : props.selectReport === "Fast moving product" &&
            props.fastMovingList.data &&
            !props.fastMovingLoading &&
            props.fastMovingList.data.results.length ? (
            <>
              {props.fastMovingList.data.results.map((prod, i) => (
                <tr key={i}>
                  <td>
                    {prod.product_name} {prod.generic_name} {prod.unit}
                  </td>
                  <td>{prod.buy_quantity}</td>
                  <td>{prod.sold_quantity}</td>
                  <td>{prod.balance_quantity}</td>
                  <td>{prod.buy_date}</td>
                  <td>{prod.sale_date.split("T")[0]}</td>
                  <td>{prod.days_passed}</td>
                </tr>
              ))}
              <tr className="grand-total-row">
                <td>Grand Total</td>
                <td>
                  {props.fastMovingList.data.results.reduce(
                    (total, prod) => (total += prod.buy_quantity),
                    0
                  )}
                </td>
                <td>
                  {props.fastMovingList.data.results.reduce(
                    (total, prod) => (total += prod.sold_quantity),
                    0
                  )}
                </td>
                <td>
                  {props.fastMovingList.data.results.reduce(
                    (total, prod) => (total += prod.balance_quantity),
                    0
                  )}
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </>
          ) : props.slowMovingLoading || props.fastMovingLoading ? (
            <tr>
              <td colSpan={12}>
                <Spinner animation="border" variant="warning" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={12}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
      {pageCount > 1 && !props.slowMovingLoading && !props.fastMovingLoading ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousClassName="hide"
          nextClassName="hide"
          activeLinkClassName="active"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MovingProducts;
