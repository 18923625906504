import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SupplierForm from "./SupplierForm";
import { createSupplier } from "../../Redux/actions/supplierActions";
import "../Products/Product.css";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import { SuccessAlert } from "../notify/notify";

const AddSupplier = () => {
  const dispatch = useDispatch();
  const { loading, message, error } = useSelector(
    (state) => state.createSupplier
  );
  const [supplierCategory, setSupplierCategory] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [supplierAddress, setSupplierAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [supplierProductCategory, setSupplierProductCategory] = useState("");
  const [status, setStatus] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);
  //console.log("loading:", loading, "message:", message, "error", error);
  useEffect(() => {
    if (alertMessage && message === undefined && error !== undefined) {
      if (error.contact_number) {
        alert(`Contact Number: ${error.contact_number}`);
      } else if (error.supplier_name) {
        alert(`Supplier Name: ${error.supplier_name}`);
      } else if (error.message) {
        alert(`${error.message}`);
      } else {
        alert("Something Went Wrong");
      }
      setAlertMessage(false);
    }
    if (alertMessage && message !== undefined) {
      //console.log(message);
      SuccessAlert("New Supplier Created Successfully");
      setSupplierCategory("");
      setSupplierName("");
      setSupplierAddress("");
      setContactNumber("");
      setEmailAddress("");
      setContactPerson("");
      setSupplierProductCategory("");
      setStatus("");
      setAlertMessage(false);
    }
  }, [alertMessage, message, error]);
  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (contactNumber.length) {
      const body = {
        category_of_Supplier: supplierCategory,
        supplier_name: supplierName,
        supplier_address: supplierAddress,
        contact_number: contactNumber,
        email_address: emailAddress,
        contact_person: contactPerson,
        supplier_product_category: supplierProductCategory,
        status: status,
      };
      dispatch(createSupplier(body));
    } else {
      const body = {
        category_of_Supplier: supplierCategory,
        supplier_name: supplierName,
        supplier_address: supplierAddress,
        email_address: emailAddress,
        contact_person: contactPerson,
        supplier_product_category: supplierProductCategory,
        status: status,
      };
      dispatch(createSupplier(body));
    }
    setAlertMessage(true);
  };
  //console.log(contactNumber);
  return (
    <div>
      <SideBar />
      <div className="mainContent">
        <ToastContainer />
        <Header></Header>
        <Container fluid>
          <h4 className="product-heading container-view">Supplier Add</h4>
          <p className="product-paragraph container-view">
            Supplier <FontAwesomeIcon icon={faChevronRight} size="xs" /> Add
            Supplier
          </p>
          <div className="mb-4 card product-card container-view">
            <SupplierForm
              loading={loading}
              supplierCategory={supplierCategory}
              setSupplierCategory={setSupplierCategory}
              supplierName={supplierName}
              setSupplierName={setSupplierName}
              supplierAddress={supplierAddress}
              setSupplierAddress={setSupplierAddress}
              contactNumber={contactNumber}
              setContactNumber={setContactNumber}
              emailAddress={emailAddress}
              setEmailAddress={setEmailAddress}
              contactPerson={contactPerson}
              setContactPerson={setContactPerson}
              supplierProductCategory={supplierProductCategory}
              setSupplierProductCategory={setSupplierProductCategory}
              status={status}
              setStatus={setStatus}
              handleSubmitForm={handleSubmitForm}
            />
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AddSupplier;
