export const VoidReasonsData = [
  {
    id: 1,
    value: "Customer Doesn’t Have Enough Money",
  },
  {
    id: 7,
    value: "Delayed Product Delivery",
  },
  {
    id: 2,
    value: "Over Price",
  },
  {
    id: 3,
    value: "Discount Issues",
  },
  {
    id: 4,
    value: "VAT Issues",
  },
  {
    id: 5,
    value: "Card & Cash Issues",
  },
  {
    id: 6,
    value: "Wrong Billing",
  },

  {
    id: 8,
    value: "Others",
  },
];
