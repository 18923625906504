import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./Routes/PrivateRoute";
import Login from "./Components/LoginPage/Login";
import DashBoard from "./Components/DashBoard/Dashboard";
import ProductList from "./Components/Products/ProductList";
import AddProduct from "./Components/Products/AddProduct";
import Category from "./Components/Products/Category";
import SubCategory from "./Components/Products/SubCategory";
import EachProduct from "./Components/Products/EachProduct";
import SupplierList from "./Components/Supplier/SupplierList";
import AddSupplier from "./Components/Supplier/AddSupplier";
import EachSupplier from "./Components/Supplier/EachSupplier";
import VendorList from "./Components/Vendor/VendorList";
import AddVendor from "./Components/Vendor/AddVendor";
import EachVendor from "./Components/Vendor/EachVendor";
import AddPurchase from "./Components/Purchase/AddPurchase";
import PurchaseList from "./Components/Purchase/PurchaseList";
import PurchaseRequest from "./Components/Purchase/RequestPurchase";
import AddRequestPurchase from "./Components/Purchase/AddRequestPurchase";
import ReturnList from "./Components/Return/ReturnList";
import AddReturn from "./Components/Return/AddReturn";
import NotFoundPage from "./Components/NotFoundPage/NotFoundPage";
import UpdateProduct from "./Components/Products/UpdateProduct";
import UpdateSupplier from "./Components/Supplier/UpdateSupplier";
import UpdateVendor from "./Components/Vendor/UpdateVendor";
import StockReport from "./Components/Reports/StockReport";
import WarehouseList from "./Components/Warehouse/WarehouseList";
import AddWarehouse from "./Components/Warehouse/AddWarehouse";
import EachWarehouse from "./Components/Warehouse/EachWarehouse";
import UpdateWarehouse from "./Components/Warehouse/UpdateWarehouse";
import TransferList from "./Components/Transfer/TransferList";
import EachTransfer from "./Components/Transfer/EachTransfer";
import AddTransfer from "./Components/Transfer/AddTransfer";
import DueDetailList from "./Components/DueDetails/DueDetails";
import AddPurchaseOrder from "./Components/Purchase/AddPurchaseOrder";
import PurchaseOrderList from "./Components/Purchase/PurchaseOrderList";
import EachPurchaseOrder from "./Components/Purchase/EachPurchaseOrder";
import StockAnalysis from "./Components/Reports/StockAnalysis";
import ReturnStockReport from "./Components/Reports/ReturnStockReport";
import EachPurchase from "./Components/Purchase/EachPurchase";
import ProductBiography from "./Components/Utilities/ProductBiography";
import CreateInventory from "./Components/Utilities/CreateInventory";
import PhysicalInventory from "./Components/Utilities/PhysicalInventory";
import InventoryLogs from "./Components/Utilities/InventoryLogs";
import SupplierWiseRcv from "./Components/Reports/SupplierWiseRcv";
import VendorWiseRcv from "./Components/Reports/VendorWiseRcv";
import VoidList from "./Components/Void/VoidList";
import AddVoid from "./Components/Void/AddVoid";
import EachVoid from "./Components/Void/EachVoid";
import GenericList from "./Components/Products/GenericList";
import "./App.css";

const App = () => {
  return (
    <>
      {/** <Login></Login> */}
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <DashBoard></DashBoard>
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<Login></Login>} />

          <Route
            path="/productList"
            element={
              <PrivateRoute>
                <ProductList></ProductList>
              </PrivateRoute>
            }
          />
          <Route
            path="/addProducts"
            element={
              <PrivateRoute>
                <AddProduct></AddProduct>
              </PrivateRoute>
            }
          />
          <Route
            path="/category"
            element={
              <PrivateRoute>
                <Category></Category>
              </PrivateRoute>
            }
          />
          <Route
            path="/subCategory"
            element={
              <PrivateRoute>
                <SubCategory></SubCategory>
              </PrivateRoute>
            }
          />
          <Route
            path="/product/:id"
            element={
              <PrivateRoute>
                <EachProduct></EachProduct>
              </PrivateRoute>
            }
          />
          <Route
            path="/updateProduct/:id"
            element={
              <PrivateRoute>
                <UpdateProduct></UpdateProduct>
              </PrivateRoute>
            }
          />
          <Route
            path="/genericList"
            element={
              <PrivateRoute>
                <GenericList></GenericList>
              </PrivateRoute>
            }
          />

          <Route
            path="/supplierList"
            element={
              <PrivateRoute>
                <SupplierList></SupplierList>
              </PrivateRoute>
            }
          />
          <Route
            path="/addSupplier"
            element={
              <PrivateRoute>
                <AddSupplier></AddSupplier>
              </PrivateRoute>
            }
          />
          <Route
            path="/supplier/:id"
            element={
              <PrivateRoute>
                <EachSupplier></EachSupplier>
              </PrivateRoute>
            }
          />
          <Route
            path="/updateSupplier/:id"
            element={
              <PrivateRoute>
                <UpdateSupplier></UpdateSupplier>
              </PrivateRoute>
            }
          />

          <Route
            path="/vendorList"
            element={
              <PrivateRoute>
                <VendorList></VendorList>
              </PrivateRoute>
            }
          />
          <Route
            path="/addVendor"
            element={
              <PrivateRoute>
                <AddVendor></AddVendor>
              </PrivateRoute>
            }
          />
          <Route
            path="/vendor/:id"
            element={
              <PrivateRoute>
                <EachVendor></EachVendor>
              </PrivateRoute>
            }
          />
          <Route
            path="/updateVendor/:id"
            element={
              <PrivateRoute>
                <UpdateVendor></UpdateVendor>
              </PrivateRoute>
            }
          />

          <Route
            path="/addPurchase"
            element={
              <PrivateRoute>
                <AddPurchase></AddPurchase>
              </PrivateRoute>
            }
          />
          <Route
            path="/purchaseList"
            element={
              <PrivateRoute>
                <PurchaseList></PurchaseList>
              </PrivateRoute>
            }
          />
          <Route
            path="/purchase/:id"
            element={
              <PrivateRoute>
                <EachPurchase></EachPurchase>
              </PrivateRoute>
            }
          />
          <Route
            path="/requestPurchase"
            element={
              <PrivateRoute>
                <PurchaseRequest></PurchaseRequest>
              </PrivateRoute>
            }
          />
          <Route
            path="/addRequestPurchase"
            element={
              <PrivateRoute>
                <AddRequestPurchase></AddRequestPurchase>
              </PrivateRoute>
            }
          />

          <Route
            path="/returnList"
            element={
              <PrivateRoute>
                <ReturnList></ReturnList>
              </PrivateRoute>
            }
          />
          <Route
            path="/addReturn"
            element={
              <PrivateRoute>
                <AddReturn></AddReturn>
              </PrivateRoute>
            }
          />

          <Route
            path="/destinationList"
            element={
              <PrivateRoute>
                <WarehouseList></WarehouseList>
              </PrivateRoute>
            }
          />
          <Route
            path="/addDestination"
            element={
              <PrivateRoute>
                <AddWarehouse></AddWarehouse>
              </PrivateRoute>
            }
          />
          <Route
            path="/destination/:id"
            element={
              <PrivateRoute>
                <EachWarehouse></EachWarehouse>
              </PrivateRoute>
            }
          />
          <Route
            path="/updateDestination/:id"
            element={
              <PrivateRoute>
                <UpdateWarehouse></UpdateWarehouse>
              </PrivateRoute>
            }
          />

          <Route
            path="/transferList"
            element={
              <PrivateRoute>
                <TransferList></TransferList>
              </PrivateRoute>
            }
          />
          <Route
            path="/transfer/:id"
            element={
              <PrivateRoute>
                <EachTransfer></EachTransfer>
              </PrivateRoute>
            }
          />
          <Route
            path="/addTransfer"
            element={
              <PrivateRoute>
                <AddTransfer></AddTransfer>
              </PrivateRoute>
            }
          />

          <Route
            path="/due-details"
            element={
              <PrivateRoute>
                <DueDetailList></DueDetailList>
              </PrivateRoute>
            }
          />

          <Route
            path="/purchaseOrderList"
            element={
              <PrivateRoute>
                <PurchaseOrderList></PurchaseOrderList>
              </PrivateRoute>
            }
          />

          <Route
            path="/addPurchaseOrder"
            element={
              <PrivateRoute>
                <AddPurchaseOrder></AddPurchaseOrder>
              </PrivateRoute>
            }
          />

          <Route
            path="/purchaseOrder/:id"
            element={
              <PrivateRoute>
                <EachPurchaseOrder></EachPurchaseOrder>
              </PrivateRoute>
            }
          />

          <Route
            path="/productBiography"
            element={
              <PrivateRoute>
                <ProductBiography></ProductBiography>
              </PrivateRoute>
            }
          />
          <Route
            path="/createInventory"
            element={
              <PrivateRoute>
                <CreateInventory></CreateInventory>
              </PrivateRoute>
            }
          />
          <Route
            path="/physicalInventory"
            element={
              <PrivateRoute>
                <PhysicalInventory></PhysicalInventory>
              </PrivateRoute>
            }
          />
          <Route
            path="/inventoryLogs"
            element={
              <PrivateRoute>
                <InventoryLogs></InventoryLogs>
              </PrivateRoute>
            }
          />

          <Route
            path="/stockReports"
            element={
              <PrivateRoute>
                <StockReport></StockReport>
              </PrivateRoute>
            }
          />

          <Route
            path="/stockAnalysis"
            element={
              <PrivateRoute>
                <StockAnalysis></StockAnalysis>
              </PrivateRoute>
            }
          />

          <Route
            path="/returnReports"
            element={
              <PrivateRoute>
                <ReturnStockReport></ReturnStockReport>
              </PrivateRoute>
            }
          />

          <Route
            path="/supplierRcv"
            element={
              <PrivateRoute>
                <SupplierWiseRcv></SupplierWiseRcv>
              </PrivateRoute>
            }
          />

          <Route
            path="/vendorRcv"
            element={
              <PrivateRoute>
                <VendorWiseRcv></VendorWiseRcv>
              </PrivateRoute>
            }
          />

          <Route
            path="/voidList"
            element={
              <PrivateRoute>
                <VoidList></VoidList>
              </PrivateRoute>
            }
          />
          <Route
            path="/addVoid"
            element={
              <PrivateRoute>
                <AddVoid></AddVoid>
              </PrivateRoute>
            }
          />
          <Route
            path="/void/:id"
            element={
              <PrivateRoute>
                <EachVoid></EachVoid>
              </PrivateRoute>
            }
          />

          <Route path="*" element={<NotFoundPage></NotFoundPage>} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
