import React, { Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Spinner,
} from "react-bootstrap";
import { convertTime } from "../../../Services/ConvertDateTime";

const PurchaseWiseStockRcvReport = (props) => {
  return (
    <>
      {props.supplierWiseRcvList.data &&
      props.supplierWiseRcvList.data.products_data.length ? (
        props.supplierWiseRcvList.data.sub_total.map((purchase, i) => (
          <Fragment key={i}>
            <div className="my-2">
              <Row
                className="mx-0 py-2 table-header"
                style={{
                  borderTop: "1px solid #dee2e6",
                  borderLeft: "1px solid #dee2e6",
                  borderRight: "1px solid #dee2e6",
                  fontSize: "14px",
                }}
              >
                <Col lg={3}>
                  <b>Purchase ID: </b> {purchase.pop_id}
                </Col>
                <Col lg={2}>
                  <b> Reference: </b>{" "}
                  {purchase.reference ? purchase.reference : "N/A"}
                </Col>
                <Col lg={3}>
                  <b>Purchase Date: </b>
                  {purchase.purchase_date}
                </Col>
                <Col lg={2}>
                  <b> Purchase Time: </b> {purchase.purchase_time.slice(0, 8)}
                </Col>
                <Col lg={2}>
                  <b> Posted By: </b> {purchase.posted_by}
                </Col>
              </Row>
              {props.supplierWiseRcvList.data.supplier_list.map((supplier, j) =>
                supplier.supplier === purchase.supplier ? (
                  <Fragment key={j + 500}>
                    <Table className="my-0" responsive bordered>
                      <thead className="text-center report-table-header">
                        <tr>
                          <th className="text-start" colSpan={7}>
                            Supplier Name: {supplier.supplier}
                          </th>
                        </tr>
                        <tr>
                          <th>Category</th>
                          <th>Barcode</th>
                          <th>Product Information</th>
                          <th>CPU</th>
                          <th>Batch</th>
                          <th>Total Receive Qty</th>
                          <th>Total Value at Cost</th>
                        </tr>
                      </thead>
                      <tbody className="text-center report-table-body">
                        {props.supplierWiseRcvList.data.products_data.map(
                          (prod) => (
                            <tr key={prod.id}>
                              <td>{prod.product_category}</td>
                              <td>{prod.barcode ? prod.barcode : "N/A"}</td>
                              <td>
                                {prod.product_name} {prod.product_unit}{" "}
                                {prod.generic_name}
                              </td>
                              <td>{prod.cpu}</td>
                              <td>{prod.product_batch}</td>
                              <td>{prod.receive_qty}</td>
                              <td>{prod.value_at_cost}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </Fragment>
                ) : (
                  <></>
                )
              )}
            </div>
          </Fragment>
        ))
      ) : props.loading === true ? (
        <div className="mt-2 transfer-table">
          <Row className="p-2">
            <Col className="d-flex justify-content-center">
              <Spinner animation="border" variant="warning" />
            </Col>
          </Row>
        </div>
      ) : (
        <div className="mt-2 transfer-table">
          <Row className="p-2">
            <Col className="d-flex justify-content-center">No Data Found</Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default PurchaseWiseStockRcvReport;
