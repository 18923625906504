import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../Products/Product.css";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faTrash,
  faBarcode,
} from "@fortawesome/free-solid-svg-icons";
import WarehouseForm from "./WarehouseForm";
import { SuccessAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";
import {
  getWarehouseDetails,
  updateWarehouse,
} from "../../Redux/actions/warehouseActions";

const UpdateWarehouse = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const { loading, warehouse, error } = useSelector(
    (state) => state.warehouseDetails
  );
  const { updateLoading, message, updateError } = useSelector(
    (state) => state.updateWarehouse
  );

  const [destinationName, setDestinationName] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);

  useEffect(() => {
    dispatch(getWarehouseDetails(param.id));
  }, [dispatch, param.id]);

  useEffect(() => {
    if (warehouse && warehouse.id !== undefined) {
      setDestinationName(warehouse.name);
      setDestinationAddress(warehouse.address);
      setCity(warehouse.city);
      setContactNumber(warehouse.contact_number);
      setEmailAddress(warehouse.email);
      setCountry(warehouse.country);
      setZipcode(warehouse.zip_code);
      setDescription(warehouse.description);
      setType(warehouse.warehouse_status);
      setStatus(warehouse.active_status);
    }
  }, [warehouse]);

  useEffect(() => {
    if (alertMessage && message === undefined && updateError !== undefined) {
      console.log(updateError);
      alert("Something Went Wrong");
      setAlertMessage(false);
    }
    if (alertMessage && message !== undefined) {
      SuccessAlert("Destination Updated Successfully");
      setAlertMessage(false);
      dispatch(getWarehouseDetails(param.id));
    }
  }, [alertMessage, message, updateError]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const body = {
      name: destinationName,
      contact_number: contactNumber,
      email: emailAddress,
      address: destinationAddress,
      city: city,
      country: country,
      zip_code: zipcode,
      description: description,
      warehouse_status: type,
      active_status: status,
    };
    dispatch(updateWarehouse(warehouse.id, body));
    setAlertMessage(true);
  };

  return (
    <div>
      <SideBar />
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <h4 className="product-heading container-view">Destination Update</h4>
          <p className="product-paragraph container-view">
            Destination <FontAwesomeIcon icon={faChevronRight} size="xs" />{" "}
            Update Destination
          </p>
          <div className="mb-4 card product-card container-view">
            <WarehouseForm
              loading={updateLoading}
              destinationName={destinationName}
              setDestinationName={setDestinationName}
              destinationAddress={destinationAddress}
              setDestinationAddress={setDestinationAddress}
              contactNumber={contactNumber}
              setContactNumber={setContactNumber}
              emailAddress={emailAddress}
              setEmailAddress={setEmailAddress}
              city={city}
              setCity={setCity}
              country={country}
              setCountry={setCountry}
              zipcode={zipcode}
              setZipcode={setZipcode}
              description={description}
              setDescription={setDescription}
              status={status}
              setStatus={setStatus}
              type={type}
              setType={setType}
              handleSubmitForm={handleSubmitForm}
            />
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default UpdateWarehouse;
