import {
  PRODUCT_BIOGRAPHY_REQUEST,
  PRODUCT_BIOGRAPHY_SUCCESS,
  PRODUCT_BIOGRAPHY_FAILURE,
  INVENTORY_LIST_REQUEST,
  INVENTORY_LIST_SUCCESS,
  INVENTORY_LIST_FAILURE,
  CREATE_PHYSICAL_INVENTORY_REQUEST,
  CREATE_PHYSICAL_INVENTORY_SUCCESS,
  CREATE_PHYSICAL_INVENTORY_FAILURE,
  PHYSICAL_INVENTORY_LIST_REQUEST,
  PHYSICAL_INVENTORY_LIST_SUCCESS,
  PHYSICAL_INVENTORY_LIST_FAILURE,
  PHYSICAL_INVENTORY_UPDATE_REQUEST,
  PHYSICAL_INVENTORY_UPDATE_SUCCESS,
  PHYSICAL_INVENTORY_UPDATE_FAILURE,
  INVENTORY_LOG_LIST_REQUEST,
  INVENTORY_LOG_LIST_SUCCESS,
  INVENTORY_LOG_LIST_FAILURE,
} from "../type";

export const productBiographyReducers = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_BIOGRAPHY_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_BIOGRAPHY_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_BIOGRAPHY_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const inventoryListReducers = (
  state = { inventoryList: {} },
  action
) => {
  switch (action.type) {
    case INVENTORY_LIST_REQUEST:
      return { loading: true, ...state };
    case INVENTORY_LIST_SUCCESS:
      return { loading: false, inventoryList: action.payload };
    case INVENTORY_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createPhysicalInventoryReducers = (
  state = { createInventory: {} },
  action
) => {
  switch (action.type) {
    case CREATE_PHYSICAL_INVENTORY_REQUEST:
      return { createLoading: true, ...state };
    case CREATE_PHYSICAL_INVENTORY_SUCCESS:
      return { createLoading: false, createInventory: action.payload };
    case CREATE_PHYSICAL_INVENTORY_FAILURE:
      return { createLoading: false, createError: action.payload };
    default:
      return state;
  }
};

export const physicalInventoryListReducers = (
  state = { physicalInventoryList: {} },
  action
) => {
  switch (action.type) {
    case PHYSICAL_INVENTORY_LIST_REQUEST:
      return { Loading: true, ...state };
    case PHYSICAL_INVENTORY_LIST_SUCCESS:
      return { Loading: false, physicalInventoryList: action.payload };
    case PHYSICAL_INVENTORY_LIST_FAILURE:
      return { Loading: false, error: action.payload };
    default:
      return state;
  }
};

export const physicalInventoryUpdateReducers = (state = {}, action) => {
  switch (action.type) {
    case PHYSICAL_INVENTORY_UPDATE_REQUEST:
      return { updateLoading: true, ...state };
    case PHYSICAL_INVENTORY_UPDATE_SUCCESS:
      return { updateLoading: false, message: action.payload };
    case PHYSICAL_INVENTORY_UPDATE_FAILURE:
      return { updateLoading: false, updateError: action.payload };
    default:
      return state;
  }
};

export const inventoryLogListReducers = (
  state = { inventoryLogList: {} },
  action
) => {
  switch (action.type) {
    case INVENTORY_LOG_LIST_REQUEST:
      return { loading: true, ...state };
    case INVENTORY_LOG_LIST_SUCCESS:
      return { loading: false, inventoryLogList: action.payload };
    case INVENTORY_LOG_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
