import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideBar from "../SideBar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Col,
  Container,
  Row,
  Table,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import "../Products/Product.css";
import "../Transfer/transfer.css";
import { SuccessAlert } from "../notify/notify";
import { ToastContainer } from "react-toastify";
import calender from "../../Assets/icons/calender.png";
import cancel from "../../Assets/icons/cancel.png";
import { getSupplierList } from "../../Redux/actions/supplierActions";
import {
  poProductFilterAction,
  createPurchaseOrder,
} from "../../Redux/actions/purchaseOrderActions";
import { getVendorList } from "../../Redux/actions/vendorActions";
import "../Return/Return.css";
import "./Purchase.css";
import PoPreviewModal from "../PopupModal/PoPreviewModal";

const AddPurchaseOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { supplierList } = useSelector((state) => state.supplierList);
  const { vendorList } = useSelector((state) => state.vendorList);
  const { loading, productFilterList, error } = useSelector(
    (state) => state.poProductFilter
  );
  const { createLoading, message, createError } = useSelector(
    (state) => state.createPurchaseOrder
  );

  const [filterDateButton, setFilterDateButton] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [supplierId, setSupplierId] = useState("");
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [vendorId, setVendorId] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [sortLowToHigh, setSortLowToHigh] = useState(false);
  const [sortHighToLow, setSortHighToLow] = useState(false);
  const [addedProduct, setAddedProduct] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getSupplierList(500, 0));
  }, []);

  useEffect(() => {
    if (showResults) {
      dispatch(getVendorList(500, 0));
    }
  }, [showResults]);

  useEffect(() => {
    if (productFilterList && productFilterList.data) {
      setPurchaseOrderList(
        productFilterList.data.map((product) => ({
          ...product,
          order_quantity: addedProduct.filter((prod) => prod.id === product.id)
            .length
            ? addedProduct.filter((prod) => prod.id === product.id)[0]
                .order_quantity
            : 0,
          max_quentity: 0,
        }))
      );
    }
  }, [productFilterList]);

  useEffect(() => {
    if (searchProduct.length) {
      applyParams();
      //console.log("inside");
    }
  }, [searchProduct]);

  useEffect(() => {
    if (alertMessage && message === undefined && createError !== undefined) {
      //console.log(createError);
      setAlertMessage(false);
      if (createError.message) {
        alert(createError.message);
      } else if (createError.Warning) {
        alert(createError.Warning);
      } else {
        alert("Something Went Wrong");
      }
    }
    if (alertMessage && message !== undefined) {
      //console.log(message);
      setPurchaseOrderList([]);
      setFromDate("");
      setToDate("");
      setSupplierId("");
      setVendorId("");
      setSearchProduct("");
      setSelectAll(false);
      SuccessAlert(
        `New Purchase Order Created Successfully. PO ID: ${message.data.po_id}`
      );
      setAlertMessage(false);
      setShowResults(false);
      setTimeout(() => {
        navigate(`/purchaseOrder/${message.data.id}`);
      }, 2500);
    }
  }, [alertMessage, message, createError]);

  // Key Press Select
  const [suggestionSelect, setSuggestionSelect] = useState(null);
  const containerRef = useRef(null);
  const [childOffsets, setChildOffsets] = useState([]);
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [suggestionSelect, inputRef]);

  useEffect(() => {
    if (showResults && supplierId && purchaseOrderList.length > 0) {
      //console.log("containerRef = ", containerRef.current);
      const childDivs = Array.from(containerRef.current?.children);
      //console.log("childDivs = ", childDivs);
      const offsets = childDivs.map((child) => child.offsetTop);
      //console.log("offsets", offsets);
      setChildOffsets(offsets);
    }
  }, [purchaseOrderList, supplierId, showResults]);

  const searchInputSuggestionHendler = (e) => {
    //console.log("test");
    if (supplierId && purchaseOrderList.length !== 0) {
      if (e.key === "q") {
        //console.log("In searchInputSuggestionHndler, you pressed = ", e.key);
        if (suggestionSelect > 0) {
          setSuggestionSelect((previousState) => previousState - 1);
          containerRef.current.scrollTo({
            top: childOffsets[suggestionSelect - 1],
            behavior: "smooth",
          });
        } else {
          setSuggestionSelect(0);
        }
      }
      if (e.key === "a") {
        if (suggestionSelect || suggestionSelect === 0) {
          //console.log("if");
          if (purchaseOrderList.length > suggestionSelect + 1) {
            //console.log("if if");
            //console.log("inside-select", suggestionSelect);
            setSuggestionSelect((previousState) => previousState + 1);
            containerRef.current.scrollTo({
              top: childOffsets[suggestionSelect],
              behavior: "smooth",
            });
          }
        } else {
          //console.log("else");
          setSuggestionSelect(0);
        }
      }
    }
  };
  const handleGenerateReport = () => {
    if (supplierId) {
      setShowResults(true);
      //console.log("inside");
      if (fromDate.length && toDate.length) {
        dispatch(
          poProductFilterAction(
            `/?from_date=${fromDate}&to_date=${toDate}`,
            supplierId
          )
        );
      } else {
        dispatch(poProductFilterAction("/", supplierId));
      }
    } else {
      alert("Select Supplier To Generate Product List");
    }
  };
  const applyParams = () => {
    if (vendorId !== "") {
      setShowLoading(false);
      if (searchProduct !== "" && fromDate !== "" && toDate !== "") {
        dispatch(
          poProductFilterAction(
            `/?vendor_id=${vendorId}&from_date=${fromDate}&to_date=${toDate}&product_name=${searchProduct}`,
            supplierId
          )
        );
      } else if (searchProduct !== "") {
        dispatch(
          poProductFilterAction(
            `/?vendor_id=${vendorId}&product_name=${searchProduct}`,
            supplierId
          )
        );
      } else if (fromDate !== "" && toDate !== "") {
        dispatch(
          poProductFilterAction(
            `/?vendor_id=${vendorId}&from_date=${fromDate}&to_date=${toDate}`,
            supplierId
          )
        );
      } else {
        dispatch(poProductFilterAction(`/?vendor_id=${vendorId}`, supplierId));
      }
    } else if (vendorId === "") {
      setShowLoading(false);
      if (searchProduct !== "" && fromDate !== "" && toDate !== "") {
        dispatch(
          poProductFilterAction(
            `/?product_name=${searchProduct}&from_date=${fromDate}&to_date=${toDate}`,
            supplierId
          )
        );
      } else if (searchProduct !== "") {
        dispatch(
          poProductFilterAction(`/?product_name=${searchProduct}`, supplierId)
        );
      } else if (fromDate !== "" && toDate !== "") {
        dispatch(
          poProductFilterAction(
            `/?from_date=${fromDate}&to_date=${toDate}`,
            supplierId
          )
        );
      } else {
        alert("Please Select Something to Apply");
      }
    }
  };
  const updateProductOrderQty = (event, id) => {
    setPurchaseOrderList(
      purchaseOrderList.map((product) =>
        product.id === id
          ? {
              ...product,
              order_quantity:
                event.target.value.length === 0
                  ? ""
                  : event.target.value >= 0
                  ? parseInt(event.target.value)
                  : product.order_quantity,
            }
          : product
      )
    );
  };
  const updateProductMaxQty = (event, id) => {
    setPurchaseOrderList(
      purchaseOrderList.map((product) =>
        product.id === id
          ? {
              ...product,
              max_quentity:
                event.target.value.length === 0
                  ? 0
                  : event.target.value >= 0
                  ? parseInt(event.target.value)
                  : product.max_quentity,
            }
          : product
      )
    );
  };
  const changeSoldQtyToOrderQty = () => {
    if (!selectAll) {
      setSelectAll(true);
      setPurchaseOrderList(
        purchaseOrderList.map((product) => ({
          ...product,
          order_quantity: product.sold_quantity,
        }))
      );
    } else {
      setSelectAll(false);
      setPurchaseOrderList(
        purchaseOrderList.map((product) => ({
          ...product,
          order_quantity: 0,
        }))
      );
    }
  };
  const submitPurchaseOrder = () => {
    setPreviewOpen(false);
    const body = selectAll
      ? purchaseOrderList.filter((purchase) => purchase.order_quantity > 0)
      : addedProduct.filter((purchase) => purchase.order_quantity > 0);
    const bodyData = body.map((p) => ({
      id: p.id,
      order_quantity: p.order_quantity,
      max_quentity: p.max_quentity,
      purchase_price: p.new_purchase_price
        ? p.new_purchase_price
        : p.purchase_price,
    }));
    //console.log(bodyData);
    if (bodyData.length) {
      dispatch(createPurchaseOrder(supplierId, vendorId, bodyData));
      setAlertMessage(true);
    } else {
      alert("Please Add PO Quantity");
    }
  };
  const sortDataLowToHigh = () => {
    setSortLowToHigh(true);
    setSortHighToLow(false);
    purchaseOrderList.sort((a, b) => a.sold_quantity - b.sold_quantity);
  };
  const sortDataHighToLow = () => {
    setSortLowToHigh(false);
    setSortHighToLow(true);
    purchaseOrderList
      .sort((a, b) => a.sold_quantity - b.sold_quantity)
      .reverse();
  };

  //console.log("p", purchaseOrderList);
  //console.log("a", addedProduct);

  return (
    <div>
      <PoPreviewModal
        show={previewOpen}
        onHide={() => setPreviewOpen(false)}
        select={selectAll ? "true" : "false"}
        purchaseorderlist={purchaseOrderList.filter(
          (purchase) => purchase.order_quantity > 0
        )}
        addedproduct={addedProduct.filter(
          (purchase) => purchase.order_quantity > 0
        )}
        action={() => submitPurchaseOrder()}
        supplierId={supplierId}
      />
      <SideBar></SideBar>
      <div className="mainContent">
        <Header></Header>
        <ToastContainer></ToastContainer>
        <Container fluid>
          <h4 className="product-heading container-view">Purchase Order Add</h4>
          <p className="product-paragraph container-view">
            {" "}
            Purchase <FontAwesomeIcon icon={faChevronRight} size="xs" /> Add
            Purchase Order
          </p>
          <div
            className="card container-view"
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
          >
            <Row className="mb-4 pt-3" style={{ backgroundColor: "#DCE0E4" }}>
              <Col lg={3}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    Select Supplier
                    <span className="required-field">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => {
                      setSupplierId(e.target.value);
                      setShowResults(false);
                      setAddedProduct([]);
                    }}
                    value={supplierId}
                  >
                    <option value="">Select Supplier</option>
                    {supplierList.data?.results.map((supplier) => (
                      <option key={supplier.id} value={supplier.id}>
                        {supplier.supplier_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group
                  className=" form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    S. Id<span></span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Id"
                    value={supplierId}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group
                  className="form-group mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>From Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="From Date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    style={{ fontSize: "14px" }}
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group
                  className="form-group  mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="To Date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    style={{ fontSize: "14px" }}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Button
                  className="report-btn border-0"
                  onClick={() => {
                    setVendorId("");
                    setSearchProduct("");
                    handleGenerateReport();
                  }}
                >
                  Generate
                </Button>
              </Col>
            </Row>
            {showResults && supplierId ? (
              <div
                className="mt-3 transfer-table"
                //onKeyDown={(e) => searchInputSuggestionHendler(e)}
              >
                <Row
                  className="mx-0 pt-3 pb-2 table-header"
                  style={{ borderBottom: "1px solid #dee2e6" }}
                >
                  <Col lg={6} xl={4}>
                    <Form.Group
                      className="d-flex gap-3 mb-3"
                      controlId="formBasicEmail"
                    >
                      <img
                        className="d-none"
                        src={filterDateButton ? cancel : calender}
                        alt="calender"
                        height={45}
                        style={{ cursor: "pointer" }}
                        onClick={() => setFilterDateButton(!filterDateButton)}
                      />
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => setVendorId(e.target.value)}
                        value={vendorId}
                      >
                        <option value="">Select Vendor</option>
                        {vendorList.data?.results.map((vendor) => (
                          <option key={vendor.id} value={vendor.id}>
                            {vendor.vendor_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xl={1}>
                    <Form.Group
                      className="form-group mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="number"
                        placeholder="Id"
                        value={vendorId}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={5} xl={3}>
                    <Form.Group className="form-group mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Search By Product Name or Generic Name"
                        value={searchProduct}
                        onChange={(e) => {
                          setSearchProduct(e.target.value);
                          if (!e.target.value.length) {
                            if (vendorId === "") {
                              handleGenerateReport();
                            } else if (vendorId !== "") {
                              console.log("check");
                              dispatch(
                                poProductFilterAction(
                                  `/?vendor_id=${vendorId}`,
                                  supplierId
                                )
                              );
                            }
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3} xl={2}>
                    <Button
                      className="border-0"
                      style={{
                        height: "45px",
                        width: "100%",
                        backgroundColor: "#ff9900",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        setShowLoading(true);
                        applyParams();
                        setAddedProduct([]);
                      }}
                    >
                      Apply
                    </Button>
                  </Col>
                  <Col
                    lg={4}
                    xl={2}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Form.Check
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      label="Treat Sold Qty As PO Qty"
                      onChange={changeSoldQtyToOrderQty}
                      checked={selectAll}
                    />
                  </Col>
                </Row>
                {filterDateButton ? (
                  <Row className="d-none mx-0 py-2 table-header">
                    <Col lg={2} md={4} sm={6}>
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="report-form-label">
                          From Date
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="From Date"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="report-form-label">
                          To Date
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="To Date"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                {showLoading ? (
                  <Row className="mt-2">
                    <Col
                      xl={4}
                      lg={12}
                      className="d-flex justify-content-center"
                    >
                      <Spinner animation="border" variant="warning" />
                    </Col>
                  </Row>
                ) : (
                  <div
                    className="table-container"
                    onKeyDown={(e) => searchInputSuggestionHendler(e)}
                  >
                    <Table bordered hover style={{ fontSize: "14px" }}>
                      <thead>
                        <tr>
                          <th>SL No.</th>
                          <th>Product Information</th>
                          <th>Total Stock</th>
                          <th>PO Quantity</th>
                          <th>
                            Sold
                            <FontAwesomeIcon
                              icon={faArrowUp}
                              size="sm"
                              style={{
                                paddingLeft: "0.3rem",
                                cursor: "pointer",
                                opacity: !sortHighToLow ? "100%" : "50%",
                              }}
                              onClick={() =>
                                !sortHighToLow ? sortDataHighToLow() : ""
                              }
                            />
                            <FontAwesomeIcon
                              icon={faArrowDown}
                              size="sm"
                              style={{
                                paddingLeft: "0.2rem",
                                cursor: "pointer",
                                opacity: !sortLowToHigh ? "100%" : "50%",
                              }}
                              onClick={() =>
                                !sortLowToHigh ? sortDataLowToHigh() : ""
                              }
                            />
                          </th>
                          <th>Max. Order</th>
                          <th>CPU</th>
                          <th>RPU</th>
                          <th>Pack Size</th>
                        </tr>
                      </thead>
                      <tbody className="" ref={containerRef}>
                        {supplierId &&
                        purchaseOrderList.length > 0 &&
                        !loading ? (
                          purchaseOrderList.map((product, i) => (
                            <tr
                              key={product.id}
                              className={`${
                                suggestionSelect !== null &&
                                suggestionSelect === i
                                  ? "selectedSearchItemHover"
                                  : ""
                              }`}
                            >
                              <td>{i + 1}</td>
                              <td>
                                {product.product_name}
                                {" - "}
                                {product.unit}
                                {" - "}
                                {product.sub_category}
                              </td>
                              <td>{product.stock}</td>
                              <td>
                                <input
                                  className="table-data-input-field"
                                  ref={
                                    suggestionSelect !== null &&
                                    suggestionSelect === i
                                      ? inputRef
                                      : null
                                  }
                                  type="number"
                                  min={0}
                                  value={product.order_quantity}
                                  onFocus={() => {
                                    if (product.order_quantity === 0) {
                                      setPurchaseOrderList(
                                        purchaseOrderList.map((p) =>
                                          p.id === product.id
                                            ? {
                                                ...p,
                                                order_quantity: "",
                                              }
                                            : p
                                        )
                                      );
                                    }
                                  }}
                                  onChange={(event) => {
                                    updateProductOrderQty(event, product.id);
                                    setAddedProduct((prevState) => {
                                      const updatedProducts = [...prevState];
                                      const productIndex =
                                        updatedProducts.findIndex(
                                          (prod) => prod.id === product.id
                                        );

                                      if (productIndex !== -1) {
                                        {
                                          updatedProducts[
                                            productIndex
                                          ].order_quantity = parseInt(
                                            event.target.value
                                          );
                                        }
                                      } else {
                                        updatedProducts.push({
                                          ...product,
                                          order_quantity:
                                            event.target.value.length === 0
                                              ? 0
                                              : event.target.value >= 0
                                              ? parseInt(event.target.value)
                                              : product.order_quantity,
                                        });
                                      }

                                      return updatedProducts;
                                    });
                                  }}
                                />
                              </td>
                              <td>{product.sold_quantity}</td>
                              <td>
                                {/* <input
                                className="table-data-input-field"
                                type="number"
                                min={0}
                                value={product.max_quentity}
                                onChange={(event) => {
                                  updateProductMaxQty(event, product.id);
                                }}
                              /> */}
                                {product.max_quentity}
                              </td>
                              <td>
                                {product.new_purchase_price
                                  ? product.new_purchase_price
                                  : product.purchase_price}
                              </td>
                              <td>
                                {product.new_product_price
                                  ? product.new_product_price
                                  : product.product_price}
                              </td>
                              <td>
                                {product.pack_size ? product.pack_size : 1}
                              </td>
                            </tr>
                          ))
                        ) : loading ? (
                          <tr className="text-center">
                            <td colSpan="15">
                              <Spinner animation="border" variant="warning" />
                            </td>
                          </tr>
                        ) : (
                          <tr className="text-center">
                            <td colSpan="12">No Product Found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </div>
            ) : (
              <div className="mt-3 d-flex justify-content-center">
                <p style={{ fontWeight: 700, fontSize: "16px" }}>
                  Select A Supplier To Show Product List
                </p>
              </div>
            )}

            {createLoading === true ? (
              <Row className="mt-2">
                <Col xl={4} lg={12} className="d-flex justify-content-center">
                  <Spinner animation="border" variant="warning" />
                </Col>
              </Row>
            ) : (
              <></>
            )}
            {showResults && supplierId ? (
              <Row className="my-5">
                <Col xl={2} lg={4} className="mb-2">
                  {createLoading === true ? (
                    <Button
                      className="disable-submit-btn"
                      variant="secondary"
                      disabled
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      className="submit-btn border-0"
                      onClick={submitPurchaseOrder}
                    >
                      Submit
                    </Button>
                  )}
                </Col>
                <Col xl={2} lg={4} className="mb-2">
                  <Button
                    className="preview-btn border-0"
                    onClick={() => setPreviewOpen(true)}
                  >
                    Preview
                  </Button>
                </Col>
                <Col xl={2} lg={4}>
                  <Button
                    className="cancel-btn border-0"
                    //onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AddPurchaseOrder;
