import {
  RETURN_STOCK_REPORT_REQUEST,
  RETURN_STOCK_REPORT_SUCCESS,
  RETURN_STOCK_REPORT_FAILURE,
} from "../type.js";
import axios from "axios";

export const getReturnStockReport = (keyword) => async (dispatch) => {
  try {
    dispatch({
      type: RETURN_STOCK_REPORT_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/report/v1/stock_return/${keyword}`,
      config
    );

    dispatch({
      type: RETURN_STOCK_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RETURN_STOCK_REPORT_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
