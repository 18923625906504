import { Col } from "react-bootstrap";
import CountUp from "react-countup";

const TotalCard = ({ title, icon, value = 0, backgroundColor = "#FF9900" }) => {
  return (
    <Col xl={4}>
      <div className="dashborad-card" style={{ background: backgroundColor }}>
        <div className="d-flex justify-content-between p-3">
          <div className="dashboard-color-card-para">
            <p className="text-white dashboard-card-text">{title}</p>
            <span className="text-white dashboard-card-count">
              <CountUp start={0} end={value} duration={1} separator="," />
            </span>
          </div>
          <img className="dashboard-card-image" src={icon} alt={title} />
        </div>
      </div>
    </Col>
  );
};

export default TotalCard;
