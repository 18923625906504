import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getAllSupplierStockReport } from "../../../Redux/actions/stockReportActions";

const AllSupplierStock = (props) => {
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (props.allSupplierStockList?.data) {
      setTotalItems(props.allSupplierStockList.data.count);
    }
  }, [props.allSupplierStockList]);
  const pageCount = Math.ceil(totalItems / props.limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.limit) % totalItems;
    dispatch(
      getAllSupplierStockReport(props.limit, newOffset, props.productType)
    );
  };
  return (
    <>
      <Table className="mt-3" responsive bordered>
        <thead className="text-center report-table-header">
          <tr>
            <th rowSpan={2} style={{ verticalAlign: "middle" }}>
              Supplier Name
            </th>
            <th colSpan={2}>Received</th>
            <th colSpan={3}>Stock Return</th>
            <th colSpan={3}>Sold</th>
            <th colSpan={3}>Exchange/Void</th>
            <th colSpan={3}>Balance</th>
          </tr>
          <tr>
            <th>Qty</th>
            <th>Value(Cost)</th>
            <th>Qty</th>
            <th>Value(Cost)</th>
            <th>Return Value</th>
            <th>Qty</th>
            <th>Value(Cost)</th>
            <th>Sale Value</th>
            <th>Qty</th>
            <th>Value(Cost)</th>
            <th>Sale Value</th>
            <th>Qty</th>
            <th>Value(Cost)</th>
            <th>Sale Value</th>
          </tr>
        </thead>
        <tbody className="text-center report-table-body">
          {props.allSupplierStockList.data && !props.allSupplierStockLoading ? (
            <>
              {props.allSupplierStockList.data.results.map((prod, i) => (
                <tr key={i}>
                  <td className="text-start">{prod.supplier_name}</td>
                  <td>{prod.received_quantity}</td>
                  <td>{prod.received_cost}</td>
                  <td>{prod.return_quantity}</td>
                  <td>{prod.return_cost}</td>
                  <td>{prod.return_price}</td>
                  <td>{prod.sold_quantity}</td>
                  <td>{prod.sold_cost}</td>
                  <td>{prod.sold_price}</td>
                  <td>{prod.exchange_quantity}</td>
                  <td>{prod.exchange_cost}</td>
                  <td>{prod.exchange_price}</td>
                  <td>{prod.blance_quantity}</td>
                  <td>{prod.blance_cost}</td>
                  <td>{prod.blance_price}</td>
                </tr>
              ))}
              <tr className="grand-total-row">
                <td className="text-start">Grand Total</td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.received_quantity),
                    0
                  )}
                </td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.received_cost),
                    0
                  )}
                </td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.return_quantity),
                    0
                  )}
                </td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.return_cost),
                    0
                  )}
                </td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.return_price),
                    0
                  )}
                </td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.sold_quantity),
                    0
                  )}
                </td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.sold_cost),
                    0
                  )}
                </td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.sold_price),
                    0
                  )}
                </td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.exchange_quantity),
                    0
                  )}
                </td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.exchange_cost),
                    0
                  )}
                </td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.exchange_price),
                    0
                  )}
                </td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.blance_quantity),
                    0
                  )}
                </td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.blance_cost),
                    0
                  )}
                </td>
                <td>
                  {props.allSupplierStockList.data.results.reduce(
                    (total, prod) => (total += prod.blance_price),
                    0
                  )}
                </td>
              </tr>
            </>
          ) : props.allSupplierStockLoading ? (
            <tr>
              <td colSpan={12}>
                <Spinner animation="border" variant="warning" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={12}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
      {pageCount > 1 && !props.allSupplierStockLoading ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousClassName="hide"
          nextClassName="hide"
          activeLinkClassName="active"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AllSupplierStock;
