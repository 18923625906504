import {
  SUBCATEGORY_LIST_REQUEST,
  SUBCATEGORY_LIST_SUCCESS,
  SUBCATEGORY_LIST_FAILURE,
  SUBCATEGORY_DELETE_REQUEST,
  SUBCATEGORY_DELETE_SUCCESS,
  SUBCATEGORY_DELETE_FAILURE,
  CREATE_SUBCATEGORY_REQUEST,
  CREATE_SUBCATEGORY_SUCCESS,
  CREATE_SUBCATEGORY_FAILURE,
  SUBCATEGORY_UPDATE_REQUEST,
  SUBCATEGORY_UPDATE_SUCCESS,
  SUBCATEGORY_UPDATE_FAILURE,
  SEARCH_SUBCATEGORY_LIST_REQUEST,
  SEARCH_SUBCATEGORY_LIST_SUCCESS,
  SEARCH_SUBCATEGORY_LIST_FAILURE,
} from "../type.js";

export const subcategoryListReducer = (
  state = { subcategoryList: {} },
  action
) => {
  switch (action.type) {
    case SUBCATEGORY_LIST_REQUEST:
      return { loading: true, ...state };
    case SUBCATEGORY_LIST_SUCCESS:
      return { loading: false, subcategoryList: action.payload };
    case SUBCATEGORY_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteSubcategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBCATEGORY_DELETE_REQUEST:
      return { loading: true };
    case SUBCATEGORY_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case SUBCATEGORY_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createSubcategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_SUBCATEGORY_REQUEST:
      return { createLoading: true };
    case CREATE_SUBCATEGORY_SUCCESS:
      return {
        createLoading: false,
        message: action.payload,
      };
    case CREATE_SUBCATEGORY_FAILURE:
      return {
        createLoading: false,
        createError: action.payload,
      };
    default:
      return state;
  }
};

export const updateSubcategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBCATEGORY_UPDATE_REQUEST:
      return { loading: true };
    case SUBCATEGORY_UPDATE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case SUBCATEGORY_UPDATE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const searchSubcategoryListReducer = (
  state = { searchSubcategoryList: {} },
  action
) => {
  switch (action.type) {
    case SEARCH_SUBCATEGORY_LIST_REQUEST:
      return { searchLoading: true, ...state };
    case SEARCH_SUBCATEGORY_LIST_SUCCESS:
      return { searchLoading: false, searchSubcategoryList: action.payload };
    case SEARCH_SUBCATEGORY_LIST_FAILURE:
      return { searchLoading: false, searchError: action.payload };
    default:
      return state;
  }
};
